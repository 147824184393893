import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auto } from '../auto';
import { log } from '../common/dev/log';
import { Feed, FeedService } from '../feed';

@Component({
    selector: "mv-603-letters",
    templateUrl: "./mv-603-letters.component.html",
    styleUrls: ['./single-print.component.css']
})

export class MV603LettersComponent implements OnInit {

    currentAuto: Auto;

    fields = {
        CurrentDate: new Date(),
        TagTagState: '',
        YearMakeModel: '',
        VIN: '',
        TitleNumber: '',
        TotalDue: '',
        CompanyName: '',
        CompanyAddress: '',
        CompanyCityStateZip: '',
        RemovalStreetCity: '',
        RecoveryDate: new Date(),
        AbandonedDate: ''
    }

    constructor (
        private route: ActivatedRoute,
        private FeedService: FeedService,
    ){}
    ngOnInit(): void {
        this.route.data.subscribe((data: {
            isBlank: boolean,
            currentAuto: Auto,
            settings: {}
        }) => {
            log.Debug("data: ", data)

            if(!data.isBlank) {

                this.currentAuto = data.currentAuto;
                this.fields.TagTagState = this.getTagTagState(data.currentAuto.Tag, data.currentAuto.State);
                this.fields.YearMakeModel = this.getYearMakeModel(data.currentAuto.AutoYear, data.currentAuto.Make.Name, data.currentAuto.AutoModel.Name);
                this.fields.VIN = data.currentAuto.Vin || '';
                this.fields.TitleNumber = 'unsure';
                this.fields.TotalDue = data.currentAuto.PriceOut.toString() || '';
                this.fields.CompanyName = data.settings['Company Name'] || '';
                this.fields.CompanyAddress = data.settings['Company Address'] || '';
                this.fields.CompanyCityStateZip = data.settings['Company City'] + ', ' + data.settings['Company State'] + ' '
                    + data.settings['Company Zip'] || '';
                this.fields.RemovalStreetCity = this.getRemovalStreetCity(data.currentAuto.RemovalStreet, data.currentAuto.RemovalCity);
                this.fields.RecoveryDate = new Date(data.currentAuto.DateIn)
                this.fields.AbandonedDate =  'unsure' //this._months[this.moment.getMonth(abandonedDate)] + ' ' + this.moment.getDate(abandonedDate) + ', ' + this.moment.getYear(abandonedDate) || '';

            }
        });
    }

    getYearMakeModel(year: string, make: string, model: string): string {
        if( !this.empty(year) ) {
            return year + ' ' + make + ' ' + model;
        }
        return make + ' ' + model;
    }

    getTagTagState(tag: string, state: string): string {

        let tagValid = !this.empty(tag);
        let stateValid = !this.empty(state);

        if( tagValid && stateValid ) {
            return tag + ' ' + state;
        } else if( !tagValid && stateValid ) {
            return state;
        } else if ( tagValid && !stateValid ) {
            return tag;
        }

        return ''
    }

    getRemovalStreetCity( street: string, city: string ): string {

        let streetValid = !this.empty(street);
        let cityValid = !this.empty(city);

        if( streetValid && cityValid ) {
            return street + ', ' + city;
        } else if( !streetValid && cityValid ) {
            return city;
        } else if( streetValid && !cityValid ) {
            return street;
        }

        return '';
    }

    getCityStateZip(city: string, state: string, zip: string): string {

        let cityValid = !this.empty(city);
        let stateValid = !this.empty(state);
        let zipValid = !this.empty(zip);

        if( cityValid && stateValid && zipValid ) {
            return city + ', ' + state + ' ' + zip;
        } else if( cityValid && stateValid && !zipValid ) {
            return city + ', ' + state;
        } else if( !cityValid && stateValid && zipValid ) {
            return state + ' ' + zip;
        } else if(!cityValid && stateValid && !zipValid ) {
            return state;
        } else if( cityValid && !stateValid && zipValid ) {
            return city + ' ' + zip;
        } else if( cityValid && !stateValid &&  !zipValid ) {
            return city;
        } else if( !cityValid && !stateValid && zipValid ) {
            return zip;
        }

        return '';

    }

    empty(str: string): boolean {
        if( '' != str && null != str && undefined != str ) {
            return false;
        }
        return true;
    }

    print(): void {
        //Change to note maybe?
        this.FeedService.save(new Feed({ AutoID: this.currentAuto.ID, Description: "MV603 Letters Printed", Created: new Date() })).subscribe(
            resp => log.Debug("resp: ", resp)
        )
        window.print();
    }
}
