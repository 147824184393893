import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from '../common/dev/log';
import { Auto } from './auto';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AutoService } from './auto.service';
import { AppService } from '../app.service';

export interface Button {
    name: string;
    icon: string;
    text: string;
}

@Component({
    selector: "mark-t158-autos",
    templateUrl: "./mark-t158-autos.component.html"
})

export class MarkT158AutosComponent implements OnInit {

    title: string;
    autos: Auto[];
    button: Button;

    form: FormGroup;

    constructor (
        private route: ActivatedRoute,
        private as: AutoService,
        private apps: AppService,

    ){
        this.form = this.createFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            button: Button,
            autos: Auto[]
        }) => {
            log.Debug("data: ", data)
            this.title = data.title;
            this.button = data.button
            this.autos = data.autos
            for (let a in this.autos) {
                this.addAuto();
            }
            this.form.get('Autos').patchValue(this.autos);
        })
    }

    createFormGroup() {
        return new FormGroup({
            Autos: new FormArray([]),
            CheckAll: new FormControl()
        })
    }

    createAutoFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            IsT158: new FormControl(),
        })
    }

    addAuto(): void {
        let autos = this.form.get('Autos') as FormArray;
        autos.push(this.createAutoFormGroup());
    }

    checkAll(e): void {
        //e.preventDefault()
        log.Debug("value: ", this.form.get('CheckAll').value)
        let value = this.form.get('CheckAll').value

        if (value == null || !value) {
            value = true
        } else {
             value = false
        }

        log.Debug("value after: ", value)

        let autos = this.form.get('Autos') as FormArray
        for (let i in autos.controls) {
            autos.controls[i].patchValue({IsT158: value})
        }
    }

    onSubmit(e): void {

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched: ", this.form)
            return;
        }

        this.save();
    }

    save() {
        this.as.markAsT158(this.form.get('Autos').value)
        .subscribe((savedAutos: Auto[]) => {
            this.apps.Confirmation('Autos updated.');
            this.form.markAsPristine();

            log.Debug("savedAutos: ", savedAutos)
            this.form.get('Autos').patchValue(savedAutos);
            this.autos = savedAutos
        })
    }
}