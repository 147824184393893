import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DeleteResponse } from '../common/delete-response';
import { StatusDescription } from './status-description';

@Injectable({
    providedIn: 'root'
})

export class StatusDescriptionService extends HttpService implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    GetAll(): Observable<StatusDescription[]> {
        return this.http.get<StatusDescription[]>('/api/status_descriptions/all').pipe(map(this.toStatusDescription));
    }

    toStatusDescription(statusDescription) {
        const temp = statusDescription.map( statusDescription => {
            return new StatusDescription(statusDescription);
        });
        return temp;
    }

    save(statusDescriptions: StatusDescription[]): Observable<StatusDescription[]> {
        return this.http.put<StatusDescription[]>('/api/status_descriptions/update_all', statusDescriptions);
    }

    delete(statusDescription: StatusDescription): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/status_descriptions/delete/' + statusDescription.ID)
    }

}