import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from '../common/dev/log';
import { Officer } from './officer';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { OfficerService } from './officer.service';
import { AppService } from '../app.service'

@Component({
    selector: "manage-officers",
    templateUrl: "./manage-officers.component.html"
})

export class ManageOfficersComponent implements OnInit {

    title: string;
    officers: Officer[];

    form: FormGroup;

    constructor (
        private route: ActivatedRoute,
        private os: OfficerService,
        private apps: AppService,

    ){
        this.form = this.createFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            officers: Officer[]
        }) => {
            log.Debug("data: ", data)
            this.title = data.title;
            this.officers = data.officers
            for (let cT in this.officers) {
                this.addOfficer();
            }
            this.form.get('Officers').patchValue(this.officers);
        })
    }

    createFormGroup() {
        return new FormGroup({
            Officers: new FormArray([])
        })
    }

    createOfficerFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            Name: new FormControl(null, Validators.required),
            Badge: new FormControl(null, Validators.required),
            Title: new FormControl(null, Validators.required),
            AgencyName: new FormControl(null, Validators.required),
            AgencyPhone: new FormControl(null, Validators.required),
            AgencyAddress: new FormControl(null, Validators.required),
            AgencyCity: new FormControl(null, Validators.required),
            AgencyCounty: new FormControl(null, Validators.required),
        })
    }

    addOfficer(): void {
        let officers = this.form.get('Officers') as FormArray;
        officers.push(this.createOfficerFormGroup());
    }
    removeOfficer(i: number): void {
        let officers = this.form.get('Officers') as FormArray;
        officers.removeAt(i);
        this.officers.splice(i, 1)
    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }

    onSubmit(e): void {

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched: ", this.form)
            return;
        }

        this.save();
    }

    save() {
        this.os.save(this.form.get('Officers').value)
        .subscribe((savedOfficers: Officer[]) => {
            this.apps.Confirmation('Officers saved.');
            this.form.markAsPristine();

            log.Debug("savedOfficers: ", savedOfficers)
            this.form.get('Officers').patchValue(savedOfficers);
            this.officers = savedOfficers
        })
    }

    deleteOfficer(e, index: number) {
        e.preventDefault();
        e.stopPropagation();


        const officer = this.officers[index] as Officer;
        if(!Boolean(officer)) {
            log.Debug("officer: ", officer)
            this.removeOfficer(index);
            return
        }

        if(confirm("This officer will be deleted. Are you sure?")) {
            this.os.delete(<Officer>officer).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Officer deleted.');
                    this.removeOfficer(index);
                }
            })
        }
    }
}
