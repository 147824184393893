<mat-card class="edit-auto">
    <h1 class="title">{{ title }}</h1>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
        <mat-card-content>
            <div class="row gutters">
                <div class="col">
                    <mat-card class="mat-card deck">
                        <mat-card-content>
                            <div class="row">
                                <div class="col">
                                    <mat-form-field>
                                        <input type="text" placeholder="Auto Number" matInput [matAutocomplete]="auto" formControlName="AutoNumber">
                                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                            <mat-option *ngIf="!isEdit && !isSearch" [value]="nextAutoNumber" selected="true">{{ nextAutoNumber }}</mat-option>
                                        </mat-autocomplete>
                                        <mat-error *ngIf="form.get('AutoNumber')?.invalid && !isSearch">{{ getErrorMessage(form.get('AutoNumber')) }}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col">
                                    <mat-form-field>
                                        <mat-select placeholder="Status" formControlName="StatusID">
                                            <mat-option *ngFor="let status of statuses" [value]="status.ID">{{ status.Name }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="form.get('StatusID')?.invalid">{{ getErrorMessage(form.get('StatusID')) }}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field>
                                        <mat-select placeholder="Status Description" formControlName="StatusDescriptionID">
                                            <mat-option *ngFor="let statDesc of statusDescriptions" [value]="statDesc.ID">{{ statDesc.Name }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="form.get('StatusDescriptionID')?.invalid">{{ getErrorMessage(form.get('StatusDescriptionID')) }}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput [matDatepicker]="DateIn" placeholder="Date In" formControlName="DateIn" [max]="form.value.DateOut">
                                        <mat-error *ngIf="form.get('DateIn')?.invalid">{{ getErrorMessage(form.get('DateIn')) }}</mat-error>
                                        <mat-datepicker-toggle matSuffix [for]="DateIn"></mat-datepicker-toggle>
                                        <mat-datepicker #DateIn></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field>
                                        <mat-select placeholder="Impound Type" formControlName="ImpoundTypeID">
                                            <mat-option *ngFor="let impoundType of impoundTypes" [value]="impoundType.ID">{{ impoundType.Name }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="form.get('ImpoundTypeID')?.invalid">{{ getErrorMessage(form.get('ImpoundTypeID')) }}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col">
                                    <mat-form-field>
                                        <mat-label>Make</mat-label>
                                        <pgl-search-select
                                            placeholder="Make"
                                            label="Make"
                                            formControlName="Make"
                                            [filterWith]="filterMakes"
                                            [displayWith]="displayMakes"
                                            startWith="a"
                                            (onSelect)="initializeAutoModels"
                                            autoActiveFirstOption>
                                        </pgl-search-select>
                                        <mat-error *ngIf="form.get('Make')?.invalid">{{ getErrorMessage(form.get('Make')) }}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field>
                                        <mat-label>Model</mat-label>
                                        <pgl-search-select
                                            placeholder="Model"
                                            label="Model"
                                            formControlName="AutoModel"
                                            [filterWith]="filterAutoModels"
                                            [displayWith]="displayAutoModels"
                                            startWith="a"
                                            autoActiveFirstOption>
                                        </pgl-search-select>
                                        <mat-error *ngIf="form.get('AutoModel')?.invalid">{{ getErrorMessage(form.get('AutoModel')) }}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput type="text" placeholder="Year" formControlName="AutoYear">
                                        <mat-error *ngIf="form.get('AutoYear')?.invalid">{{ getErrorMessage(form.get('AutoYear')) }}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput type="text" placeholder="Color" formControlName="Color">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput type="text" placeholder="VIN" formControlName="Vin">
                                        <mat-error *ngIf="form.get('Vin')?.invalid">{{ getErrorMessage(form.get('Vin')) }}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput type="text" placeholder="Tag #" formControlName="Tag">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col">
                                    <mat-form-field>
                                        <mat-select placeholder="State" formControlName="State">
                                            <mat-option *ngFor="let state of states" [value]="state.Code">{{ state.Code }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput type="text" placeholder="Year" formControlName="DecalYear">
                                        <mat-error *ngIf="form.get('DecalYear')?.invalid">{{ getErrorMessage(form.get('DecalYear')) }}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput type="text" placeholder="Decal #" formControlName="DecalNumber">
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput type="text" placeholder="Case #" formControlName="CaseNumber">
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card>
                        <mat-card-content>
                            <p style="text-align: center;">Removal</p>
                            <div class="row">
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput type="text" placeholder="Street" formControlName="RemovalStreet">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col--size-3">
                                    <mat-form-field>
                                        <input matInput type="text" placeholder="City" formControlName="RemovalCity">
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field>
                                        <mat-select placeholder="State" formControlName="RemovalState">
                                            <mat-option *ngFor="let state of states" [value]="state.Code">{{ state.Code }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col--size-3">
                                    <mat-form-field>
                                        <input matInput type="text" placeholder="Zip" formControlName="RemovalZip">
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="col">
                    <mat-card>
                        <mat-card-content>
                            <p style="text-align: center;">Charges</p>
                            <div class="charges" formArrayName="Charges" *ngFor="let charge of form.get('Charges')['controls']; let i = index">
                                <div class="row gutters" [formGroupName]="i">
                                    <div class="col">
                                        <mat-form-field>
                                            <mat-select placeholder="Charge Type" formControlName="Name" required>
                                                <mat-option *ngFor="let chargeType of chargeTypes" [value]="chargeType.Name">{{ chargeType.Name }}</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="charge.get('Name')?.invalid">{{ getErrorMessage(charge.get('Name')) }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field>
                                            <input matInput type="number" placeholder="Charge" formControlName="Charge" required>
                                            <mat-error *ngIf="charge.get('Charge')?.invalid">{{ getErrorMessage(charge.get('Charge')) }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <button type="button" mat-raised-button color="warn" (click)="deleteCharge($event, i)"><mat-icon>delete</mat-icon></button>
                                    </div>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col">
                                    <button type="button" mat-raised-button color="primary" (click)="addCharge()"><mat-icon>add</mat-icon>Add Charge</button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput type="number" placeholder="Price In" formControlName="PriceIn">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput [matDatepicker]="DateOut" placeholder="Date Out" formControlName="DateOut" [min]="form.value.DateIn">
                                        <mat-datepicker-toggle matSuffix [for]="DateOut"></mat-datepicker-toggle>
                                        <mat-datepicker #DateOut></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput type="number" placeholder="Price Out" formControlName="PriceOut">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row gutters" *ngIf="isEdit">
                                <div class="col">
                                    <mat-checkbox color="primary" formControlName="IsRequest">Registration Request Report</mat-checkbox>
                                </div>
                                <div class="col">
                                    <mat-checkbox color="primary" formControlName="IsAuction">Auction</mat-checkbox>
                                </div>
                            </div>
                            <div class="row gutters" *ngIf="isEdit">
                                <div class="col">
                                    <mat-checkbox color="primary" formControlName="IsNotification">Notification Letters</mat-checkbox>
                                </div>
                                <div class="col">
                                    <mat-checkbox color="primary" formControlName="IsT158">T-158</mat-checkbox>
                                </div>
                            </div>
                            <div class="row gutters" *ngIf="isEdit">
                                <div class="col">
                                    <mat-checkbox color="primary" formControlName="IsInsurancePickup">Available for Insurance Pickup</mat-checkbox>
                                </div>
                                <div class="col">
                                    <mat-checkbox color="primary" formControlName="HasKeys">Keys</mat-checkbox>
                                </div>
                            </div>
                            <div class="row gutters" *ngIf="isEdit">
                                <div class="col">
                                </div>
                                <div class="col">
                                	<p>Days since date in: {{ daysSinceDateIn }}</p>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="col">
                    <mat-card>
                        <mat-card-content>
                            <mat-tab-group>
                                <mat-tab label="Owners">
                                    <div class="owners-form-array" formArrayName="Owners" *ngFor="let owner of form.get('Owners')['controls']; let i = index">
                                        <div class="form-group-container" [formGroupName]="i">
                                            <div class="row gutters owner-title">
                                                <div class="col">
                                                    <p>{{ (owner.get('LastName').value || owner.get('Company').value) ? getOwnerText(owner) : 'Owner' }}</p>
                                                    <mat-error *ngIf="owner.errors?.hasNoName && (owner.touched || owner.dirty)">Owner must have a last name or company name.</mat-error>
                                                </div>
                                                <div class="col" style="text-align: right">
                                                    <button type="button" mat-raised-button color="warn" (click)="deleteOwner($event, i)"><mat-icon>delete</mat-icon></button>
                                                </div>
                                            </div>
                                            <div class="row gutters">
                                                <div class="col--size-5">
                                                    <mat-form-field>
                                                        <input matInput type="text" placeholder="Last Name" formControlName="LastName">
                                                        <mat-error *ngIf="owner.get('LastName')?.invalid">{{ getErrorMessage(owner.get('LastName')) }}</mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col--size-5">
                                                    <mat-form-field>
                                                        <input matInput type="text" placeholder="First Name" formControlName="FirstName">
                                                        <mat-error *ngIf="owner.get('FirstName')?.invalid">{{ getErrorMessage(owner.get('FirstName')) }}</mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col">
                                                    <mat-form-field>
                                                        <input matInput type="text" placeholder="MI" formControlName="MiddleInitial">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <mat-form-field>
                                                        <input matInput type="text" placeholder="Company" formControlName="Company">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <mat-form-field>
                                                        <input matInput type="text" placeholder="Address 1" formControlName="Address1">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <mat-form-field>
                                                        <input matInput type="text" placeholder="Address 2" formControlName="Address2">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row gutters">
                                                <div class="col--size-3">
                                                    <mat-form-field>
                                                        <input matInput type="text" placeholder="City" formControlName="City">
                                                    </mat-form-field>
                                                </div>
                                                <div class="col">
                                                    <mat-form-field>
                                                        <mat-select placeholder="State" formControlName="State">
                                                            <mat-option *ngFor="let state of states" [value]="state.Code">{{ state.Code }}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col--size-3">
                                                    <mat-form-field>
                                                        <input matInput type="text" placeholder="Zip" formControlName="Zip">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <mat-form-field>
                                                        <input matInput type="tel" placeholder="Phone 1" formControlName="Phone1">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <mat-form-field>
                                                        <input matInput type="tel" placeholder="Phone 2" formControlName="Phone2">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <mat-form-field>
                                                        <input matInput type="tel" placeholder="Fax" formControlName="Fax">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <button type="button" mat-raised-button color="primary" (click)="addOwner()"><mat-icon>add</mat-icon>Add Owner</button>
                                        </div>
                                    </div>
                                </mat-tab>
                                <mat-tab label="Lienholders">
                                    <div class="lienholders-form-array" formArrayName="Lienholders" *ngFor="let lienholder of form.get('Lienholders')['controls']; let i = index">
                                        <div class="form-group-container" [formGroupName]="i">
                                            <div class="row gutters owner-title">
                                                <div class="col">
                                                    <p>{{ (lienholder.get('LastName').value || lienholder.get('Company').value) ? getOwnerText(lienholder) : 'Lienholder' }}</p>
                                                    <mat-error *ngIf="lienholder.errors?.hasNoName && (lienholder.touched || lienholder.dirty)">Lienholder must have a last name or company name.</mat-error>
                                                </div>
                                                <div class="col" style="text-align: right">
                                                    <button type="button" mat-raised-button color="warn" (click)="deleteLienholder($event, i)"><mat-icon>delete</mat-icon></button>
                                                </div>
                                            </div>
                                            <div class="row gutters">
                                                <div class="col--size-5">
                                                    <mat-form-field>
                                                        <input matInput type="text" placeholder="Last Name" formControlName="LastName">
                                                        <mat-error *ngIf="lienholder.get('LastName')?.invalid">{{ getErrorMessage(lienholder.get('LastName')) }}</mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col--size-5">
                                                    <mat-form-field>
                                                        <input matInput type="text" placeholder="First Name" formControlName="FirstName">
                                                        <mat-error *ngIf="lienholder.get('FirstName')?.invalid">{{ getErrorMessage(lienholder.get('FirstName')) }}</mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col">
                                                    <mat-form-field>
                                                        <input matInput type="text" placeholder="MI" formControlName="MiddleInitial">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <mat-form-field>
                                                        <input matInput type="text" placeholder="Company" formControlName="Company">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <mat-form-field>
                                                        <input matInput type="text" placeholder="Address 1" formControlName="Address1">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <mat-form-field>
                                                        <input matInput type="text" placeholder="Address 2" formControlName="Address2">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row gutters">
                                                <div class="col--size-3">
                                                    <mat-form-field>
                                                        <input matInput type="text" placeholder="City" formControlName="City">
                                                    </mat-form-field>
                                                </div>
                                                <div class="col">
                                                    <mat-form-field>
                                                        <mat-select placeholder="State" formControlName="State">
                                                            <mat-option *ngFor="let state of states" [value]="state.Code">{{ state.Code }}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col--size-3">
                                                    <mat-form-field>
                                                        <input matInput type="text" placeholder="Zip" formControlName="Zip">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <mat-form-field>
                                                        <input matInput type="tel" placeholder="Phone 1" formControlName="Phone1">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <mat-form-field>
                                                        <input matInput type="tel" placeholder="Phone 2" formControlName="Phone2">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <mat-form-field>
                                                        <input matInput type="tel" placeholder="Fax" formControlName="Fax">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <button type="button" mat-raised-button color="primary" (click)="addLienholder()"><mat-icon>add</mat-icon>Add Lienholder</button>
                                        </div>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions end>
            <button mat-raised-button color="primary" *ngIf="!isSearch" type="submit"><mat-icon>save</mat-icon>Save</button>
            <button mat-raised-button color="primary" *ngIf="isSearch" type="submit"><mat-icon>search</mat-icon>Search</button>
            <button type="button" mat-raised-button color="primary" *ngIf="isEdit" (click)="openReportsDialog()"><mat-icon>format_list_bulleted</mat-icon>Reports</button>
            <button type="button" mat-raised-button color="primary" *ngIf="isEdit" (click)="openStatusesDialog()"><mat-icon>history</mat-icon>Status Feed</button>
            <button type="button" mat-raised-button color="primary" *ngIf="isEdit" (click)="openNotesDialog()"><mat-icon>notes</mat-icon>Notes</button>
            <!-- <button type="button" mat-raised-button color="primary" *ngIf="isEdit" (click)="gotoInvoice()"><mat-icon>attach_money</mat-icon>Invoice</button> -->
            <button type="button" mat-raised-button color="primary" *ngIf="isEdit" (click)="gotoNew()"><mat-icon>clear</mat-icon>Clear</button>
            <button type="button" mat-raised-button color="warn" *ngIf="isEdit" (click)="delete($event)"><mat-icon>delete</mat-icon>Delete</button>
        </mat-card-actions>
    </form>
</mat-card>
<pre class="debug">{{ form.value | json }}</pre>
