import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { AppService } from '../../app.service';

import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize, tap } from 'rxjs/operators';
import { log } from '../dev/log';
import { AuthService } from '../../user_auth';
import { Router } from '@angular/router';
import { LoadingService } from '../loading.service';

@Injectable()

export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(
        private apps: AppService,
        private auths: AuthService,
        private router: Router,
        private ls: LoadingService
    ){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.ls.isLoading$.next(true)

        //const token: string = localStorage.getItem('token');
        //request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token)});
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json')});
        //request = request.clone({ headers: request.headers.set('Accept', 'application/json')});

        log.Debug("in interceptor")

        return next.handle(request).pipe(
            tap(val => log.Debug("request sent")),
            catchError((error: HttpErrorResponse) => {
                switch(error.status) {
                    case 401: {
                        log.Debug("401 error")
                        this.apps.Confirmation(error.error)
                        this.auths.Logout();
                        log.Debug("navigating")
                        this.router.navigate(['/login']);
                        break;
                    }
                    case 404: {
                        log.Debug("404 error")
                        this.apps.Confirmation("This url does not exist on the server.")
                        break;
                    }
                    case 500: {
                        log.Debug("500 error")
                        this.apps.Confirmation("Something went wrong. Error: " + error.message)
                        break;
                    }
                    default: {
                        log.Debug("random error")
                        this.apps.Confirmation("Something went wrong. Error: " + error.message)
                        break;
                    }
                }
                log.Debug("error: ", error)
                return throwError(error);
            }),
            finalize(() => {this.ls.isLoading$.next(false); log.Debug("in finalize interceptor ") }) 

        )
    }
}