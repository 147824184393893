import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TransactionService } from './transaction.service';
import { Transaction } from './transaction';

@Injectable()
export class NextTransactionNumberResolve implements Resolve<string> {
    constructor (
        private router: Router,
        private ts: TransactionService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<string> {
        return this.ts.GetNextTransactionNumber();
    }
}