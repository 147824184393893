import { Model } from '../common/model';

export class Customer extends Model {

    CustomerNumber: string;
    Company: string;
    LastName: string;
    FirstName: string;
    MiddleInitial: string;
    Address1: string;
    Address2: string;
    City: string;
    State: string;
    Zip: number;
    Phone: string;
    County: string;
    CreditLimit: number;
    Terms: string;
    StatementFrequency: string;
    Category: string;
    Active: boolean;
    Created: Date;
    Modified: Date;

    get FullName(): string {
        return this.FirstName + " " + this.LastName;
    }

    get Name(): string {
        if(this.Company && this.Company != '') {
            return this.Company
        }

        if(this.LastName && this.LastName != '') {
            if(this.FirstName && this.FirstName != '') {
                return this.FirstName + " " + this.LastName
            } else {
                return this.LastName
            }
        }

        return ''
    }

    constructor(properties?: Object) {
        super(properties)
    }
}