import { Model } from '../common/model';
import { Customer } from '../customer';
import { Auto } from '../auto';

export class Transaction extends Model {

        TransactionNumber: string;
        AutoID: number;
        Auto: Auto;
        AutoNumber: string;
        CustomerID: number;
        CustomerNumber: string;
        Customer: Customer;
        BeginDate: Date;
        Transacted: Date;
        EndDate: Date;
        SaleAmount: number;
        Active: boolean;
        Created: Date;
        Modified: Date;

        get CustomerFullName(): string {
            return this.Customer ? this.Customer.FirstName+" "+this.Customer.LastName : '';
        }

    constructor(properties?: Object) {
        super(properties)
    }
}