import { ValidatorFn, AbstractControl } from '@angular/forms';

export function objectSelectedValidator(): ValidatorFn {
    return ( control: AbstractControl ): {[key: string]: any} | null => {
        let isObject = false;
        if( typeof control.value === 'object' ) {
            isObject = true;
        }

        return !isObject ? {'isNotObject': !isObject} : null;
    };
}