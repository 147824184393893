import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Driver } from './driver';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { DriversSearchService } from './drivers-search.service'

export class DriversDataSource implements DataSource<Driver> {

    private driversSubject = new BehaviorSubject<Driver[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private lengthSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public length$ = this.lengthSubject.asObservable();

    constructor(
        private dss: DriversSearchService
    ){}

    connect( collectionViewer: CollectionViewer ): Observable<Driver[]> {
        return this.driversSubject.asObservable();
    }

    disconnect( collectionViewer: CollectionViewer ): void {
        this.driversSubject.complete();
        this.loadingSubject.complete();
    }

    loadDrivers( driver: Driver, pageIndex = 0, pageSize = 10 ) {

        this.loadingSubject.next(true);

        this.dss.SearchDrivers( driver, pageIndex, pageSize).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe( autos => this.driversSubject.next(autos) )
    }

    updateLength( driver: Driver ) {

        this.dss.GetResultsLength( driver ).pipe(
            catchError( () => of(0))
        ).subscribe( l => this.lengthSubject.next(l) )
        
    }

}