import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { InvoiceService } from './invoice.service';
import { Statement } from './statement';

@Injectable()
export class StatementsResolve implements Resolve<Statement[]> {
    constructor (
        private router: Router,
        private is: InvoiceService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<Statement[]> {
        return this.is.GetStatements();
    }
}