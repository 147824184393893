import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Transaction } from '../transaction';
import { MONTHS } from '../common/month';
import { log } from '../common/dev/log';

@Component({
    selector: "bill-of-sale",
    templateUrl: "./bill-of-sale.component.html",
    styleUrls: ['./single-print.component.css']
})

export class BillOfSaleComponent implements OnInit {

    private _months = MONTHS;
    isBlank: boolean
    copies = [1, 2];

    fields = {
        UnitNumber: '',
        TransactionDate: new Date(),
        CaseNumber: '',
        Name: '',
        Address: '',
        CityState: '',
        ZipCode: '',
        YearMakeModel: '',
        VIN: '',
        Amount: '',
        SalesTax: '',
        TotalSale: ''
    }

    constructor (
        private route: ActivatedRoute
    ){}
    ngOnInit(): void {
        this.route.data.subscribe((data: {
            isBlank: boolean,
            currentTransaction: Transaction
        }) => {

            log.Debug("data: ", data)

            this.isBlank = data.isBlank
            if(!data.isBlank) {

                this.fields.UnitNumber = data.currentTransaction.Auto.AutoNumber || '';
                this.fields.TransactionDate = data.currentTransaction.Transacted;
                this.fields.CaseNumber = data.currentTransaction.Auto.CaseNumber || '';
                this.fields.Name = this.getName(data.currentTransaction.Customer.FirstName, data.currentTransaction.Customer.LastName, data.currentTransaction.Customer.Company);
                this.fields.Address = this.getAddress(data.currentTransaction.Customer.Address1, data.currentTransaction.Customer.Address2);
                this.fields.CityState = this.getCityState(data.currentTransaction.Customer.City, data.currentTransaction.Customer.State);
                this.fields.ZipCode = data.currentTransaction.Customer.Zip.toString()
                this.fields.YearMakeModel = this.getYearMakeModel(data.currentTransaction.Auto.AutoYear, data.currentTransaction.Auto.Make.Name, data.currentTransaction.Auto.AutoModel.Name);
                this.fields.VIN = data.currentTransaction.Auto.Vin || '';
                this.fields.Amount = data.currentTransaction.SaleAmount.toString() || '';
                this.fields.SalesTax = '0';
                this.fields.TotalSale = this.fields.Amount;

            }

        });
    }

    getYearMakeModel(year: string, make: string, model: string): string {

        let yearValid = !this.empty(year);

        if( yearValid ) {
            return year + ' ' + make + ' ' + model;
        }

        return make + ' ' + model;

    }

    getCityState( city: string, state: string ): string {

        let cityValid = !this.empty(city);
        let stateValid = !this.empty(state);

        if( cityValid && stateValid ) {
            return city + ', ' + state;
        } else if( !cityValid && stateValid ) {
            return state;
        } else if( cityValid && !stateValid ) {
            return city;
        }

        return '';
    }

    getName(firstName: string, lastName: string, company: string): string {

        let fNameValid = !this.empty(firstName);
        let lNameValid = !this.empty(lastName);
        let companyValid = !this.empty(company);

        if( companyValid ) {
            return company;
        }

        if( lNameValid ) {
            if ( fNameValid ) {
                return firstName + ' ' + lastName;
            }
            return lastName;
        }

        return '';
    }

    getAddress(address1: string, address2: string): string {

        let a1Valid = !this.empty(address1);
        let a2Valid = !this.empty(address2);

        if( a1Valid && a2Valid ) {
            return address1 + ' ' + address2;
        } else if( a1Valid && !a2Valid ) {
            return address1;
        }

        return ''
    }

    empty(str: string): boolean {
        if( '' != str && null != str && undefined != str ) {
            return false;
        }
        return true;
    }

    print(): void {
        window.print();
    }
}