import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auto } from '../auto';
import { Make } from '../make';
import { AutoModel } from '../auto-model';
import { MONTHS } from '../common/month';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Setting } from '../setting';
import { FeedService, Feed } from '../feed/';
import { log } from '../common/dev/log';

@Component({
    selector: "request-for-owner-information",
    templateUrl: "./request-for-owner-information.component.html",
    styleUrls: ['./single-print.component.css']
})

export class RequestForOwnerInformationComponent implements OnInit {

    private _months = MONTHS;
    private makes: Make[];
    private autoModels: AutoModel[];
    autoID: number;
    currentDate: Date;

    fields = {
        CompanyName: '',
        CompanyPOBox: '',
        CompanyAddress: '',
        CompanyCity: '',
        CompanyState: '',
        CompanyZip: '',
        CompanyPhone: '',
        AutoNumber: '',
        AutoVin: '',
        AutoYear: '',
        AutoMake: '',
        AutoModel: '',
        AutoTag: '',
        AutoState: '',
        RemovedDate: new Date(),
        AbandonedDate: new Date(),
        AutoRemovalAddress: '',
        AutoRemovalCity: '',
        AutoRemovalState: '',
        AutoRemovalZip: '',
    }

    constructor (
        private route: ActivatedRoute,
        private FeedService: FeedService,
    ){}
    ngOnInit(): void {
        this.route.data.subscribe((data: {
            isBlank: boolean,
            currentAuto: Auto,
            settings: {}
        }) => {
            if(!data.isBlank) {

                this.currentDate = new Date()
                this.autoID = data.currentAuto.ID



                this.fields.CompanyName = data.settings['Company Name'] || '';
                this.fields.CompanyPOBox = data.settings['Company PO Box'] || '';
                this.fields.CompanyAddress = data.settings['Company Address'] || '';
                this.fields.CompanyCity = data.settings['Company City'] || '';
                this.fields.CompanyState = data.settings['Company State'] || '';
                this.fields.CompanyZip = data.settings['Company Zip'] || '';
                this.fields.CompanyPhone = data.settings['Company Phone'] || '';
                this.fields.AutoNumber = data.currentAuto.AutoNumber || '';
                this.fields.AutoVin = data.currentAuto.Vin || '';
                this.fields.AutoYear = data.currentAuto.AutoYear || '';
                this.fields.AutoMake = data.currentAuto.Make.Name || '';
                this.fields.AutoModel = data.currentAuto.AutoModel.Name || '';
                this.fields.AutoTag = data.currentAuto.Tag || '';
                this.fields.AutoState = data.currentAuto.State || ''
                this.fields.RemovedDate = new Date(data.currentAuto.DateIn)
                this.fields.AbandonedDate = this.currentDate;
                this.fields.AutoRemovalAddress = data.currentAuto.RemovalStreet || '';
                this.fields.AutoRemovalCity = data.currentAuto.RemovalCity || '';
                this.fields.AutoRemovalState = data.currentAuto.RemovalState || '';
                this.fields.AutoRemovalZip = data.currentAuto.RemovalZip || '';
            }
        });
    }

    print(): void {
        //Change to note maybe?
        log.Debug("printed request for owner information")
        this.FeedService.save(new Feed({ AutoID: this.autoID, Description: "Request for Owner Information Printed", Created: this.currentDate })).subscribe(
            resp => log.Debug("resp: ", resp)
        );
        window.print();
    }
}
