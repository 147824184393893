import { Routes } from '@angular/router';
import { MV603Component } from './mv-603.component';
import { MV603RComponent } from './mv-603r.component';
import { MV603AComponent } from './mv-603a.component';
import { ForeclosureOfLienComponent } from './foreclosure-of-lien.component';
import { T158Component } from './t-158.component';
import { MV603LettersComponent } from './mv-603-letters.component';
import { BillOfSaleComponent } from './bill-of-sale.component';
import { AffidavitCourtOrderComponent } from './affidavit-court-order.component';

import { PrintComponent } from './print.component';
// import { ToPDFComponent } from './topdf.component';

import { RegistrationRequestComponent } from './registration-request.component';
import { NotificationLetterComponent } from './notification-letter.component';

import { CurrentAutoResolve, RegistrationRequestAutosResolve, NotificationLetterAutosResolve, T158AutosResolve } from '../auto';
import { CurrentTransactionResolve } from '../transaction';

import { DailyDebitReportComponent } from './daily-debit-report.component';
import { DailyCreditReportComponent } from './daily-credit-report.component';
import { DailyCashSalesReportComponent } from './daily-cash-sales-report.component';
import { DailyDebitReportResolve } from '../daily-report';
import { DailyCreditReportResolve } from '../daily-report';

import { CustomerListingComponent } from './customer-listing.component';
import { InvoiceCustomersResolve } from '../customer/invoice-customers.resolve';
import { CustomersResolve } from '../customer/customers.resolve';

import { InvoicesResolve } from '../invoice/invoices.resolve';
import { StatementsResolve } from '../invoice/statements.resolve';
import { StatementsComponent } from './statements.component';
import { StatementsPDFComponent } from './statements-pdf.component';

import { AgingReportComponent } from './aging-report.component';
import { AgingReportResolve } from '../invoice/aging-report.resolve';
import { AgingReportPDFComponent } from './aging-report-pdf.component';

import { DriverReportComponent } from './driver-report.component';
import { DriversResolve } from '../driver';

import { DriverCommissionDueReportComponent } from './driver-commission-due-report.component';
import { DriverReportsResolve } from '../driver';
import { DriverCommissionDueReportPDFComponent } from './driver-commission-due-report-pdf.component';

import { SettingsResolve, SettingsToMapResolve } from '../setting';

import { RequestForOwnerInformationComponent } from './request-for-owner-information.component';
import { PetitionForLienForeclosureComponent } from './petition-for-lien-foreclosure.component';
import { MotionForLienForeclosureComponent } from './motion-for-lien-foreclosure.component';
import { AMVNoticeComponent } from './amv-notice.component'

import { TestPDFComponent } from './test-pdf.component';
import { AutoByDateRangeComponent } from './auto-by-date-range.component';
import { T22BComponent } from './t-22b.component';
import { OfficersResolve } from '../officer';

import { SalesReportPDFComponent } from './sales-report-pdf.component';


export const PRINT_ROUTES: Routes = [
    {
        path: '',
        component: PrintComponent
    },
    // {
    //     path: 'topdf',
    //     component: ToPDFComponent
    // },
    {
        path: 'mv-603',
        children: [
            {
                path: 'blank',
                component: MV603Component,
                data: {
                    isBlank: true
                }
            },
            {
                path: ':auto_id',
                component: MV603Component,
                data: {
                    isBlank: false
                },
                resolve: {
                    currentAuto: CurrentAutoResolve,
                    settings: SettingsToMapResolve
                }
            }
        ]
    },
    {
        path: 'request-for-owner-information',
        children: [
            {
                path: ':auto_id',
                component: RequestForOwnerInformationComponent,
                data: {
                    isBlank: false
                },
                resolve: {
                    currentAuto: CurrentAutoResolve,
                    settings: SettingsToMapResolve
                }
            }
        ]
    },
    {
        path: 'petition-for-lien-foreclosure',
        children: [
            {
                path: ':auto_id',
                component: PetitionForLienForeclosureComponent,
                data: {
                    isBlank: false
                },
                resolve: {
                    currentAuto: CurrentAutoResolve,
                    settings: SettingsToMapResolve
                }
            }
        ]
    },
    {
        path: 'motion-for-lien-foreclosure',
        children: [
            {
                path: ':auto_id',
                component: MotionForLienForeclosureComponent,
                data: {
                    isBlank: false
                },
                resolve: {
                    currentAuto: CurrentAutoResolve,
                    settings: SettingsToMapResolve
                }
            }
        ]
    },
    {
        path: 'amv-notice',
        children: [
            {
                path: ':auto_id',
                component: AMVNoticeComponent,
                data: {
                    isBlank: false
                },
                resolve: {
                    currentAuto: CurrentAutoResolve,
                    settings: SettingsToMapResolve
                }
            }
        ]
    },
    {
        path: 'test-pdf',
        children: [
            {
                path: '',
                component: TestPDFComponent,
            }
        ]
    },
    {
        path: 'mv-603r',
        children: [
            {
                path: 'blank',
                component: MV603RComponent,
                data: {
                    isBlank: true
                }
            },
            {
                path: ':auto_id',
                component: MV603RComponent,
                data: {
                    isBlank: false
                },
                resolve: {
                    currentAuto: CurrentAutoResolve,
                    settings: SettingsToMapResolve
                }
            }
        ]
    },
    {
        path: 'mv-603a',
        children: [
            {
                path: 'blank',
                component: MV603AComponent,
                data: {
                    isBlank: true
                }
            },
            {
                path: ':auto_id',
                component: MV603AComponent,
                data: {
                    isBlank: false
                },
                resolve: {
                    currentAuto: CurrentAutoResolve,
                    settings: SettingsToMapResolve
                }
            }
        ]
    },
    {
        path: 't-158',
        children: [
            {
                path: '',
                component: T158Component,
                resolve: {
                    autos: T158AutosResolve,
                    settings: SettingsToMapResolve
                }
            }
        ]
    },
    {
        path: 't-22b',
        children: [
            {
                path: 'blank',
                component: T22BComponent,
            },
            {
                path: ':auto_id',
                component: T22BComponent,
                resolve: {
                    currentAuto: CurrentAutoResolve,
                    officers: OfficersResolve,
                    settings: SettingsToMapResolve
                }
            }
        ]
    },
    {
        path: 'court-order',
        children: [
            {
                path: 'blank',
                component: ForeclosureOfLienComponent,
                data: {
                    isBlank: true
                }
            },
            {
                path: ':auto_id',
                component: ForeclosureOfLienComponent,
                data: {
                    isBlank: false
                },
                resolve: {
                    currentAuto: CurrentAutoResolve
                }
            }
        ]
    },
    {
        path: 'affidavit-court-order',
        children: [
            {
                path: 'blank',
                component: AffidavitCourtOrderComponent,
                data: {
                    isBlank: true
                }
            },
            {
                path: ':auto_id',
                component: AffidavitCourtOrderComponent,
                data: {
                    isBlank: false,
                },
                resolve: {
                    currentAuto: CurrentAutoResolve,
                    settings: SettingsToMapResolve
                }
            }
        ]
    },
    {
        path: 'mv-603-letters',
        children: [
            {
                path: 'blank',
                component: MV603LettersComponent,
                data: {
                    isBlank: true
                }
            },
            {
                path: ':auto_id',
                component: MV603LettersComponent,
                data: {
                    isBlank: false
                },
                resolve: {
                    currentAuto: CurrentAutoResolve,
                    settings: SettingsToMapResolve
                }
            }
        ]
    },
    {
        path: 'registration-request-report',
        children: [
            {
                path: '',
                component: RegistrationRequestComponent,
                data: {
                    title: 'Registration Request Report'
                },
                resolve: {
                    autos: RegistrationRequestAutosResolve
                }
            }
        ]
    },
    {
        path: 'notification-letters',
        children: [
            {
                path: '',
                component: NotificationLetterComponent,
                data: {
                    title: 'Notification Letters'
                },
                resolve: {
                    autos: NotificationLetterAutosResolve,
                    settings: SettingsToMapResolve
                }
            }
        ]
    },
    {
        path: 'bill-of-sale',
        children: [
            {
                path: 'blank',
                component: BillOfSaleComponent,
                data: {
                    title: 'Bill of Sale',
                    isBlank: true
                }
            },
            {
                path: ':transaction_id',
                component: BillOfSaleComponent,
                data: {
                    title: 'Bill of Sale',
                    isBlank: false,
                },
                resolve: {
                    currentTransaction: CurrentTransactionResolve
                }
            }
        ]
    },
    {
        path: 'daily-debit-report',
        children: [
            {
                path: '',
                component: DailyDebitReportComponent,
                resolve: {
                    report: DailyDebitReportResolve
                }
            }
        ]
    },
    {
        path: 'daily-credit-report',
        children: [
            {
                path: '',
                component: DailyCreditReportComponent,
                resolve: {
                    report: DailyCreditReportResolve
                }
            }
        ]
    },
    {
        path: 'daily-cash-sales-report',
        children: [
            {
                path: '',
                component: DailyCashSalesReportComponent,
                resolve: {
                    report: DailyCreditReportResolve
                }
            }
        ]
    },
    {
        path: 'customer-listing',
        children: [
            {
                path: '',
                component: CustomerListingComponent,
                resolve: {
                    customerListing: InvoiceCustomersResolve
                }
            }
        ]
    },
    {
        path: 'driver-report',
        children: [
            {
                path: '',
                component: DriverReportComponent,
                resolve: {
                    report: DriversResolve
                }
            }
        ]
    },
    {
        path: 'statements',
        children: [
            {
                path: '',
                component: StatementsComponent,
                resolve: {
                    //statements: StatementsResolve,
                    settings: SettingsToMapResolve
                }
            }
        ]
    },
    {
        path: 'statements-pdf',
        children: [
            {
                path: '',
                component: StatementsPDFComponent,
                resolve: {}
            }
        ]
    },
    {
        path: 'sales-report-pdf',
        children: [
            {
                path: '',
                component: SalesReportPDFComponent,
                resolve: {
                    customers: InvoiceCustomersResolve,
                }
            }
        ]
    },
    {
        path: 'aging-report',
        children: [
            {
                path: '',
                component: AgingReportComponent,
                resolve: {
                    report: AgingReportResolve,                }
            }
        ]
    },
    {
        path: 'aging-report-pdf',
        children: [
            {
                path: '',
                component: AgingReportPDFComponent
            }
        ]
    },
    {
        path: 'driver-commission-due-report',
        children: [
            {
                path: '',
                component: DriverCommissionDueReportComponent,
                resolve: {
                    reports: DriverReportsResolve,                }
            }
        ]
    },
    {
        path: 'driver-commission-due-report-pdf',
        children: [
            {
                path: '',
                component: DriverCommissionDueReportPDFComponent,
            }
        ]
    },
    {
        path: 'auto-by-date-range',
        children: [
            {
                path: '',
                component: AutoByDateRangeComponent,
            }
        ]
    },
]
