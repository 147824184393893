<mat-card class="email small-form">
    <h1 class="title">{{ title }}</h1>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
        <mat-card-content>
            <div class="row">
                <div class="col">
                    <mat-form-field>
                        <input matInput type="text" placeholder="To" formControlName="To" required>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field>
                        <input matInput type="text" placeholder="From" formControlName="From">
                    </mat-form-field>
                </div>
            </div>
            <div class="row" *ngIf="isAuctionAdPetition">
                <div class="col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="PetitionDeadlineDate" placeholder="Petition Deadline Date" formControlName="PetitionDeadlineDate" [min]="form.value.PetitionDeadlineDate">
                        <mat-datepicker-toggle matSuffix [for]="PetitionDeadlineDate"></mat-datepicker-toggle>
                        <mat-datepicker #PetitionDeadlineDate></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="emailData && emailData.Autos && emailData.Autos.length > 0">
              <div class="row gutters" *ngFor="let auto of emailData.Autos" style="margin-bottom:5px;">
                <div class="col"><p>{{ auto.AutoNumber }}</p></div>
                <div class="col"><p>{{ auto.Make.Name }}</p></div>
                <div class="col"><p>{{ auto.AutoModel.Name }}</p></div>
                <div class="col"><p>{{ auto.AutoYear }}</p></div>
                <button mat-raised-button color="warn" type="button" (click)="removeAuto(auto.ID)"><mat-icon>delete</mat-icon></button>
              </div>
            </div>
            <!-- <div class="row">
                <div class="col">
                    <mat-form-field>
                        <textarea matInput type="text" cdkAutosizeMinRows="5" rows="15" placeholder="Message" formControlName="Message"></textarea>
                    </mat-form-field>
                </div>
            </div> -->
            <div class="row">
                <div class="col">
                    <mat-form-field>
                        <textarea matInput type="text" cdkAutosizeMinRows="5" rows="10" placeholder="Custom Message" formControlName="CustomMessage"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions end>
            <button mat-raised-button color="primary" type="submit"><mat-icon>send</mat-icon>Send</button>
        </mat-card-actions>
    </form>
</mat-card>
<pre class="debug">{{ form.value | json }}</pre>
