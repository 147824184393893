import { Model } from '../common/model';
import { Driver } from '../driver';
import { Customer } from '../customer';
import { Auto } from '../auto';
import { Payment } from '../payment';

export class Invoice extends Model {

    InvoiceNumber: string;
    Invoiced: Date;
    Name: string;
    Note: string;
    AutoID: number;
    Auto: Auto;
    InvoicesDrivers: Driver[];
    AdditionalBalance: number;
    InitialBalance: number;
    RemainingBalance: number;
    IsClosed: boolean;
    IsPosted: boolean;
    CustomerID: number;
    Customer: Customer;
    Payments: Payment[];
    Active: boolean;
    Created: Date;
    Modified: Date;

    constructor(properties?: Object) {
        super(properties)
    }
}