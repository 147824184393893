<mat-card class="search search-drivers">
    <h1 class="title">{{ title }}</h1>
    <div class="row gutters">
        <div class="col">
            <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
                <mat-card class="searh-panel">
                    <mat-card-content>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                        <input type="text" placeholder="Driver Number" matInput [matAutocomplete]="auto" formControlName="DriverNumber">
                                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                            <mat-option *ngFor="let driver of filteredDrivers | async" [value]="driver.DriverNumber">{{ driver.DriverNumber }}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Last Name" formControlName="LastName">
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="First Name" formControlName="FirstName">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Phone" formControlName="Phone">
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="number" placeholder="Comm %" formControlName="Commission">
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-actions end>
                        <button color="primary" type="submit" mat-raised-button class="button--float-right"><mat-icon>search</mat-icon>Search</button>
                        <button color="primary" type="button" mat-raised-button class="button--float-right" (click)="reset($event)"><mat-icon>autorenew</mat-icon>Clear</button>
                    </mat-card-actions>
                </mat-card>
            </form>
        </div>
        <div class="col--size-2" style="position: relative;">

            <div class="spinner-container" *ngIf="dataSource.loading$ | async" style="position: absolute; top: 200px; left: 50%; transform: translate( -50%, 0 );">
                <mat-spinner></mat-spinner>
            </div>

            <table mat-table [dataSource]="dataSource">

                <!-- driver_number -->
                <ng-container matColumnDef="driver_number">
                    <th mat-header-cell *matHeaderCellDef>Driver Number</th>
                    <td mat-cell *matCellDef="let driver"><a [routerLink]="['edit', driver.ID]">{{ driver.DriverNumber }}</a></td>
                </ng-container>

                <!-- last_name -->
                <ng-container matColumnDef="last_name">
                    <th mat-header-cell *matHeaderCellDef>Last Name</th>
                    <td mat-cell *matCellDef="let driver">{{ driver.LastName }}</td>
                </ng-container>

                <!-- first_name -->
                <ng-container matColumnDef="first_name">
                    <th mat-header-cell *matHeaderCellDef>First Name</th>
                    <td mat-cell *matCellDef="let driver">{{ driver.FirstName }}</td>
                </ng-container>

                <!-- phone -->
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>Phone</th>
                    <td mat-cell *matCellDef="let driver">{{ driver.Phone }}</td>
                </ng-container>

                <!-- commission -->
                <ng-container matColumnDef="commission">
                    <th mat-header-cell *matHeaderCellDef>Commission</th>
                    <td mat-cell *matCellDef="let driver">{{ driver.Commission }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <mat-paginator [length]="dataSource.length$ | async" [pageSize]="10" [pageSizeOptions]="[10, 50, 100]"></mat-paginator>

        </div>
    </div>
</mat-card>
<pre class="debug">{{ form.value | json }}</pre>