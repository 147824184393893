import { Injectable, OnDestroy } from '@angular/core';
import { HttpService } from './common/services';
import { HttpClient } from '@angular/common/http';

import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable } from 'rxjs';

@Injectable()
export class AppService extends HttpService implements OnDestroy {

    constructor (
        protected http: HttpClient,
        private snackBar: MatSnackBar,
    ) {
        super(http);
    }

    ngOnDestroy() {
    
    }

    ErrorMessages(formControl: FormControl): string {
        return formControl.hasError('required') ? 'This field is required.' :
            formControl.hasError('isNotObject') ? 'Please select an item from the list.' :
            formControl.hasError('isNotID') ? 'Please select an item from the list.' :
            formControl.hasError('nonUniqueNumber') ? 'This number is already in use.' :
            formControl.hasError('nonUniqueUsername') ? 'This username is already in use.' :
            formControl.hasError('min') ? 'The value entered is too low.' :
            formControl.hasError('max') ? 'The value entered is too high.' :
            formControl.hasError('minlength') ? 'The value entered is not long enough.' :
            formControl.hasError('maxlength') ? 'The value entered is too long.' : '';
    }

    Confirmation( message: string ): Observable<void> {
        return this.snackBar.open(message, 'Ok', {
            duration: 2000,
            verticalPosition: 'bottom',
            panelClass: ['snack-bar--notify', 'snack-bar--confirmation'],
        }).afterOpened()
    }

    Error( message: string ) {
        this.snackBar.open(message, 'Ok', {
            duration: 5000,
            verticalPosition: 'top',
            panelClass: ['snack-bar--notify', 'snack-bar--error'],
        });
    }

    GetAppDaysSinceStart(): Observable<number> {
    	return this.http.get<number>('/api/days_since_start');
    }

}