<button class="print-button" mat-raised-button (click)="print()" color="primary" style="margin-bottom: 10px;"><mat-icon>print</mat-icon>Print</button>
<div id="print-section" style="position: relative;">
    <div class="report-container print-out-container">
        <div class="report report--aging-report print-out">
            <p class="title bold">Aging Report</p>
            <p class="bold">{{ currentDate | date:'M/d/yyyy':'UTC' }}</p>
            <div class="row gutters border-bottom">
                <div class="col--size-2 text-center">
                    <p class="bold">Company</p>
                </div>
                <div class="col text-center">
                    <p class="bold">Invoice Number</p>
                </div>
                <div class="col text-center">
                    <p class="bold">Invoice Date</p>
                </div>
                <div class="col text-center">
                    <p class="bold">Current</p>
                </div>
                <div class="col text-center">
                    <p class="bold">> 30</p>
                </div>
                <div class="col text-center">
                    <p class="bold">> 60</p>
                </div>
                <div class="col text-center">
                    <p class="bold">> 90</p>
                </div>
                <div class="col text-center">
                    <p class="bold">Total Due</p>
                </div>
            </div>
            <div *ngFor="let report of report.CompanyAgingReports">
                <div class="row gutters" *ngFor="let invoice of report.Invoices; index as i;">
                    <div class="col--size-2 text-left">
                        <p>{{ 0 == i ? getName(report.Company, report.LastName, report.FirstName) : "" }}</p>
                    </div>
                    <div class="col text-center">
                        <p>{{ invoice.InvoiceNumber }}</p>
                    </div>
                    <div class="col text-right">
                        <p>{{ invoice.Invoiced | date:'M/d/yyyy':'UTC' }}</p>
                    </div>
                    <div class="col text-right">
                        <p>{{ (showDue( invoice.Invoiced, 0 ) ? invoice.RemainingBalance : "") | currency }}</p>
                    </div>
                    <div class="col text-right">
                        <p>{{ (showDue( invoice.Invoiced, -30 ) ? invoice.RemainingBalance :"") | currency }}</p>
                    </div>
                    <div class="col text-right">
                        <p>{{ (showDue( invoice.Invoiced, -60 ) ? invoice.RemainingBalance : "") | currency }}</p>
                    </div>
                    <div class="col text-right">
                        <p>{{ (showDue( invoice.Invoiced, -90 ) ? invoice.RemainingBalance : "") | currency }}</p>
                    </div>
                    <div class="col text-right">
                        <p>{{ invoice.RemainingBalance | currency }}</p>
                    </div>
                </div>
                <div class="row gutters margin-bottom">
                    <div class="col"></div>
                    <div class="col"></div>
                    <div class="col--size-2">
                        <p class="bold">Company Totals:</p>
                    </div>
                    <div class="col text-right border-top">
                        <p class="bold">{{ report.CurrentDue | currency }}</p>
                    </div>
                    <div class="col text-right border-top">
                        <p class="bold">{{ report.ThirtyDayDue | currency }}</p>
                    </div>
                    <div class="col text-right border-top">
                        <p class="bold">{{ report.SixtyDayDue | currency }}</p>
                    </div>
                    <div class="col text-right border-top">
                        <p class="bold">{{ report.NinetyDayDue | currency }}</p>
                    </div>
                    <div class="col text-right border-top">
                        <p class="bold">{{ report.TotalDue | currency }}</p>
                    </div>
                </div>
            </div>
            <div class="row gutters margin-bottom">
                <div class="col"></div>
                <div class="col"></div>
                <div class="col--size-2">
                    <p class="bold">Report Totals:</p>
                </div>
                <div class="col text-right">
                    <p class="bold">{{ report.CurrentDue | currency }}</p>
                </div>
                <div class="col text-right">
                    <p class="bold">{{ report.ThirtyDayDue | currency }}</p>
                </div>
                <div class="col text-right">
                    <p class="bold">{{ report.SixtyDayDue | currency }}</p>
                </div>
                <div class="col text-right">
                    <p class="bold">{{ report.NinetyDayDue | currency }}</p>
                </div>
                <div class="col text-right">
                    <p class="bold">{{ report.TotalDue | currency }}</p>
                </div>
            </div>
        </div>
    </div>
</div>