<mat-card class="edit-payment small-form">
    <h1 class="title">{{ title }}</h1>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
        <mat-card-content>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <mat-label>Invoice</mat-label>
                        <pgl-search-select
                            placeholder="Invoice"
                            label="Invoice"
                            formControlName="Invoice"
                            [options]="filteredInvoices$ | async"
                            [filterWith]="filterInvoices"
                            [displayWith]="displayInvoices"
                            startWith="1"
                            [required]="true"
                            (onSelect)="setRemainingBalance($event)"
                            autoActiveFirstOption>
                        </pgl-search-select>
                        <mat-error *ngIf="form.get('Invoice')?.invalid">{{ getErrorMessage(form.get('Invoice')) }}</mat-error>
                    </mat-form-field> 
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput type="number" placeholder="Remaining Balance" formControlName="RemainingBalance">
                        <mat-error *ngIf="form.get('RemainingBalance')?.invalid">{{ getErrorMessage(form.get('RemainingBalance')) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Name" formControlName="Name" required>
                        <mat-error *ngIf="form.get('Name')?.invalid">{{ getErrorMessage(form.get('Name')) }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput type="number" placeholder="Amount" formControlName="Amount" required>
                        <mat-error *ngIf="form.get('Amount')?.invalid">{{ getErrorMessage(form.get('Amount')) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="Date" placeholder="Date" formControlName="Date">
                        <mat-datepicker-toggle matSuffix [for]="Date"></mat-datepicker-toggle>
                        <mat-datepicker #Date></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions end>
            <button type="button" mat-raised-button color="primary" *ngIf="isEdit" (click)="gotoNew()"><mat-icon>clear</mat-icon>Clear</button>
            <button mat-raised-button color="primary" type="submit"><mat-icon>save</mat-icon>Save</button>
        </mat-card-actions>
    </form>
</mat-card>
<pre class="debug">{{ form.value | json }}</pre>