<div class="menu-dialog">
    <h2 mat-dialog-title>{{ data['title'] }}</h2>
    <div mat-dialog-content>
        <mat-list role="list" class="menu-dialog-menu">
                <mat-list-item role="listitem" *ngFor="let item of data['items']">
                    <a (click)="close()" [routerLink]="item.Link">{{ item.Name }}</a>
                </mat-list-item>
        </mat-list>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button (click)="close()" color="primary"><mat-icon>close</mat-icon>Close</button>
    </div>
</div>