<mat-card class="edit-driver small-form">
    <h1 class="title">{{ title }}</h1>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
        <mat-card-content>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input type="text" placeholder="Driver Number" matInput [matAutocomplete]="auto" formControlName="DriverNumber">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let driver of filteredDrivers | async" [value]="driver.DriverNumber">{{ driver.DriverNumber }}</mat-option>
                            <mat-option *ngIf="!isEdit && !isSearch" [value]="nextDriverNumber" selected="true">{{ nextDriverNumber }}</mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="form.get('DriverNumber')?.invalid && !isSearch && form.dirty">{{ getErrorMessage(form.get('DriverNumber')) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col--size-5">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Last Name" formControlName="LastName" [required]="!isSearch">
                        <mat-error *ngIf="form.get('LastName')?.invalid">{{ getErrorMessage(form.get('LastName')) }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col--size-5">
                    <mat-form-field>
                        <input matInput type="text" placeholder="First Name" formControlName="FirstName">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput type="text" placeholder="MI" formControlName="MiddleInitial">
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input matInput type="tel" placeholder="Phone" formControlName="Phone">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput type="number" placeholder="Comm %" formControlName="Commission" [required]="!isSearch">
                        <mat-error *ngIf="form.get('Commission')?.invalid">{{ getErrorMessage(form.get('Commission')) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions end>
            <button mat-raised-button color="primary" *ngIf="!isSearch" type="submit"><mat-icon>save</mat-icon>Save</button>
            <button mat-raised-button color="primary" *ngIf="isSearch" type="submit"><mat-icon>search</mat-icon>Search</button>
            <!-- <button mat-raised-button color="accent" click><mat-icon>clear</mat-icon>Clear Form</button> -->
            <button type="button" mat-raised-button color="primary" *ngIf="isEdit" (click)="gotoNew()"><mat-icon>clear</mat-icon>Clear</button>
            <button type="button" mat-raised-button color="warn" *ngIf="isEdit" (click)="delete($event)"><mat-icon>delete</mat-icon>Delete</button>
        </mat-card-actions>
    </form>
</mat-card>
<pre class="debug">{{ form.value | json }}</pre>