import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map, tap } from 'rxjs/operators';
import { log } from '../common/dev/log';
import { Transaction } from './transaction';
import { TransactionService } from './transaction.service';
import { STATES } from '../common/state';
import { TransactionsDataSource } from './transactions-data-source'
import { MatPaginator } from '@angular/material/paginator';
import { TransactionsSearchService } from './transactions-search.service';

@Component({
    selector: "search-transactions",
    templateUrl: "./search-transactions.component.html"
})

export class SearchTransactionsComponent implements OnInit {

    filteredTransactions;
    title: string;

    form: FormGroup;
    public states = STATES;

    displayedColumns: string[] = ['transaction_number', 'date', 'auto_number', 'customer_number', 'sale_amount']
    dataSource: TransactionsDataSource

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator

    constructor (
        private route: ActivatedRoute,
        private ts: TransactionService,
        private tss: TransactionsSearchService
    ){
        this.form = this.createFormGroup()
    }
    ngOnInit(): void {

        this.dataSource = new TransactionsDataSource(this.tss)
        this.dataSource.loadTransactions(new Transaction())
        this.dataSource.updateLength(new Transaction())

        this.route.data.subscribe((data: {
            title: string,
        }) => {
            log.Debug("data: ", data)

            this.title = data.title;

        });
    }

    ngAfterViewInit() {
        this.paginator.page.pipe(
            tap(() => this.loadNextPage())
        ).subscribe();
    }

    loadNextPage() {
        this.dataSource.loadTransactions(this.beforeSubmit(), this.paginator.pageIndex, this.paginator.pageSize)
    }

    createFormGroup() {
        return new FormGroup({
            TransactionNumber: new FormControl(),
            BeginDate: new FormControl(),
            EndDate: new FormControl(),
            AutoNumber: new FormControl(),
            CustomerNumber: new FormControl()
        });
    }


    reset(e) {
        e.preventDefault()
        e.stopPropagation()

        this.form.reset();
    }

    beforeSubmit(): Transaction {
        let transaction = new Transaction(this.form.value)
        return Object.keys(transaction).reduce((acc, cur) => {
            const value = transaction[cur]
            if (value != null) {
                acc[cur] = value
            }
            return acc
        }, new Transaction());
    }

    onSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        this.paginator.pageIndex = 0
        this.loadNextPage()
        this.dataSource.updateLength(this.beforeSubmit())
    }
}