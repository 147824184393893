import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from '../common/dev/log';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { Payment } from './payment';
import { PaymentService } from './payment.service';
import { Invoice, InvoiceService } from '../invoice';
import { Observable, of } from 'rxjs';
import { startWith, map, tap, take } from 'rxjs/operators';
import { STATES } from '../common/state';
import { UniqueNumberValidator, CanUnique, hasNameValidator } from '../common/validators';

@Component({
    selector: "edit-payment",
    templateUrl: "./edit-payment.component.html"
})

export class EditPaymentComponent implements OnInit {

    filteredInvoices$: Observable<Invoice[]>;
    remainingBalance: number;

    title: string;
    isEdit: boolean;

    form: FormGroup;
    Invoice: FormGroup;
    yesterday = new Date()
    

    public payments: Payment[];
    public currentPayment: Payment;
    
    constructor (
        private route: ActivatedRoute,
        private apps: AppService,
        private ps: PaymentService,
        private router: Router,
        private is: InvoiceService
    ) {
        this.currentPayment = new Payment();
        this.form = this.createFormGroup();
        this.Invoice = this.createFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            edit: boolean,
            currentPayment: Payment,
        }) => {
            log.Debug("data: ", data)
            this.title = data.title;
            this.isEdit = data.edit;
            this.payments = []

            if (this.isEdit) {
                Object.assign(this.currentPayment, data.currentPayment);
                this.form.patchValue(this.currentPayment);
                this.setRemainingBalance(this.currentPayment.Invoice)
            }

            this.form.get('Amount').valueChanges.pipe(
                map(val => this.remainingBalance - val)
            ).subscribe(val => this.form.get('RemainingBalance').patchValue(val))

            this.yesterday.setDate(this.yesterday.getDate() - 1);

        })
    }

    createFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            Invoice: new FormControl(),
            RemainingBalance: new FormControl(0, Validators.required),
            Name: new FormControl(null, Validators.required),
            Amount: new FormControl(null, Validators.required),
            Date: new FormControl(this.yesterday, Validators.required)
        })
    }

    filterInvoices = (value: string | Invoice): Observable<Invoice[]> => {
        value = value || '0';

        if (typeof value != 'string' ) {
            return of([value])
        }

        return this.is.SearchByNumber(<string>value).pipe(take(1));
    }

    displayInvoices = (invoice: Invoice): string => {
        return invoice ? invoice.InvoiceNumber : ""
    }

    setRemainingBalance = (invoice: Invoice) => {
        if(!invoice) {
            invoice = this.form.get('Invoice').value
        }
        this.form.get('RemainingBalance').patchValue(invoice.RemainingBalance || 0)
    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }

    gotoNew() {
        this.router.navigate(['../../', 'new'], {relativeTo: this.route});
    }

    onSubmit(e): void {

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched: ", this.form)
            return;
        }

        this.save();
    }

    save() {
        let payment = new Payment(this.form.value)
        payment.Invoice.RemainingBalance = this.form.get('RemainingBalance').value
        this.ps.SaveWithInvoice(new Payment(this.form.value))
        .subscribe((savedPayment: Payment) => {
            this.apps.Confirmation('Payment saved.');
            this.form.markAsPristine();

            if( !this.isEdit ) {
                this.currentPayment = new Payment(savedPayment);
                this.remainingBalance = this.currentPayment.Invoice.RemainingBalance
                this.router.navigate(['../', 'edit', savedPayment.ID], {relativeTo: this.route});
            }

            Object.assign(this.currentPayment, savedPayment)
            this.form.patchValue(savedPayment, {emitEvent: false});
            this.setRemainingBalance(savedPayment.Invoice);
        })
    }

    delete(e) {
        e.preventDefault();
        e.stopPropagation();
        if (confirm('This payment will be deleted. Are you sure?')) {
            this.ps.delete(this.currentPayment).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Driver deleted.');
                    const index = this.payments.findIndex( driver => driver.ID === this.currentPayment.ID);
                    this.payments.splice(index, 1);
                    this.router.navigate(['dashboard']);
                }
            })
        }
    }

}