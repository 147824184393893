import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DefaultService } from '../common/validators';
import { log } from '../common/dev/log';
import { EmailData } from './email-data';

@Injectable({
    providedIn: 'root'
})

export class EmailService extends HttpService implements OnDestroy, DefaultService {
    private _subscriptions: Subscription[] = [];

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    isNumberUnique(number: string): Observable<boolean> {
        return of(false);
    }

    submit( e: EmailData ): Observable<boolean> {
        return this.http.post<boolean>('/api/emails/send', e).pipe(map(val => val));
    }

    GetAuctionAdEmail(): Observable<EmailData> {
        return this.http.get<EmailData>('/api/emails/auction-ad').pipe(map(val => new EmailData(val)));
    }

    GetAuctionAdPetitionEmail(): Observable<EmailData> {
        return this.http.get<EmailData>('/api/emails/auction-ad-petition').pipe(map(val => new EmailData(val)));
    }

    GetInvoiceEmailData(id: number): Observable<EmailData> {
        return this.http.get<EmailData>('/api/emails/invoice/' + id).pipe(map(val => new EmailData(val)));
    }

}