import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { User } from '../user';
import { log } from '../common/dev/log';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthGroup, AuthRoles } from '../common/model';
import { UserRoleCache } from './user-role-cache';

@Injectable()

export class AuthGuardService implements CanActivate {

    constructor(
        public auths: AuthService,
        public router: Router
    ) {}

    canActivate( route: ActivatedRouteSnapshot ): boolean {
    	const role = localStorage.getItem('user_role');
    	const created = localStorage.getItem('user_created');
        log.Debug("in can activate: ", this.auths.LocalUserRoleIsValid(role, created))
        if (!this.auths.LocalUserRoleIsValid(role, created)) {
            log.Debug("role is invalid: ", role, created)
            // this.auths.GetLoggedInUser()
            // .subscribe(( loggedInUser: User) => {
            //     log.Debug("loggedInUser: ", loggedInUser)
            //     this.auths.SetLocalUserRole(loggedInUser);
            // })
            //this.auths.Logout();
            this.router.navigate(["/logout"])
        }

        return this.hasPermission(route.data['auth']);
    }

    protected hasPermission( authRoles: AuthGroup[] = null ): boolean {

        log.Debug("in has permission")
        
        return this.auths.HasPermission( authRoles );

    }
}