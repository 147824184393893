import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { UserRole } from './user-role';

@Injectable()
export class UserRolesResolve implements Resolve<UserRole[]> {
    constructor (
        private router: Router,
        private us: UserService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<UserRole[]> {
        return this.us.GetUserRoles();
    }
}