import { Model } from '../common/model';

export class User extends Model {

    Username: string;
    RoleID: number;
    Role: string;
    Password: string;
    Active: boolean;
    Created: Date;
    Modified: Date;

    constructor(properties?: Object) {
        super(properties)
    }
}