<button class="print-button" mat-raised-button (click)="print()" color="primary" style="margin-bottom: 10px;"><mat-icon>print</mat-icon>Print</button>
<div *ngIf="currentAuto.Owners.length <= 0; else ownersExist">
    <p style="text-align: center; font-size: 20px;margin-bottom: 10px;">There are no owners for this auto.</p>
</div>
<ng-template #ownersExist>
<mat-card class="amv-notice-form small-form hide-in-print" style="margin-bottom: 30px;">
    <!-- <h1 class="title">{{ title }}</h1> -->
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
        <mat-card-content>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input matInput type="number" placeholder="Removal Fees" formControlName="RemovalFees" required>
                            <mat-error *ngIf="form.get('RemovalFees')?.invalid">{{ getErrorMessage(form.get('RemovalFees')) }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput type="number" placeholder="Storage Per Day" formControlName="StoragePerDay" required>
                            <mat-error *ngIf="form.get('StoragePerDay')?.invalid">{{ getErrorMessage(form.get('StoragePerDay')) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions end>
        </mat-card-actions>
    </form>
</mat-card>
<div></div>
<div id="print-section">

    <div *ngFor="let owner of currentAuto.Owners">
        <div class="amv-notice">
            <p style="page-break-before: always; margin-bottom: 0;"></p>
            <div class="amv-notice" style="position: relative; padding: 0; height: 822px; box-shadow: none;">

<html>
<head><meta http-equiv=Content-Type content="text/html; charset=UTF-8">
<style type="text/css">
<!--
span.cls_003{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_003{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_004{font-family:Times,serif;font-size:11.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_004{font-family:Times,serif;font-size:11.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
-->
</style>
<script type="text/javascript" src="71a0df54-f9b6-11e9-9d71-0cc47a792c0a_id_71a0df54-f9b6-11e9-9d71-0cc47a792c0a_files/wz_jsgraphics.js"></script>
</head>
<body>
<div style="position:absolute;left:50%;margin-left:-306px;top:0px;width:612px;height:792px;border-style:outset;overflow:hidden">

<div style="position:absolute;left:212.81px;top:71.40px" class="cls_003"><span class="cls_003">ABANDONED MOTOR VEHICLE NOTICE</span></div>
<div style="position:absolute;left:223.61px;top:91.82px" class="cls_003"><span class="cls_003">(TOWING OR STORAGE COMPANY)</span></div>
<div style="position:absolute;left:72.02px;top:132.62px" class="cls_003"><span class="cls_003">Owner Name:</span></div>
<div style="position:absolute;left:146.54px;top:132.62px" class="cls_003"><span class="cls_003">{{ getName(owner.Company, owner.FirstName, owner.LastName) }}</span></div>
<div style="position:absolute;left:146.54px;top:132.62px" class="cls_003"><span class="cls_003">__________________________________</span></div>
<div style="position:absolute;left:72.02px;top:153.02px" class="cls_003"><span class="cls_003">Owner Address:</span></div>
<div style="position:absolute;left:144.02px;top:153.02px" class="cls_003"><span class="cls_003">{{ getAddress(owner.Address1, owner.Address2) }}</span></div>
<div style="position:absolute;left:144.02px;top:153.02px" class="cls_003"><span class="cls_003">___________________________________</span></div>
<div style="position:absolute;left:144.02px;top:173.42px" class="cls_003"><span class="cls_003">{{ getCityStateZip(owner.City, owner.State, owner.Zip) }}</span></div>
<div style="position:absolute;left:144.02px;top:173.42px" class="cls_003"><span class="cls_003">___________________________________</span></div>
<div style="position:absolute;left:72.02px;top:193.82px" class="cls_003"><span class="cls_003">Vehicle Make:</span></div>
<div style="position:absolute;left:144.02px;top:193.82px" class="cls_003"><span class="cls_003">{{ fields.AutoMake }}</span></div>
<div style="position:absolute;left:270px;top:193.82px" class="cls_003"><span class="cls_003">{{ fields.AutoYear }}</span></div>
<div style="position:absolute;left:144.02px;top:193.82px" class="cls_003"><span class="cls_003">____________________ Year: ______</span></div>
<div style="position:absolute;left:324.07px;top:193.82px" class="cls_003"><span class="cls_003">Model:</span></div>
<div style="position:absolute;left:360.07px;top:193.82px" class="cls_003"><span class="cls_003">{{ fields.AutoModel }}</span></div>
<div style="position:absolute;left:360.07px;top:193.82px" class="cls_003"><span class="cls_003">_______________________________</span></div>
<div style="position:absolute;left:153px;top:214.34px" class="cls_003"><span class="cls_003">{{ fields.AutoVin }}</span></div>
<div style="position:absolute;left:387px;top:214.34px" class="cls_003"><span class="cls_003">{{ fields.AutoTag }}</span></div>
<div style="position:absolute;left:462px;top:214.34px" class="cls_003"><span class="cls_003">{{ fields.AutoTagState }}</span></div>
<div style="position:absolute;left:72.02px;top:214.34px" class="cls_003"><span class="cls_003">Vehicle ID (VIN)#: _______________________________    Vehicle License #: __________ State ________</span></div>
<div style="position:absolute;left:72.02px;top:234.74px" class="cls_003"><span class="cls_003">Dear Owner:</span></div>
<div style="position:absolute;left:72.02px;top:255.17px" class="cls_003"><span class="cls_003">You have been identified as an owner of the above-referenced vehicle, which is currently being held at the business</span></div>
<div style="position:absolute;left:72.02px;top:267.53px" class="cls_003"><span class="cls_003">listed below, and are being notified in accordance with OCGA 40-11-19(a)(1).   The following fees are currently</span></div>
<div style="position:absolute;left:72.02px;top:280.01px" class="cls_003"><span class="cls_003">due:</span></div>
<div style="position:absolute;left:72.02px;top:300.41px" class="cls_003"><span class="cls_003">{{ this.form.get('RemovalFees').value | currency }}</span></div>
<div style="position:absolute;left:72.02px;top:300.41px" class="cls_003"><span class="cls_003">_____________</span></div>
<div style="position:absolute;left:144.02px;top:300.41px" class="cls_003"><span class="cls_003">Fees associated with removal of the vehicle</span></div>
<div style="position:absolute;left:72.02px;top:320.81px" class="cls_003"><span class="cls_003">{{ this.form.get('StoragePerDay').value | currency }}</span></div>
<div style="position:absolute;left:72.02px;top:320.81px" class="cls_003"><span class="cls_003">_____________</span></div>
<div style="position:absolute;left:144.02px;top:320.81px" class="cls_003"><span class="cls_003">Daily fee for storage of the vehicle</span></div>
<div style="position:absolute;left:350px;top:341.21px" class="cls_003"><span class="cls_003">{{ this.form.get('StoragePerDay').value | currency }}</span></div>
<div style="position:absolute;left:72.02px;top:341.21px" class="cls_003"><span class="cls_003">Daily fees will accrue until full payment has been made at the rate of ________ per day.  Additional fees that are</span></div>
<div style="position:absolute;left:72.02px;top:353.57px" class="cls_003"><span class="cls_003">recoverable by a towing or storage firm include fees for obtaining the owner’s information, notifying the owner</span></div>
<div style="position:absolute;left:72.02px;top:366.05px" class="cls_003"><span class="cls_003">(including advertising, if necessary), court filing costs, attorney’s fees, and interest.</span></div>
<div style="position:absolute;left:72.02px;top:386.45px" class="cls_003"><span class="cls_003">The towing and storage firm has the right to petition a court to foreclose a lien for all amounts owed after 10</span></div>
<div style="position:absolute;left:72.02px;top:398.81px" class="cls_003"><span class="cls_003">calendar days from the date this notice was sent.  If the lien is foreclosed, a court shall order the sale of the vehicle</span></div>
<div style="position:absolute;left:72.02px;top:411.19px" class="cls_003"><span class="cls_003">to satisfy the debt.</span></div>
<div style="position:absolute;left:72.02px;top:431.71px" class="cls_003"><span class="cls_003">If you wish to disclaim ownership in this vehicle, complete and return the form found on the next page/back of this</span></div>
<div style="position:absolute;left:72.02px;top:444.07px" class="cls_003"><span class="cls_003">letter.  Disclaiming ownership and judgment of foreclosure being entered shall result in the holding of an auction.</span></div>
<div style="position:absolute;left:72.02px;top:456.43px" class="cls_003"><span class="cls_003">The excess proceeds (if any), that may exist shall be deposited with the Department of Revenue and be treated as</span></div>
<div style="position:absolute;left:72.02px;top:468.91px" class="cls_003"><span class="cls_003">unclaimed property.  Disposition of such property shall be governed pursuant to O.C.G.A. § 44-12-211.1.</span></div>
<div style="position:absolute;left:72.02px;top:509.71px" class="cls_003"><span class="cls_003">Please contact us immediately, or we will avail ourselves of all remedies allowed by law.</span></div>
<div style="position:absolute;left:211px;top:530.11px" class="cls_003"><span class="cls_003">{{ fields.CompanyAddress }} {{ fields.CompanyCityStateZip }}</span></div>
<div style="position:absolute;left:72.02px;top:530.11px" class="cls_003"><span class="cls_003">The vehicle is currently located at _________________________________________________________________.</span></div>
<div style="position:absolute;left:72.02px;top:550.51px" class="cls_003"><span class="cls_003">Anyone with an ownership interest in this vehicle should contact the following business immediately.</span></div>
<div style="position:absolute;left:72.02px;top:570.91px" class="cls_003"><span class="cls_003">Business Name:</span></div>
<div style="position:absolute;left:144.02px;top:570.91px" class="cls_003"><span class="cls_003">{{ fields.CompanyName }}</span></div>
<div style="position:absolute;left:144.02px;top:570.91px" class="cls_003"><span class="cls_003">___________________________________________</span></div>
<div style="position:absolute;left:72.02px;top:591.34px" class="cls_003"><span class="cls_003">Address:</span></div>
<div style="position:absolute;left:144.02px;top:591.34px" class="cls_003"><span class="cls_003">{{ fields.CompanyPOBox }}</span></div>
<div style="position:absolute;left:144.02px;top:591.34px" class="cls_003"><span class="cls_003">___________________________________________</span></div>
<div style="position:absolute;left:144.02px;top:611.74px" class="cls_003"><span class="cls_003">{{ fields.CompanyPOBoxCityStateZip }}</span></div>
<div style="position:absolute;left:144.02px;top:611.74px" class="cls_003"><span class="cls_003">___________________________________________</span></div>
<div style="position:absolute;left:72.02px;top:632.14px" class="cls_003"><span class="cls_003">Telephone #:</span></div>
<div style="position:absolute;left:144.02px;top:632.14px" class="cls_003"><span class="cls_003">{{ fields.CompanyPhone }}</span></div>
<div style="position:absolute;left:144.02px;top:632.14px" class="cls_003"><span class="cls_003">___________________________________________</span></div>
<div style="position:absolute;left:72.02px;top:765.24px" class="cls_004"><span class="cls_004">MAG 40-03</span></div>
</div>

</body>
</html>


            </div>
            <p style="page-break-before: always; margin-bottom: 0;"></p>
            <div class="amv-notice" style="position: relative; padding: 0; height: 822px; box-shadow: none;">

<html>
<head><meta http-equiv=Content-Type content="text/html; charset=UTF-8">
<style type="text/css">
<!--
span.cls_003{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_003{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_005{font-family:Times,serif;font-size:11.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_005{font-family:Times,serif;font-size:11.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
-->
</style>
<script type="text/javascript" src="c60ead0a-f9b6-11e9-9d71-0cc47a792c0a_id_c60ead0a-f9b6-11e9-9d71-0cc47a792c0a_files/wz_jsgraphics.js"></script>
</head>
<body>
<div style="position:absolute;left:50%;margin-left:-306px;top:0px;width:612px;height:792px;border-style:outset;overflow:hidden">

<div style="position:absolute;left:170.57px;top:112.22px" class="cls_003"><span class="cls_003">DISCLAIMER OF MOTOR VEHICLE OWNERSHIP INTEREST</span></div>
<div style="position:absolute;left:286px;top:144.14px" class="cls_003"><span class="cls_003">{{ fields.CompanyCounty }}</span></div>
<div style="position:absolute;left:248.93px;top:144.14px" class="cls_003"><span class="cls_003">Georgia, _________ County</span></div>
<div style="position:absolute;left:72.02px;top:176.06px" class="cls_003"><span class="cls_003">I, ___________________ (name of motor vehicle owner) do hereby swear that I disclaim any and all ownership</span></div>
<div style="position:absolute;left:72.02px;top:188.42px" class="cls_003"><span class="cls_003">interest in:</span></div>
<div style="position:absolute;left:72.02px;top:220.34px" class="cls_003"><span class="cls_003">Vehicle Make:</span></div>
<div style="position:absolute;left:144.02px;top:220.34px" class="cls_003"><span class="cls_003">{{ fields.AutoMake }}</span></div>
<div style="position:absolute;left:270px;top:220.34px" class="cls_003"><span class="cls_003">{{ fields.AutoYear }}</span></div>
<div style="position:absolute;left:144.02px;top:220.34px" class="cls_003"><span class="cls_003">____________________ Year: ______</span></div>
<div style="position:absolute;left:324.07px;top:220.34px" class="cls_003"><span class="cls_003">Model:</span></div>
<div style="position:absolute;left:360.07px;top:220.34px" class="cls_003"><span class="cls_003">{{ fields.AutoModel }}</span></div>
<div style="position:absolute;left:360.07px;top:220.34px" class="cls_003"><span class="cls_003">_______________________________</span></div>
<div style="position:absolute;left:72.02px;top:240.74px" class="cls_003"><span class="cls_003">Vehicle ID #:</span></div>
<div style="position:absolute;left:144.02px;top:240.74px" class="cls_003"><span class="cls_003">{{ fields.AutoVin }}</span></div>
<div style="position:absolute;left:144.02px;top:240.74px" class="cls_003"><span class="cls_003">_______________________________</span></div>
<div style="position:absolute;left:400px;top:240.74px" class="cls_003"><span class="cls_003">{{ fields.AutoTag }}</span></div>
<div style="position:absolute;left:476px;top:240.74px" class="cls_003"><span class="cls_003">{{ fields.AutoTagState }}</span></div>
<div style="position:absolute;left:324.07px;top:240.74px" class="cls_003"><span class="cls_003">Vehicle License #: __________ State ________</span></div>
<div style="position:absolute;left:72.02px;top:281.69px" class="cls_003"><span class="cls_003">I understand that by signing this form I will no longer hold any rights to the above listed vehicle and such vehicle</span></div>
<div style="position:absolute;left:72.02px;top:294.05px" class="cls_003"><span class="cls_003">may be sold at auction to satisfy a debt related to a lien filed against the vehicle.</span></div>
<div style="position:absolute;left:72.02px;top:314.45px" class="cls_003"><span class="cls_003">I further understand that disclaiming an ownership interest in this vehicle does not relieve me of any other debts</span></div>
<div style="position:absolute;left:72.02px;top:326.81px" class="cls_003"><span class="cls_003">related to such vehicle, such as a debt entered into for the purchase of such vehicle.</span></div>
<div style="position:absolute;left:72.02px;top:347.21px" class="cls_003"><span class="cls_003">By signing this form, I understand that I waive all future notices to which I am entitled under Article 1A of Chapter</span></div>
<div style="position:absolute;left:72.02px;top:359.69px" class="cls_003"><span class="cls_003">11 of Title 40, “The Abandoned Motor Vehicle Act.”</span></div>
<div style="position:absolute;left:72.02px;top:380.09px" class="cls_003"><span class="cls_003">Sworn to and Subscribed before me</span></div>
<div style="position:absolute;left:72.02px;top:400.49px" class="cls_003"><span class="cls_003">This ____ day of_____________20___</span></div>
<div style="position:absolute;left:72.02px;top:420.91px" class="cls_003"><span class="cls_003">________________________________</span></div>
<div style="position:absolute;left:324.07px;top:420.91px" class="cls_003"><span class="cls_003">________________________________________</span></div>
<div style="position:absolute;left:72.02px;top:441.31px" class="cls_003"><span class="cls_003">Notary Public or Clerk</span></div>
<div style="position:absolute;left:324.07px;top:441.31px" class="cls_003"><span class="cls_003">Owner of Vehicle</span></div>
<div style="position:absolute;left:72.02px;top:461.71px" class="cls_003"><span class="cls_003">My Commission Expires:</span></div>
<div style="position:absolute;left:390px;top:502.51px" class="cls_003"><span class="cls_003">{{ fields.CompanyName }}</span></div>
<div style="position:absolute;left:72.02px;top:502.51px" class="cls_003"><span class="cls_003">If you wish to disclaim ownership, return this completed and notarized form to _____________________________</span></div>
<div style="position:absolute;left:72.02px;top:521.95px" class="cls_003"><span class="cls_003">{{ fields.CompanyPOBox }} {{ fields.CompanyPOBoxCityStateZip }}</span></div>
<div style="position:absolute;left:72.02px;top:521.95px" class="cls_003"><span class="cls_003">______________________________________________________________(name and address of party owed fees)</span></div>
<div style="position:absolute;left:72.02px;top:765.24px" class="cls_005"><span class="cls_005">MAG 40-03</span></div>
</div>

</body>
</html>


            </div>
        </div>
    </div>
    </div>
</ng-template>
