import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, from } from 'rxjs';
import { map, concatMap, reduce } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DeleteResponse } from '../common/delete-response';
import { Setting } from './setting';
import { log } from '../common/dev/log';

@Injectable({
    providedIn: 'root'
})

export class SettingService extends HttpService implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    GetAllToMap(): Observable<{}> {
        return this.http.get<Setting[]>('/api/settings/all').pipe(
            concatMap(settings => from(settings)),
            reduce((acc, setting) => {
                acc[setting['Name']] = setting['Value']
                return acc;
            }, {})
        );
    }

    GetAll(): Observable<Setting[]> {
        return this.http.get<Setting[]>('/api/settings/all').pipe(map(this.toNote));
    }

    toNote(setting) {
        const temp = setting.map( note => {
            return new Setting(note);
        });
        return temp;
    }

    save(settings: Setting[]): Observable<Setting[]> {
        return this.http.put<Setting[]>('/api/settings/update_all', settings);
    }

    delete(setting: Setting): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/settings/delete/' + setting.ID)
    }

}