import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from './user';
import { log } from '../common/dev/log';
import { UserService } from './user.service';
import { AppService } from '../app.service';

@Component({
    selector: "users",
    templateUrl: "users.component.html"
})

export class UsersComponent implements OnInit {

    title: string;
    users: User[];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private us: UserService,
        private apps: AppService
    ){}

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            users: User[]
        }) => {
            log.Debug("data: ", data);
            this.title = data.title;
            this.users = data.users;
        });
    }

    deleteUser(e, user: User) {
        e.preventDefault();
        e.stopPropagation();
        if (confirm('This user will be deleted. Are you sure?')) {
            this.us.delete(user).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('User deleted.');
                    const index = this.users.findIndex( item => item.ID === user.ID);
                    this.users.splice(index, 1);
                    //this.router.navigate(['dashboard']);
                }
            })
        }
    }

    delete(e) {
        
    }

    addUser(e) {
        e.preventDefault();
        this.router.navigate(['new'], {relativeTo: this.route});
    }

}