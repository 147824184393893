<mat-card class="manage-status-descriptions small-form manage-table">
    <h1 class="title">{{ title }}</h1>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
        <mat-card-content>
            <div class="status-descriptions-form-array" formArrayName="StatusDescriptions" *ngFor="let statusDescription of form.get('StatusDescriptions')['controls']; let i = index">
                <div class="form-group-container" [formGroupName]="i">
                    <div class="row gutters">
                        <div class="col--size-3" style="padding: 0 10px;">
                            <mat-form-field>
                                <input matInput type="text" placeholder="Name" formControlName="Name" required>
                                <mat-error *ngIf="statusDescription.get('Name')?.invalid">{{ getErrorMessage(statusDescription.get('Name')) }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col" style="margin: auto;">
                            <button type="button" mat-raised-button color="warn" (click)="deleteStatusDescription($event, i)" style="display: block; margin: 0 auto;"><mat-icon>delete</mat-icon></button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions end>
            <button type="button" mat-raised-button color="primary" (click)="addStatusDescription()"><mat-icon>add</mat-icon>Add</button>
            <button type="submit" mat-raised-button color="primary"><mat-icon>save</mat-icon>Save Changes</button>
        </mat-card-actions>
    </form>
</mat-card>
<pre class="debug">{{ form.value | json }}</pre>