import { Component, ChangeDetectorRef } from '@angular/core';
import { AuthRoles, AuthGroup } from './common/model';
import { AuthService } from './user_auth';
import { log } from './common/dev/log';
import { tap } from 'rxjs/operators';
import { AppService } from './app.service';

interface MenuItem {
    Name: string;
    Link?: string;
    SubMenu?: MenuItem[];
    HasPermission?: boolean;
    AuthRoles: AuthGroup[];
    AuthUpdate?: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

    public IsLoggedIn = this.auths.userHasRole();;
    public authChanged = false;
    public daysSinceAppStart;

    constructor(
        private auths: AuthService,
        private cdr: ChangeDetectorRef,
        private appService: AppService
    ) {
        this.auths.isLoggedIn$.pipe( tap( bool => log.Debug("isloggedin: ", bool))).subscribe( val => {
            this.IsLoggedIn = val;
            this.updateMenuItems();
        })
        this.IsLoggedIn = this.auths.userHasRole();
        // this.appService.GetAppDaysSinceStart().subscribe( val => {
        // 	console.log("days: ", val);
        // 	this.daysSinceAppStart = val;
        // });
    }

    public MainMenu: MenuItem[] = [
        {
            Name: "New",
            SubMenu: [
                        {Name: "Auto", Link: "autos/new", AuthRoles: AuthRoles.STANDARD},
                        {Name: "Invoice", Link: "invoices/new", AuthRoles: AuthRoles.ACCOUNTING},
                        {Name: "Customer", Link: "customers/new", AuthRoles: AuthRoles.ACCOUNTING},
                        {Name: "Driver", Link: "drivers/new", AuthRoles: AuthRoles.ACCOUNTING},
                        {Name: "Auction", Link: "auctions/new", AuthRoles: AuthRoles.ALL},
                        {Name: "Payment", Link: "payments/new", AuthRoles: AuthRoles.STANDARD}
                ],
            AuthRoles: AuthRoles.STANDARD
        },
        {
            Name: "Edit",
            SubMenu: [
                        {Name: "Auto", Link: "autos/edit", AuthRoles: AuthRoles.STANDARD},
                        {Name: "Invoice", Link: "invoices/edit", AuthRoles: AuthRoles.ACCOUNTING},
                        {Name: "Customer", Link: "customers/edit", AuthRoles: AuthRoles.ACCOUNTING},
                        {Name: "Driver", Link: "drivers/edit", AuthRoles: AuthRoles.ACCOUNTING},
                        {Name: "Auction", Link: "auctions/edit", AuthRoles: AuthRoles.ALL}
                ],
            AuthRoles: AuthRoles.STANDARD
        },
        {
            Name: "Search",
            SubMenu:[
                        {Name: "Auto", Link: "autos", AuthRoles: AuthRoles.STANDARD},
                        {Name: "Invoice", Link: "invoices", AuthRoles: AuthRoles.ACCOUNTING},
                        {Name: "Customer", Link: "customers", AuthRoles: AuthRoles.ACCOUNTING},
                        {Name: "Driver", Link: "drivers", AuthRoles: AuthRoles.ACCOUNTING},
                        {Name: "Auction", Link: "auctions", AuthRoles: AuthRoles.ALL}
                ],
            AuthRoles: AuthRoles.STANDARD
        },
        {
            Name: "Utilities",
            SubMenu: [
                        {Name: "Mark Autos for Auction", Link: "autos/mark-for-auction", AuthRoles: AuthRoles.ALL},
                        {Name: "Mark Autos for T158", Link: "autos/mark-for-t158", AuthRoles: AuthRoles.STANDARD},
                        {Name: "Close Autos", Link: "autos/close", AuthRoles: AuthRoles.ALL},
                        {Name: "Auction Ad Email", Link: "email/auction-ad", AuthRoles: AuthRoles.ALL},
                        {Name: "Auction Ad Petition Email", Link: "email/auction-ad-petition", AuthRoles: AuthRoles.ALL},
                        {Name: "Print Blank MV603", Link: "print/mv-603/blank", AuthRoles: AuthRoles.STANDARD},
                        {Name: "Print Blank MV603R", Link: "print/mv-603r/blank", AuthRoles: AuthRoles.STANDARD},
                        {Name: "Print Blank MV603A", Link: "print/mv-603a/blank", AuthRoles: AuthRoles.STANDARD},
                        {Name: "Print Blank Court Order", Link: "print/court-order/blank", AuthRoles: AuthRoles.STANDARD},
                        {Name: "Print Blank Bill of Sale", Link: "print/bill-of-sale/blank",AuthRoles: AuthRoles.ALL},
                        //{Name: "Print Blank Customer Info Sheets", Link: ""},
                        //{Name: "Print Customer Verification Report", Link: ""},
                        //{Name: "Print Labels", Link: ""},
                        //{Name: "Uncheck Print Flags", Link: ""}
                ],
            AuthRoles: AuthRoles.ALL
        },
        {
            Name: "Reports",
            SubMenu: [
                        {Name: "Registration Request Report", Link: "print/registration-request-report", AuthRoles: AuthRoles.STANDARD},
                        {Name: "Notification Letters", Link: "print/notification-letters", AuthRoles: AuthRoles.ALL},
                        {Name: "T-158s", Link: "print/t-158", AuthRoles: AuthRoles.STANDARD},
                        {Name: "Daily Debit Report", Link: "print/daily-debit-report", AuthRoles: AuthRoles.ACCOUNTING},
                        {Name: "Daily Credit Report", Link: "print/daily-credit-report", AuthRoles: AuthRoles.ACCOUNTING},
                        {Name: "Daily Cash Sales", Link: "print/daily-cash-sales-report", AuthRoles: AuthRoles.ACCOUNTING},
                        {Name: "Statements", Link: "print/statements-pdf", AuthRoles: AuthRoles.ACCOUNTING},
                        {Name: "Aging Report", Link: "print/aging-report-pdf", AuthRoles: AuthRoles.ACCOUNTING},
                        {Name: "Driver Report", Link: "print/driver-report", AuthRoles: AuthRoles.ACCOUNTING},
                        {Name: "Driver Commission Due Report", Link: "print/driver-commission-due-report-pdf", AuthRoles: AuthRoles.ACCOUNTING},
                        {Name: "Customer Listing Report", Link: "print/customer-listing", AuthRoles: AuthRoles.STANDARD},
                        {Name: "Autos By Date Range Search", Link: "print/auto-by-date-range", AuthRoles: AuthRoles.STANDARD},
                        {Name: "Sales Report", Link: "print/sales-report-pdf", AuthRoles: AuthRoles.ACCOUNTING},

                        //{Name: "Auto Inventory Report", Link: ""}
                ],
            AuthRoles: AuthRoles.STANDARD
        },
        {
            Name: "Manage",
            SubMenu: [
                        {Name: "Charges Types", Link: "manage/charge-types", AuthRoles: AuthRoles.ADMIN},
                        {Name: "Impound Types", Link: "manage/impound-types", AuthRoles: AuthRoles.ADMIN},
                        {Name: "Makes", Link: "/makes", AuthRoles: AuthRoles.ADMIN},
                        {Name: "Models", Link: "/models", AuthRoles: AuthRoles.ADMIN},
                        {Name: "Officers", Link: "manage/officers", AuthRoles: AuthRoles.ADMIN},
                        {Name: "Statuses", Link: "manage/statuses", AuthRoles: AuthRoles.ADMIN},
                        {Name: "Status Descriptions", Link: "manage/status-descriptions", AuthRoles: AuthRoles.ADMIN},
                        {Name: "Users", Link: "users", AuthRoles: AuthRoles.ADMIN},
                        {Name: "Settings", Link: "manage/settings", AuthRoles: AuthRoles.ADMIN},
                ],
            AuthRoles: AuthRoles.ADMIN
        }
    ];

    updateMenuItems(opt? :MenuItem[]): void {
        opt = opt || this.MainMenu
        opt.forEach( item => {
            if( item.SubMenu && item.SubMenu.length > 0 ) {
                this.updateMenuItems(item.SubMenu)
            }
            item.AuthUpdate = true
        })
    }

    hasPermission( m: MenuItem ): boolean {
        if( m.HasPermission == undefined || m.AuthUpdate  ) {
            m.HasPermission = this.auths.HasPermission(m.AuthRoles);
            m.AuthUpdate = false;
        }
        return m.HasPermission;
    }
}
