import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from './user';
import { UserRole } from './user-role';
import { UserService } from './user.service';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { log } from '../common/dev/log';
import { AppService } from '../app.service';
import { UniqueUsernameValidator, passwordsEqualValidator } from '../common/validators';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
    selector: "edit-user",
    templateUrl: "edit-user.component.html"
})

export class EditUserComponent implements OnInit {

    getUsername = () => this.currentUser ? this.currentUser.Username : '';

    title: string;
    currentUser: User;
    userRoles: UserRole[];
    isEdit: boolean;
    users: User[]

    form: FormGroup;

    matcher = new MyErrorStateMatcher()

    constructor(
        private route: ActivatedRoute,
        private us: UserService,
        private apps: AppService,
        private router: Router
    ){
        this.currentUser = new User();
        this.form = this.createFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            userRoles: UserRole[],
            currentUser: User,
            edit: boolean,
            users: User[],
        }) => {

            log.Debug("data: ", data)
            log.Debug("user: ", this.currentUser.Username)

            this.title = data.title;
            this.userRoles = data.userRoles;
            this.isEdit = data.edit;
            this.currentUser = data.currentUser;
            this.users = data.users

            if(this.isEdit) {
                Object.assign(this.currentUser, data.currentUser);
                this.currentUser.Password = null;
                this.form.patchValue(this.currentUser);
            }
            
        });
    }

    createFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            Username: new FormControl(null, {
                validators: [Validators.required],
                asyncValidators: [UniqueUsernameValidator.createValidator({username: this.getUsername, service: this.us})]
            }),
            RoleID: new FormControl(null, Validators.required),
            Password: new FormControl(null, [Validators.minLength(12)]),
            ConfirmPassword: new FormControl()
        }, { validators: [passwordsEqualValidator] })
    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }

    onSubmit(e): void {

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched or invalid: ", this.form)
            log.Debug("hasErrors: ", this.form.hasError('passwordsNoMatch'))
            return;
        }

        this.save();
    }

    save() {
        this.us.save(this.form.value)
        .subscribe((savedUser: User) => {
            this.apps.Confirmation('User saved.');
            this.form.markAsPristine();

            if( !this.isEdit ) {
                this.currentUser = new User(savedUser);
                this.users.push(this.currentUser);
                this.router.navigate(['../', 'edit', this.currentUser.ID], {relativeTo: this.route});
                return
            }

            log.Debug("savedUser: ", savedUser)
            
            const index = this.users.findIndex( user => user.ID === this.currentUser.ID );
            if (index != -1) {
                Object.assign(this.users[index], new User(savedUser));
            }
            this.currentUser = savedUser
            this.form.patchValue(savedUser);
            this.router.navigate(['../..'], {relativeTo: this.route});
        })
    }

    delete(e) {
        e.preventDefault();
        e.stopPropagation();
        if (confirm('This user will be deleted. Are you sure?')) {
            this.us.delete(this.currentUser).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('User deleted.');
                    const index = this.users.findIndex( user => user.ID === this.currentUser.ID);
                    this.users.splice(index, 1);
                    this.router.navigate(['dashboard']);
                }
            })
        }
    }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && control.parent.dirty && control.touched);
        const invalidParent = !!(control && control.parent && control.parent.hasError('passwordsNoMatch') && control.parent.dirty && control.touched);
        log.Debug("control parent: ", control.parent)

        return (invalidCtrl || invalidParent)   
    }
}