<button mat-raised-button (click)="print()" color="primary" style="margin-bottom: 10px; float: left; z-index:100;"><mat-icon>print</mat-icon>Print</button>
<div id="print-section" style="position: relative;">
    <div class="print-out-container">
        <div class="print-out">
            <p style="text-align: center;">IN THE MAGISTRATE COURT OF CLAYTON COUNTY</p>
            <p style="text-align: center;">STATE OF GEORGIA</p>
            <div class="row" style="padding: 20px 0;">
                <div class="col" style="margin-top: auto; margin-bottom: auto; padding: 20px 40px; border-right: 1px solid black;">
                    <p>Plaintiff,</p>
                    <p>vs.</p>
                    <p style="margin-bottom: 0;">Defendant.</p>
                </div>
                <div class="col" style="margin-top: auto; margin-bottom: auto; padding-left: 40px;padding-right: 40px;">
                    <p>CASE No.: <span *ngIf="isBlank">_______________________</span><span *ngIf="!isBlank" class="bold-underline">{{ fields.CaseNumber }}</span></p>
                    <p style="margin-bottom: 0;">Foreclosure of Lien on Abandoned Motor Vehicle</p>
                </div>
            </div>
            <p class="bold-underline" style="text-align: center;">Order</p>
            <p><span style="padding-left: 40px;"></span>The Plaintiff having demonstrated to the Court that the following vehicle:</p>
            <div class="row">
                <div class="col">
                    <p *ngIf="isBlank" style="text-align: center; border-top: 1px solid black;">(Year)</p>
                    <p class="bold-underline" *ngIf="!isBlank" style="text-align: center; margin-bottom: 0;">{{ fields.Year }}</p>
                    <p *ngIf="!isBlank" style="text-align: center;">(Year)</p>
                </div>
                <div class="col">
                    <p *ngIf="isBlank" style="text-align: center; border-top: 1px solid black;">(Make)</p>
                    <p class="bold-underline" *ngIf="!isBlank" style="text-align: center; margin-bottom: 0;">{{ fields.Make }}</p>
                    <p *ngIf="!isBlank" style="text-align: center;">(Make)</p>
                </div>
                <div class="col">
                    <p *ngIf="isBlank" style="text-align: center; border-top: 1px solid black;">(Model)</p>
                    <p class="bold-underline" *ngIf="!isBlank" style="text-align: center; margin-bottom: 0;">{{ fields.Model }}</p>
                    <p *ngIf="!isBlank" style="text-align: center;">(Model)</p>
                </div>
                <div class="col">
                    <p *ngIf="isBlank" style="text-align: center; border-top: 1px solid black;">(Vehicle Identification Number)</p>
                    <p class="bold-underline" *ngIf="!isBlank" style="text-align: center; margin-bottom: 0;">{{ fields.VIN }}</p>
                    <p *ngIf="!isBlank" style="text-align: center;">(Vin)</p>
                </div>
            </div>
            <p>is an abandoned vehicle as described in O.C.G.A. S40-11-1(1) and subject to a lien pursuant to ).C.G.A. S40-11-4; and</p>
            <p><span style="padding-left: 40px;"></span>The Plaintiff having submitted an affidavit as required in O.C.G.A. S40-11-5(2) wherein compliance with the requirements of said statue for the foreclosure of said lien has been demonstrated; and</p>
            <p><span style="padding-left: 40px;"></span>No timely petition for a hearing has been filed with the Court or Plaintiff has otherwise proven by a preponderance of the evidence that such lien is valid;</p>
            <p class="bold"><span style="padding-left: 40px;"></span>IT IS HEREBY ORDERED THAT:</p>
            <p><span style="padding-left: 40px;"></span>The lien be established on the above described vehicle in the amount of <span *ngIf="isBlank">_____</span><span class="bold-underline" *ngIf="!isBlank">{{ fields.Amount | currency }}</span> through <span *ngIf="isBlank">__________</span><span class="bold-underline" *ngIf="!isBlank">{{ fields.LienEndDate }}</span>, in accordance with O.C.G.A. S40-11-6 governing Abandoned Motor Vehicles; that foreclosure thereof be allowed through public sale in accordance with the terms and provisions of O.C.G.A. S40-11-6 and S40-11-201, that the vehicle be sold free and clear of any and all liens and encumbrances. ( ) The Plaintiff is entitled to additional storage charges through the date of sale at the rate of <span *ngIf="isBlank">_____</span><span class="bold-underline" *ngIf="!isBlank">{{ fields.StoragePerDay | currency }}</span> per day.</p>
            <p>    Within thirty (30) days of the date of such sale, the Plaintiff shall file a report to the Court in compliance with O.C.G.A. S40-11-6.</p>
            <p><span style="padding-left: 40px;"></span>SO ORDERED this <span *ngIf="isBlank">___</span><span class="bold-underline" *ngIf="!isBlank">{{ fields.CurrentDay }}</span> day of <span *ngIf="isBlank">___________</span><span class="bold-underline" *ngIf="!isBlank">{{ fields.CurrentMonth }}</span>, <span *ngIf="isBlank">______</span><span class="bold-underline" *ngIf="!isBlank">{{ fields.CurrentYear }}</span>.</p>
            <div>
                <p style="text-align: right;"><span style="border-top: 1px solid black;">Judge, Magistrate Court of Clayton County</span></p>
            </div>
        </div>
    </div>
</div>