<button mat-raised-button (click)="print()" color="primary" style="margin-bottom: 10px; float: left; z-index:100;"><mat-icon>print</mat-icon>Print</button>

<div id="print-section" style="position: relative;">
    <div class="print-out-container" *ngFor="let count of copies">
        <p style="page-break-before: always; margin-bottom: 0;"></p>
        <div class="print-out" style="font-size: 19px;">
            <p class="bold">BILL OF SALE WITHOUT WARRANTY</p>
            <div class="row">
                <div class="col">
                    <p>Unit Number: <span class="bold">{{ fields.UnitNumber }}</span></p>
                </div>
                <div class="col" style="text-align: right;">
                    <div class="row gutters">
                        <div class="col--size-2">
                            <p style="text-align: right;">Date:</p>
                            <p style="text-align: right;">Case Number:</p>
                        </div>
                        <div class="col">
                            <p style="text-align: left;"><span class="bold">{{ fields.TransactionDate | date: 'longDate': 'UTC' }}</span></p>
                            <p style="text-align: left;"><span class="bold">{{ fields.CaseNumber }}</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <p>The follwing described property is hereby sold "AS IS" to:</p>
            <div class="row" style="border-bottom: 2px solid black;margin-bottom: 10px">
                <div class="col--size-2">
                    <p style="margin-bottom: 3px;"><span class="bold">{{ fields.Name }}</span></p>
                </div>
                <div class="col">
                    <p style="margin-bottom: 3px; text-align: center;"><span class="bold">{{ fields.Address }}</span></p>
                </div>
                <div class="col">
                    <p style="margin-bottom: 3px; text-align: center;"><span class="bold">{{ fields.CityState }}</span></p>
                </div>
                <div class="col">
                    <p style="margin-bottom: 3px; text-align: right;"><span class="bold">{{ fields.ZipCode }}</span></p>
                </div>
            </div>
            <div class="row">
                <div class="col--size-2">
                    <p>Name of individual or company</p>
                </div>
                <div class="col" style="text-align: center;">
                    <p>Address</p>
                </div>
                <div class="col" style="text-align: center;">
                    <p>City / State</p>
                </div>
                <div class="col" style="text-align: right;">
                    <p>Zip Code</p>
                </div>
            </div>
            <p>It is expressly understood that a condition of this sale is that Tara Wrecker, Inc. MAKES NO WARRANTY EITHER EXPRESSED OR IMPLIED AS TO THE TITLE TO THE PROPERTY, its suitability for purpose nor to any defects either latent or patent. Any subsequent of title that may paramount to that of the purchaser as derived from this sale shall not entitle the purchaser to recieve any refund of any portion of the purchase price paid for the articles described herein:</p>
            <div class="row" style="border-bottom: 2px solid black; margin-bottom: 10px;">
                <div class="col">
                    <p class="bold" style="margin-bottom: 3px;">{{ fields.YearMakeModel}}</p>
                </div>
                <div class="col" style="text-align: center;">
                    <p class="bold" style="margin-bottom: 3px;">{{ fields.VIN }}</p>
                </div>
                <div class="col" style="text-align: right;">
                    <p class="bold" style="margin-bottom: 3px;">{{ fields.Amount | currency }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p>Year / Make / Model</p>
                </div>
                <div class="col" style="text-align: center;">
                    <p>VIN</p>
                </div>
                <div class="col" style="text-align: right;">
                    <p>Amount Paid</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                </div>
                <div class="col">
                    <div class="row gutters">
                        <div class="col" style="text-align: right;">
                            <p style="margin-bottom: 10px;">0% Sales Tax</p>
                            <p>Total Sale</p>
                        </div>
                        <div class="col" style="text-align: right;">
                            <p class="bold" style="border-bottom: 1px solid black; margin-bottom: 10px;">{{ fields.SalesTax | currency }}</p>
                            <p class="bold">{{ fields.TotalSale | currency }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gutters" style="margin-bottom: 40px;">
                <div class="col" style="text-align: right;">
                    <p class="bold">By:</p>
                    <p class="bold">Buyer Signature: </p>
                    <p class="bold">Odometer Reading: </p>
                </div>
                <div class="col--size-3">
                    <p class="bold">____________________________________________________</p>
                    <p class="bold">____________________________________________________</p>
                    <p class="bold">____________________________________________________</p>
                </div>
            </div>
            <p>BUYER ACKNOWLEDGES THE FOLLOWING:</p>
            <p>ANY AND ALL VEHICLES PURCHASED FROM TARA WRECKER, INC. BY YOU ARE PURCHASED IN AN AS IS CONDITION, NOT FOR HIGHWAY USE. ALL VEHICLES ARE TO BE TOWED OFF PROPERTY. IF ANY VEHICLE YOU PURCHASE IS EVER INTENDED FOR HIGHWAY USE, IT WILL BE THE OWNER'S (PURCHASER'S) RESPONSIBILITY TO GET ALL VEHICLES IN COMPLIANCE WITH THE STATE EMISSIONS LAWS.</p>
        </div>
    </div>
</div>