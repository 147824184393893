import { Routes } from '@angular/router';

import { ManageStatusDescriptionsComponent } from './manage-status-descriptions.component';
import { StatusDescriptionsResolve } from './status-descriptions.resolve';

export const STATUS_DESCRIPTION_ROUTES: Routes = [
    {
        path: 'status-descriptions',
        component: ManageStatusDescriptionsComponent,
        data: {
            title: 'Manage Status Descriptions'
        },
        resolve: {
            statusDescriptions: StatusDescriptionsResolve
        }
    }
]