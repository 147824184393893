import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TransactionSearch } from './transaction-search';
import { Transaction } from './transaction';

@Injectable()

export class TransactionsSearchService {

    constructor(
        private http: HttpClient,
    ){}

    SearchTransactions( transaction: Transaction, pageIndex = 0, pageSize = 10): Observable<Transaction[]> {

        const search = new TransactionSearch({ Transaction: transaction, PageIndex: pageIndex, PageSize: pageSize })

        return this.http.post<Transaction[]>('/api/transactions/search', search).pipe(val => val)
    }

    GetResultsLength( transaction: Transaction ): Observable<number> {
        return this.http.post<number>('/api/transactions/search-results-length', transaction).pipe(val => val)
    }

}