import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from '../common/dev/log';
import { Driver } from '../driver';

@Component({
    selector: "driver-report",
    templateUrl: "./driver-report.component.html"
})

export class DriverReportComponent implements OnInit {

    report: Driver[];
    currentDate = new Date()

    constructor (
        private route: ActivatedRoute,
    ){}
    ngOnInit(): void {
        this.route.data.subscribe((data: {
            report: Driver[],
        }) => {
            log.Debug("data: ", data)

            this.report = data.report
        });
    }

    getName( company: string, lastName: string, firstName: string ) {
        if(!this.empty(company)) {
            return company
        }

        if(!this.empty(lastName)) {
            if(!this.empty(firstName)) {
                return firstName + ' ' + lastName
            }
            return lastName
        }

        return ""
    }

    getCityStateZip(city: string, state: string, zip: string): string {

        let cityValid = !this.empty(city);
        let stateValid = !this.empty(state);
        let zipValid = !this.empty(zip);

        if( cityValid && stateValid && zipValid ) {
            return city + ', ' + state + ' ' + zip;
        } else if( cityValid && stateValid && !zipValid ) {
            return city + ', ' + state;
        } else if( !cityValid && stateValid && zipValid ) {
            return state + ' ' + zip;
        } else if(!cityValid && stateValid && !zipValid ) {
            return state;
        } else if( cityValid && !stateValid && zipValid ) {
            return city + ' ' + zip;
        } else if( cityValid && !stateValid &&  !zipValid ) {
            return city;
        } else if( !cityValid && !stateValid && zipValid ) {
            return zip;
        }

        return '';

    }

    print(): void {
        window.print();
    }

    empty(str: string): boolean {
        if( '' != str && null != str && undefined != str ) {
            return false;
        }
        return true;
    }
}