import { Model } from '../common/model';
import { Invoice } from './invoice';


export class Statement extends Model {

    CustomerNumber: string;
    Company: string;
    FirstName: string;
    LastName: string;
    Address1: string;
    Address2: string;
    City: string;
    State: string;
    Zip: string;
    Invoices: Invoice[];
    CurrentDue: number;
    ThirtyDayDue: number;
    SixtyDayDue: number;
    NinetyDayDue: number;
    ServiceCharge: number;
    TotalPaid: number;
    TotalDue: number;
    Active: boolean;
    Created: Date;
    Modified: Date;

    constructor(properties?: Object) {
        super(properties)
    }
}