import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from '../common/dev/log';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { debounceTime, map, concatMap, toArray, tap, switchMap, take, reduce } from 'rxjs/operators';
import { Observable, of, from } from 'rxjs';
import { AuthService } from './auth.service';
import { User } from '../user';
import { ChangeDetectorRef, AfterContentInit  } from '@angular/core'

@Component({
    selector: "logout",
    templateUrl: "./logout.component.html"
})

export class LogoutComponent implements OnInit, AfterContentInit {

    constructor (
        private route: ActivatedRoute,
        private auths: AuthService,
        private apps: AppService,
        private router: Router,
        private changeRef: ChangeDetectorRef

    ){}

    ngOnInit(): void {
        
    }

    ngAfterContentInit(): void {
        this.route.data.subscribe((data: {
            isLoggedOut: boolean
        }) => {
            log.Debug("data: ", data)
            if( data.isLoggedOut ) {
                // this.apps.Confirmation("You have been logged out.").toPromise().then(
                //    _ => this.router.navigate(["..", "login"], {relativeTo: this.route})
                // )
                this.router.navigate(["..", "login"], {relativeTo: this.route})
                //this.changeRef.detectChanges();
            }
        })
    }

}