import { Model } from '../common/model';
import { AutoSearchData } from './auto-search-data';

export class AutoSearch extends Model {

    AutoAearchData: AutoSearchData;
    Filter: string;
    SortOrder: string;
    PageIndex: number;
    PageSize: number;

    constructor(properties?: Object) {
        super(properties);
    }

}