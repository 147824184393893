<button class="print-button" mat-raised-button (click)="print()" color="primary" style="margin-bottom: 10px;"><mat-icon>print</mat-icon>Print</button>
<mat-card class="small-form no-print" style="margin-bottom: 30px;">
    <form [formGroup]="form">
        <div class="row gutters">
            <div class="col">
                <mat-form-field>
                    <mat-select placeholder="Frequency" formControlName="StatementFrequency">
                        <mat-option value="Monthly">Monthly</mat-option>
                        <mat-option value="Weekly">Weekly</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-card>
<div id="print-section" style="position: relative;">
    <div class="spinner-container" *ngIf="isLoading" style="position: absolute; left: 50%; top: 200px; transform: translate(-50%, 0);">
        <mat-spinner></mat-spinner>
    </div>
    <div class="statements print-out-container">
        <ng-container *ngIf="(statements | async) as list">
            <ng-container *ngIf="list.length > 0; else empty">
                <div class="statement print-out" *ngFor="let statement of list">
                    <div class="row gutters left-center-right">
                        <div class="col">
                            <p class="bold marginless">{{ fields.CompanyName }}</p>
                            <p class="bold marginless">{{ fields.CompanyPOBox }}</p>
                            <p class="bold marginless">{{ fields.CityStateZip }}</p>
                        </div>
                        <div class="col">
                            <p class="title bold">Statement</p>
                        </div>
                        <div class="col">
                            <p>{{ fields.CurrentDate | date:'EEEE, MMMM dd, yyyy':'UTC' }}</p>
                            <p>Account #: {{ statement.CustomerNumber }}</p>
                            <p class="bold">Please pay within 10 days of receipt of this Statement</p>
                        </div>
                    </div>
                    <div class="row gutters margin-bottom">
                        <div class="col">
                            <p class="bold marginless">{{ getName(statement.Company, statement.LastName, statement.FirstName) }}</p>
                            <p class="bold marginless">{{ getAddress(statement.Address1, statement.Address2) }}</p>
                            <p class="bold marginless">{{ getCityStateZip(statement.City, statement.State, statement.Zip) }}</p>
                        </div>
                    </div>
                    <div class="row gutters border-bottom">
                        <div class="col">
                            <p class="bold">Invoice:</p>
                        </div>
                        <div class="col">
                            <p class="bold">Inv. Date:</p>
                        </div>
                        <div class="col">
                            <p class="bold">PO Number:</p>
                        </div>
                        <div class="col">
                            <p class="bold">Amount:</p>
                        </div>
                        <div class="col">
                            <p class="bold">Paid:</p>
                        </div>
                        <div class="col">
                            <p class="bold">Due:</p>
                        </div>
                    </div>
                    <div class="row gutters invoices" *ngFor="let invoice of statement.Invoices">
                        <div class="col">
                            <p class="marginless">{{ invoice.InvoiceNumber }}</p>
                        </div>
                        <div class="col text-right">
                            <p class="marginless">{{ invoice.Invoiced | date:'M/d/yyyy':'UTC' }}</p>
                        </div>
                        <div class="col">
                            <p class="marginless">{{ invoice.PONumber }}</p>
                        </div>
                        <div class="col text-right">
                            <p class="marginless">{{ invoice.RemainingBalance + invoice.Paid | currency }}</p>
                        </div>
                        <div class="col text-right">
                            <p class="marginless">{{ invoice.Paid | currency }}</p>
                        </div>
                        <div class="col text-right">
                            <p class="marginless">{{ invoice.RemainingBalance | currency }}</p>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col text-right border-top">
                            <p class="marginless">{{ statement.TotalDue + statement.TotalPaid | currency }}</p>
                        </div>
                        <div class="col text-right border-top">
                            <p class="marginless">{{ statement.TotalPaid | currency }}</p>
                        </div>
                        <div class="col text-right border-top">
                            <p class="marginless">{{ statement.TotalDue | currency }}</p>
                        </div>
                    </div>
                    <div class="row gutters margin-top">
                        <div class="col text-right">
                            <p class="bold marginless">Current::</p>
                            <p class="marginless">{{ statement.CurrentDue | currency }}</p>
                        </div>
                        <div class="col text-right">
                            <p class="bold marginless">Thirty::</p>
                            <p class="marginless">{{ statement.ThirtyDayDue | currency }}</p>
                        </div>
                        <div class="col text-right">
                            <p class="bold marginless">Sixty::</p>
                            <p class="marginless">{{ statement.SixtyDayDue | currency }}</p>
                        </div>
                        <div class="col text-right">
                            <p class="bold marginless">Ninety::</p>
                            <p class="marginless">{{ statement.NinetyDayDue | currency }}</p>
                        </div>
                        <div class="col text-right">
                            <p class="bold marginless">Svc Chg::</p>
                            <p class="marginless">{{ 0 | currency }}</p>
                        </div>
                        <div class="col text-right">
                            <p class="bold marginless">Total Due::</p>
                            <p class="marginless">{{ statement.TotalDue | currency }}</p>
                        </div>
                    </div>
                    <div class="pagebreak"></div>
                </div>
            </ng-container>
            <ng-template #empty><p style="margin-top: 60px; text-align: center;">No statements found.</p></ng-template>
        </ng-container>
    </div>
</div>