<button class="print-button" mat-raised-button (click)="print()" color="primary" style="margin-bottom: 10px;"><mat-icon>print</mat-icon>Print</button>
<div id="print-section" style="position: relative;">
    <div class="report-container print-out-container">
        <div class="report report--customer-listing print-out">
            <p class="title bold">Driver Report</p>
            <p class="bold">{{ currentDate | date: 'M/d/yyyy': 'UTC' }}</p>
            <p></p>
            <div class="row gutters border-bottom">
                <div class="col">
                    <p class="bold">Name</p>
                </div>
                <div class="col">
                    <p class="bold">%</p>
                </div>
                <div class="col">
                    <p class="bold">Initials</p>
                </div>
                <div class="col">
                    <p class="bold">Radio #</p>
                </div>
            </div>
            <div class="row gutters" *ngFor="let driver of report">
                <div class="col">
                    <p>{{ driver.FirstName }} {{ driver.LastName }}</p>
                </div>
                <div class="col">
                    <p>{{ driver.Commission }}</p>
                </div>
                <div class="col">
                    <p>{{ driver.DriverNumber }}</p>
                </div>
                <div class="col">
                    <p>{{ "" }}</p>
                </div>
            </div>
        </div>
    </div>
</div>