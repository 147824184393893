import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map, tap } from 'rxjs/operators';
import { log } from '../common/dev/log';
import { Invoice } from './invoice';
import { InvoiceService } from './invoice.service';
import { STATES } from '../common/state';
import { InvoicesDataSource } from './invoices-data-source'
import { MatPaginator } from '@angular/material/paginator'
import { InvoicesSearchService } from './invoices-search.service'

@Component({
    selector: "search-invoices",
    templateUrl: "./search-invoices.component.html"
})

export class SearchInvoicesComponent implements OnInit {

    filteredInvoices;

    title: string;

    form: FormGroup;
    public states = STATES;

    displayedColumns: string[] = ['invoice_number', 'date', 'driver_numbers', 'customer_number', 'remaining_balance']
    dataSource: InvoicesDataSource

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    constructor (
        private route: ActivatedRoute,
        private is: InvoiceService,
        private iss: InvoicesSearchService
    ){
        this.form = this.createFormGroup()
    }
    ngOnInit(): void {

        this.dataSource = new InvoicesDataSource(this.iss)
        this.dataSource.loadInvoices(new Invoice())
        this.dataSource.updateLength(new Invoice())

        this.route.data.subscribe((data: {
            title: string,
            invoices: Invoice[],
        }) => {
            log.Debug("data: ", data)

            this.title = data.title;

        });
    }

    ngAfterViewInit() {
        this.paginator.page.pipe(
            tap(() => this.loadNextPage())
        ).subscribe();
    }

    loadNextPage() {
        this.dataSource.loadInvoices(this.beforeSubmit(), this.paginator.pageIndex, this.paginator.pageSize)
    }

    createFormGroup() {
        return new FormGroup({
            InvoiceNumber: new FormControl(),
            BeginDate: new FormControl(),
            EndDate: new FormControl(),
            DriverNumber: new FormControl(),
            CustomerNumber: new FormControl(),
            Name: new FormControl(),
            RemainingBalance: new FormControl(),
        });
    }

    reset(e) {
        e.preventDefault()
        e.stopPropagation()

        this.form.reset();
    }

    beforeSubmit(): Invoice {
        let invoice = new Invoice(this.form.value)
        return Object.keys(invoice).reduce((acc, cur) => {
            const value = invoice[cur]
            if (value != null) {
                acc[cur] = value
            }
            return acc
        }, new Invoice());
    }

    onSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        this.paginator.pageIndex = 0
        this.loadNextPage()
        this.dataSource.updateLength(this.beforeSubmit())
    }
}