import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TransactionService } from './transaction.service';
import { Transaction } from './transaction';

@Injectable()
export class CurrentTransactionResolve implements Resolve<Transaction> {
    constructor (
        private router: Router,
        private ts: TransactionService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<Transaction> {
        const transaction_id = +route.paramMap.get('transaction_id');
        return this.ts.Get(transaction_id);
    }
}