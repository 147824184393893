import { Model } from '../common/model';

export class UserRole extends Model {

    Name: string;
    Active: boolean;
    Created: Date;
    Modified: Date;

    constructor(properties?: Object) {
        super(properties)
    }
}