import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from '../common/dev/log';
import { MONTHS } from '../common/month';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Driver, DriverReport, DriverInvoice, DriverService } from '../driver';
import { FormGroup, FormControl } from '@angular/forms';
import { map, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: "driver-commission-due-report",
    templateUrl: "./driver-commission-due-report.component.html"
})

export class DriverCommissionDueReportComponent implements OnInit {

    reports: Observable<DriverReport[]>;
    private _months = MONTHS
    moment = new MomentDateAdapter('L');
    currentDate = this.moment.today()

    form: FormGroup

    constructor (
        private route: ActivatedRoute,
        private ds: DriverService
    ){
        this.form = this.createFormGroup()
    }
    ngOnInit(): void {
        this.route.data.subscribe((data: {
            reports: DriverReport[],
        }) => {
            log.Debug("data: ", data)

            //this.reports = data.reports

            this.form.get('StartDate').valueChanges.pipe(
                map(value => this.ds.SearchDriverReports(this.form.value))
            ).subscribe( value => this.reports = value)
            this.form.get('EndDate').valueChanges.pipe(
                map(value => this.ds.SearchDriverReports(this.form.value))
            ).subscribe( value => this.reports = value)
            this.form.get('DriverNumber').valueChanges.pipe(
                debounceTime(500),
                map(value => this.ds.SearchDriverReports(this.form.value))
            ).subscribe( value => this.reports = value)
        });
    }

    createFormGroup(): FormGroup {
        return new FormGroup({
            StartDate: new FormControl(),
            EndDate: new FormControl(),
            DriverNumber: new FormControl()
        })
    }

    getDate() {
        if( !this.empty(this.form.value.StartDate) && !this.empty(this.form.value.EndDate) ) {
            return 
        }
        return this.currentDate
    }

    getName( number: string, lastName: string, firstName: string ) {
        if(!this.empty(number)) {
            if(!this.empty(lastName)) {
                if(!this.empty(firstName)) {
                    return number + ' ' + firstName + ' ' + lastName
                } else {
                    return number + ' ' + lastName
                }
            } else {
                if(!this.empty(firstName)) {
                    return number + ' ' + firstName
                }
            }
            return number
        }

        return ""
    }

    getCityStateZip(city: string, state: string, zip: string): string {

        let cityValid = !this.empty(city);
        let stateValid = !this.empty(state);
        let zipValid = !this.empty(zip);

        if( cityValid && stateValid && zipValid ) {
            return city + ', ' + state + ' ' + zip;
        } else if( cityValid && stateValid && !zipValid ) {
            return city + ', ' + state;
        } else if( !cityValid && stateValid && zipValid ) {
            return state + ' ' + zip;
        } else if(!cityValid && stateValid && !zipValid ) {
            return state;
        } else if( cityValid && !stateValid && zipValid ) {
            return city + ' ' + zip;
        } else if( cityValid && !stateValid &&  !zipValid ) {
            return city;
        } else if( !cityValid && !stateValid && zipValid ) {
            return zip;
        }

        return '';

    }

    print(): void {
        window.print();
    }


    formatDate(date: Date): string {
        let tempDate = this.moment.parse(date, 'YYYY-MM-DDTHH:mm:ssZ');
        return this.moment.getMonth(tempDate) + '/' + this.moment.getDate(tempDate) + '/' + this.moment.getYear(tempDate);
    }

    empty(str: string): boolean {
        if( '' != str && null != str && undefined != str ) {
            return false;
        }
        return true;
    }

    getTotalCommission( invoice: DriverInvoice[] ): number {
        return invoice.reduce( (acc, cur) => acc + cur.Commission, 0 );
    }
    getTotalAmount( invoice: DriverInvoice[] ): number {
        return invoice.reduce( (acc, cur) => acc + cur.Amount, 0 ) ;
    }
}