import { Model } from '../common/model';

export class Note extends Model {

    AutoID: number;
    Value: string;
    Created: Date;
    Modified: Date;

    // constructor(properties?: Object) {
    //     super(properties);
    // }

}