import { Model } from '../common/model';

export class Charge extends Model {

    AutoID: number;
    Name: string;
    Charge: number;
    Created: Date;
    Modified: Date;

    // constructor(properties?: Object) {
    //     super(properties);
    // }

}