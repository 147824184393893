import { Model } from '../common/model';

export class InvoicesDriver extends Model {

    CommissionPercentage: number;
    CommissionValue: number;
    Commission: number;
    DriverID: number;
    InvoiceID: number;
    DriverNumber: string;
    IsCommissionOnly: boolean;
    Active: boolean;
    Created: Date;
    Modified: Date;

    constructor(properties?: Object) {
        super(properties)
    }
}