<div class="list-dialog" style="width: 100%; max-width: 500px;">
    <h2 mat-dialog-title>{{ data['title'] }}</h2>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
        <div mat-dialog-content>
            <div class="notes-form-array" formArrayName="Notes" *ngFor= "let note of form.get('Notes')['controls']; let i = index">
                <div class="form-group-container" [formGroupName]="i">
                    <div class="row gutters">
                        <div class="col--size-3" style="padding: 0 10px 0 0;">
                            <mat-form-field>
                                <textarea matInput
                                    type="text"
                                    placholder="Note"
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="1"
                                    formControlName="Value"></textarea>
                            </mat-form-field>
                        </div>
                        <div class="col" style="margin: auto;">
                            <button type="button" mat-raised-button color="warn" (click)="deleteNote($event, i)" style="display: block; margin: 0 auto;"><mat-icon>delete</mat-icon></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div mat-dialog-actions>
            <button type="button" mat-raised-button color="primary" (click)="addNote()"><mat-icon>add</mat-icon>Add</button>
            <button type="submit" mat-raised-button color="primary"><mat-icon>save</mat-icon>Save</button>
            <button type="button" mat-raised-button (click)="close()" color="primary"><mat-icon>close</mat-icon>Close</button>
        </div>
    </form>
</div>
<!-- <pre class="debug">{{ form.value | json }}</pre> -->