import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DeleteResponse } from '../common/delete-response';
import { Make } from './make';
import { log } from '../common/dev/log';

@Injectable({
    providedIn: 'root'
})

export class MakeService extends HttpService implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    GetAll(): Observable<Make[]> {
        return this.http.get<Make[]>('/api/makes/all').pipe(map(this.toMake));
    }

    GetByID(id: number): Observable<Make> {
        return this.http.get<Make>('/api/makes/get/' + id);
    }

    toMake(make) {
        const temp = make.map( make => {
            return new Make(make);
        });
        return temp;
    }

    save(make: Make): Observable<Make> {
        if (!!make.ID) {
            return this.http.put<Make>('/api/makes/update/' + make.ID, make);
        }
        return this.http.post<Make>('/api/makes/create', make);
    }

    saveAll(makes: Make): Observable<Make> {
        return this.http.put<Make>('/api/makes/update_all',makes);
    }

    delete(make: Make): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/makes/delete/' + make.ID)
    }

    UpdateMakesModels(): Observable<string> {
        return this.http.get<string>('/api/makes/updatemakesmodels').pipe(val => val)
    }

    SearchByValue(value: string): Observable<Make[]> {
        log.Debug("value: ", value)
        return this.http.post<Make[]>('/api/makes/search-by-value', {Name: value}).pipe(map(val => val));
    }

}