import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { StatusDescriptionService } from './status-description.service';
import { StatusDescription } from './status-description';

@Injectable()
export class StatusDescriptionsResolve implements Resolve<StatusDescription[]> {
    constructor (
        private router: Router,
        private sds: StatusDescriptionService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<StatusDescription[]> {
        return this.sds.GetAll();
    }
}