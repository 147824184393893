<mat-card class="edit-make small-form">
    <h1 class="title">{{ title }}</h1>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
        <mat-card-content>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input type="text" matInput placeholder="Make" formControlName="Name">
                        <mat-error *ngIf="form.get('Name')?.invalid">{{ getErrorMessage(form.get('Name')) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions end>
            <button mat-raised-button color="primary" type="submit"><mat-icon>save</mat-icon>Save</button>
            <button type="button" mat-raised-button color="warn" *ngIf="isEdit" (click)="delete($event)"><mat-icon>delete</mat-icon>Delete</button>
        </mat-card-actions>
    </form>
</mat-card>
