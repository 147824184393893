import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CustomerSearch } from './customer-search';
import { Customer } from './customer';

@Injectable()

export class CustomersSearchService {

    constructor(
        private http: HttpClient,
    ){}

    SearchCustomers( customer: Customer, pageIndex = 0, pageSize = 10): Observable<Customer[]> {

        const search = new CustomerSearch({ Customer: customer, PageIndex: pageIndex, PageSize: pageSize })

        return this.http.post<Customer[]>('/api/customers/search', search).pipe(val => val)
    }

    GetResultsLength( customer: Customer ): Observable<number> {
        return this.http.post<number>('/api/customers/search-results-length', customer).pipe(val => val)
    }

}