import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DeleteResponse } from '../common/delete-response';
import { ImpoundType } from './impound-type';

@Injectable({
    providedIn: 'root'
})

export class ImpoundTypeService extends HttpService implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    GetAll(): Observable<ImpoundType[]> {
        return this.http.get<ImpoundType[]>('/api/impound_types/all').pipe(map(this.toImpoundType));
    }

    toImpoundType(impoundType) {
        const temp = impoundType.map( impoundType => {
            return new ImpoundType(impoundType);
        });
        return temp;
    }

    save(impoundTypes: ImpoundType[]): Observable<ImpoundType[]> {
        return this.http.put<ImpoundType[]>('/api/impound_types/update_all', impoundTypes);
    }

    delete(impoundType: ImpoundType): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/impound_types/delete/' + impoundType.ID)
    }

}