import { Model } from '../common/model';

import { Auto } from '../auto';
import { Invoice } from '../invoice';

export class EmailData extends Model {

    To: string;
    From: string;
    Message: string;
    CustomMessage: string;
    PetitionDeadlineDate: string;
    Autos: Auto[]
    Invoice: Invoice
    Date: string;
    Name: string;
    Address: string;
    City: string;
    State: string;
    Zip: string;
    County: string;
    Phone: string;
    TemplateID: string;

    constructor(properties?: Object) {
        super(properties)
    }
}