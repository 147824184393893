import { Routes } from '@angular/router';

import { EditAutoComponent } from './edit-auto.component';
import { SearchAutosComponent } from './search-autos.component';
import { MarkAuctionAutosComponent } from './mark-auction-autos.component';
import { MarkT158AutosComponent } from './mark-t158-autos.component';
import { CloseAutosComponent } from './close-autos.component';
import { CloseAutosResolve } from './close-autos.resolve';
import { CurrentAutoResolve } from './current-auto.resolve';
import { AutosResolve } from './autos.resolve';
import { AutoNumbersResolve } from './auto-numbers.resolve';
import { NextAutoNumberResolve } from './next-auto-number.resolve';
import { StatusesResolve } from '../status';
import { StatusDescriptionsResolve } from '../status-description';
import { MakesResolve } from '../make';
import { AutoModelsResolve } from '../auto-model';
import { ImpoundTypesResolve } from '../impound-type';
import { ChargeTypesResolve } from '../charge-type';
import { FeedsResolve } from '../feed';
import { NotesResolve } from '../note';

import { INVOICE_AUTO_ROUTES } from '../invoice/invoice.routes';

export const AUTO_ROUTES: Routes = [
    {
        path: '',
        component: SearchAutosComponent,
        data: {
            title: 'Search Autos'
        },
        resolve: {
            statuses: StatusesResolve,
            impoundTypes: ImpoundTypesResolve,
            statusDescriptions: StatusDescriptionsResolve,
        }
    },
    {
        path: 'new',
        component: EditAutoComponent,
        data: {
            title: 'New Auto',
            edit: false,
            search: false,
        },
        resolve: {
            nextAutoNumber: NextAutoNumberResolve,
            statuses: StatusesResolve,
            impoundTypes: ImpoundTypesResolve,
            chargeTypes: ChargeTypesResolve,
            statusDescriptions: StatusDescriptionsResolve,
        }
    },
    {
        path: 'edit',
        children: [
            {
                path: '',
                component: EditAutoComponent,
                data: {
                    title: 'Edit Auto',
                    edit: false,
                    search: true,
                }
            },
            {
                path: ':auto_id',
                component: EditAutoComponent,
                data: {
                    title: 'Edit Auto',
                    edit: true,
                    search: false,
                },
                resolve: {
                    currentAuto: CurrentAutoResolve,
                    statuses: StatusesResolve,
                    statusDescriptions: StatusDescriptionsResolve,
                    impoundTypes: ImpoundTypesResolve,
                    chargeTypes: ChargeTypesResolve,
                    notes: NotesResolve,
                    feeds: FeedsResolve
                }
            }
        ]
    },
    {
        path: ':auto_id/invoices',
        resolve: {
            currentAuto: CurrentAutoResolve
        },
        children: [...INVOICE_AUTO_ROUTES]
    },
    {
        path: 'mark-for-auction',
        component: MarkAuctionAutosComponent,
        data: {
            title: 'Mark Autos For Auction',
            type: 'mark-for-auction',
            button: {
                icon: 'check',
                text: 'Mark'
            }
        },
        resolve: {
            autos: AutosResolve
        }
    },
    {
        path: 'mark-for-t158',
        component: MarkT158AutosComponent,
        data: {
            title: 'Mark Autos For T158',
            type: 'mark-for-t158',
            button: {
                icon: 'check',
                text: 'Mark'
            }
        },
        resolve: {
            autos: AutosResolve
        }
    },
    {
        path: 'close',
        component: CloseAutosComponent,
        data: {
            title: 'Close Autos',
            type: 'close',
            button: {
                icon: 'remove_circle',
                text: 'Close'
            }
        },
        resolve: {
            autos: CloseAutosResolve
        }
    }
]