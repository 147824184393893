import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DeleteResponse } from '../common/delete-response';
import { Invoice } from './invoice';
import { DefaultService } from '../common/validators';
import { Payment } from '../payment';
import { InvoicesDriver } from '../driver';
import { Statement } from './statement';
import { AgingReport } from './aging-report';
import { Customer } from '../customer';
import { log } from '../common/dev/log';

@Injectable({
    providedIn: 'root'
})

export class InvoiceService extends HttpService implements OnDestroy, DefaultService {
    private _subscriptions: Subscription[] = [];

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    Get( invoice_id: number ): Observable<Invoice> {
        return this.http.get<Invoice>('/api/invoices/get/' + invoice_id).pipe(map(val => new Invoice(val)));
    }

    GetAll(): Observable<Invoice[]> {
        return this.http.get<Invoice[]>('/api/invoices/all').pipe(map(this.toInvoice));
    }

    GetStatements(): Observable<Statement[]> {
        return this.http.get<Statement[]>('/api/invoices/statements').pipe(map(this.toStatement));
    }

    toStatement(statement) {
        const temp = statement.map( statement => {
            return new Statement(statement);
        });
        return temp;
    }

    GetAgingReport(): Observable<AgingReport> {
        return this.http.get<AgingReport>('/api/invoices/aging_report').pipe(map(val => new AgingReport(val)));
    }

    toInvoice(invoice) {
        const temp = invoice.map( invoice => {
            return new Invoice(invoice);
        });
        return temp;
    }

    save(invoice: Invoice): Observable<Invoice> {
        if (!!invoice.ID) {
            return this.http.put<Invoice>('/api/invoices/update/' + invoice.ID, invoice);
        }
        return this.http.post<Invoice>('/api/invoices/create', invoice);
    }

    delete(invoice: Invoice): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/invoices/delete/' + invoice.ID)
    }

    isNumberUnique(number: string): Observable<boolean> {
        return this.http.get<boolean>('/api/invoices/is_unique/' + number).pipe(map(val => val));
    }

    GetByNumber(invoiceNumber: string): Observable<number> {
        return this.http.get<number>('/api/invoices/get_by_number/' + invoiceNumber).pipe(map(val => val));
    }

    GetNextInvoiceNumber(): Observable<string> {
        return this.http.get<string>('/api/invoices/next').pipe(map(val => val));
    }

    deletePayment(payment: Payment): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/payments/delete/' + payment.ID);
    }

    deleteInvoicesDriver(invoicesDriver: InvoicesDriver): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/invoices_drivers/delete/' + invoicesDriver.ID);
    }

    Search(invoice: Invoice): Observable<Invoice[]> {
        return this.http.post<Invoice[]>('/api/invoices/search', invoice).pipe(map(val => val));
    }
    SearchByNumber(invoiceNumber: string): Observable<Invoice[]> {
        return this.http.post<Invoice[]>('/api/invoices/search_by_number', {InvoiceNumber: invoiceNumber}).pipe(map(val => val));
    }

    SearchStatements( values ): Observable<Statement[]> {
        return this.http.post<Statement[]>('/api/invoices/search_statements', new Customer({StatementFrequency: values.StatementFrequency})).pipe(
                map(this.toStatement),
                tap(val => log.Debug(val))
            );
    }

    GetAgingReportPDF() {
        return this.http.get('/api/invoices/aging_report_pdf', {responseType: 'blob'} )
    }

    SearchStatementsPDF( frequency, customer_id ) {
        return this.http.post('/api/invoices/search_statements_pdf', new Customer({StatementFrequency: frequency, ID: customer_id}), {responseType: 'blob'});
    }

    SearchSalesReportPDF( customer, year, month ) {
        return this.http.post('/api/invoices_drivers/search_sales_report_pdf', {Customer: customer, Year: year, Month: month}, {responseType: 'blob'});
    }

}
