import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { log } from '../common/dev/log';

@Injectable({
    providedIn: 'root'
})

export class LoadingService extends HttpService implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    public isLoading$ = new BehaviorSubject(false)

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

}