import { Routes } from '@angular/router';

import { EmailComponent } from './email.component';

import { AuctionAdEmailResolve } from './auction-ad-email.resolve';
import { AuctionAdPetitionEmailResolve } from './auction-ad-petition-email.resolve';
import { InvoiceEmailResolve } from './invoice-email.resolve';

export const EMAIL_ROUTES: Routes = [
    {
        path: "",
        component: EmailComponent,
        data: {
            title: 'Email',
            isBlank: true,
            isAuctionAd: false,
            isInvoice: false,
            isAuctionAdPetition: false,
        }
    },
    {
        path: "auction-ad",
        component: EmailComponent,
        data: {
            title: 'Auction Ad Email',
            isBlank: false,
            isAuctionAd: true,
            isInvoice: false,
            isAuctionAdPetition: false,
        },
        resolve: {
            emailData: AuctionAdEmailResolve
        }
    },
    {
        path: "auction-ad-petition",
        component: EmailComponent,
        data: {
            title: 'Auction Ad Petition Email',
            isBlank: false,
            isAuctionAd: false,
            isInvoice: false,
            isAuctionAdPetition: true,
        },
        resolve: {
            emailData: AuctionAdPetitionEmailResolve
        }
    },
    {
        path: "invoice/:invoice_id",
        component: EmailComponent,
        data: {
            title: 'Invoice Email',
            isBlank: false,
            isAuctionAd: false,
            isInvoice: true,
            isAuctionAdPetition: false,
        },
        resolve: {
            emailData: InvoiceEmailResolve
        }
    }
]