<div style="text-align: center;margin-bottom: 10px;">
    <button class="print-button" mat-raised-button (click)="print()" color="primary" style="margin-bottom:10px;display: inline-block"><mat-icon>print</mat-icon>Print</button>

    <form style="margin-left:10px;display:inline-block;width: 700px" [formGroup]="letterForm">
        <mat-form-field>
            <mat-label>Select Individual Letters</mat-label>
            <mat-select formControlName="letters">
                <mat-option [value]=null>All</mat-option>
                <mat-option *ngFor="let a of autos" [value]=a.ID>{{a.AutoYear}} {{a.Color}} {{a.Make.Name}} {{a.AutoModel.Name}}
                    -  VIN: {{a.Vin}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>

<div id="print-section" style="position: relative;">
    <ng-container *ngIf="selectedAutos.length > 0; else empty">
        <div class="notification-letters">
            <div *ngFor="let auto of selectedAutos">
                <div class="notification-letter" *ngFor="let owner of auto.Owners">
                    <div class="notification-letter--header">
                        <div>
                            <p class="italic">{{ fields.CompanyName }}<br>{{ fields.CompanyPOBox }}<br>{{ fields.CompanyCity }}, {{ fields.CompanyState }} {{ fields.CompanyZip }}<br>{{ fields.CompanyPhone }}</p>
                        </div>
                    </div>
                    <div class="notification-letter--body">
                        <p class="bold">{{ fields.CurrentDate | date:'longDate':'UTC'}}</p>
                        <ul>
                            <li class="bold">{{ owner.Company ? owner.Company : owner.FirstName + ' ' + owner.LastName }}</li>
                            <li class="bold">{{ owner.Address1 }}{{ owner.Address2 ? ' ' + owner.Address2 : '' }}</li>
                            <li class="bold">{{ getCityStateZip(owner.City, owner.State, owner.Zip) }}</li>
                        </ul>
                        <p>Refer to File Number: <span class="bold">{{ auto.AutoNumber }}</span></p>
                        <p>To: <span class="bold">{{ owner.Company ? owner.Company : (owner.FirstName + ' ' + owner.LastName) }}</span></p>
                        <p>As mandated by the Official Code of Georgia Annotated, 40-11-2 (D), this letter is to notify you of the following:</p>
                        <p>On <span class="bold">{{ auto.DateIn | date:'shortDate':'UTC' }}</span>, the vehicle described below was impounded as either an Abandoned Vehicle under the Abandoned Motor Vehicle Law or at the request of a local law enforcement agency.</p>
                        <ul>
                            <li>Year: <span class="bold">{{ auto.AutoYear }}</span></li>
                            <li>Make: <span class="bold">{{ auto.Make.Name }}</span></li>
                            <li>Model: <span class="bold">{{ auto.AutoModel.Name }}</span></li>
                            <li>VIN: <span class="bold">{{ auto.Vin }}</span></li>
                            <li>License #: <span class="bold">{{ auto.Tag }}</span></li>
                            <li>State: <span class="bold">{{ auto.State }}</span></li>
                        </ul>
                        <p>The vehicle is now located at {{ fields.CompanyAddress }}, {{ fields.CompanyCity }}, {{ fields.CompanyState }} {{ fields.CompanyZip }}.</p>
                        <p>The removal or towing fee is {{ getTowingFee(auto.Charges) | currency }}; the storage fee is {{ getStoragePerDayFee(auto.Charges) | currency }} per day and the administration fees vary up to {{ getAdministrativeFee(auto.Charges) | currency }} or more.</p>
                        <p>Please be advised that: "such motor vehicle will be deemed abandoned under Chapter 11 of this title unless the owner redeems such motor vehicle within 30 days of the day such vehicle was removed" and will be sold with Contents at Public Auction.</p>
                        <p>In addition, please be advised that if this vehicle meets the specified criteria under 40-11-9, it will be deemed derelict ten (10) days after your reciept of this notice and will be sold to salvage disposal company to help pay for the charges.</p>
                        <p>Please redeem your vehicle and its contents or make disposition to avoid further cost and inconvenience to yourself.</p>
                        <p>According to the DMV records, <span class="bold-underline">you</span> are listed as the owner of said vehicle. If you have sold said vehicle, you will need to send documented proof along with this letter signed and notarized. If you would like us to dispose of this vehicle, forward this entire letter - signed and notarized along with the "towing fee" in certified funds and a clear title. Lienholders, send this letter signed and notarized if lien has been satisfied.</p>
                        <p>I no longer ___own (or) ___have interest (or) ___hold a lien against above vehicle.</p>
                        <p>Signed ______________________________</p>
                        <p>Notary _______________________________</p>
                        <p>My Commission Expires _________________</p>
                        <div class="notification-letter--footer">
                            <p class="italic">{{ fields.CompanyAgent }}<br>{{ fields.CompanyName }}</p>
                        </div>
                    </div>
                </div>
                <div class="notification-letter" *ngFor="let lienholder of auto.Lienholders">
                    <div class="notification-letter--header">
                        <div>
                            <p class="italic">{{ fields.CompanyName }}<br>{{ fields.CompanyPOBox }}<br>{{ fields.CompanyCity }}, {{ fields.CompanyState }} {{ fields.CompanyZip }}<br>{{ fields.CompanyPhone }}</p>
                        </div>
                    </div>
                    <div class="notification-letter--body">
                        <p class="bold">{{ fields.CurrentDate | date:'longDate':'UTC' }}</p>
                        <ul>
                            <li class="bold">{{ lienholder.Company ? lienholder.Company : lienholder.FirstName + ' ' + lienholder.LastName }}</li>
                            <li class="bold">{{ lienholder.Address1 }}{{ lienholder.Address2 ? ' ' + lienholder.Address2 : '' }}</li>
                            <li class="bold">{{ getCityStateZip(lienholder.City, lienholder.State, lienholder.Zip) }}</li>
                        </ul>
                        <p>Refer to File Number: <span class="bold">{{ auto.AutoNumber }}</span></p>
                        <p>To: <span class="bold">{{ lienholder.Company ? lienholder.Company : lienholder.FirstName + ' ' + lienholder.LastName }}</span></p>
                        <p>As mandated by the Official Code of Georgia Annotated, 40-11-2 (D), this letter is to notify you of the following:</p>
                        <p>On <span class="bold">{{ auto.DateIn | date:'shortDate':'UTC' }}</span>, the vehicle described below was impounded as either an Abandoned Vehicle under the Abandoned Motor Vehicle Law or at the request of a local law enforcement agency.</p>
                        <ul>
                            <li>Year: <span class="bold">{{ auto.AutoYear }}</span></li>
                            <li>Make: <span class="bold">{{ auto.Make.Name }}</span></li>
                            <li>Model: <span class="bold">{{ auto.AutoModel.Name }}</span></li>
                            <li>VIN: <span class="bold">{{ auto.Vin }}</span></li>
                            <li>License #: <span class="bold">{{ auto.Tag }}</span></li>
                            <li>State: <span class="bold">{{ auto.State }}</span></li>
                        </ul>
                        <div class="notification-letter--owner" *ngIf="auto.Owners[0]">
                            <p>The Owner information is as follows:</p>
                            <ul>
                                <li class="bold">{{ auto.Owners[0].FirstName + ' ' + auto.Owners[0].LastName }}</li>
                                <li class="bold">{{ auto.Owners[0].Address1 }}</li>
                                <li class="bold">{{ getCityStateZip(auto.Owners[0].City, auto.Owners[0].State, auto.Owners[0].Zip) }}</li>
                            </ul>
                        </div>
                        <p>The vehicle is now located at {{ fields.CompanyAddress }}, {{ fields.CompanyCity }}, {{ fields.CompanyState }} {{ fields.CompanyZip }}.</p>
                        <p>The removal or towing fee is {{ getTowingFee(auto.Charges) | currency }}; the storage fee is {{ getStoragePerDayFee(auto.Charges) | currency }} per day and the administration fees vary up to {{ getAdministrativeFee(auto.Charges) | currency }} or more.</p>
                        <p>Please be advised that: "such motor vehicle will be deemed abandoned under Chapter 11 of this title unless the owner redeems such motor vehicle within 30 days of the day such vehicle was removed" and will be sold with Contents at Public Auction.</p>
                        <p>In addition, please be advised that if this vehicle meets the specified criteria under 40-11-9, it will be deemed derelict ten (10) days after your reciept of this notice and will be sold to salvage disposal company to help pay for the charges.</p>
                        <p>Please redeem your vehicle and its contents or make disposition to avoid further cost and inconvenience to yourself.</p>
                        <p>According to the DMV records, <span class="bold-underline">you</span> are listed as the lienholder of said vehicle. If you have sold said vehicle, you will need to send documented proof along with this letter signed and notarized. If you would like us to dispose of this vehicle, forward this entire letter - signed and notarized along with the "towing fee" in certified funds and a clear title. Lienholders, send this letter signed and notarized if lien has been satisfied.</p>
                        <p>I no longer ___own (or) ___have interest (or) ___hold a lien against above vehicle.</p>
                        <p>Signed ______________________________</p>
                        <p>Notary _______________________________</p>
                        <p>My Commission Expires _________________</p>
                        <div class="notification-letter--footer">
                            <p class="italic">{{ fields.CompanyAgent }}<br>{{ fields.CompanyName }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #empty><p style="text-align: center; margin-top: 60px;">No autos marked for notification.</p></ng-template>
</div>