import { Model } from '../common/model';

export class StatusDescription extends Model {

    Name: string;
    Active: boolean;
    Created: Date;
    Modified: Date;

    // constructor(properties?: Object) {
    //     super(properties);
    // }

}