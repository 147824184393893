import { Routes } from '@angular/router';

import { SearchTransactionsComponent } from './search-transactions.component';
import { EditTransactionComponent } from './edit-transaction.component';
import { NextTransactionNumberResolve } from './next-transaction-number.resolve';
import { AutoNumbersResolve } from '../auto';
import { AuctionCustomerNamesResolve } from '../customer';
import { CurrentTransactionResolve } from './current-transaction.resolve';

export const TRANSACTION_ROUTES: Routes = [
    {
        path: '',
        component: SearchTransactionsComponent,
        data: {
            title: 'Search Auctions'
        }
    },
    {
        path: 'new',
        component: EditTransactionComponent,
        data: {
            title: 'New Auction',
            edit: false,
            search: false
        },
        resolve: {
            nextTransactionNumber: NextTransactionNumberResolve,
            autoNumbers: AutoNumbersResolve,
            customers: AuctionCustomerNamesResolve
        }
    },
    {
        path: 'edit',
        children: [
            {
                path: '',
                component: EditTransactionComponent,
                data: {
                    title: 'Edit Auction',
                    edit: false,
                    search: true
                }
            },
            {
                path: ':transaction_id',
                component: EditTransactionComponent,
                data: {
                    title: 'Edit Auction',
                    edit: true,
                    search: false
                },
                resolve: {
                    currentTransaction: CurrentTransactionResolve,
                    autoNumbers: AutoNumbersResolve,
                    customers: AuctionCustomerNamesResolve
                }
            }
        ]
    }
]