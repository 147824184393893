import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from '../common/dev/log';
import { Status } from './status';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { StatusService } from './status.service';
import { AppService } from '../app.service'

@Component({
    selector: "manage-statuses",
    templateUrl: "./manage-statuses.component.html"
})

export class ManageStatusesComponent implements OnInit {

    title: string;
    statuses: Status[];

    form: FormGroup;

    constructor (
        private route: ActivatedRoute,
        private ss: StatusService,
        private apps: AppService,

    ){
        this.form = this.createFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            statuses: Status[]
        }) => {
            log.Debug("data: ", data)
            this.title = data.title;
            this.statuses = data.statuses
            for (let cT in this.statuses) {
                this.addStatus();
            }
            this.form.get('Statuses').patchValue(this.statuses);
        })
    }

    createFormGroup() {
        return new FormGroup({
            Statuses: new FormArray([])
        })
    }

    createStatusFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            Name: new FormControl(null, Validators.required),
        })
    }

    addStatus(): void {
        let statuses = this.form.get('Statuses') as FormArray;
        statuses.push(this.createStatusFormGroup());
    }
    removeStatus(i: number): void {
        let statuses = this.form.get('Statuses') as FormArray;
        statuses.removeAt(i);
        this.statuses.splice(i, 1)
    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }
    
    onSubmit(e): void {

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched: ", this.form)
            return;
        }

        this.save();
    }

    save() {
        this.ss.save(this.form.get('Statuses').value)
        .subscribe((savedStatuses: Status[]) => {
            this.apps.Confirmation('Statuses saved.');
            this.form.markAsPristine();

            log.Debug("savedStatuses: ", savedStatuses)
            this.form.get('Statuses').patchValue(savedStatuses);
            this.statuses = savedStatuses
        })
    }

    deleteStatus(e, index: number) {
        e.preventDefault();
        e.stopPropagation();


        const status = this.statuses[index] as Status;
        if(!Boolean(status)) {
            log.Debug("status: ", status)
            this.removeStatus(index);
            return
        }

        if(confirm("This status will be deleted. Are you sure?")) {
            this.ss.delete(<Status>status).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Status deleted.');
                    this.removeStatus(index);
                }
            })
        }
    }
}