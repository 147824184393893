<mat-card class="edit-invoice medium-form">
    <h1 class="title">{{ title }}</h1>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
        <mat-card-content>
            <div class="row gutters">
                <div class="col">
                    <mat-card class="deck">
                        <mat-card-content>
                            <div class="row gutters">
                                <div class="col">
                                    <mat-form-field>
                                        <input type="text" placeholder="Invoice Number" matInput [matAutocomplete]="invoice" formControlName="InvoiceNumber">
                                        <mat-autocomplete autoActiveFirstOption #invoice="matAutocomplete">
                                            <mat-option *ngFor="let invoice of filteredInvoices | async" [value]="invoice.InvoiceNumber">{{ invoice.InvoiceNumber }}</mat-option>
                                            <mat-option *ngIf="!isEdit && !isSearch" [value]="nextInvoiceNumber" selected="true">{{ nextInvoiceNumber }}</mat-option>
                                        </mat-autocomplete>
                                        <mat-error *ngIf="form.get('InvoiceNumber')?.invalid">{{ getErrorMessage(form.get('InvoiceNumber')) }}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field>
                                        <mat-label>Customer Number</mat-label>
                                        <pgl-search-select
                                            placeholder="Customer Number"
                                            label="Customer Number"
                                            formControlName="Customer"
                                            [options]="filteredCustomers$ | async"
                                            [filterWith]="filterCustomers"
                                            [displayWith]="displayCustomers"
                                            startWith="0"
                                            autoActiveFirstOption>
                                        </pgl-search-select>
                                        <mat-error *ngIf="form.get('Customer')?.invalid">{{ getErrorMessage(form.get('Customer')) }}</mat-error>
                                    </mat-form-field>
                                    
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput [matDatepicker]="Date" placeholder="Date" formControlName="Invoiced">
                                        <mat-datepicker-toggle matSuffix [for]="Date"></mat-datepicker-toggle>
                                        <mat-datepicker #Date></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput type="text" placeholder="Company Name" formControlName="CompanyName" readonly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput type="text" placeholder="Name" formControlName="Name">
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput type="text" placeholder="Note" formControlName="Note">
                                    </mat-form-field>
                                </div>
                            </div>
<!--                             <div class="row gutters" *ngIf="isEdit">
                                <div class="col">
                                    <mat-checkbox color="primary" formControlName="IsClosed">Closed</mat-checkbox>
                                </div>
                                <div class="col">
                                    <mat-checkbox color="primary" formControlName="IsPosted">Posted</mat-checkbox>
                                </div>
                            </div> -->
                        </mat-card-content>
                    </mat-card>
                    <mat-card>
                        <mat-card-content>
                            <div class="row gutters">
                                <div class="col">
                                    <p>Drivers</p>
                                </div>
                                <div class="col" style="text-align: right;margin-bottom: 15px;">
                                    <button type="button" mat-raised-button color="primary" (click)="addDriver()" [disabled]="isSearch"><mat-icon>add</mat-icon>Add Driver</button>
                                </div>
                            </div>
                            <div class="drivers-form-array" formArrayName="InvoicesDrivers" *ngFor="let driver of form.get('InvoicesDrivers')['controls']; let i = index">
                                <div class="form-group-container" [formGroupName]="i">
                                    <div class="row gutters">
                                        <div class="col">
                                            <mat-form-field>
                                                <mat-label>Driver</mat-label>
                                                <pgl-search-select
                                                    placeholder="Driver"
                                                    label="Driver"
                                                    formControlName="DriverNumber"
                                                    [options]="filteredDrivers$ | async"
                                                    [filterWith]="filterDrivers"
                                                    [searchWait]="searchWait"
                                                    (onSelect)="setCommission(i)"
                                                    [required]=true
                                                    autoActiveFirstOption>
                                                </pgl-search-select>
                                                <mat-error *ngIf="form.get('DriverNumber')?.invalid">{{ getErrorMessage(form.get('DriverNumber')) }}</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            <mat-form-field>
                                                <input matInput type="number" placeholder="Commission Percentage" formControlName="CommissionPercentage">
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            <mat-form-field>
                                                <input matInput type="number" placeholder="Commission" formControlName="CommissionValue">
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            <button type="button" mat-raised-button color="warn" (click)="deleteInvoicesDriver($event, i)"><mat-icon>delete</mat-icon></button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col" style="margin-bottom: 10px;">
                                            <mat-checkbox color="primary" formControlName="IsCommissionOnly">Commission Only</mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="col">
                    <mat-card class="deck">
                        <mat-card-content>
                            <p>Initial Balance</p>
                            <div class="row gutters">
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput type="number" placeholder="Additional Balance" formControlName="AdditionalBalance">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput type="number" placeholder="Balance" formControlName="InitialBalance">
                                        <mat-error *ngIf="form.get('InitialBalance')?.invalid">{{ getErrorMessage(form.get('InitialBalance')) }}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <button type="button" mat-raised-button color="primary" (click)="calculateInitialBalance()"><mat-icon>attach_money</mat-icon>Calculate</button>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="deck">
                        <mat-card-content>
                            <div class="row gutters">
                                <div class="col">
                                    <p>Payments</p>
                                </div>
                                <div class="col" style="text-align: right;margin-bottom: 15px;">
                                    <button type="button" mat-raised-button color="primary" (click)="addPayment()"><mat-icon>add</mat-icon>Add Payment</button>
                                </div>
                            </div>
                            <div class="payments" formArrayName="Payments" *ngFor="let payment of form.get('Payments')['controls']; let i = index">
                                <div class="row gutters" [formGroupName]="i">
                                    <div class="col">
                                        <mat-form-field>
                                            <input matInput type="text" placeholder="Description" formControlName="Name" required>
                                            <mat-error *ngIf="payment.get('Name')?.invalid">{{ getErrorMessage(payment.get('Name')) }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field>
                                            <input matInput type="number" placeholder="Payment" formControlName="Amount" required>
                                            <mat-error *ngIf="payment.get('Amount')?.invalid"> {{ getErrorMessage(payment.get('Amount')) }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field>
                                            <input matInput [matDatepicker]="Date" placeholder="Date" formControlName="Date">
                                            <mat-datepicker-toggle matSuffix [for]="Date"></mat-datepicker-toggle>
                                            <mat-datepicker #Date></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <button type="button" mat-raised-button color="warn" (click)="deletePayment($event, i)"><mat-icon>delete</mat-icon></button>
                                    </div>
                                </div>
                            </div>

                        </mat-card-content>
                    </mat-card>
                    <mat-card>
                        <mat-card-content>
                            <p>Remaining Balance</p>
                            <div class="row gutters">
                                <div class="col">
                                    <mat-form-field>
                                        <input matInput type="number" placeholder="Balance" formControlName="RemainingBalance">
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <button type="button" mat-raised-button color="primary" (click)="calculateRemainingBalance()"><mat-icon>attach_money</mat-icon>Calculate</button>
                                </div>
                                <div class="col">
                                    <button type="button" mat-raised-button color="primary" (click)="zero()"><mat-icon>cancel</mat-icon>Zero</button>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions end>
            <button mat-raised-button color="primary" *ngIf="!isSearch" type="submit"><mat-icon>save</mat-icon>Save</button>
            <button mat-raised-button color="primary" *ngIf="isEdit" type="button" (click)="email()"><mat-icon>email</mat-icon>Email</button>
            <button mat-raised-button color="primary" *ngIf="isSearch" type="submit"><mat-icon>search</mat-icon>Search</button>
            <button type="button" mat-raised-button color="primary" *ngIf="isEdit" (click)="gotoEdit()"><mat-icon>clear</mat-icon>Edit</button>
            <button type="button" mat-raised-button color="primary" *ngIf="isEdit" (click)="gotoNew()"><mat-icon>clear</mat-icon>New</button>
            <button type="button" mat-raised-button color="warn" *ngIf="isEdit" (click)="delete($event)"><mat-icon>delete</mat-icon>Delete</button>
        </mat-card-actions>
    </form>
</mat-card>
