import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from '../common/dev/log';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Invoice, AgingReport, InvoiceService } from '../invoice';

@Component({
    selector: "aging-report-pdf",
    templateUrl: "./aging-report-pdf.component.html"
})

export class AgingReportPDFComponent implements OnInit {

    downloading = false;

    constructor (
        private route: ActivatedRoute,
        private is: InvoiceService,
    ){}
    ngOnInit(): void {
        this.route.data.subscribe((data: {
        }) => {
            log.Debug("data: ", data)

        });
    }

    agingReportPDF() {
        this.downloading = true
        this.is.GetAgingReportPDF().subscribe( blob => {
            const a = document.createElement('a');
            a.setAttribute('download', 'aging-report.pdf');
            a.setAttribute('href', window.URL.createObjectURL(blob));
            a.click()
            this.downloading = false;
        })
    }

}