import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DeleteResponse } from '../common/delete-response';
import { ChargeType } from './charge-type';

@Injectable({
    providedIn: 'root'
})

export class ChargeTypeService extends HttpService implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    GetAll(): Observable<ChargeType[]> {
        return this.http.get<ChargeType[]>('/api/charge_types/all').pipe(map(this.toChargeType));
    }

    toChargeType(chargeType) {
        const temp = chargeType.map( chargeType => {
            return new ChargeType(chargeType);
        });
        return temp;
    }

    save(chargeTypes: ChargeType[]): Observable<ChargeType[]> {
        return this.http.put<ChargeType[]>('/api/charge_types/update_all', chargeTypes);
    }

    delete(chargeType: ChargeType): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/charge_types/delete/' + chargeType.ID)
    }

}