import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AutoService } from './auto.service';
import { Auto } from './auto';

@Injectable()
export class CurrentAutoResolve implements Resolve<Auto> {
    constructor (
        private router: Router,
        private as: AutoService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<Auto> {
        const auto_id = +route.paramMap.get('auto_id');
        return this.as.Get(auto_id);
    }
}