import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Customer } from './customer';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { CustomersSearchService } from './customers-search.service'

export class CustomersDataSource implements DataSource<Customer> {

    private customersSubject = new BehaviorSubject<Customer[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private lengthSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public length$ = this.lengthSubject.asObservable();

    constructor(
        private css: CustomersSearchService
    ){}

    connect( collectionViewer: CollectionViewer ): Observable<Customer[]> {
        return this.customersSubject.asObservable();
    }

    disconnect( collectionViewer: CollectionViewer ): void {
        this.customersSubject.complete();
        this.loadingSubject.complete();
    }

    loadCustomers( customer: Customer, pageIndex = 0, pageSize = 10 ) {

        this.loadingSubject.next(true);

        this.css.SearchCustomers( customer, pageIndex, pageSize).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe( customers => this.customersSubject.next(customers) )
    }

    updateLength( customer: Customer ) {

        this.css.GetResultsLength( customer ).pipe(
            catchError( () => of(0))
        ).subscribe( l => this.lengthSubject.next(l) )
        
    }

}