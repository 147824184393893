import { Model } from '../common/model';

export class Officer extends Model {

    Name: string;
    Badge: string;
    Title: string;
    AgencyName: string;
    AgencyPhone: string;
    AgencyAddress: string;
    AgencyCity: string;
    AgencyCounty: string;
    Active: boolean;
    Created: Date;
    Modified: Date;

    // constructor(properties?: Object) {
    //     super(properties);
    // }

}
