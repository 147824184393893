import { Routes } from '@angular/router';

import { ManageAutoModelsComponent } from './manage-auto-models.component';
import { AutoModelsResolve } from './auto-models.resolve';
import { EditAutoModelComponent } from './edit-auto-model.component';
import { CurrentAutoModelResolve } from './current-auto-model.resolve';

export const AUTO_MODEL_ROUTES: Routes = [
    {
        path: '',
        component: ManageAutoModelsComponent,
        data: {
            title: 'Manage Models'
        },
    },
    {
        path: 'new',
        component: EditAutoModelComponent,
        data: {
            title: 'New Model',
            edit: false,
        },
    },
    {
        path: 'edit',
        children: [
            {
                path: ':auto_model_id',
                component: EditAutoModelComponent,
                data: {
                    title: 'Edit Model',
                    edit: true,
                },
                resolve: {
                    currentAutoModel: CurrentAutoModelResolve,
                }
            }
        ]
    }
]