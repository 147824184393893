<button class="print-button" mat-raised-button (click)="print()" color="primary" style="margin-bottom: 10px;"><mat-icon>print</mat-icon>Print</button>
<mat-card class="small-form no-print" style="margin-bottom: 30px;">
    <form [formGroup]="form">
        <div class="row gutters">
            <div class="col">
                <mat-form-field>
                    <input matInput [matDatepicker]="DateIn" placeholder="Start Date" formControlName="DateIn" [max]="form.value.DateOut">
                    <mat-datepicker-toggle matSuffix [for]="DateIn"></mat-datepicker-toggle>
                    <mat-datepicker #DateIn></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field>
                    <input matInput [matDatepicker]="DateOut" placeholder="End Date" formControlName="DateOut" [min]="form.value.DateIn">
                    <mat-datepicker-toggle matSuffix [for]="DateOut"></mat-datepicker-toggle>
                    <mat-datepicker #DateOut></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </form>
    <mat-card-actions>
        <button type="submit" mat-raised-button color="primary" (click)="onSubmit()"><mat-icon>search</mat-icon>Search</button>
    </mat-card-actions>
</mat-card>
<div id="print-section" style="position: relative;">
    <ng-container>
        <table class="table">
            <tr>
                <th>Ref #</th>
                <th>Make</th>
                <th>Model</th>
                <th>Year</th>
                <th>VIN #</th>
                <th>Color</th>
                <th>Tag #</th>
                <th>State</th>
                <th>Date In</th>
            </tr>
            <tr *ngFor="let auto of autos | async">
                <th>{{ auto.AutoNumber }}</th>
                <th>{{ auto.Make.Name }}</th>
                <th>{{ auto.AutoModel.Name }}</th>
                <th>{{ auto.AutoYear }}</th>
                <th>{{ auto.Vin }}</th>
                <th>{{ auto.Color }}</th>
                <th>{{ auto.Tag }}</th>
                <th>{{ auto.State }}</th>
                <th>{{ auto.DateIn | date:'longDate':'UTC' }}</th>
            </tr>
        </table>
    </ng-container>
</div>