import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from '../common/dev/log';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { debounceTime, map, concatMap, toArray, tap, switchMap, take, reduce } from 'rxjs/operators';
import { Observable, of, from } from 'rxjs';
import { AuthService } from './auth.service';
import { User } from '../user';

@Component({
    selector: "login",
    templateUrl: "./login.component.html"
})

export class LoginComponent implements OnInit {

    title: string;

    form: FormGroup;

    constructor (
        private route: ActivatedRoute,
        private auths: AuthService,
        private apps: AppService,
        private router: Router,

    ){
        this.form = this.createFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
        }) => {
            log.Debug("data: ", data)
            this.title = data.title;    
        })
    }

    createFormGroup() {
        return new FormGroup({
            Username: new FormControl(),
            Password: new FormControl(),
        })
    }

    onSubmit(e): void {

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched: ", this.form)
            return;
        }

        this.login();
    }

    login() {
        this.auths.Login(this.form.value)
        .subscribe((loggedInUser: User) => {
            this.apps.Confirmation('You have been successfully logged in.');
            this.form.markAsPristine();
            this.auths.SetLocalUserRole(loggedInUser);
            log.Debug("logged in user: ", loggedInUser);
            this.router.navigate(["..", "dashboard"], {relativeTo: this.route});
        })
    }

    signUp() {
        this.router.navigate(["/sign-up"]);
    }

}