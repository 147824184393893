import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ImpoundTypeService } from './impound-type.service';
import { ImpoundType } from './impound-type';

@Injectable()
export class ImpoundTypesResolve implements Resolve<ImpoundType[]> {
    constructor (
        private router: Router,
        private fs: ImpoundTypeService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<ImpoundType[]> {
        return this.fs.GetAll();
    }
}