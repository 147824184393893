import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auto } from '../auto';
import { Make } from '../make';
import { AutoModel } from '../auto-model';
import { MONTHS } from '../common/month';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Setting } from '../setting';
import { FeedService, Feed } from '../feed';
import { log } from '../common/dev/log';

@Component({
    selector: "mv-603",
    templateUrl: "./mv-603.component.html",
    styleUrls: ['./single-print.component.css']
})

export class MV603Component implements OnInit {

    private _months = MONTHS;
    private makes: Make[];
    private autoModels: AutoModel[];
    autoID: number;

    fields = {
        RemoverName: '',
        RemoverStreet: '',
        RemoverCity: '',
        RemoverState: '',
        RemoverZip: '',
        PresentLocation: '',
        PresentCity: '',
        PresentState: '',
        PresentZip: '',
        PresentPhone: '',
        VIN: '',
        Year: '',
        Make: '',
        Model: '',
        Tag: '',
        TagState: '',
        RemovedDate: new Date(),
        AbandonedDate: new Date(),
        FromLocation: '',
        FromCity: '',
        FromState: '',
        FromZip: '',
        CurrentDay: '',
        CurrentMonth: '',
        CurrentYear: ''
    }

    constructor (
        private route: ActivatedRoute,
        private FeedService: FeedService,
    ){}
    ngOnInit(): void {
        this.route.data.subscribe((data: {
            isBlank: boolean,
            currentAuto: Auto,
            settings: {}
        }) => {
            if(!data.isBlank) {

                let currentDate = new Date()
                this.autoID = data.currentAuto.ID

                this.fields.RemoverName = data.settings['Company Name'] || '';
                this.fields.RemoverStreet = data.settings['Company Address'] || '';
                this.fields.RemoverCity = data.settings['Company City'] || '';
                this.fields.RemoverState = data.settings['Company State'] || '';
                this.fields.RemoverZip = data.settings['Company Zip'] || '';
                this.fields.PresentLocation = data.settings['Company Address'] || '';
                this.fields.PresentCity = data.settings['Company City'] || '';
                this.fields.PresentState = data.settings['Company State'] || '';
                this.fields.PresentZip = data.settings['Company Zip'] || '';
                this.fields.PresentPhone = data.settings['Company Phone'] || '';
                this.fields.VIN = data.currentAuto.Vin || '';
                this.fields.Year = data.currentAuto.AutoYear || '';
                this.fields.Make = data.currentAuto.Make.Name || '';
                this.fields.Model = data.currentAuto.AutoModel.Name || '';
                this.fields.Tag = data.currentAuto.Tag || '';
                this.fields.TagState = data.currentAuto.State || ''
                this.fields.RemovedDate = new Date(data.currentAuto.DateIn)
                this.fields.AbandonedDate = new Date();
                this.fields.FromLocation = data.currentAuto.RemovalStreet || '';
                this.fields.FromCity = data.currentAuto.RemovalCity || '';
                this.fields.FromState = data.currentAuto.RemovalState || '';
                this.fields.FromZip = data.currentAuto.RemovalZip || '';
                this.fields.CurrentDay = currentDate.getDate().toString() || '';
                this.fields.CurrentMonth = this._months[currentDate.getMonth()] || '';
                this.fields.CurrentYear = currentDate.getFullYear().toString() || '';

            }
        });
    }

    print(): void {
        //Change to note maybe?
        this.FeedService.save(new Feed({ AutoID: this.autoID, Description: "MV603 Printed", Created: new Date() })).subscribe(
            resp => log.Debug("resp: ", resp)
        )
        window.print();
    }
}
