import { Routes } from '@angular/router';

import { SearchCustomersComponent } from './search-customers.component';
import { EditCustomerComponent } from './edit-customer.component';

import { NextCustomerNumberResolve } from './next-customer-number.resolve';
import { CurrentCustomerResolve } from './current-customer.resolve';

export const CUSTOMER_ROUTES: Routes = [
    {
        path: '',
        component: SearchCustomersComponent,
        data: {
            title: 'Search Customers'
        }
    },
    {
        path: 'new',
        component: EditCustomerComponent,
        data: {
            title: 'New Customer',
            edit: false,
            search: false
        },
        resolve: {
            nextCustomerNumber: NextCustomerNumberResolve
        }
    },
    {
        path: 'edit',
        children: [
            {
                path: '',
                component: EditCustomerComponent,
                data: {
                    title: 'Edit Customer',
                    edit: false,
                    search: true
                }
            },
            {
                path: ':customer_id',
                component: EditCustomerComponent,
                data: {
                    title: 'Edit Customer',
                    edit: true,
                    search: false
                },
                resolve: {
                    currentCustomer: CurrentCustomerResolve
                }
            }
        ]
    }
]