import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from '../common/dev/log';
import { AutoModel } from './auto-model';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AutoModelService } from './auto-model.service';
import { AppService } from '../app.service'
import { debounceTime, map, concatMap, toArray, tap, switchMap, take, reduce, distinctUntilChanged } from 'rxjs/operators';
import { Observable, of, from } from 'rxjs';
import { Make, MakeService } from '../make';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: "manage-auto-models",
    templateUrl: "./manage-auto-models.component.html"
})

export class ManageAutoModelsComponent implements OnInit {

    title: string;
    autoModelsArray: AutoModel[];
    autoModels;
    isLoading = false;

    form: FormGroup;
    filterForm: FormGroup;

    makeFilterCtrl: FormControl = new FormControl();
    filteredMakes;

    isLoading$: Observable<Boolean>;

    displayedColumns = [
        'name',
        'make',
        'actions'
    ]

    constructor (
        private route: ActivatedRoute,
        private ams: AutoModelService,
        private apps: AppService,
        private ms: MakeService,
        private router: Router

    ){
        this.filterForm = this.createFilterFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string
        }) => {
            log.Debug("data: ", data)
            this.title = data.title;
        })

        this.route.queryParams.subscribe(v => {
            const makeID = parseInt(v['make_id']) || null
            this.filterForm.patchValue({
                'make_id': makeID,
                'filter': v['filter'],
            }, {emitEvent: false});
            this.setInitialFilterMakeValue(makeID)
            this.ams.SearchByValue(new AutoModel({MakeID: makeID, Name: v['filter']})).subscribe(r => {
                this.autoModelsArray = Object.keys(r).map(i => r[i])
                    this.autoModels = new MatTableDataSource(<any> this.autoModelsArray)
            })
        });

        this.filterForm.valueChanges.pipe(
            debounceTime(500),
          ).subscribe(v => {
           this.router.navigate([], {queryParams: v});
        });

        this.makeFilterCtrl.valueChanges.subscribe(() => {
            this.filterMakes();
        });

    }

    createFilterFormGroup() {
        return new FormGroup({
            make_id: new FormControl(),
            filter: new FormControl()
        })
    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }

    removeAutoModel(i: number): void {
        // autoModels.removeAt(i);
    }

    setInitialFilterMakeValue(make_id: number | null) {
        if(make_id) {
            this.ms.GetByID(make_id).subscribe(r => {
                this.makeFilterCtrl.patchValue(r.Name)
                this.filteredMakes = [r];
            })
            return 
        }

        this.ms.SearchByValue('a').subscribe(r => {
            this.filteredMakes = r
        })
    }

    protected filterMakes() {
        // if (!this.filteredMakes) {
        //     return;
        // }

        // get the search keyword
        let search = this.makeFilterCtrl.value;
        if(search === '' || search === null) {
            return
        }

        search = search.toLowerCase();

        // filter makes
        this.ms.SearchByValue(search).subscribe(r => {
            this.filteredMakes = r
        })
    }

    // filterFilterMakes = (value: string | Make) => {
    //     value = value || 'a';
    //     log.Debug("in filter value makes: ", value)
    //     if (typeof value != 'string' ) {
    //         return
    //     }
    //     this.filteredFilterMakes = this.ms.SearchByValue(<string>value).pipe(take(1));
    // }

    // filterAutoModelMakes = (value: string | Make) => {
    //     if (value == '') {
    //         return
    //     }
    //     log.Debug("in filter value auto model: ", value)
    //     if (typeof value != 'string' ) {
    //         return
    //     }
    //     this.filteredAutoModelMakes = this.ms.SearchByValue(<string>value).pipe(take(1));
    // }

    delete(autoModelID: number, autoModelName: string) {

        let autoModel = new AutoModel({ ID: autoModelID, Name: autoModelName });


        if(confirm("This model will be deleted. Are you sure?")) {
            this.ams.delete(<AutoModel>autoModel).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Model deleted.');
                    this.router.navigate([]);
                }
            })
        }
    }
}