import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DeleteResponse } from '../common/delete-response';
import { Driver } from './driver';
import { DefaultService } from '../common/validators';
import { DriverReport } from './driver-report';

@Injectable({
    providedIn: 'root'
})

export class DriverService extends HttpService implements OnDestroy, DefaultService {
    private _subscriptions: Subscription[] = [];

    //isNumberUnique: CanCheckUnique;

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    Get( driver_id: number ): Observable<Driver> {
        return this.http.get<Driver>('/api/drivers/get/' + driver_id).pipe(map(val => new Driver(val)));
    }

    GetAll(): Observable<Driver[]> {
        return this.http.get<Driver[]>('/api/drivers/all').pipe(map(this.toDriver));
    }

    toDriver(driver) {
        const temp = driver.map( driver => {
            return new Driver(driver);
        });
        return temp;
    }

    GetDriverReports(): Observable<DriverReport[]> {
        return this.http.get<DriverReport[]>('/api/invoices_drivers/driver_commission_due_reports').pipe(map(this.toDriverReport));
    }

    SearchDriverReports( dates ): Observable<DriverReport[]> {
        return this.http.post<DriverReport[]>('/api/invoices_drivers/search_driver_commission_due_reports', dates).pipe(map(this.toDriverReport));
    }

    toDriverReport(driverReport) {
        const temp = driverReport.map( driverReport => {
            return new DriverReport(driverReport);
        });
        return temp;
    }

    save(driver: Driver): Observable<Driver> {
        if (!!driver.ID) {
            return this.http.put<Driver>('/api/drivers/update/' + driver.ID, driver);
        }
        return this.http.post<Driver>('/api/drivers/create', driver);
    }

    delete(driver: Driver): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/drivers/delete/' + driver.ID)
    }

    isNumberUnique(number: string): Observable<boolean> {
        return this.http.get<boolean>('/api/drivers/is_unique/' + number).pipe(map(val => val));
    }

    GetByNumber(driverNumber: string): Observable<number> {
        return this.http.get<number>('/api/drivers/get_by_number/' + driverNumber).pipe(map(val => val));
    }

    GetNextDriverNumber(): Observable<string> {
        return this.http.get<string>('/api/drivers/next').pipe(map(val => val));
    }

    Search(driver: Driver): Observable<Driver[]> {
        return this.http.post<Driver[]>('/api/drivers/search', driver).pipe(map(val => val));
    }

    GetAllCommissions(): Observable<Driver[]> {
        return this.http.get<Driver[]>('/api/drivers/all_commissions').pipe(map(this.toDriver));
    }

    SearchDriverReportsPDF( dates ) {
        return this.http.post('/api/invoices_drivers/search_driver_commission_due_reports_pdf', dates, {responseType: 'blob'});
    }

    SearchDriverReportsPDFByIsInvoicePrinted( data ) {
        return this.http.post('/api/invoices_drivers/search_driver_commission_due_reports_pdf_by_is_invoice_printed', data, {responseType: 'blob'});
    }

    SearchDriverReportsPDFByIsInvoicePrintedPreview( data ) {
        return this.http.post('/api/invoices_drivers/search_driver_commission_due_reports_pdf_by_is_invoice_printed_preview', data, {responseType: 'blob'});
    }

}
