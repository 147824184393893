import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { InvoiceService } from './invoice.service';
import { AgingReport } from './aging-report';

@Injectable()
export class AgingReportResolve implements Resolve<AgingReport> {
    constructor (
        private router: Router,
        private is: InvoiceService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<AgingReport> {
        return this.is.GetAgingReport();
    }
}