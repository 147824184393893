import { Model } from '../common/model';
import { Customer } from './customer';

export class CustomerSearch extends Model {

    Auto: Customer;
    Filter: string;
    SortOrder: string;
    PageIndex: number;
    PageSize: number;

    constructor(properties?: Object) {
        super(properties);
    }

}