import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auto } from '../auto';
import { MONTHS } from '../common/month';
import { log } from '../common/dev/log';
import { Setting } from '../setting';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { FeedService, Feed } from '../feed';

@Component({
    selector: "mv-603r",
    templateUrl: "./mv-603r.component.html",
    styleUrls: ['./single-print.component.css']
})

export class MV603RComponent implements OnInit {

    private _months = MONTHS;
    moment = new MomentDateAdapter('L')
    autoID: number;

    fields = {
        RemoverName: '',
        RemoverStreet: '',
        RemoverCityStateZip: '',
        PresentLocation: '',
        PresentCityStateZip: '',
        PresentPhone: '',
        VIN: '',
        YearMakeModel: '',
        TagTagState: '',
        RemovedDate: new Date(),
        AbandonedDate: new Date(),
        FromLocation: '',
        FromCityStateZip: ''
    }

    constructor (
        private route: ActivatedRoute,
        private FeedService: FeedService,
    ){}
    ngOnInit(): void {
        this.route.data.subscribe((data: {
            isBlank: boolean,
            currentAuto: Auto,
            settings: {}
        }) => {

            log.Debug("data: ", data)

            this.autoID = data.currentAuto.ID;

            if(!data.isBlank) {

                this.fields.RemoverName = data.settings['Company Name'] || '';
                this.fields.RemoverStreet = data.settings['Company Address'] || '';
                this.fields.RemoverCityStateZip = data.settings['Company City'] + ', ' + data.settings['Company State']
                    + ' ' + data.settings['Company Zip'] || '';
                this.fields.PresentLocation = this.fields.RemoverStreet;
                this.fields.PresentCityStateZip = this.fields.RemoverCityStateZip;
                this.fields.PresentPhone = data.settings['Company Phone'] || '';
                this.fields.VIN = data.currentAuto.Vin || '';
                this.fields.YearMakeModel = this.getYearMakeModel(data.currentAuto.AutoYear, data.currentAuto.Make.Name, data.currentAuto.AutoModel.Name);
                this.fields.TagTagState = this.getTagTagState(data.currentAuto.Tag, data.currentAuto.State);
                this.fields.RemovedDate = new Date(data.currentAuto.DateIn)
                this.fields.FromLocation = data.currentAuto.RemovalStreet || '';
                this.fields.FromCityStateZip = this.getFromCityStateZip(data.currentAuto.RemovalCity, data.currentAuto.RemovalState, data.currentAuto.RemovalZip);

            }
        });
    }

    getYearMakeModel(year: string, make: string, model: string): string {
        if( !this.empty(year) ) {
            return year + ' ' + make + ' ' + model;
        }
        return make + ' ' + model;
    }

    getTagTagState(tag: string, state: string): string {

        let tagValid = !this.empty(tag);
        let stateValid = !this.empty(state);

        if( tagValid && stateValid ) {
            return tag + ' ' + state;
        } else if( !tagValid && stateValid ) {
            return state;
        } else if ( tagValid && !stateValid ) {
            return tag;
        }

        return ''
    }

    getFromCityStateZip(city: string, state: string, zip: string): string {

        let cityValid = !this.empty(city);
        let stateValid = !this.empty(state);
        let zipValid = !this.empty(zip);

        if( cityValid && stateValid && zipValid ) {
            return city + ', ' + state + ' ' + zip;
        } else if( cityValid && stateValid && !zipValid ) {
            return city + ', ' + state;
        } else if( !cityValid && stateValid && zipValid ) {
            return state + ' ' + zip;
        } else if(!cityValid && stateValid && !zipValid ) {
            return state;
        } else if( cityValid && !stateValid && zipValid ) {
            return city + ' ' + zip;
        } else if( cityValid && !stateValid &&  !zipValid ) {
            return city;
        } else if( !cityValid && !stateValid && zipValid ) {
            return zip;
        }

        return '';

    }

    empty(str: string): boolean {
        if( '' != str && null != str && undefined != str ) {
            return false;
        }
        return true;
    }



    print(): void {
        //Change to note maybe?
        this.FeedService.save(new Feed({ AutoID: this.autoID, Description: "MV603R Printed", Created: new Date() })).subscribe(
            resp => log.Debug("resp: ", resp)
        )
        window.print();
    }
}
