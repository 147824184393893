import { Component } from '@angular/core';
// import { PDFDocumentProxy } from 'ng2-pdf-viewer';
// import { PDFAnnotationData } from 'pdfjs-dist';
// import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

// export class Input {
//     name: string;
//     type: string;
//     top: number;
//     left: number;
//     width: number;
//     height: number;
//     value: any;
// }

@Component({
    selector: 'print',
    templateUrl: './print.component.html',
    styleUrls: ['./print.component.css']
})

export class PrintComponent {

//     // screen DPI / PDF DPI
//     readonly dpiRatio = 96 / 72;

//     public pdfSrc = './test.pdf';

//     public myForm: FormGroup;

//     public inputList: Input[] = [];

//     public pdf: PDFDocumentProxy;

//     constructor(private _fb: FormBuilder) {
//         this.myForm =  this._fb.group({});
//     }

//     private createInput(annotation: PDFAnnotationData, rect: number[] = null) {
//         let formControl = new FormControl('');

//         const input = new Input();
//         input.name = annotation["fieldName"];

//         if(annotation["fieldType"] === 'Tx') {
//             input.type = 'text';
//             input.value = '';
//         }

//         // Calculate all the positions and sizes
//         if (rect) {
//             input.top = rect[1] - (rect[1] - rect[3]);
//             input.left = rect[0];
//             input.height = (rect[1] - rect[3]);
//             input.width = (rect[2] - rect[0]);
//         }

//         this.inputList.push(input);
//         return formControl;
//     }

//     private addInput(annotation: PDFAnnotationData, rect: number[] = null): void {
//         //add input to page
//         this.myForm.addControl(annotation["fieldName"], this.createInput(annotation, rect));
//     }

//     public getInputPosition(input: Input): any {
//         return {
//             top: `${input.top}px`,
//             left: `${input.left}px`,
//             height: `${input.height}px`,
//             width: `${input.width}px`
//         };
//     }

//     loadComplete(pdf: PDFDocumentProxy): void {
//         console.log("pdf page: ", pdf.getPage(1))
//         this.pdf = pdf;
//         for (let i = 1; i <= pdf.numPages; i++ ) {

//             // track the current page
//             let currentPage = null;
//             pdf.getPage(i).then(p => {
//                 currentPage = p;

//                 // get the annotaions of the current page
//                 return p.getAnnotations();
//             }).then(ann => {

//                 // ugly case due to missing typescript definitions
//                 // please contribute to complete @types/pdfjs-dist
//                 const annotations = (<any>ann) as PDFAnnotationData[];

//                 annotations
//                     .filter(a => a.subtype === 'Widget')
//                     .forEach(a => {

//                         // get the rectangle that represent the single field
//                         // and resize it according to the current DPI
//                         const fieldRect = currentPage.getViewport(this.dpiRatio)
//                                                 .convertToViewportRectangle(a.rect);

//                         // add the corresponding input
//                         this.addInput(a, fieldRect);
//                     });
//             });
//         }
//     }

//     print( event: Event) {
//         console.log("this.pdf.getPage(1): ", this.pdf.getPage(1));
//     }
}