import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DriverService } from './driver.service';
import { Driver } from './driver';

@Injectable()
export class DriverCommissionsResolve implements Resolve<Driver[]> {
    constructor (
        private router: Router,
        private ds: DriverService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<Driver[]> {
        return this.ds.GetAllCommissions();
    }
}