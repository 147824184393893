import { Model } from '../common/model';
import { DriverInvoice } from '../driver';

export class DriverReport extends Model {

    DriverNumber: string;
    FirstName: string;
    LastName: string;
    DriverInvoices: DriverInvoice[];
    Active: boolean;
    Created: Date;
    Modified: Date;

    constructor(properties?: Object) {
        super(properties)
    }
}