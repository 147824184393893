import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { NoteService } from './note.service';
import { Note } from './note';

@Injectable()
export class NotesResolve implements Resolve<Note[]> {
    constructor (
        private router: Router,
        private ns: NoteService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<Note[]> {
        const auto_id = +route.paramMap.get('auto_id');
        return this.ns.GetByAutoID(auto_id);
    }
}