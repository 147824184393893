import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { EmailService } from './email.service';
import { EmailData } from './email-data';

@Injectable()
export class AuctionAdPetitionEmailResolve implements Resolve<EmailData> {
    constructor (
        private router: Router,
        private es: EmailService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<EmailData> {
        return this.es.GetAuctionAdPetitionEmail();
    }
}