import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auto } from '../auto';
import { MONTHS } from '../common/month';
import { Setting } from '../setting';
import { log } from '../common/dev/log';

@Component({
    selector: "mv-603a",
    templateUrl: "./mv-603a.component.html",
    styleUrls: ['./single-print.component.css']
})

export class MV603AComponent implements OnInit {

    private _months = MONTHS

    fields = {
        CompanyCounty: '',
        CompanyAgent: '',
        VIN: '',
        VehicleYear: '',
        Make: '',
        Model: '',
        Location: '',
        Payment: '',
        CompanyAddress: '',
        CompanyCityStateZipCounty: '',
        CompanyPhone: '',
        CurrentDate: new Date(),
        CurrentDay: '',
        CurrentMonth: '',
        CurrentYearTruncated: ''
    }

    constructor (
        private route: ActivatedRoute
    ){}
    ngOnInit(): void {
        this.route.data.subscribe((data: {
            isBlank: boolean,
            currentAuto: Auto,
            settings: {}
        }) => {

            log.Debug("data: ", data)

            if(!data.isBlank) {
                let currentDate = new Date();

                this.fields.CompanyCounty = data.settings['Company County'] || '';
                this.fields.CompanyAgent = data.settings['Form Agent'] || '';
                this.fields.VIN = data.currentAuto.Vin || '';
                this.fields.VehicleYear = data.currentAuto.AutoYear || '';
                this.fields.Make = data.currentAuto.Make.Name || '';
                this.fields.Model = data.currentAuto.AutoModel.Name || '';
                this.fields.Location = data.settings['Company Address'] + ', ' + data.settings['Company State'] + ' '
                    + data.settings['Company Zip'];
                this.fields.Payment = data.currentAuto.PriceOut.toString() || '';
                this.fields.CompanyAddress = data.settings['Company Address'] || '';
                this.fields.CompanyCityStateZipCounty = data.settings['Company City'] + ', ' + data.settings['Company State']
                    + ', ' + data.settings['Company Zip'] + ', ' + data.settings['Company County'];
                this.fields.CompanyPhone = data.settings['Company Phone'] || '';
                this.fields.CurrentDay = currentDate.getDate().toString() || '';
                this.fields.CurrentMonth = this._months[currentDate.getMonth()] || '';
                this.fields.CurrentYearTruncated = this.getTruncatedYear(currentDate.getFullYear());

            }
        });
    }

    getTruncatedYear( year: number ): string {

        if( null == year || undefined == year ) {
            return ''
        }

        return year.toString().substring(2);

    }

    print(): void {
        window.print();
    }
}