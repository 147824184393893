import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from '../common/dev/log';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { Invoice } from './invoice';
import { InvoiceService } from './invoice.service';
import { CustomerService } from '../customer/customer.service';
import { Observable, of } from 'rxjs';
import { startWith, map, tap, take } from 'rxjs/operators';
import { STATES } from '../common/state';
import { UniqueNumberValidator, CanUnique, hasNameValidator } from '../common/validators';
import { Auto } from '../auto/auto';
import { Customer } from '../customer';
import { Charge } from '../charge';
import { Driver, DriverService, InvoicesDriver } from '../driver';
import { Payment } from '../payment';

@Component({
    selector: "edit-invoice",
    templateUrl: "./edit-invoice.component.html"
})

export class EditInvoiceComponent implements OnInit {

    searchWait: number = 1

    filteredInvoices: Observable<Invoice[]>;
    filteredCustomers$: Observable<Customer[]>;
    filteredDrivers: Observable<string[]>[];

    getInvoiceNumber = () => this.currentInvoice.InvoiceNumber

    title: string;
    isEdit: boolean;
    isSearch: boolean;
    nextInvoiceNumber: string;
    yesterday = new Date()
    getParameterDate = null;

    form: FormGroup;

    initialBalance: number;

    public invoices: Invoice[];
    public states = STATES;
    public currentInvoice: Invoice;

    private _zeroed = false;

    private _customerNumbers: Customer[];
    private _driverCommissions: Driver[];

    public currentAuto: Auto;
    public customers: Customer[];
    public drivers: Driver[];

    constructor (
        private route: ActivatedRoute,
        private apps: AppService,
        private is: InvoiceService,
        private router: Router,
        private ds: DriverService,
        private cs: CustomerService
    ) {
        this.currentInvoice = new Invoice();
        this.form = this.createFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            edit: boolean,
            search: boolean,
            nextInvoiceNumber: string,
            currentInvoice: Invoice,
            currentAuto: Auto,
            customerNumbers: Customer[],
            driverCommissions: Driver[],
            invoices: Invoice[]
        }) => {
            log.Debug("data: ", data)
            this.title = data.title;
            this.isEdit = data.edit;
            this.isSearch = data.search;
            this.nextInvoiceNumber = data.nextInvoiceNumber
            this.currentAuto = data.currentAuto
            this.invoices = []
            this.drivers = []
            this.customers = []
            this._customerNumbers = []
            this._driverCommissions = data.driverCommissions
            if( !this.isSearch ) {
                this.filteredDrivers = [of(data.driverCommissions.map(driver => driver.DriverNumber))]
            }
            if( Boolean(this.currentAuto) ) {
                this.form.get('AutoID').patchValue(this.currentAuto.ID);
            }
            log.Debug("filtered drivers: ", this.filteredDrivers)

            this.filteredInvoices = this.form.controls['InvoiceNumber'].valueChanges.pipe(
                startWith(''),
                map(value => this._filterInvoices(value))
            );

            // this.filteredCustomers = this.form.controls['CustomerID'].valueChanges.pipe(
            //     startWith(''),
            //     map(value => typeof value != 'string' ? this._filterCustomers(this._getCustomerNumberByID(value)) : this._filterCustomers(value))
            // );

            // this.filteredDrivers = this.form.get('Drivers').valueChanges.pipe(
            //     startWith(''),
            //     map(value => typeof value != 'string' ? this._filterDrivers(this._getDriverNumberByID(value)) : this._filterDrivers(value))
            // );

            // this.form.controls['Payments'].valueChanges.pipe(
            //     map(value => this._calculateRemainingBalance(this.form.value.InitialBalance, value))
            // ).subscribe(value => this.form.controls.RemainingBalance.patchValue(value));

            // this.form.controls['InitialBalance'].valueChanges.pipe(
            //     map(value => this._calculateRemainingBalance(value, this.form.value.Payments))
            // ).subscribe( value =>  this.form.controls.RemainingBalance.patchValue(value));

            // this.form.controls['InvoicesDrivers'].valueChanges.pipe(
            //     tap(value => log.Debug("invoices drivers value: ", value )),
            //     map(value => this._calculateInitialBalance(this.form.value.AdditionalBalance, value))
            // ).subscribe( value => this.form.controls.InitialBalance.patchValue(value));

            // this.form.controls['AdditionalBalance'].valueChanges.pipe(
            //     map(value => this._calculateInitialBalance(value, this.form.value.InvoicesDrivers ))
            // ).subscribe(value => this.form.controls.InitialBalance.patchValue(value));

            if( Boolean(this.currentAuto) ) {
                this.initialBalance = this.getInitialBalance();
                this.form.controls['InitialBalance'].setValue(this.initialBalance);
            }

            this.yesterday.setDate(this.yesterday.getDate() - 1);

            if (!this.isEdit && !this.isSearch) {
                log.Debug("invoice number: ", this.nextInvoiceNumber)
                this.getParameterDate = this.route.snapshot.paramMap.get('date');
                this._customerNumbers = data.customerNumbers
                this.currentInvoice = new Invoice();
                this.form.controls['Invoiced'].setValue(this.yesterday);
                if(this.getParameterDate) {
                    this.form.controls['Invoiced'].setValue(this.getParameterDate);
                }
            } else if ( this.isEdit ) {
                Object.assign(this.currentInvoice, data.currentInvoice);
                for (let p in this.currentInvoice.Payments) {
                    this.addPayment();
                }
                for (let d in this.currentInvoice.InvoicesDrivers) {
                    this.addDriver();
                }
                this.form.patchValue(this.currentInvoice);
                this.invoices = []
                this.drivers = []
                this.customers = []
                this._customerNumbers = data.customerNumbers
                this.form.get('Customer').patchValue(this._customerNumbers.find(({ID}) => ID == this.currentInvoice.CustomerID))
                this.form.get('CompanyName').patchValue(this._customerNumbers.find(({ID}) => ID == this.currentInvoice.CustomerID).Company)

            } else {
                this.resetForm()
                this.invoices = data.invoices
            }

        })
    }

    resetForm() {
        this.form.reset()
        this.form.get('InvoiceNumber').clearValidators();
        this.form.get('InvoiceNumber').clearAsyncValidators();
        this.form.get('InvoiceNumber').updateValueAndValidity();

        this.form.get('Customer').clearValidators();
        this.form.get('Customer').updateValueAndValidity();

        this.form.get('InitialBalance').clearValidators();
        this.form.get('InitialBalance').updateValueAndValidity();

        this.form.get('AdditionalBalance').patchValue(0)

    }

    getInitialBalance(): number {
        return this.currentAuto.Charges.reduce((acc, cur) => acc + cur.Charge, 0)
    }

    private _calculateRemainingBalance(initialBalance: number, paymentsArray: any[]): number {
        if (this._zeroed) {
            return 0
        }

        this.initialBalance = 0
        if( Boolean(initialBalance) ) {
            this.initialBalance = initialBalance
        }

        log.Debug("initial balance: ", this.initialBalance);

        let payments = paymentsArray.reduce((acc, cur) => acc + cur.Amount, 0)
        log.Debug("payments: ", payments);
        let remaining = this.initialBalance - payments
        return +(Math.round((this.initialBalance - payments) * 100) / 100);
    }

    calculateRemainingBalance(): void {
        this.form.controls.RemainingBalance.patchValue(this._calculateRemainingBalance(this.form.value.InitialBalance, this.form.value.Payments));
    }

    private _calculateInitialBalance(additionalBalance: number, driversArray: any[]): number {

        const commissions = driversArray.filter( driver => driver.IsCommissionOnly == false)
            .reduce((acc, cur) => acc + cur.CommissionValue, 0)
        log.Debug("commissions: ", commissions);
        const initial = additionalBalance + commissions
        return +(Math.round((initial) * 100) / 100);
    }

    calculateInitialBalance(): void {
        this.form.controls.InitialBalance.patchValue(this._calculateInitialBalance(this.form.value.AdditionalBalance, this.form.value.InvoicesDrivers));
    }

    zero() {
        this.form.controls.InitialBalance.patchValue(0);
        this.form.controls.RemainingBalance.patchValue(0);
    }

    addZeroPayment(): void {
        let payments = this.form.get('Payments') as FormArray;
        payments.push(this.createZeroPaymentFormGroup());
    }

    createZeroPaymentFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            Name: new FormControl("Payment",Validators.required),
            Amount: new FormControl(this.form.value.InitialBalance, Validators.required),
            Date: new FormControl(this.getParameterDate ? this.getParameterDate : this.yesterday, Validators.required)
        })
    }

    createFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            InvoiceNumber: new FormControl(null, {
                validators: [Validators.required],
                asyncValidators: [UniqueNumberValidator.createValidator({ number: this.getInvoiceNumber, service: this.is })]
            }),
            Invoiced: new FormControl(),
            Name: new FormControl(),
            Note: new FormControl(),
            Customer: new FormControl(null, Validators.required),
            CompanyName: new FormControl(),
            CustomerID: new FormControl(),
            AutoID: new FormControl(),
            InvoicesDrivers: new FormArray([]),
            Charges: new FormArray([]),
            AdditionalBalance: new FormControl(0),
            InitialBalance: new FormControl(null, Validators.required),
            Payments: new FormArray([]),
            RemainingBalance: new FormControl(),
            IsClosed: new FormControl(),
            IsPosted: new FormControl(),
        })
    }

    createDriverFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            DriverID: new FormControl(),
            DriverNumber: new FormControl(),
            CommissionPercentage: new FormControl(),
            CommissionValue: new FormControl(),
            Commission: new FormControl(),
            IsCommissionOnly: new FormControl(false)
        })
    }

    createCustomerFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            CustomerNumber: new FormControl(),
            Company: new FormControl(),
            LastName: new FormControl(),
            FirstName: new FormControl(),
            MiddleInitial: new FormControl(),
            Address1: new FormControl(),
            Address2: new FormControl(),
            City: new FormControl(),
            State: new FormControl(),
            Zip: new FormControl(),
            Phone: new FormControl(),
            County: new FormControl(),
            CreditLimit: new FormControl(),
            Terms: new FormControl(),
            StatementFrequency: new FormControl()
        })
    }

    createPaymentFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            Name: new FormControl(null,Validators.required),
            Amount: new FormControl(null, Validators.required),
            Date: new FormControl(this.yesterday, Validators.required),
        })
    }

    gotoNew() {
        this.router.navigate(['../../', 'new', this.form.get('Invoiced').value], {relativeTo: this.route});
    }

    gotoEdit() {
        this.router.navigate(['../../', 'edit'], {relativeTo: this.route});
    }

    // NATODO on change error once driver input and add driver clicked
    addDriver(): void {
        let drivers = this.form.get('InvoicesDrivers') as FormArray;
        drivers.push(this.createDriverFormGroup());
    }
    removeDriver(i: number): void {
        let drivers = this.form.get('InvoicesDrivers') as FormArray;
        drivers.removeAt(i);
        if (!this.isEdit && !this.isSearch) {
            return
        }
       this.currentInvoice.InvoicesDrivers.splice(i, 1)
    }

    addPayment(): void {
        let payments = this.form.get('Payments') as FormArray;
        payments.push(this.createPaymentFormGroup());
    }
    removePayment(i: number): void {
        let payments = this.form.get('Payments') as FormArray;
        payments.removeAt(i);
        if (!this.isEdit && !this.isSearch) {
            return
        }
        this.currentInvoice.Payments.splice(i, 1)
    }

    private _getCustomerNumberByID(id: number): string {
        const index = this._customerNumbers.findIndex( customer => customer.ID === id);
        if (index === -1) {
            return ''
        }
        return this._customerNumbers[index].CustomerNumber;
    }

    private _getCompanyNameByID(id: number): string {
        const index = this._customerNumbers.findIndex( customer => customer.ID === id);
        if (index === -1) {
            return ''
        }
        if (this._customerNumbers[index].Company == null || this._customerNumbers[index].Company == '') {
            return this._customerNumbers[index].FullName;
        }
        return this._customerNumbers[index].Company;
    }

    displayDriver(driverID?: number): string {
        return driverID ? this._getDriverNumberByID(driverID) : '';
    }

    private _getDriverNumberByID(id: number): string {
        const index = this.drivers.findIndex( driver => driver.ID === id);
        if (index === -1) {
            return ''
        }
        return this.drivers[index].DriverNumber;
    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }

    private _filterInvoices(value: string): Invoice[] {
        value = value || ''
        log.Debug('value: ', value)
        if(!this.invoices) {
            return null
        }
        log.Debug('return: ', this.invoices.filter(option => option.InvoiceNumber.toLowerCase().indexOf(value.toLowerCase()) === 0))
        return this.invoices.filter(option => option.InvoiceNumber.toLowerCase().indexOf(value.toLowerCase()) === 0);
    }

    // private _filterCustomers(value: string): Customer[] {
    //     log.Debug("value: ", value)
    //     if(!this._customerNumbers) {
    //         return null
    //     }
    //     return this._customerNumbers.filter(option => option.CustomerNumber.toLowerCase().indexOf(value.toLowerCase()) === 0);
    // }

    filterCustomers = (value: string | Customer): Observable<Customer[]> => {
        value = value || '0';
        
        if (typeof value != 'string') {;
            return of([value])
        }

        return of(this._customerNumbers.filter(val => val.CustomerNumber.toUpperCase().indexOf(value.toString().toUpperCase()) !== -1));
    }

    displayCustomers = (customer: Customer): string => {
        return customer ? customer.CustomerNumber : ""
    }

    filterDrivers = (value: string): Observable<string[]> => {
        if(!this._driverCommissions) {
            return null
        }
        return of(this._driverCommissions.filter(option => option.DriverNumber.toLowerCase().indexOf(value.toLowerCase()) === 0)
        .map(driver => driver.DriverNumber));
    }

    setCommission = (index: number) => {
        const control = this.form.get('InvoicesDrivers') as FormArray;
        const driverNumber = control.at(index).get('DriverNumber').value
        let driver = this._driverCommissions.find(val => val.DriverNumber == driverNumber)
        if (  driver != undefined ) {
            control.at(index).patchValue({DriverID: driver.ID, CommissionPercentage: driver.Commission})
        }
    }

    email() {
        this.router.navigate(['../../../', 'email', 'invoice', this.currentInvoice.ID], {relativeTo: this.route});
    }

    onSubmit(e): void {

        if( this.isSearch && this.form.dirty ) {
            this.form.setErrors(null)
            this.is.GetByNumber(this.form.get('InvoiceNumber').value).pipe(take(1)).subscribe(invoiceID => {
                if (invoiceID == -1) {
                    this.apps.Error('Cannot find an invoice with that invoice number.');
                    return
                }
                this.router.navigate([invoiceID], {relativeTo: this.route});
            });

            return
        }

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched: ", this.form)
            return;
        }

        this.form.get('CustomerID').patchValue(this.form.get('Customer').value.ID)

        if( this.form.get('Customer').value.CustomerNumber == "101" ) {
            log.Debug("customer number: ", this.form.get('Customer').value.CustomerNumber)
            this.addZeroPayment();
            this.form.get('RemainingBalance').patchValue(0);
        } else {
            log.Debug("customer number false");
        }

        this.save();
    }

    save() {
        log.Debug("before Save: ", this.form.value);
        this.is.save(this.form.value)
        .subscribe((savedInvoice: Invoice) => {
            this.apps.Confirmation('Invoice saved.');
            this.form.markAsPristine();

            if( !this.isEdit ) {
                this.currentInvoice = new Invoice(savedInvoice);
                this.invoices.push(this.currentInvoice);

                // NA behavoir request by accountant 5/11/23
                // this.resetForm()
                this.router.navigate(['invoices', 'edit', this.currentInvoice.ID])
                return
            }

            log.Debug("savedInvoice: ", savedInvoice)

            const index = this.invoices.findIndex( invoice => invoice.ID === this.currentInvoice.ID );
            if (index != -1) {
                Object.assign(this.invoices[index], new Invoice(savedInvoice));
            }
            this.currentInvoice = savedInvoice
            this.form.patchValue(savedInvoice);
            // this.form.get('Customer').patchValue(this._customerNumbers.find(({ID}) => ID == savedInvoice.CustomerID))
        })
    }

    delete(e) {
        e.preventDefault();
        e.stopPropagation();
        if (confirm('This invoice will be deleted. Are you sure?')) {
            this.is.delete(this.currentInvoice).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Invoice deleted.');
                    const index = this.invoices.findIndex( invoice => invoice.ID === this.currentInvoice.ID);
                    this.invoices.splice(index, 1);
                    this.router.navigate(['dashboard']);
                }
            })
        }
    }

    deletePayment(e, index: number) {
        e.preventDefault();
        e.stopPropagation();

        if(!this.isEdit) {
            log.Debug("is edit: ", this.isEdit)
            this.removePayment(index);
            return
        }

        const payment = this.currentInvoice.Payments[index] as Payment;
        if(!Boolean(payment)) {
            log.Debug("payment: ", payment)
            this.removePayment(index);
            return
        }

        if(confirm("This payment will be deleted. Are you sure?")) {
            this.is.deletePayment(<Payment>payment).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Payment deleted.');
                    this.removePayment(index);
                }
            })
        }
    }

    deleteInvoicesDriver(e, index: number) {
        e.preventDefault();
        e.stopPropagation();

        if(!this.isEdit) {
            log.Debug("is edit: ", this.isEdit)
            this.removeDriver(index);
            return
        }

        log.Debug("invoices drivers: ", this.currentInvoice.InvoicesDrivers)

        const driver = this.currentInvoice.InvoicesDrivers[index] as Driver;
        const invoiceDriver = new InvoicesDriver(driver);

        log.Debug("invoice driver: ", invoiceDriver)
        if(!Boolean(invoiceDriver.ID)) {
            log.Debug("invoiceDriver: ", invoiceDriver)
            this.removeDriver(index);
            return
        }

        if(confirm("This driver will be removed. Are you sure?")) {
            this.is.deleteInvoicesDriver(<InvoicesDriver>invoiceDriver).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.removeDriver(index);
                }
            })
        }
    }

}
