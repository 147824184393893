import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { InvoiceService } from './invoice.service';
import { Invoice } from './invoice';

@Injectable()
export class InvoicesResolve implements Resolve<Invoice[]> {
    constructor (
        private router: Router,
        private is: InvoiceService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<Invoice[]> {
        return this.is.GetAll();
    }
}