<mat-card class="search search-invoices">
    <h1 class="title">{{ title }}</h1>
    <div class="row gutters">
        <div class="col">
            <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
                <mat-card class="searh-panel">
                    <mat-card-content>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                        <input type="text" placeholder="Invoice Number" matInput [matAutocomplete]="auto" formControlName="InvoiceNumber">
                                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                            <mat-option *ngFor="let invoice of filteredInvoices | async" [value]="invoice.InvoiceNumber">{{ transaction.InvoiceNumber }}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                    <input matInput [matDatepicker]="BeginDate" placeholder="Beginning Date" formControlName="BeginDate" [max]="form.value.EndDate">
                                    <mat-datepicker-toggle matSuffix [for]="BeginDate"></mat-datepicker-toggle>
                                    <mat-datepicker #BeginDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <input matInput [matDatepicker]="EndDate" placeholder="End Date" formControlName="EndDate" [min]="form.value.BeginDate">
                                    <mat-datepicker-toggle matSuffix [for]="EndDate"></mat-datepicker-toggle>
                                    <mat-datepicker #EndDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Driver Number" formControlName="DriverNumber">
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Customer Number" formControlName="CustomerNumber">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="number" placeholder="Remaining Balance" formControlName="RemainingBalance">
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Name" formControlName="Name">
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-actions end>
                        <button color="primary" type="submit" mat-raised-button class="button--float-right"><mat-icon>search</mat-icon>Search</button>
                        <button color="primary" type="button" mat-raised-button class="button--float-right" (click)="reset($event)"><mat-icon>autorenew</mat-icon>Clear</button>
                    </mat-card-actions>
                </mat-card>
            </form>
        </div>
        <div class="col--size-2" style="position: relative;">
            <div class="spinner-container" *ngIf="dataSource.loading$ | async" style="position: absolute; top: 200px; left: 50%; transform: translate( -50%, 0 );">
                <mat-spinner></mat-spinner>
            </div>

            <table mat-table [dataSource]="dataSource">

                <!-- invoice_number -->
                <ng-container matColumnDef="invoice_number">
                    <th mat-header-cell *matHeaderCellDef>Invoice Number</th>
                    <td mat-cell *matCellDef="let invoice"><a [routerLink]="['edit', invoice.ID]">{{ invoice.InvoiceNumber }}</a></td>
                </ng-container>

                <!-- date -->
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Date</th>
                    <td mat-cell *matCellDef="let invoice">{{ invoice.Invoiced | date: 'shortDate': 'UTC' }}</td>
                </ng-container>

                <!-- driver_numbers -->
                <ng-container matColumnDef="driver_numbers">
                    <th mat-header-cell *matHeaderCellDef>Driver Numbers</th>
                    <td mat-cell *matCellDef="let invoice"><p *ngFor="let driver of invoice.InvoicesDrivers" style="margin-bottom: 1px;">{{ driver.DriverNumber }}</p></td>
                </ng-container>

                <!-- customer_number -->
                <ng-container matColumnDef="customer_number">
                    <th mat-header-cell *matHeaderCellDef>Customer Number</th>
                    <td mat-cell *matCellDef="let invoice">{{ invoice.CustomerNumber }}</td>
                </ng-container>

                <!-- remaining_balance -->
                <ng-container matColumnDef="remaining_balance">
                    <th mat-header-cell *matHeaderCellDef>Remaining Balance</th>
                    <td mat-cell *matCellDef="let invoice">{{ invoice.RemainingBalance | currency }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <mat-paginator [length]="dataSource.length$ | async" [pageSize]="10" [pageSizeOptions]="[10, 50, 100]"></mat-paginator>

        </div>
    </div>
</mat-card>
<pre class="debug">{{ form.value | json }}</pre>