import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from '../common/dev/log';
import { MONTHS } from '../common/month';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { map, debounceTime, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { LoadingService } from '../common/loading.service';
import { Customer } from '../customer';
import { InvoiceService } from '../invoice';
import { AppService } from '../app.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: "sales-report-pdf",
    templateUrl: "./sales-report-pdf.component.html"
})

export class SalesReportPDFComponent implements OnInit {

    form: FormGroup;

    isLoading = true;
    downloading = false;
    years = [];

    customers: Customer[]
    filteredCustomers$: Observable<Customer[]>;

    months = MONTHS

    @ViewChild('showYearRange', {static: true}) showYearrange;

    constructor (
        private route: ActivatedRoute,
        public ls: LoadingService,
        private cdr: ChangeDetectorRef,
        public InvoiceService: InvoiceService,
        public AppService: AppService,
        private _snackBar: MatSnackBar,
    ){
        this.form = this.createFormGroup()
    }
    ngOnInit(): void {
        this.route.data.subscribe((data: {
            customers: Customer[]
        }) => {
            log.Debug("data: ", data);
            this.customers = data.customers
        });

        var d = new Date();
        var currentYear = (d.getFullYear());

        this.years = Array.from(Array(20), (_, i) => currentYear - i);
    }

    createFormGroup(): FormGroup {
        return new FormGroup({
            Customer: new FormControl(),
            Year: new FormControl(),
            FromYear: new FormControl(),
            ToYear: new FormControl(),
            Month: new FormControl(),
        });
    }

    filterCustomers = (value: string | Customer): Observable<Customer[]> => {
        value = value || 'a';
        
        if (typeof value != 'string') {
            return of([value])
        }

        return of(this.customers.filter(val => val.Company.toUpperCase().indexOf(value.toString().toUpperCase()) !== -1));
    }

    displayCustomers = (customer: Customer): string => {
        return customer ? customer.Company : ""
    }

    getErrorMessage(field: FormControl): string {
        console.log("field: ", field)
        return this.AppService.ErrorMessages(field)
    }

    salesReportPDF() {
        if( this.form.invalid ) {
            console.log("form invalid")
            return
        }
        let selectedYears = [];
        if(this.showYearrange.checked){
            if((this.form.get('ToYear').value < this.form.get('FromYear').value)){
                this._snackBar.open('From Year must be smaller than To Year!',null ,{duration: 3000});
                console.log("form invalid")
                return
            }
            if((this.form.get('ToYear').value - this.form.get('FromYear').value) > 3){
                this._snackBar.open('Selected year range can only be four years apart!',null ,{duration: 3000});
                console.log("form invalid")
                return
            }

            var liselectedYearsst = [];
            for (var i = this.form.get('FromYear').value; i <= this.form.get('ToYear').value; i++) {
                selectedYears.push(i);
            }

        }else{
            selectedYears = [this.form.get('Year').value]
        }
        console.log("form valid: ", this.form)
        this.downloading = true;
        this.InvoiceService.SearchSalesReportPDF(this.form.get('Customer').value, selectedYears, this.form.get('Month').value).subscribe( blob => {
            const a = document.createElement('a');
            a.setAttribute('download', 'sales-report.pdf');
            a.setAttribute('href', window.URL.createObjectURL(blob));
            a.click()
            this.downloading = false;
        })
    }

}
