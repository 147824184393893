import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard';
import { AUTO_ROUTES } from './auto';
import { INVOICE_ROUTES } from './invoice';
import { CUSTOMER_ROUTES } from './customer';
import { DRIVER_ROUTES } from './driver';
import { TRANSACTION_ROUTES } from './transaction';
import { USER_ROUTES } from './user';
import { CHARGE_TYPE_ROUTES } from './charge-type';
import { IMPOUND_TYPE_ROUTES } from './impound-type';
import { STATUS_DESCRIPTION_ROUTES } from './status-description';
import { MAKE_ROUTES } from './make';
import { AUTO_MODEL_ROUTES } from './auto-model';
import { STATUS_ROUTES } from './status';
import { PRINT_ROUTES } from './print';
import { SETTING_ROUTES } from './setting';
import { OFFICER_ROUTES } from './officer';
import { AUTH_ROUTES, LoginComponent, AuthGuardService as AuthGuard } from './user_auth';
import { EMAIL_ROUTES } from './email';
import { PAYMENT_ROUTES } from './payment';
import { AuthGroups, AuthRoles } from './common/model';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
            auth: AuthRoles.PENDING
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'autos',
        children: [...AUTO_ROUTES],
        data: {
            auth: AuthRoles.STANDARD
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'invoices',
        children: [...INVOICE_ROUTES],
        data: {
            auth: AuthRoles.ACCOUNTING
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'payments',
        children: [...PAYMENT_ROUTES],
        data: {
            auth: AuthRoles.ACCOUNTING
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'customers',
        children: [...CUSTOMER_ROUTES],
        data: {
            auth: AuthRoles.ACCOUNTING
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'drivers',
        children: [...DRIVER_ROUTES],
        data: {
            auth: AuthRoles.ACCOUNTING
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'makes',
        children: [...MAKE_ROUTES],
        data: {
            auth: AuthRoles.ADMIN
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'models',
        children: [...AUTO_MODEL_ROUTES],
        data: {
            auth: AuthRoles.ADMIN
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'auctions',
        children: [...TRANSACTION_ROUTES],
        data: {
            auth: AuthRoles.ALL
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'users',
        children: [...USER_ROUTES],
        data: {
            auth: AuthRoles.ADMIN
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'manage',
        children: [
            ...CHARGE_TYPE_ROUTES,
            ...IMPOUND_TYPE_ROUTES,
            // ...MAKE_ROUTES,
            // ...AUTO_MODEL_ROUTES,
            ...STATUS_ROUTES,
            ...SETTING_ROUTES,
            ...OFFICER_ROUTES,
            ...STATUS_DESCRIPTION_ROUTES,
        ],
        data: {
            auth: AuthRoles.ADMIN
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'print',
        children: [...PRINT_ROUTES],
        data: {
            auth: AuthRoles.STANDARD
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'email',
        children: [...EMAIL_ROUTES],
        data: {
            auth: AuthRoles.STANDARD
        },
        canActivate: [AuthGuard],
    },
    ...AUTH_ROUTES,
    {
        path: "**",
        redirectTo: "dashboard"
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
