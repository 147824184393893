import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DeleteResponse } from '../common/delete-response';
import { User } from './user';
import { UserRole } from './user-role';

@Injectable({
    providedIn: 'root'
})

export class UserService extends HttpService implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    Get( user_id: number ): Observable<User> {
        return this.http.get<User>('/api/users/get/' + user_id).pipe(map(val => new User(val)));
    }

    GetAll(): Observable<User[]> {
        return this.http.get<User[]>('/api/users/all').pipe(map(this.toUser));
    }

    toUser(user) {
        const temp = user.map( user => {
            return new User(user);
        });
        return temp;
    }

    GetUserRoles(): Observable<UserRole[]> {
        return this.http.get<UserRole[]>('/api/roles/all').pipe(map(this.toUserRole));
    }

    toUserRole(userRole) {
        const temp = userRole.map( userRole => {
            return new UserRole(userRole);
        });
        return temp;
    }

    save(user: User): Observable<User> {
        if (!!user.ID) {
            return this.http.put<User>('/api/users/update/' + user.ID, user);
        }
        return this.http.post<User>('/api/users/create', user);
    }

    SignUp(user: User): Observable<User> {
        return this.http.post<User>('/api/users/sign-up', user);
    }

    delete(user: User): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/users/delete/' + user.ID)
    }

    isNumberUnique(userNumber: string): Observable<boolean> {
        return this.http.get<boolean>('/api/users/is_unique/' + userNumber).pipe(map(val => val));
    }

    GetByNumber(userNumber: string): Observable<number> {
        return this.http.get<number>('/api/users/get_by_number/' + userNumber).pipe(map(val => val));
    }
    GetNextUserNumber(): Observable<string> {
        return this.http.get<string>('/api/users/next').pipe(map(val => val));
    }

    Search(user: User): Observable<User[]> {
        return this.http.post<User[]>('/api/users/search', user).pipe(map(val => val));
    }

    isUsernameUnique(username: string): Observable<boolean> {
        return this.http.get<boolean>('/api/users/is_unique/' + username).pipe(map(val => val));
    }

}