import { Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';

import { Observable, throwError} from 'rxjs';

@Injectable()
export class HttpService {

    constructor(protected http: HttpClient) {}

    protected handleError(res: Response) {
        console.error(res);
        const err = res.text() || 'Server error.';
        return throwError(err);
    }
}