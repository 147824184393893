import { Routes } from '@angular/router';

import { ManageStatusesComponent } from './manage-statuses.component';
import { StatusesResolve } from './statuses.resolve';

export const STATUS_ROUTES: Routes = [
    {
        path: 'statuses',
        component: ManageStatusesComponent,
        data: {
            title: 'Manage Statuses'
        },
        resolve: {
            statuses: StatusesResolve
        }
    }
]