import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Make } from "./make";
import { log } from "../common/dev/log";
import { AppService } from "../app.service";
import { MakeService } from "./make.service";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
    selector: "edit-make",
    templateUrl: "edit-make.component.html"
})

export class EditMakeComponent implements OnInit {

    title: string;
    isEdit: boolean;
    currentMake: Make;
    form: FormGroup;
    Make_to_edit;

constructor(
    private route: ActivatedRoute,
    private apps: AppService,
    private router: Router,
    private ms: MakeService
){
    this.currentMake = new Make();
    this.form = this.createFormGroup();
}

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            edit: boolean,
            currentMake: Make,
        }) => {
            log.Debug("data: ", data)

            this.title = data.title;
            this.isEdit = data.edit;
            this.currentMake = data.currentMake;

            if (this.isEdit) {
                Object.assign(this.currentMake, data.currentMake);
                this.form.patchValue(this.currentMake);

                this.ms.GetByID(this.currentMake.ID).subscribe( r => {
                    this.Make_to_edit = r;
                })
            }
        });
    }

    createMakeArr() {
        return new Make;
    }

    createFormGroup() {
        return new FormGroup({
            Name: new FormControl(),
            ID: new FormControl()
        })
    }

    onSubmit(e): void{
        if (!this.form.dirty || this.form.invalid) {
            log.Debug("Input invalid: ", this.form);
            return;
        }

        this.save();
    }

    save() {
        this.ms.save(this.form.value).subscribe((savedMake: Make) => {
            this.apps.Confirmation('Make Saved.');
            this.form.markAsPristine();

            if ( !this.isEdit ) {
                this.currentMake = new Make(savedMake);
                this.router.navigate(['../', 'edit', this.currentMake.ID], {relativeTo: this.route})
                return
            }

            log.Debug("savedMake: ", savedMake);

            this.currentMake = savedMake;
            this.form.patchValue(savedMake);
        })
    }

    delete(e) {
        e.preventDefault();
        e.stopPropagation();
        if (confirm('This make will be deleted. Are you sure?')) {
            this.ms.delete(this.currentMake).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Make deleted');
                        this.router.navigate(['makes']);
                }
            })
        }
    }
}