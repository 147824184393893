import { Routes } from '@angular/router'

import { EditPaymentComponent } from './edit-payment.component';

import { CurrentPaymentResolve } from './current-payment.resolve';

export const PAYMENT_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'new',
        pathMatch: 'full'
    },
    {
        path: 'new',
        component: EditPaymentComponent,
        data: {
            title: 'New Payment',
            edit: false,
            search: false
        },
    },
    {
        path: 'edit',
        children: [
            {
                path: '',
                redirectTo: '..',
                pathMatch: 'full'
            },
            {
                path: ':payment_id',
                component: EditPaymentComponent,
                data: {
                    title: 'Edit Payment',
                    edit: true,
                    search: false
                },
                resolve: {
                    currentPayment: CurrentPaymentResolve
                }
            }
        ]
    }
]