import { Routes } from '@angular/router'

import { SearchDriversComponent } from './search-drivers.component';
import { EditDriverComponent } from './edit-driver.component';

import { NextDriverNumberResolve } from './next-driver-number.resolve';
import { CurrentDriverResolve } from './current-driver.resolve';

export const DRIVER_ROUTES: Routes = [
    {
        path: '',
        component: SearchDriversComponent,
        data: {
            title: 'Search Drivers'
        }
    },
    {
        path: 'new',
        component: EditDriverComponent,
        data: {
            title: 'New Driver',
            edit: false,
            search: false
        },
        resolve: {
            nextDriverNumber: NextDriverNumberResolve
        }
    },
    {
        path: 'edit',
        children: [
            {
                path: '',
                component: EditDriverComponent,
                data: {
                    title: 'Edit Driver',
                    edit: false,
                    search: true
                }
            },
            {
                path: ':driver_id',
                component: EditDriverComponent,
                data: {
                    title: 'Edit Driver',
                    edit: true,
                    search: false
                },
                resolve: {
                    currentDriver: CurrentDriverResolve
                }
            }
        ]
    }
]