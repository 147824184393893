import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NotesDialog, FeedsDialog, MenuDialog } from '../common/dialog';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { from, Observable, of } from 'rxjs';
import { startWith, map, tap, debounceTime, distinctUntilChanged, take, switchMap, throwIfEmpty } from 'rxjs/operators';
import { log } from '../common/dev/log';
import { Auto } from './auto';
import { AutoService } from './auto.service';
import { Owner } from '../owner';
import { Status } from '../status';
import { StatusDescription } from '../status-description';
import { ImpoundType } from '../impound-type';
import { Make, MakeService } from '../make';
import { AutoModel, AutoModelService } from '../auto-model';
import { STATES } from '../common/state';
import { ChargeType } from '../charge-type';
import { Charge } from '../charge';
import { Feed } from '../feed';
import { Note } from '../note';
import { AppService } from '../app.service';
import { idSelectedValidator } from '../common/validators';
import { hasNameValidator } from '../common/validators';
import { UniqueNumberValidator } from '../common/validators';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export class Report {
    Name: string;
    Link: string;
}

@Component({
    selector: "edit-auto",
    templateUrl: "./edit-auto.component.html"
})

export class EditAutoComponent implements OnInit {

    title: string;
    isEdit: boolean;
    isSearch: boolean;
    daysSinceDateIn: number;

    getAutoNumber = () => this.currentAuto.AutoNumber;

    form: FormGroup;
    public nextAutoNumber: string;
    public currentAuto: Auto;
    public owners: Owner[];
    public lienholders: Owner[];
    public statuses: Status[];
    public statusDescriptions: StatusDescription[];
    public impoundTypes: ImpoundType[];
    public makes: Make[];
    public autoModels: AutoModel[];
    public chargeTypes: ChargeType[];
    public charges: Charge[];
    public feeds: Feed[];
    public notes: Note[];
    public states = STATES;

    moment = new MomentDateAdapter('L');

    private _reports: Report[];

    autoIncludeChargeTypes: number[] = [4,8,1,2];
    autoIncludeCharges: Charge[] = [];

    filteredMakes$: Observable<Make[]>;
    filteredAutoModels$: Observable<AutoModel[]>;
    displayObjectFn = (value) => value.Name;

    calculatedPriceIn: Observable<number>;

    constructor (
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private apps: AppService,
        private as: AutoService,
        private router: Router,
        private ms: MakeService,
        private ams: AutoModelService
    ) {
        this.currentAuto = new Auto();
        this.form = this.createFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            edit: boolean,
            search: boolean,
            nextAutoNumber: string,
            currentAuto: Auto,
            statuses: Status[],
            statusDescriptions: StatusDescription[],
            impoundTypes: ImpoundType[],
            makes: Make[],
            autoModels: AutoModel[],
            chargeTypes: ChargeType[],
            feeds: Feed[],
            notes: Note[]
        }) => {
            log.Debug("data: ", data)
            log.Debug("auto: ", this.currentAuto.AutoNumber)
            this.title = data.title;
            this.isEdit = data.edit;
            this.isSearch = data.search;
            this.nextAutoNumber = data.nextAutoNumber;
            this.statuses = data.statuses;
            this.statusDescriptions = data.statusDescriptions;
            this.impoundTypes = data.impoundTypes;
            this.makes = data.makes;
            this.autoModels = data.autoModels;
            this.chargeTypes = data.chargeTypes;

            if( this.isEdit ) {
                Object.assign(this.currentAuto, data.currentAuto);
                this.feeds = data.feeds;
                this.notes = data.notes;
            }

        })

        this.form.controls['Charges'].valueChanges.pipe(
            map(value => this._calculatePrices(value))
        ).subscribe(value => this.patchPrices(value))

        this.form.get('DateOut').valueChanges.pipe(
            map(value => this._calculatePrices(this.form.controls['Charges'].value))
        ).subscribe(value => this.patchPrices(value))

        this.form.get('Vin').valueChanges.pipe(
            debounceTime(500),
            map(val => val.toUpperCase()),
        ).subscribe(val => this.form.get('Vin').patchValue(val))

        if (!this.isEdit && !this.isSearch) {
            this.form.controls['AutoNumber'].setValue(this.nextAutoNumber);
            this.form.controls['StatusID'].setValue(this.statuses[this.statuses.findIndex(val => val.Name == "Open")].ID);
            this.form.controls['DateIn'].setValue(new Date());

            for (let ct in this.autoIncludeChargeTypes) {
                const ID = this.autoIncludeChargeTypes[ct];
                const index = this.chargeTypes.findIndex(c => c.ID == ID); 
                this.addCharge()
                this.autoIncludeCharges.push(new Charge({ID: null, Name: this.chargeTypes[index].Name, Charge: 0}))
            }

            this.form.controls['Charges'].patchValue(this.autoIncludeCharges);

        } else if ( this.isEdit ) {
                
            if (!this.currentAuto.Owners) {
                this.currentAuto.Owners = []
            }
            for (let o in this.currentAuto.Owners) {
                this.addOwner();
            }
            if (!this.currentAuto.Lienholders) {
                this.currentAuto.Lienholders = []
            }
            for (let l in this.currentAuto.Lienholders) {
                this.addLienholder();
            }
            if (!this.currentAuto.Charges) {
                this.currentAuto.Charges = []
            }
            for (let c in this.currentAuto.Charges) {
                this.addCharge();
            }
            log.Debug("curent auto: ", this.currentAuto)
            this.form.patchValue(this.currentAuto);
                
                
            this._reports = [
                {Name: "Request for Owner Information", Link: "/print/request-for-owner-information/" + this.currentAuto.ID},
                {Name: "Petition for Lien Foreclosure", Link: "/print/petition-for-lien-foreclosure/" + this.currentAuto.ID},
                {Name: "Motion for Lien Foreclosure", Link: "/print/motion-for-lien-foreclosure/" + this.currentAuto.ID},
                {Name: "AMV Notice", Link: "/print/amv-notice/" + this.currentAuto.ID},
                {Name: "MV-603", Link: "/print/mv-603/" + this.currentAuto.ID},
                {Name: "MV-603R", Link: "/print/mv-603r/" + this.currentAuto.ID},
                {Name: "Affidavit & Court Order", Link: "/print/affidavit-court-order/" + this.currentAuto.ID},
                {Name: "MV603 Letters", Link: "/print/mv-603-letters/" + this.currentAuto.ID},
                {Name: "T-22B", Link: "/print/t-22b/" + this.currentAuto.ID},
            ]
            // this.daysSinceDateIn = new Date(data.currentAuto.DateIn).getTime();
            // this.daysSinceDateIn = Math.abs(new Date(data.currentAuto.DateIn).getTime() - this.moment.today().unix());
            this.daysSinceDateIn = Math.ceil(Math.abs(new Date(this.currentAuto.DateIn).getTime() - this.moment.today().valueOf()) / (1000 * 60 * 60 * 24));
        } else {
            this.form.get('AutoNumber').clearValidators();
            this.form.get('AutoNumber').clearAsyncValidators();
            this.form.get('AutoNumber').updateValueAndValidity();

            this.form.get('StatusID').clearValidators();
            this.form.get('StatusID').updateValueAndValidity();

            this.form.get('StatusDescriptionID').clearValidators();
            this.form.get('StatusDescriptionID').updateValueAndValidity();

            this.form.get('DateIn').clearValidators();
            this.form.get('DateIn').updateValueAndValidity();

            this.form.get('ImpoundTypeID').clearValidators();
            this.form.get('ImpoundTypeID').updateValueAndValidity();

            this.form.get('Make').clearValidators();
            this.form.get('Make').updateValueAndValidity();

            this.form.get('AutoModel').clearValidators();
            this.form.get('AutoModel').updateValueAndValidity();
        }
            

    }

    createFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            Owners: new FormArray([]),
            Lienholders: new FormArray([]),
            Charges: new FormArray([]),
            AutoNumber: new FormControl(null, {
                validators: [Validators.required],
                asyncValidators: [UniqueNumberValidator.createValidator({number: this.getAutoNumber, service: this.as})]
            }),
            StatusID: new FormControl(null, Validators.required),
            StatusDescriptionID: new FormControl(),
            DateIn: new FormControl(null, Validators.required),
            ImpoundTypeID: new FormControl(null, Validators.required),
            Make: new FormControl(null, [Validators.required]),
            AutoModel: new FormControl(null, Validators.required),
            AutoYear: new FormControl(null, [Validators.minLength(4), Validators.maxLength(4)]),
            Color: new FormControl(),
            Vin: new FormControl(null, [Validators.minLength(11), Validators.maxLength(17)]),
            Tag: new FormControl(),
            State: new FormControl(),
            DecalYear: new FormControl(null, [Validators.minLength(4), Validators.maxLength(4)]),
            DecalNumber: new FormControl(),
            PriceIn: new FormControl(),
            DateOut: new FormControl(),
            PriceOut: new FormControl(),
            CaseNumber: new FormControl(),
            IsRequest: new FormControl(),
            IsNotification: new FormControl(),
            IsAuction: new FormControl(),
            IsT158: new FormControl(),
            IsInsurancePickup: new FormControl(),
            HasKeys: new FormControl(),
            RemovalStreet: new FormControl(),
            RemovalCity: new FormControl(),
            RemovalState: new FormControl(),
            RemovalZip: new FormControl(),
        })
    }

    createOwnerFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            LastName: new FormControl(),
            FirstName: new FormControl(),
            MiddleInitial: new FormControl(),
            Company: new FormControl(),
            Address1: new FormControl(),
            Address2: new FormControl(),
            City: new FormControl(),
            State: new FormControl(),
            Zip: new FormControl(),
            Phone1: new FormControl(),
            Phone2: new FormControl(),
            Fax: new FormControl(),
        }, { validators: hasNameValidator })
    }

    createChargeFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            Name: new FormControl(null,Validators.required),
            Charge: new FormControl(null, Validators.required)
        })
    }

    filterMakes = (value: string | Make): Observable<Make[]> => {
        log.Debug("filter makes called: ", value)
        value = value || 'a';
        if (typeof value != 'string' ) {
            return of([value])
        }
        return this.ms.SearchByValue(<string>value).pipe(take(1));
    }

    displayMakes = (make: Make): string => {
        return make ? make.Name : '';
    }

    initializeAutoModels() {
        const make = this.form.get('Make').value;
        this.filteredAutoModels$ = this.ams.SearchByValue(new AutoModel({Name: 'a', MakeID: make.ID})) 
    }

    // filterMakes(value: string | Make) {
    //     value = value || 'a';
    //     log.Debug("in filter value: ", value)
    //     if (typeof value != 'string' ) {
    //         // this.filterAutoModels('');
    //         this.filteredMakes$ = this.filteredMakes$.pipe(map(value => [...value]));
    //     }
    //     this.filteredMakes$ = this.ms.SearchByValue(<string>value).pipe(take(1));
    // }

    // private _getMakeNameByID(id: number): string {
    //     if(!this.makes) {
    //         return ''
    //     }
    //     const index = this.makes.findIndex( make => make.ID === id);
    //     if (index === -1) {
    //         return '';
    //     }
    //     return this.makes[index].Name;
    // }

    filterAutoModels = (value: string | AutoModel): Observable<AutoModel[]> => {
        value = value || ''

        // if auto model is selected
        if (typeof value != 'string') {
            return of([value])
        }

        // if make is selected and searching auto model value
        let make = this.form.controls['Make'].value;
        if (make) {
            return this.ams.SearchByValue(new AutoModel({Name: <string>value, MakeID: make.ID})).pipe(take(1));
        }

        // initial values
        return this.ams.SearchByValue(new AutoModel({Name: <string>value, MakeID: 1})).pipe(take(1));
    }

    displayAutoModels = (autoModel: AutoModel): string => {
        return autoModel ? autoModel.Name : '';
    }

    // private _getAutoModelNameByID(id: number): string {
    //     const index = this.autoModels.findIndex( autoModel => autoModel.ID === id);
    //     if (index === -1) {
    //         return ''
    //     }
    //     return this.autoModels[index].Name;
    // }

    addCharge(): void {
        let charges = this.form.get('Charges') as FormArray;
        charges.push(this.createChargeFormGroup());
    }
    removeCharge(i: number): void {
        let charges = this.form.get('Charges') as FormArray;
        charges.removeAt(i);
        if (!this.isEdit) {
            return
        }
        this.currentAuto.Charges.splice(i, 1)
    }

    addOwner(): void {
        let owners = this.form.get('Owners') as FormArray;
        owners.push(this.createOwnerFormGroup());
    }

    removeOwner(i: number): void {
        let owners = this.form.get('Owners') as FormArray;
        owners.removeAt(i);
        if (!this.isEdit && !this.isSearch) {
            return
        }
        this.currentAuto.Owners.splice(i, 1)
    }

    addLienholder(): void {
        let lienholders = this.form.get('Lienholders') as FormArray;
        lienholders.push(this.createOwnerFormGroup());
    }
    removeLienholder(i: number): void {
        let lienholders = this.form.get('Lienholders') as FormArray;
        lienholders.removeAt(i);
        if (!this.isEdit && !this.isSearch) {
            return
        }
        this.currentAuto.Lienholders.splice(i, 1)
    }

    private _calculatePrices(valueIn: any[]): number[] {
        let value = valueIn.map(val => ({...val}));
        const index = value.findIndex(charge => charge.Name == "Storage Per Day");
        let storagePerDayCharge = value[index];
        if(storagePerDayCharge == undefined) {
            return [0,0]
        }
        let storagePerDay = storagePerDayCharge.Charge
        value.splice(index, 1);

        let priceIn = value.reduce((acc, cur) => acc + cur.Charge, 0)
        log.Debug("priceIn: ", priceIn)

        let dateIn = this.form.get('DateIn').value;
        log.Debug("dateIn: ", dateIn)

        let dateOut = this.form.get('DateOut').value;
        if( dateOut == null ) {
            log.Debug("dateOut is null")
            dateOut = this.moment.today().format('YYYY-MM-DDTHH:mm:ssZ');
            //log.Debug("dateOut: ", dateOut.format('YYYY-MM-DDTHH:mm:ssZ'));
        }
        log.Debug("dateOut: ", dateOut)

        let daysStored = this.dateDiff(new Date(dateIn), new Date(dateOut))
        log.Debug("daysStored: ", daysStored)

        let priceOut = priceIn + (daysStored) * storagePerDay
        log.Debug("priceOut: ", priceOut)

        return [priceIn, priceOut]
    }

    dateDiff( beginDate: Date, endDate: Date): number {
        const oneDay = 1000*60*60*24

        let begin = beginDate.getTime()
        let end = endDate.getTime()

        let difference = end - begin;

        return Math.round(difference/oneDay)
    }

    patchPrices(value: number[]) {
        this.form.get('PriceIn').patchValue(value[0]);
        this.form.get('PriceOut').patchValue(value[1]);
    }

    openStatusesDialog(): void {
        const dialogRef = this.dialog.open( FeedsDialog, {
            data: {
                title: 'Status Feed',
                items: this.feeds,
                isEdit: false,
                autoID: this.currentAuto.ID
            }
        });

        dialogRef.afterClosed().subscribe( result => {
            log.Debug("dialog closed");
        });
    }

    openNotesDialog(): void {
        const dialogRef = this.dialog.open( NotesDialog, {
            width: '500px',
            maxWidth: 500,
            data: {
                title: 'Notes',
                items: this.notes,
                isEdit: true,
                autoID: this.currentAuto.ID
            }
        });

        dialogRef.afterClosed().subscribe( result => {
            log.Debug("dialog closed");
        });
    }

    openReportsDialog(): void {
        const dialogRef = this.dialog.open( MenuDialog, {
            data: {
                title: 'Reports',
                items: this._reports
            }
        });

        dialogRef.afterClosed().subscribe( result => {
            log.Debug("dialog closed");
        });
    }

    gotoInvoice() {
        if(Boolean(this.currentAuto.InvoiceID)) {
            this.router.navigate(['../../', this.currentAuto.ID, 'invoices', 'edit', this.currentAuto.InvoiceID], {relativeTo: this.route});
        } else {
            this.router.navigate(['../../', this.currentAuto.ID, 'invoices', 'new'], {relativeTo: this.route});
        }
    }

    gotoNew() {
        this.router.navigate(['../../', 'new'], {relativeTo: this.route});
    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }

    getOwnerText(owner: FormGroup): string {
        if( owner.get('LastName').value ) {
            if( owner.get('FirstName').value ) {
                return owner.get('FirstName').value + ' ' + owner.get('LastName').value;
            }
            return owner.get('LastName').value
        }

        return owner.get('Company').value
    }

    onSubmit(e): void {

        if( this.isSearch && this.form.dirty ) {
            this.form.setErrors(null)
            this.as.GetByNumber(this.form.get('AutoNumber').value).pipe(take(1)).subscribe(autoID => {
                if (autoID == -1) {
                    this.apps.Error('Cannot find an auto with that invoice number.');
                    return
                }
                this.router.navigate([autoID], {relativeTo: this.route});
            });
            
            return
        }

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched: ", this.form)
            return;
        }

        this.save();
    }

    save() {
        this.as.save(new Auto(this.form.value))
        .subscribe((savedAuto: Auto) => {
            this.apps.Confirmation('Auto saved.');
            this.form.markAsPristine();

            if( !this.isEdit ) {
                this.currentAuto = new Auto(savedAuto);
                this.router.navigate(['../', 'edit', savedAuto.ID], {relativeTo: this.route});
            }

            if(!Boolean(savedAuto.Owners)) {
                savedAuto.Owners = []
            }
            if(!Boolean(savedAuto.Lienholders)) {
                savedAuto.Lienholders = []
            }
            if(!Boolean(savedAuto.Charges)) {
                savedAuto.Charges = []
            }

            log.Debug("savedAuto: ", savedAuto)
            this.currentAuto = savedAuto
            this.form.patchValue(savedAuto);
        })
    }

    delete(e) {
        e.preventDefault();
        e.stopPropagation();
        if (confirm('This auto will be deleted. Are you sure?')) {
            this.as.delete(this.currentAuto).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Auto deleted.');
                    this.router.navigate(['dashboard']);
                }
            })
        }
    }

    deleteOwner(e, index: number) {
        e.preventDefault();
        e.stopPropagation();

        if(!this.isEdit) {
            log.Debug("is edit: ", this.isEdit)
            this.removeOwner(index);
            return
        }

        log.Debug("owners: ", this.currentAuto.Owners)
        const owner = this.currentAuto.Owners[index] as Owner;
        log.Debug("owner: ", owner)
        if(!Boolean(owner)) {
            log.Debug("owner: ", owner)
            this.removeOwner(index);
            return
        }

        if(confirm("This owner will be deleted. Are you sure?")) {
            this.as.deleteOwner(<Owner>owner).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Owner deleted.');
                    this.removeOwner(index);
                }
            })
        }
    }

    deleteLienholder(e, index: number) {
        e.preventDefault();
        e.stopPropagation();

        if(!this.isEdit) {
            log.Debug("is edit: ", this.isEdit)
            this.removeLienholder(index);
            return
        }

        const lienholder = this.currentAuto.Lienholders[index] as Owner;
        if(!Boolean(lienholder)) {
            log.Debug("lienholder: ", lienholder)
            this.removeLienholder(index);
            return
        }

        if(confirm("This lienholder will be deleted. Are you sure?")) {
            this.as.deleteOwner(<Owner>lienholder).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Lienholder deleted.');
                    this.removeLienholder(index);
                }
            })
        }
    }

    deleteCharge(e, index: number) {
        e.preventDefault();
        e.stopPropagation();

        if(!this.isEdit) {
            log.Debug("is edit: ", this.isEdit)
            this.removeCharge(index);
            return
        }

        const charge = this.currentAuto.Charges[index] as Charge;
        if(!Boolean(charge)) {
            log.Debug("charge: ", charge)
            this.removeCharge(index);
            return
        }

        if(confirm("This charge will be deleted. Are you sure?")) {
            this.as.deleteCharge(<Charge>charge).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Charge deleted.');
                    this.removeCharge(index);
                }
            })
        }
    }
}