import { Routes } from '@angular/router';

import { EditInvoiceComponent } from './edit-invoice.component';
import { SearchInvoicesComponent } from './search-invoices.component';

import { NextInvoiceNumberResolve } from './next-invoice-number.resolve';
import { InvoiceCustomerNumbersResolve } from '../customer';
import { DriverCommissionsResolve } from '../driver';
import { CurrentInvoiceResolve } from './current-invoice.resolve';

export const INVOICE_ROUTES: Routes = [
    {
        path: '',
        component: SearchInvoicesComponent,
        data: {
            title: 'Search Invoices'
        }
    },
    {
        path: 'new',
        children: [
            {
                path: '',
                component: EditInvoiceComponent,
                data: {
                    title: 'New Invoice',
                    edit: false,
                    search: false,
                },
                resolve: {
                    nextInvoiceNumber: NextInvoiceNumberResolve,
                    customerNumbers: InvoiceCustomerNumbersResolve,
                    driverCommissions: DriverCommissionsResolve
                }
            },
            {
                path: ':date',
                component: EditInvoiceComponent,
                data: {
                    title: 'New Invoice',
                    edit: false,
                    search: false,
                },
                resolve: {
                    nextInvoiceNumber: NextInvoiceNumberResolve,
                    customerNumbers: InvoiceCustomerNumbersResolve,
                    driverCommissions: DriverCommissionsResolve
                }
            },
        ]
    },
    {
        path: 'edit',
        children: [
            {
                path: '',
                component: EditInvoiceComponent,
                data: {
                    title: 'Edit Invoice',
                    edit: false,
                    search: true,
                }
            },
            {
                path: ':invoice_id',
                component: EditInvoiceComponent,
                data: {
                    title: 'Edit Invoice',
                    edit: true,
                    search: false,
                },
                resolve: {
                    currentInvoice: CurrentInvoiceResolve,
                    customerNumbers: InvoiceCustomerNumbersResolve,
                    driverCommissions: DriverCommissionsResolve
                }
            }
        ]
    },
]

export const INVOICE_AUTO_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'new',
        pathMatch: 'full'
    },
    {
        path: 'new',
        component: EditInvoiceComponent,
        data: {
            title: 'New Invoice',
            edit: false,
            search: false
        },
        resolve: {
            nextInvoiceNumber: NextInvoiceNumberResolve,
            customerNumbers: InvoiceCustomerNumbersResolve,
            driverCommissions: DriverCommissionsResolve
        }
    },
    {
        path: 'edit/:invoice_id',
        component: EditInvoiceComponent,
        data: {
            title: 'Edit Invoice',
            edit: true,
            search: false,
        },
        resolve: {
            currentInvoice: CurrentInvoiceResolve,
            customerNumbers: InvoiceCustomerNumbersResolve,
            driverCommissions: DriverCommissionsResolve
        }
    }
]