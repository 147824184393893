import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MakeService } from './make.service';
import { Make } from './make';

@Injectable()
export class CurrentMakeResolve implements Resolve<Make> {
    constructor (
        private router: Router,
        private _makeService: MakeService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<Make> {
        const id = +route.paramMap.get('id');
        return this._makeService.GetByID(id);
    }
}