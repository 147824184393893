import { Model } from '../common/model';

export class DriverInvoice extends Model {

    Date: Date;
    InvoiceNumber: string;
    Amount: number;
    CommissionPercentage: number;
    Commission: number;
    Active: boolean;
    Created: Date;
    Modified: Date;

    constructor(properties?: Object) {
        super(properties)
    }
}