import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from '../common/dev/log';
import { Make } from './make';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { MakeService } from './make.service';
import { AppService } from '../app.service';
import { debounceTime, map, concatMap, toArray, tap, switchMap, take, reduce } from 'rxjs/operators';
import { Observable, of, from, Subject, BehaviorSubject } from 'rxjs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabLabel } from '@angular/material/tabs';

@Component({
    selector: "manage-makes",
    templateUrl: "./manage-makes.component.html"
})

export class ManageMakesComponent implements OnInit {

    title: string;
    makes: Make[];
    isLoading = false;

    filter: FormGroup;
    filterForm: FormGroup;
    filteredMakes: Observable<Make[]>;

    make;
    autoMakes;
    displayedColumns = [
        'Make',
        'Actions'
    ]

    @ViewChild(MatPaginator) paginator: MatPaginator;
    
    constructor (
        private route: ActivatedRoute,
        private router: Router,
        private ms: MakeService,
        private apps: AppService,
    ){
        this.filterForm = this.createFilterFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            makes: Make[]
        }) => {
            this.title = data.title;
            this.makes = data.makes;
            this.autoMakes = new MatTableDataSource(<any> this.makes)
            this.autoMakes.paginator = this.paginator
            log.Debug("data: ", data);
        })
        
        this.route.queryParams.subscribe(v => {
            this.filterForm.patchValue({
                'filter': v['filter'],
            }, {emitEvent: false});
            this.ms.SearchByValue(v['filter']).subscribe(r => {
              this.makes = Object.keys(r).map(i => r[i])
                this.autoMakes = new MatTableDataSource(<any> this.makes.reverse())
            })
        });
        
        this.filterForm.valueChanges.pipe(
            debounceTime(500),
          ).subscribe(v => {
           this.router.navigate([], {queryParams: v});
        });
    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }

    addMake(e) {
        console.log("eeeeeee");
        e.preventDefault();
        this.router.navigate(['new'], {relativeTo: this.route});
   }
    removeMake(i: number): void {
        let makes = this.makes;
        makes.splice(i, 1);
    }
    
    createFilterFormGroup() {
        return new FormGroup({
            filter: new FormControl()
        })
    }

    onSubmit(e): void {
        // if( !this.form.dirty || this.form.invalid ) {
        //     log.Debug("form not touched: ", this.form)
        //     return;
        // }
        // this.save();
    }

    // save() {
    //     this.ms.save(this.makes)
    //     .subscribe((savedMakes: Make[]) => {
    //         this.apps.Confirmation('Makes saved.');

    //         log.Debug("savedMakes: ", savedMakes)
    //         this.makes.push(...savedMakes);
    //     })
    // }

    deleteMake(id: number) {

        let make = new Make({ ID: id });

        if(confirm("This make will be deleted. Are you sure?")) {
            this.ms.delete(<Make>make).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Make deleted.');
                    this.router.navigate(['makes']);
                }
            })
        }
    }
}