<button class="print-button" mat-raised-button (click)="print()" color="primary" style="margin-bottom: 10px;"><mat-icon>print</mat-icon>Print</button>
<mat-card class="small-form no-print" style="margin-bottom: 30px;">
    <form [formGroup]="form">
        <div class="row gutters">
            <div class="col">
                <mat-form-field>
                    <input matInput [matDatepicker]="StartDate" placeholder="Start Date" formControlName="StartDate" [max]="form.value.EndDate">
                    <mat-datepicker-toggle matSuffix [for]="StartDate"></mat-datepicker-toggle>
                    <mat-datepicker #StartDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field>
                    <input matInput [matDatepicker]="EndDate" placeholder="End Date" formControlName="EndDate" [min]="form.value.StartDate">
                    <mat-datepicker-toggle matSuffix [for]="EndDate"></mat-datepicker-toggle>
                    <mat-datepicker #EndDate></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row gutters">
            <div class="col">
                <mat-form-field>
                    <input matInput placeholder="Driver Number" formControlName="DriverNumber" type="text">
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-card>
<pre class="debug no-print">{{ form.value | json }}</pre>
<div id="print-section" style="position: relative;">
    <div class="report-container print-out-container">
        <div class="report report--driver-commission-due-report print-out" *ngFor="let report of reports | async">
            <p class="title bold">Driver Commission Due Report</p>
            <p class="bold" *ngIf="form.value.StartDate || form.value.EndDate">{{ !form.value.StartDate ? '...' : form.value.StartDate | date: 'M/d/yyyy': 'UTC' }} to {{ !form.value.EndDate ? '...' : form.value.EndDate | date: 'M/d/yyyy':'UTC' }}</p>
            <p class="bold">Printed: {{ currentDate | date: 'M/d/yyyy':'UTC' }}</p>
            <div class="row gutters border-bottom">
                <div class="col--size-2 text-center">
                    <p class="bold">Driver</p>
                </div>
                <div class="col text-center">
                    <p class="bold">Date</p>
                </div>
                <div class="col text-center">
                    <p class="bold">Invoice</p>
                </div>
                <div class="col text-center">
                    <p class="bold">Amount</p>
                </div>
                <div class="col text-center">
                    <p class="bold">%</p>
                </div>
                <div class="col text-center">
                    <p class="bold">Commission</p>
                </div>
                <div class="col text-center">
                    <p class="bold">Due</p>
                </div>
            </div>
            <div class="row gutters" *ngFor="let invoice of report.DriverInvoices; index as i;">
                <div class="col--size-2 text-left">
                    <p>{{ 0 == i ? getName(report.DriverNumber, report.LastName, report.FirstName) : "" }}</p>
                </div>
                <div class="col text-center">
                    <p>{{ invoice.Invoiced | date: 'M/d/yyyy':'UTC' }}</p>
                </div>
                <div class="col">
                    <p>{{ invoice.InvoiceNumber }}</p>
                </div>
                <div class="col text-right">
                    <p>{{ invoice.Amount | currency }}</p>
                </div>
                <div class="col">
                    <p>{{ invoice.CommissionPercentage }}</p>
                </div>
                <div class="col text-right">
                    <p>{{ invoice.Commission | currency }}</p>
                </div>
                <div class="col text-right">
                    <p>{{ invoice.Commission | currency }}</p>
                </div>
            </div>
            <div class="row gutters margin-bottom">
                <div class="col"></div>
                <div class="col"></div>
                <div class="col--size-2 text-right">
                    <p class="bold">Driver Totals:</p>
                </div>
                <div class="col text-right border-top">
                    <p class="bold">{{ getTotalAmount(report.DriverInvoices) | currency }}</p>
                </div>
                <div class="col"></div>
                <div class="col text-right border-top">
                    <p class="bold">{{ getTotalCommission(report.DriverInvoices) | currency }}</p>
                </div>
                <div class="col text-right border-top">
                    <p class="bold">{{ getTotalCommission(report.DriverInvoices) | currency }}</p>
                </div>
            </div>
            <div class="pagebreak"></div>
        </div>
    </div>
</div>