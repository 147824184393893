import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auto, AutoService } from '../auto';
import { Make } from '../make';
import { AutoModel } from '../auto-model';
import { log } from '../common/dev/log';
import { Charge } from '../charge';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

@Component({
    selector: "notification-letter",
    templateUrl: "./notification-letter.component.html"
})

export class NotificationLetterComponent implements OnInit {

    autos: Auto[];
    letterForm: FormGroup;
    selectedAutos: Auto[];

    fields = {
        CompanyName: '',
        CompanyPOBox: '',
        CompanyCity: '',
        CompanyState: '',
        CompanyZip: '',
        CompanyPhone: '',
        CompanyAddress: '',
        CompanyAgent: '',
        CurrentDate: new Date()
    };

    constructor (
        private route: ActivatedRoute,
        private as: AutoService,
        public formBuilder: FormBuilder,
    ){}

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            autos: Auto[],
            settings: {}
        }) => {
            log.Debug("data: ", data);

            this.autos = data.autos;

            this.selectedAutos = this.autos;

            log.Debug("autos: ", this.autos);

            this.fields.CompanyName = data.settings['Company Name'] || '';
            this.fields.CompanyPOBox = data.settings['Company PO Box'] || '';
            this.fields.CompanyCity = data.settings['Company City'] || '';
            this.fields.CompanyState = data.settings['Company State'] || '';
            this.fields.CompanyZip = data.settings['Company Zip'] || '';
            this.fields.CompanyPhone = data.settings['Company Phone'] || '';
            this.fields.CompanyAddress = data.settings['Company Address'] || '';
            this.fields.CompanyAgent = data.settings['Form Agent'] || '';

        });

        this.letterForm = this.formBuilder.group({
            'letters': new FormControl()
        });

        this.letterForm.valueChanges.subscribe(v => {
            this.getNotificationLetterByID(v.letters);
        });
    }

    private getNotificationLetterByID(id: number) {
        const index = this.autos.findIndex( autos => autos.ID === id);
        if (index === -1) {
            this.selectedAutos = this.autos;
        } else {
            this.selectedAutos = [this.autos[index]];
        }
    }

    getCityStateZip(city: string, state: string, zip: string): string {

        let cityValid = !this.empty(city);
        let stateValid = !this.empty(state);
        let zipValid = !this.empty(zip);

        if( cityValid && stateValid && zipValid ) {
            return city + ', ' + state + ' ' + zip;
        } else if( cityValid && stateValid && !zipValid ) {
            return city + ', ' + state;
        } else if( !cityValid && stateValid && zipValid ) {
            return state + ' ' + zip;
        } else if(!cityValid && stateValid && !zipValid ) {
            return state;
        } else if( cityValid && !stateValid && zipValid ) {
            return city + ' ' + zip;
        } else if( cityValid && !stateValid &&  !zipValid ) {
            return city;
        } else if( !cityValid && !stateValid && zipValid ) {
            return zip;
        }

        return '';

    }

    getAdministrativeFee( charges: Charge[] ): string {
        const index = charges.findIndex(charge => "Admin Fees" == charge.Name);
        if( index == -1) {
            return '';
        }

        return charges[index].Charge.toString();
    }

    getStoragePerDayFee( charges: Charge[] ): string {
        const index = charges.findIndex(charge => "Storage Per Day" == charge.Name);
        if( index == -1 ) {
            return '';
        }

        return charges[index].Charge.toString();
    }

    getTowingFee( charges: Charge[] ): string {
        const index = charges.findIndex(charge => "Towing Fees" == charge.Name);
        if( index == -1 ) {
            return '';
        }

        return charges[index].Charge.toString();
    }

    print(): void {
        this.markPrinted();
        window.print();
    }

    markPrinted(): void {
        log.Debug("here")
        this.as.Mark('is_notification', this.selectedAutos.map(a => a.ID)).subscribe((resp: {Success: boolean}) => {
            if (resp.Success) {
                log.Debug("success")
            }
            log.Debug("resp: ", resp)
        })
    }

    empty(str: string): boolean {
        if( '' != str && null != str && undefined != str ) {
            return false;
        }
        return true;
    }

}