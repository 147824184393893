import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auto, AutoService } from '../auto';
import { log } from '../common/dev/log';

@Component({
    selector: "t-158",
    templateUrl: "./t-158.component.html",
    styleUrls: ['./single-print.component.css']
})

export class T158Component implements OnInit {

    autos: Auto[];

    fields = {
        CompanyName: '',
        CompanyAgent: '',
        CompanyAddress: '',
        CompanyCity: '',
        CompanyState: '',
        CompanyZip: '',
        Mark: '',
        SurrenderDate: new Date(),
        YearModelMake: '',
        VIN: '',
        TagTagYear: '',
        DecalNumber: '',
        DecalYear: '',
        CurrentDate: new Date(),
    }

    constructor (
        private route: ActivatedRoute,
        private as: AutoService
    ){}
    ngOnInit(): void {
        this.route.data.subscribe((data: {
            isBlank: boolean,
            autos: Auto[],
            settings: {}
        }) => {

            log.Debug("data: ", data)

            this.autos = data.autos;

            if(!data.isBlank) {

                this.fields.CompanyName = data.settings['Company Name'] || '';
                this.fields.CompanyAgent = data.settings['Form Agent'] || '';
                this.fields.CompanyAddress = data.settings['Company Address'] || '';
                this.fields.CompanyCity = data.settings['Company City'] || '';
                this.fields.CompanyState = data.settings['Company State'] || '';
                this.fields.CompanyZip = data.settings['Company Zip'] || '';
                this.fields.Mark = 'X';
                this.fields.SurrenderDate = data.settings['T-158 Date'];

            }
        });
    }

    getYearModelMake(year: string, model: string, make: string): string {
        if( !this.empty(year) ) {
            return year + ' ' + model + ' ' + make;
        }
        return model + ' ' + make;
    }

    getTagTagState(tag: string, state: string): string {

        let tagValid = !this.empty(tag);
        let stateValid = !this.empty(state);

        if( tagValid && stateValid ) {
            return tag + ' ' + state;
        } else if( !tagValid && stateValid ) {
            return state;
        } else if ( tagValid && !stateValid ) {
            return tag;
        }

        return ''
    }

    getFromCityStateZip(city: string, state: string, zip: string): string {

        let cityValid = !this.empty(city);
        let stateValid = !this.empty(state);
        let zipValid = !this.empty(zip);

        if( cityValid && stateValid && zipValid ) {
            return city + ', ' + state + ' ' + zip;
        } else if( cityValid && stateValid && !zipValid ) {
            return city + ', ' + state;
        } else if( !cityValid && stateValid && zipValid ) {
            return state + ' ' + zip;
        } else if(!cityValid && stateValid && !zipValid ) {
            return state;
        } else if( cityValid && !stateValid && zipValid ) {
            return city + ' ' + zip;
        } else if( cityValid && !stateValid &&  !zipValid ) {
            return city;
        } else if( !cityValid && !stateValid && zipValid ) {
            return zip;
        }

        return '';

    }

    empty(str: string): boolean {
        if( '' != str && null != str && undefined != str ) {
            return false;
        }
        return true;
    }

    print(): void {
        this.markPrinted()
        window.print();
    }

    markPrinted(): void {
        log.Debug("here")
        this.as.Mark('is_t158', this.autos.map(a => a.ID)).subscribe((resp: {Success: boolean}) => {
            if (resp.Success) {
                log.Debug("success")
            }
            log.Debug("resp: ", resp)
        })
    }
}
