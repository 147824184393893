import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DeleteResponse } from '../common/delete-response';
import { Auto } from './auto';
import { Owner } from '../owner';
import { Charge } from '../charge';
import { log } from '../common/dev/log';
import { AutoSearchData } from './auto-search-data';

@Injectable({
    providedIn: 'root'
})

export class AutoService extends HttpService implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    Get( auto_id: number ): Observable<Auto> {
        return this.http.get<Auto>('/api/autos/get/' + auto_id).pipe(map(val => new Auto(val)));
    }

    GetAll(): Observable<Auto[]> {
        return this.http.get<Auto[]>('/api/autos/all').pipe(map(this.toAuto));
    }

    GetAllNumbers(): Observable<Auto[]> {
        return this.http.get<Auto[]>('/api/autos/all_numbers').pipe(map(this.toAuto));
    }

    GetAllForBulkClose(): Observable<Auto[]> {
        return this.http.get<Auto[]>('/api/autos/all-for-bulk-close').pipe(map(this.toAuto));
    }

    GetAllForBulkT158(): Observable<Auto[]> {
        return this.http.get<Auto[]>('/api/autos/all-for-bulk-t158').pipe(map(this.toAuto));
    }

    GetRegistrationRequestReportAutos(): Observable<Auto[]> {
        return this.http.get<Auto[]>('/api/autos/registration-request-report').pipe(map(val => val));
    }

    GetNotificationLetterAutos(): Observable<Auto[]> {
        return this.http.get<Auto[]>('/api/autos/notification-letters').pipe(map(val => val));
    }

    GetNextAutoNumber(): Observable<string> {
        return this.http.get<string>('/api/autos/next').pipe(map(val => val));
    }

    toAuto(autos) {
        const temp = autos.map( auto => {
            return new Auto(auto);
        });
        return temp;
    }

    save(auto: Auto): Observable<Auto> {
        log.Debug("auto in service: ", auto);
        if (!!auto.ID) {
            return this.http.put<Auto>('/api/autos/update/' + auto.ID, auto);
        }
        return this.http.post<Auto>('/api/autos/create', auto);
    }

    saveall(autos: Auto[]): Observable<Auto[]> {
        return this.http.put<Auto[]>('/api/autos/update_all', autos);
    }
    markAsAuction(autos: Auto[]): Observable<Auto[]> {
        return this.http.put<Auto[]>('/api/autos/mark-as-auction', autos);
    }
    unmarkAsAuction(auto: Auto): Observable<boolean> {
        return this.http.put<boolean>('/api/autos/unmark-as-auction', auto);
    }
    markAsClosed(autos: Auto[]): Observable<Auto[]> {
        return this.http.put<Auto[]>('/api/autos/mark-as-closed', autos);
    }
    markAsT158(autos: Auto[]): Observable<Auto[]> {
        return this.http.put<Auto[]>('/api/autos/mark-as-t158', autos);
    }

    delete(auto: Auto): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/autos/delete/' + auto.ID)
    }

    deleteOwner(owner: Owner): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/owners/delete/' + owner.ID);
    }

    deleteCharge(charge: Charge): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/charges/delete/' + charge.ID);
    }

    isNumberUnique(autoNumber: string): Observable<boolean> {
        return this.http.get<boolean>('/api/autos/is_unique/' + autoNumber).pipe(map(val => val));
    }

    Search(auto: Auto): Observable<Auto[]> {
        return this.http.post<Auto[]>('/api/autos/search', auto).pipe(map(val => val));
    }

    SearchByDateRange(auto: Auto): Observable<Auto[]> {
        return this.http.post<Auto[]>('/api/autos/search-by-date-range', auto).pipe(map(val => val));
    }

    Mark(bool: string, IDs: number[]): Observable<DeleteResponse> {
        return this.http.post<DeleteResponse>('/api/autos/set-bool/' + bool, IDs);
    }

    GetByNumber(autoNumber: string): Observable<number> {
        return this.http.get<number>('/api/autos/get_by_number/' + autoNumber).pipe(map(val => val));
    }

    SearchAutosPDF( autoSearchData: AutoSearchData ) {
        return this.http.post('/api/autos/search_pdf', autoSearchData, {responseType: 'blob'});
    }
}