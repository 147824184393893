<button mat-raised-button (click)="print()" color="primary" style="margin-bottom: 10px; float: left; z-index:100;"><mat-icon>print</mat-icon>Print</button>
<div *ngIf="currentAuto.Owners.length <= 0; else ownersExist">
    <p style="text-align: center; font-size: 20px;margin-bottom: 10px;">There are no owners on this Auto.</p>
</div>
<ng-template #ownersExist>
<div id="print-section" style="position: relative;">
    <div class="print-out-container">
        <div class="print-out" *ngFor="let owner of currentAuto.Owners">
            <div class="row">
                <div class="col">
                </div>
                <div class="col">
                    <p style="margin-bottom: 5px;" class="bold">{{ fields.CurrentDate | date:'longDate':'UTC' }}</p>
                    <p style="margin-bottom: 5px;">Tag Number: <span class="bold">{{ fields.TagTagState }}</span></p>
                    <p style="margin-bottom: 5px;">Vehicle Details: <span class="bold">{{ fields.YearMakeModel }}</span></p>
                    <p style="margin-bottom: 5px;">VIN: <span class="bold">{{ fields.VIN }}</span></p>
                    <p style="margin-bottom: 5px;">Title Number: <span class="bold">{{ fields.TitleNumber }}</span></p>
                    <p style="margin-bottom: 5px;">Owner Details:</p>
                    <ul>
                        <li class="bold">{{ owner.Company ? owner.Company : owner.FirstName + ' ' + owner.LastName }}</li>
                        <li class="bold">{{ owner.Address1 }}{{ owner.Address2 ? ' ' + owner.Address2 : '' }}</li>
                        <li class="bold">{{ this.getCityStateZip(owner.City, owner.State, owner.Zip) }}</li>
                    </ul>
                </div>
            </div>
            <p class="bold">
                {{ owner.Company ? owner.Company : owner.FirstName + ' ' + owner.LastName }}<br>
                {{ owner.Address1 }}{{ owner.Address2 ? ' ' + owner.Address2 : '' }}<br>
                {{ this.getCityStateZip(owner.City, owner.State, owner.Zip) }}
            </p>
            <p>*In accordance with the official code of Georgia 40-11-5, the above described vehicle has been declared abandoned and subject to a lien for redemption of fees due. A complete list of all fees is attached to this notice.</p>
            <p>Total Due: <span class="bold">{{ fields.TotalDue | currency }}</span></p>
            <p style="margin-bottom: 5px;">List of fees:</p>
            <ul>
                <li class="bold" *ngFor="let charge of currentAuto.Charges">{{ charge.Name }}: <span>{{ charge.Charge | currency }}</span></li>
            </ul>

            <p>Name of the (towing) company recovering vehicle is listed below:</p>
            <ul>
                 <li><p style="margin-bottom: 5px;">Name of Towing Company:</p>
                     <ul style="margin-bottom: 5px;">
                         <li class="bold">{{ fields.CompanyName }}</li>
                         <li class="bold">{{ fields.CompanyAddress }}</li>
                         <li class="bold">{{ fields.CompanyCityStateZip }}</li>
                     </ul>
                 </li>
                 <li>Location of Recovery: <span class="bold">{{ fields.RemovalStreetCity }}</span></li>
                 <li>Date of Recovery: <span class="bold">{{ fields.RecoveryDate | date:'longDate':'UTC' }}</span></li>
                 <li>Date Declared Abandoned: <span class="bold">{{ fields.AbandonedDate | date:'longDate':'UTC' }}</span></li>
             </ul>
             <p>To Owner of Vehicle:</p>
             <p>You have the following rights and responsibilities to prevent the person/company recovering this vehicle and declaring the vehicle abandoned from seeking a court order to sell this vehicle for the fees due.</p>
             <p>___You may disclaim ownership of this vehicle by signing where indicated and returning this notice to the (towing) company listed on the next page.*</p>
             <p>___You may have the right to pay the fees due for the charges of towing and storing the vehicle to the (towing) company listed on the next page within 30 days of the above date.</p>
             <p>___You have the right to request a hearing to determine the validity of the person recovering such vehicle</p>
             <ul>
                 <li>a. To request a hearing you must notify both the person/company recovering the vehicle at address listed on the next page and the magistrate court of CLAYTON COUNTY in writing of your request within 10 days.</li>
                 <li>b. You must appear in this court on the date set by the courts to present your facts as to why the person/company recovering the vehicle does not have a valid claim.</li>
             </ul>
        </div>
        <div class="print-out" *ngFor="let lienholder of currentAuto.Lienholders">
            <div class="row">
                <div class="col">
                </div>
                <div class="col">
                    <p style="margin-bottom: 5px;" class="bold">{{ fields.CurrentDate | date:'longDate':'UTC' }}</p>
                    <p style="margin-bottom: 5px;">Tag Number: <span class="bold">{{ fields.TagTagState }}</span></p>
                    <p style="margin-bottom: 5px;">Vehicle Details: <span class="bold">{{ fields.YearMakeModel }}</span></p>
                    <p style="margin-bottom: 5px;">VIN: <span class="bold">{{ fields.VIN }}</span></p>
                    <p style="margin-bottom: 5px;">Title Number: <span class="bold">{{ fields.TitleNumber }}</span></p>
                    <p style="margin-bottom: 5px;">Owner Details:</p>
                    <ul>
                        <li class="bold">{{ lienholder.Company ? lienholder.Company : lienholder.FirstName + ' ' + lienholder.LastName }}</li>
                        <li class="bold">{{ lienholder.Address1 }}{{ lienholder.Address2 ? ' ' + lienholder.Address2 : '' }}</li>
                        <li class="bold">{{ this.getCityStateZip(lienholder.City, lienholder.State, lienholder.Zip) }}</li>
                    </ul>
                </div>
            </div>
            <p class="bold">
                {{ lienholder.Company ? lienholder.Company : lienholder.FirstName + ' ' + lienholder.LastName }}<br>
                {{ lienholder.Address1 }}{{ lienholder.Address2 ? ' ' + lienholder.Address2 : '' }}<br>
                {{ this.getCityStateZip(lienholder.City, lienholder.State, lienholder.Zip) }}
            </p>
            <p>*In accordance with the official code of Georgia 40-11-5, the above described vehicle has been declared abandoned and subject to a lien for redemption of fees due. A complete list of all fees is attached to this notice.</p>
            <p>Total Due: <span class="bold">{{ fields.TotalDue | currency }}</span></p>
            <p style="margin-bottom: 5px;">List of fees:</p>
            <ul>
                <li class="bold" *ngFor="let charge of currentAuto.Charges">{{ charge.Name }}: <span>{{ charge.Charge | currency }}</span></li>
            </ul>

            <p>Name of the (towing) company recovering vehicle is listed below:</p>
            <ul>
                 <li><p style="margin-bottom: 5px;">Name of Towing Company:</p>
                     <ul style="margin-bottom: 5px;">
                         <li class="bold">{{ fields.CompanyName }}</li>
                         <li class="bold">{{ fields.CompanyAddress }}</li>
                         <li class="bold">{{ fields.CompanyCityStateZip }}</li>
                     </ul>
                 </li>
                 <li>Location of Recovery: <span class="bold">{{ fields.RemovalStreetCity }}</span></li>
                 <li>Date of Recovery: <span class="bold">{{ fields.RecoveryDate | date:'longDate':'UTC' }}</span></li>
                 <li>Date Declared Abandoned: <span class="bold">{{ fields.AbandonedDate | date:'longDate':'UTC' }}</span></li>
             </ul>
             <p>To Owner of Vehicle:</p>
             <p>You have the following rights and responsibilities to prevent the person/company recovering this vehicle and declaring the vehicle abandoned from seeking a court order to sell this vehicle for the fees due.</p>
             <p>___You may disclaim ownership of this vehicle by signing where indicated and returning this notice to the (towing) company listed on the next page.*</p>
             <p>___You may have the right to pay the fees due for the charges of towing and storing the vehicle to the (towing) company listed on the next page within 30 days of the above date.</p>
             <p>___You have the right to request a hearing to determine the validity of the person recovering such vehicle</p>
             <ul>
                 <li>a. To request a hearing you must notify both the person/company recovering the vehicle at address listed on the next page and the magistrate court of CLAYTON COUNTY in writing of your request within 10 days.</li>
                 <li>b. You must appear in this court on the date set by the courts to present your facts as to why the person/company recovering the vehicle does not have a valid claim.</li>
             </ul>
        </div>
    </div>
</div>
<ng-template>
