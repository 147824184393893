import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AutoSearch } from './auto-search';
import { AutoSearchData } from './auto-search-data';
import { Auto } from './auto';

@Injectable()

export class AutosSearchService {

    constructor(
        private http: HttpClient,
    ){}

    SearchAutos( autoSearchData: AutoSearchData, pageIndex = 0, pageSize = 10): Observable<Auto[]> {

        const search = new AutoSearch({ AutoSearchData: autoSearchData, PageIndex: pageIndex, PageSize: pageSize })

        return this.http.post<Auto[]>('/api/autos/search', search).pipe(val => val)
    }

    GetResultsLength( autoSearchData: AutoSearchData ): Observable<number> {
        return this.http.post<number>('/api/autos/search-results-length', autoSearchData).pipe(val => val)
    }

}