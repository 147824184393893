import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from '../common/dev/log';
import { MONTHS } from '../common/month';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Statement } from '../invoice/statement';
import { InvoiceService } from '../invoice/invoice.service';
import { FormGroup, FormControl } from '@angular/forms';
import { map, debounceTime, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoadingService } from '../common/loading.service';

@Component({
    selector: "statements",
    templateUrl: "./statements.component.html"
})

export class StatementsComponent implements OnInit {

    statements: Observable<Statement[]>;

    fields = {
        CompanyName: '',
        CompanyPOBox: '',
        CityStateZip: '',
        CurrentDate: new Date(),
    }

    form: FormGroup

    isLoading = true;

    constructor (
        private route: ActivatedRoute,
        private is: InvoiceService,
        public ls: LoadingService,
        private cdr: ChangeDetectorRef
    ){
        this.form = this.createFormGroup()
        
    }
    ngOnInit(): void {
        this.ls.isLoading$.subscribe( val => {
            this.isLoading = val
            //this.cdr.detectChanges()
        })
        this.route.data.subscribe((data: {
            statements: Statement[],
            settings: {},
        }) => {
            log.Debug("data: ", data)

            this.fields.CompanyName = data.settings['Company Name'] || '';
            this.fields.CompanyPOBox = data.settings['Company PO Box'] || '';
            this.fields.CityStateZip = this.getCityStateZip(data.settings['Company City'],data.settings['Company State'], data.settings['Company Zip'] );

            this.statements = this.form.get('StatementFrequency').valueChanges.pipe(
                debounceTime(500),
                switchMap(value => this.is.SearchStatements(this.form.value))
            )

        });
    }

    createFormGroup(): FormGroup {
        return new FormGroup({
            StatementFrequency: new FormControl(),
        })
    }

    getName( company: string, lastName: string, firstName: string ) {
        if(!this.empty(company)) {
            return company
        }

        if(!this.empty(lastName)) {
            if(!this.empty(firstName)) {
                return firstName + ' ' + lastName
            }
            return lastName
        }

        return ""
    }

    getAddress( address1, address2 ): string {

        if( !this.empty(address1) ) {
            if( !this.empty(address2) ) {
                return address1 + address2;
            }
            return address1
        }
        return '';
        
    }

    getCityStateZip(city: string, state: string, zip: string): string {

        let cityValid = !this.empty(city);
        let stateValid = !this.empty(state);
        let zipValid = !this.empty(zip);

        if( cityValid && stateValid && zipValid ) {
            return city + ', ' + state + ' ' + zip;
        } else if( cityValid && stateValid && !zipValid ) {
            return city + ', ' + state;
        } else if( !cityValid && stateValid && zipValid ) {
            return state + ' ' + zip;
        } else if(!cityValid && stateValid && !zipValid ) {
            return state;
        } else if( cityValid && !stateValid && zipValid ) {
            return city + ' ' + zip;
        } else if( cityValid && !stateValid &&  !zipValid ) {
            return city;
        } else if( !cityValid && !stateValid && zipValid ) {
            return zip;
        }

        return '';

    }

    print(): void {
        window.print();
    }

    // formatDate(date: Date): string {
    //     let tempDate = this.moment.parse(date, 'YYYY-MM-DDTHH:mm:ssZ');
    //     return this.moment.getMonth(tempDate) + '/' + this.moment.getDate(tempDate) + '/' + this.moment.getYear(tempDate);
    // }

    empty(str: string): boolean {
        if( '' != str && null != str && undefined != str ) {
            return false;
        }
        return true;
    }
}