<div class="list-dialog">
    <h2 mat-dialog-title>{{ data['title'] }}</h2>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
        <div mat-dialog-content>
            <div class="feeds-form-array" formArrayName="Feeds" *ngFor= "let feed of form.get('Feeds')['controls']; let i = index">
                <div class="form-group-container" [formGroupName]="i">
                    <div class="row gutters">
                        <div class="col--size-3" style="padding: 0 0 0 10px;">
                            <mat-form-field>
                                <input matInput type="text" placholder="Feed" formControlName="Description" readonly>
                            </mat-form-field>
                        </div>
                        <div class="col--size-3" style="padding: 0 10px 0 0;">
                            <mat-form-field>
                                <input matInput type="text" placholder="Created" formControlName="Created" readonly value="{{ feed.controls.Created.value | date:'short' }}">
                            </mat-form-field>
                        </div>
                        <div class="col" style="margin: auto;">
                            <button type="button" mat-raised-button color="warn" (click)="deleteFeed($event, i)" style="display: block; margin: 0 auto;"><mat-icon>delete</mat-icon></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div mat-dialog-actions>
            <button type="button" mat-raised-button (click)="close()" color="primary"><mat-icon>close</mat-icon>Close</button>
        </div>
    </form>
</div>
<pre class="debug">{{ form.value | json }}</pre>