import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from '../common/dev/log';
import { ImpoundType } from './impound-type';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ImpoundTypeService } from './impound-type.service';
import { AppService } from '../app.service'

@Component({
    selector: "manage-impound-types",
    templateUrl: "./manage-impound-types.component.html"
})

export class ManageImpoundTypesComponent implements OnInit {

    title: string;
    impoundTypes: ImpoundType[];

    form: FormGroup;

    constructor (
        private route: ActivatedRoute,
        private its: ImpoundTypeService,
        private apps: AppService,

    ){
        this.form = this.createFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            impoundTypes: ImpoundType[]
        }) => {
            log.Debug("data: ", data)
            this.title = data.title;
            this.impoundTypes = data.impoundTypes
            for (let cT in this.impoundTypes) {
                this.addImpoundType();
            }
            this.form.get('ImpoundTypes').patchValue(this.impoundTypes);
        })
    }

    createFormGroup() {
        return new FormGroup({
            ImpoundTypes: new FormArray([])
        })
    }

    createImpoundTypeFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            Name: new FormControl(null, Validators.required)
        })
    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }

    addImpoundType(): void {
        let impoundTypes = this.form.get('ImpoundTypes') as FormArray;
        impoundTypes.push(this.createImpoundTypeFormGroup());
    }
    removeImpoundType(i: number): void {
        let impoundTypes = this.form.get('ImpoundTypes') as FormArray;
        impoundTypes.removeAt(i);
        this.impoundTypes.splice(i, 1)
    }

    onSubmit(e): void {

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched: ", this.form)
            return;
        }

        this.save();
    }

    save() {
        this.its.save(this.form.get('ImpoundTypes').value)
        .subscribe((savedImpoundTypes: ImpoundType[]) => {
            this.apps.Confirmation('Impound Types saved.');
            this.form.markAsPristine();

            log.Debug("savedImpoundTypes: ", savedImpoundTypes)
            this.form.get('ImpoundTypes').patchValue(savedImpoundTypes);
            this.impoundTypes = savedImpoundTypes
        })
    }

    deleteImpoundType(e, index: number) {
        e.preventDefault();
        e.stopPropagation();


        const impoundType = this.impoundTypes[index] as ImpoundType;
        if(!Boolean(impoundType)) {
            log.Debug("impoundType: ", impoundType)
            this.removeImpoundType(index);
            return
        }

        if(confirm("This charge type will be deleted. Are you sure?")) {
            this.its.delete(<ImpoundType>impoundType).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Impound type deleted.');
                    this.removeImpoundType(index);
                }
            })
        }
    }
}