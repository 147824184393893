<mat-card class="search search-transactions">
    <h1 class="title">{{ title }}</h1>
    <div class="row gutters">
        <div class="col">
            <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
                <mat-card class="searh-panel">
                    <mat-card-content>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                        <input type="text" placeholder="Transaction Number" matInput [matAutocomplete]="auto" formControlName="TransactionNumber">
                                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                            <mat-option *ngFor="let transaction of filteredTransactions | async" [value]="transaction.TransactionNumber">{{ transaction.TransactionNumber }}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                    <input matInput [matDatepicker]="BeginDate" placeholder="Beginning Date" formControlName="BeginDate" [max]="form.value.EndDate">
                                    <mat-datepicker-toggle matSuffix [for]="BeginDate"></mat-datepicker-toggle>
                                    <mat-datepicker #BeginDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <input matInput [matDatepicker]="EndDate" placeholder="End Date" formControlName="EndDate" [min]="form.value.BeginDate">
                                    <mat-datepicker-toggle matSuffix [for]="EndDate"></mat-datepicker-toggle>
                                    <mat-datepicker #EndDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Auto Number" formControlName="AutoNumber">
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Customer Number" formControlName="CustomerNumber">
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-actions end>
                        <button color="primary" type="submit" mat-raised-button class="button--float-right"><mat-icon>search</mat-icon>Search</button>
                        <button color="primary" type="button" mat-raised-button class="button--float-right" (click)="reset($event)"><mat-icon>autorenew</mat-icon>Clear</button>
                    </mat-card-actions>
                </mat-card>
            </form>
        </div>
        <div class="col--size-2" style="position: relative;">
            <div class="spinner-container" *ngIf="dataSource.loading$ | async" style="position: absolute; top: 200px; left: 50%; transform: translate( -50%, 0 );">
                <mat-spinner></mat-spinner>
            </div>

            <table mat-table [dataSource]="dataSource">

                <!-- transaction_number -->
                <ng-container matColumnDef="transaction_number">
                    <th mat-header-cell *matHeaderCellDef>Transaction Number</th>
                    <td mat-cell *matCellDef="let transaction"><a [routerLink]="['edit', transaction.ID]">{{ transaction.TransactionNumber }}</a></td>
                </ng-container>

                <!-- date -->
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Date</th>
                    <td mat-cell *matCellDef="let transaction">{{ transaction.Transacted | date:'shortDate':'UTC' }}</td>
                </ng-container>

                <!-- auto_number -->
                <ng-container matColumnDef="auto_number">
                    <th mat-header-cell *matHeaderCellDef>Auto Number</th>
                    <td mat-cell *matCellDef="let transaction">{{ transaction.AutoNumber }}</td>
                </ng-container>

                <!-- customer_number -->
                <ng-container matColumnDef="customer_number">
                    <th mat-header-cell *matHeaderCellDef>Customer Number</th>
                    <td mat-cell *matCellDef="let transaction">{{ transaction.CustomerNumber }}</td>
                </ng-container>

                <!-- sale_amount -->
                <ng-container matColumnDef="sale_amount">
                    <th mat-header-cell *matHeaderCellDef>SaleAmount</th>
                    <td mat-cell *matCellDef="let transaction">{{ transaction.SaleAmount | currency }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <mat-paginator [length]="dataSource.length$ | async" [pageSize]="10" [pageSizeOptions]="[10, 50, 100]"></mat-paginator>

        </div>
    </div>
</mat-card>
<pre class="debug">{{ form.value | json }}</pre>