import { Model } from '../common/model';
import { Driver } from './driver';

export class DriverSearch extends Model {

    Driver: Driver;
    Filter: string;
    SortOrder: string;
    PageIndex: number;
    PageSize: number;

    constructor(properties?: Object) {
        super(properties);
    }

}