import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DriverSearch } from './driver-search';
import { Driver } from './driver';

@Injectable()

export class DriversSearchService {

    constructor(
        private http: HttpClient,
    ){}

    SearchDrivers( driver: Driver, pageIndex = 0, pageSize = 10): Observable<Driver[]> {

        const search = new DriverSearch({ Driver: driver, PageIndex: pageIndex, PageSize: pageSize })

        return this.http.post<Driver[]>('/api/drivers/search', search).pipe(val => val)
    }

    GetResultsLength( driver: Driver ): Observable<number> {
        return this.http.post<number>('/api/drivers/search-results-length', driver).pipe(val => val)
    }

}