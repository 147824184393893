import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomerService } from './customer.service';
import { Customer } from './customer';

@Injectable()
export class NextCustomerNumberResolve implements Resolve<string> {
    constructor (
        private router: Router,
        private cs: CustomerService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<string> {
        return this.cs.GetNextCustomerNumber();
    }
}