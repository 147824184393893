<div class="dashboard">
    <div class="row gutters">
        <ng-container *ngFor="let item of DashboardItems">
        <div *ngIf="item.HasPermission" class="col">
            <mat-card>
                <p class="column-label">{{ item.Name }}</p>
                <button *ngFor="let button of item.Buttons" color="primary" mat-raised-button [routerLink]="button.Link"><mat-icon>{{ button.Icon }}</mat-icon>{{ button.Name }}</button>
            </mat-card>
        </div>
        </ng-container>
    </div>
</div>