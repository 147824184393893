import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { InvoiceService } from './invoice.service';
import { Invoice } from './invoice';

@Injectable()
export class CurrentInvoiceResolve implements Resolve<Invoice> {
    constructor (
        private router: Router,
        private is: InvoiceService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<Invoice> {
        const invoice_id = +route.paramMap.get('invoice_id');
        return this.is.Get(invoice_id);
    }
}