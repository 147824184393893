import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Invoice } from './invoice';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { InvoicesSearchService } from './invoices-search.service'

export class InvoicesDataSource implements DataSource<Invoice> {

    private invoicesSubject = new BehaviorSubject<Invoice[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private lengthSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public length$ = this.lengthSubject.asObservable();

    constructor(
        private iss: InvoicesSearchService
    ){}

    connect( collectionViewer: CollectionViewer ): Observable<Invoice[]> {
        return this.invoicesSubject.asObservable();
    }

    disconnect( collectionViewer: CollectionViewer ): void {
        this.invoicesSubject.complete();
        this.loadingSubject.complete();
    }

    loadInvoices( invoice: Invoice, pageIndex = 0, pageSize = 10 ) {

        this.loadingSubject.next(true);

        this.iss.SearchInvoices( invoice, pageIndex, pageSize).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe( invoices => this.invoicesSubject.next(invoices) )
    }

    updateLength( invoice: Invoice ) {

        this.iss.GetResultsLength( invoice ).pipe(
            catchError( () => of(0))
        ).subscribe( l => this.lengthSubject.next(l) )
        
    }

}