import { Model } from '../common/model';

export class Driver extends Model {

        DriverNumber: string;
        LastName: string;
        FirstName: string;
        MIDdleInitial: string;
        Phone: string;
        Commission: number;
        Active: boolean;
        Created: Date;
        Modified: Date;

    constructor(properties?: Object) {
        super(properties)
    }
}