import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DeleteResponse } from '../common/delete-response';
import { Note } from './note';

@Injectable({
    providedIn: 'root'
})

export class NoteService extends HttpService implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    GetByAutoID( autoID ): Observable<Note[]> {
        return this.http.get<Note[]>('/api/notes/by_auto_id/' + autoID).pipe(map(this.toNote));
    }

    GetAll(): Observable<Note[]> {
        return this.http.get<Note[]>('/api/notes/all').pipe(map(this.toNote));
    }

    toNote(note) {
        const temp = note.map( note => {
            return new Note(note);
        });
        return temp;
    }

    save(note: Note): Observable<Note> {
        if (!!note.ID) {
            return this.http.put<Note>('/api/notes/update/' + note.ID, note);
        }
        return this.http.post<Note>('/api/notes/create', note);
    }

    saveAll(notes: Note[]): Observable<Note[]> {
        return this.http.put<Note[]>('/api/notes/update_all', notes);
    }

    delete(note: Note): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/notes/delete/' + note.ID)
    }

}