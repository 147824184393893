import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Transaction } from './transaction';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { TransactionsSearchService } from './transactions-search.service'

export class TransactionsDataSource implements DataSource<Transaction> {

    private transactionsSubject = new BehaviorSubject<Transaction[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private lengthSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public length$ = this.lengthSubject.asObservable();

    constructor(
        private tss: TransactionsSearchService
    ){}

    connect( collectionViewer: CollectionViewer ): Observable<Transaction[]> {
        return this.transactionsSubject.asObservable();
    }

    disconnect( collectionViewer: CollectionViewer ): void {
        this.transactionsSubject.complete();
        this.loadingSubject.complete();
    }

    loadTransactions( transaction: Transaction, pageIndex = 0, pageSize = 10 ) {

        this.loadingSubject.next(true);

        this.tss.SearchTransactions( transaction, pageIndex, pageSize).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe( transactions => this.transactionsSubject.next(transactions) )
    }

    updateLength( transaction: Transaction ) {

        this.tss.GetResultsLength( transaction ).pipe(
            catchError( () => of(0))
        ).subscribe( l => this.lengthSubject.next(l) )
        
    }

}