<mat-card class="list users-list">
    <h1 class="title">{{ title }}</h1>
    <mat-card-content>
        <div class="row gutters list list-title">
            <div class="col">
                <p>Username</p>
            </div>
            <div class="col">
                <p>Role</p>
            </div>
            <div class="col">
            </div>
        </div>
        <div class="row gutters list" *ngFor="let user of users">
            <div class="col">
                <p>{{ user.Username }}</p>
            </div>
            <div class="col">
                <p>{{ user.Role }}</p>
            </div>
            <div class="col">
                <a mat-raised-button color="primary" [routerLink]="['edit', user.ID]"><mat-icon>edit</mat-icon></a>
                <button type="button" mat-raised-button color="warn" (click)="deleteUser($event, user)"><mat-icon>delete</mat-icon></button>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions end>
        <button mat-raised-button color="primary" type="button" (click)="addUser($event)"><mat-icon>add</mat-icon>Add</button>
    </mat-card-actions>
</mat-card>