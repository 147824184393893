import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DeleteResponse } from '../common/delete-response';
import { Customer } from './customer';
import { log } from '../common/dev/log';

@Injectable({
    providedIn: 'root'
})

export class CustomerService extends HttpService implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    Get( customer_id: number ): Observable<Customer> {
        return this.http.get<Customer>('/api/customers/get/' + customer_id).pipe(map(val => new Customer(val)));
    }

    GetAll(): Observable<Customer[]> {
        return this.http.get<Customer[]>('/api/customers/all').pipe(map(this.toCustomer));
    }

    GetAllInvoice(): Observable<Customer[]> {
        return this.http.get<Customer[]>('/api/customers/all_invoice').pipe(map(this.toCustomer));
    }

    GetAllInvoiceDue(): Observable<Customer[]> {
        return this.http.get<Customer[]>('/api/customers/all_invoice_due').pipe(map(this.toCustomer));
    }

    GetAllNumbers(): Observable<Customer[]> {
        return this.http.get<Customer[]>('/api/customers/all_numbers').pipe(map(this.toCustomer));
    }

    GetAllInvoiceNumbers(): Observable<Customer[]> {
        return this.http.get<Customer[]>('/api/customers/all_invoice_numbers').pipe(map(this.toCustomer));
    }

    GetAllAuctionNames(): Observable<Customer[]> {
        return this.http.get<Customer[]>('/api/customers/all_auction_names').pipe(map(this.toCustomer));
    }

    toCustomer(customer) {
        const temp = customer.map( customer => {
            return new Customer(customer);
        });
        return temp;
    }

    save(customer: Customer): Observable<Customer> {
        if (!!customer.ID) {
            return this.http.put<Customer>('/api/customers/update/' + customer.ID, customer);
        }
        return this.http.post<Customer>('/api/customers/create', customer);
    }

    delete(customer: Customer): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/customers/delete/' + customer.ID)
    }

    GetNextCustomerNumber(): Observable<string> {
        return this.http.get<string>('/api/customers/next').pipe(map(val => val));
    }
    isNumberUnique(customerNumber: string): Observable<boolean> {
        return this.http.get<boolean>('/api/customers/is_unique/' + customerNumber).pipe(map(val => val));
    }

    GetByNumber(customerNumber: string): Observable<number> {
        return this.http.get<number>('/api/customers/get_by_number/' + customerNumber).pipe(map(val => val));
    }

    Search(customer: Customer): Observable<Customer[]> {
        return this.http.post<Customer[]>('/api/customers/search', customer).pipe(map(val => val));
    }

}