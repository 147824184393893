import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FeedService } from './feed.service';
import { Feed } from './feed';

@Injectable()
export class FeedsResolve implements Resolve<Feed[]> {
    constructor (
        private router: Router,
        private fs: FeedService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<Feed[]> {
        const auto_id = +route.paramMap.get('auto_id');
        return this.fs.GetByAutoID( auto_id );
    }
}