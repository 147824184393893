import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auto, AutoService } from '../auto';
import { Make } from '../make';
import { AutoModel } from '../auto-model';
import { log } from '../common/dev/log';

@Component({
    selector: "registration-request",
    templateUrl: "./registration-request.component.html"
})

export class RegistrationRequestComponent implements OnInit {

    autos: Auto[];

    constructor (
        private route: ActivatedRoute,
        private as: AutoService
    ){}
    ngOnInit(): void {
        this.route.data.subscribe((data: {
            autos: Auto[]
        }) => {
            log.Debug("data: ", data)
            this.autos = data.autos;
        });
    }

    print(): void {
        log.Debug("here")
        this.markPrinted()
        window.print();
    }

    markPrinted(): void {
        log.Debug("here")
        this.as.Mark('is_request', this.autos.map(a => a.ID)).subscribe((resp: {Success: boolean}) => {
            if (resp.Success) {
                log.Debug("success")
            }
            log.Debug("resp: ", resp)
        })
    }
}