<button class="print-button" mat-raised-button (click)="print()" color="primary" style="margin-bottom: 10px;"><mat-icon>print</mat-icon>Print</button>
<div id="print-section" style="position: relative;">
    <ng-container *ngIf="autos.length > 0; else empty">
        <table class="table">
            <tr>
                <th>Ref #</th>
                <th>Make</th>
                <th>Model</th>
                <th>Year</th>
                <th>VIN #</th>
                <th>Color</th>
                <th>Tag #</th>
                <th>State</th>
                <th>Date In</th>
            </tr>
            <tr *ngFor="let auto of autos">
                <th>{{ auto.AutoNumber }}</th>
                <th>{{ auto.Make.Name }}</th>
                <th>{{ auto.AutoModel.Name }}</th>
                <th>{{ auto.AutoYear }}</th>
                <th>{{ auto.Vin }}</th>
                <th>{{ auto.Color }}</th>
                <th>{{ auto.Tag }}</th>
                <th>{{ auto.State }}</th>
                <th>{{ auto.DateIn | date:'longDate':'UTC' }}</th>
            </tr>
        </table>
    </ng-container>
    <ng-template #empty><p style="text-align: center; margin-top: 60px;">No autos are marked for registration request.</p></ng-template>
</div>