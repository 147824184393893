import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'menu-dialog',
    templateUrl: 'menu-dialog.component.html'
})

export class MenuDialog {

    constructor (
        public dialogRef: MatDialogRef<MenuDialog>,
        @Inject(MAT_DIALOG_DATA) public data: Object
    ) {}

    ngOnInit(): void {
        console.log("data :", this.data['items'][0])
        console.log("data: ", typeof this.data['items'][0])
    }
    
    close(): void {
        this.dialogRef.close();
    }
}