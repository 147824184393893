import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DeleteResponse } from '../common/delete-response';
import { Status } from './status';

@Injectable({
    providedIn: 'root'
})

export class StatusService extends HttpService implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    GetAll(): Observable<Status[]> {
        return this.http.get<Status[]>('/api/statuses/all').pipe(map(this.toStatus));
    }

    toStatus(status) {
        const temp = status.map( status => {
            return new Status(status);
        });
        return temp;
    }

    save(officers: Status[]): Observable<Status[]> {
        return this.http.put<Status[]>('/api/statuses/update_all', officers);
    }

    delete(status: Status): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/statuses/delete/' + status.ID)
    }

}