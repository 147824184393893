<button class="print-button" mat-raised-button (click)="print()" color="primary" style="margin-bottom: 10px;"><mat-icon>print</mat-icon>Print</button>
<style type="text/css">
    @media print {
    thead { display: table-header-group; }
    tfoot { display: table-footer-group; }
    }
    @media screen {
    thead { display: block; }
    tfoot { display: block; }
    }
    
    </style>


<div id="print-section" style="position: relative;">
    <div class="report-container print-out-container">
        <div class="report report--customer-listing print-out">
            <table style="width: 100%;">
                <thead style="display: table-header-group;">
                    <tr>
                        <td>
                            <p class="title bold">Customer Listing</p>
                            <p class="bold">{{ currentDate | date: 'M/d/yyyy': 'UTC' }}</p>
                            <p></p>
                            <div class="row gutters border-bottom">
                                <div class="col">
                                    <p class="bold">Account Number:</p>
                                </div>
                                <div class="col">
                                    <p class="bold">Company:</p>
                                </div>
                                <div class="col">
                                    <p class="bold">Address:</p>
                                </div>
                                <div class="col">
                                    <p class="bold">Phone:</p>
                                </div>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                <tr><td>
                    <div class="row gutters" *ngFor="let customer of customerListing">
                        <div class="col">
                            <p>{{ customer.CustomerNumber }}</p>
                        </div>
                        <div class="col">
                            <p>{{ getName(customer.Company, customer.LastName, customer.FirstName) }}</p>
                        </div>
                        <div class="col">
                            <p>{{ customer.Address1 }}</p>
                        </div>
                        <div class="col">
                            <p>{{ customer.Phone }}</p>
                        </div>
                    </div>
                </td></tr>
                </tbody>
                <tfoot style="display: table-footer-group;"> - </tfoot>
            </table>
        </div>
    </div>
</div>