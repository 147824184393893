<button mat-raised-button (click)="print()" color="primary" style="margin-bottom: 10px; float: left; z-index:100;"><mat-icon>print</mat-icon>Print</button>
<div id="print-section" style="position: relative;">
    <div class="print-out-container">
        <div class="print-out" style="font-size:20px;">
            <div class="row">
                <div class="col">
                    <p>{{ fields.CompanyName }}<br>
                        {{ fields.CompanyPOBox }}<br>
                        {{ fields.CompanyCity }}, {{ fields.CompanyState }} {{ fields.CompanyZip }}<br>
                        {{ fields.CompanyPhone }}
                    </p>
                </div>
                <div class="col">
                    <p style="text-align: right;">Unit #: <span style="font-weight: bold;">{{ fields.AutoNumber }}</span></p>
                </div>
            </div>
            <p style="font-weight: bold;">ATTN: Clayton County Police Department</p>
            <p>This is a notice that {{ fields.CompanyName }} has impounded this vehicle and requests owner and lienholder information.</p>
            <div class="table" style="font-size:18px;">
                <div class="row" style="border-top: 1px solid black; border-right: 1px solid black; border-left: 1px solid black;">
                    <div class="col" style="border-right: 1px solid black; padding: 5px 7px;">
                        <p style="margin-bottom: 0;line-height: 150%;">Person Removing or Storing Vehicle<br>
                            <span style="font-weight: bold;">{{ fields.CompanyName }}</span>
                        </p>
                    </div>
                    <div class="col" style="padding: 5px 7px;">
                        <p style="margin-bottom: 0;line-height: 150%;">Vehicle Identification Number<br>
                            <span style="font-weight: bold;">{{ fields.AutoVin }}</span>
                        </p>
                    </div>
                </div>
                <div class="row" style="border-top: 1px solid black; border-right: 1px solid black; border-left: 1px solid black;">
                    <div class="col" style="border-right: 1px solid black; padding: 5px 7px;">
                        <p style="margin-bottom: 0;line-height: 150%;">Address<br>
                            <span style="font-weight: bold;">{{ fields.CompanyAddress }}</span>
                        </p>
                    </div>
                    <div class="col" style="padding: 5px 7px;">
                        <div class="row gutters">
                            <div class="col">
                                <p style="margin-bottom: 0;line-height: 150%;">Year<br>
                                <span style="font-weight: bold;">{{ fields.AutoYear }}</span></p>
                            </div>
                            <div class="col">
                                <p style="margin-bottom: 0;line-height: 150%;">Make<br>
                                <span style="font-weight: bold;">{{ fields.AutoMake }}</span></p>
                            </div>
                            <div class="col">
                                <p style="margin-bottom: 0;line-height: 150%;">Model<br>
                                <span style="font-weight: bold;">{{ fields.AutoModel }}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="border-top: 1px solid black; border-right: 1px solid black; border-left: 1px solid black;">
                    <div class="col" style="border-right: 1px solid black; padding: 5px 7px;">
                        <div class="row gutters">
                            <div class="col">
                                <p style="margin-bottom: 0;line-height: 150%;">City<br>
                                <span style="font-weight: bold;">{{ fields.CompanyCity }}</span></p>
                            </div>
                            <div class="col">
                                <p style="margin-bottom: 0;line-height: 150%;">State<br>
                                <span style="font-weight: bold;">{{ fields.CompanyState }}</span></p>
                            </div>
                            <div class="col">
                                <p style="margin-bottom: 0;line-height: 150%;">Zip<br>
                                <span style="font-weight: bold;">{{ fields.CompanyZip }}</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="col" style="padding: 5px 7px;">
                        <div class="row gutters">
                            <div class="col">
                                <p style="margin-bottom: 0;line-height: 150%;">Tag No.<br>
                                <span style="font-weight: bold;">{{ fields.AutoTag }}</span></p>
                            </div>
                            <div class="col">
                                <p style="margin-bottom: 0;line-height: 150%;">State of Issue<br>
                                <span style="font-weight: bold;">{{ fields.AutoState }}</span></p>
                            </div>
                            <div class="col">
                                <p style="margin-bottom: 0;line-height: 150%;">Date Removed<br>
                                <span style="font-weight: bold;">{{ fields.RemovedDate | date:'longDate':'UTC' }}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="border-top: 1px solid black; border-right: 1px solid black; border-left: 1px solid black;">
                    <div class="col" style="border-right: 1px solid black; padding: 5px 7px;">
                        <p style="margin-bottom: 0;line-height: 150%;">
                            Present Location of Vehicle (Street Address)<br>
                            <span style="font-weight: bold;">{{ fields.CompanyAddress }}</span>
                        </p>
                    </div>
                    <div class="col" style="padding: 5px 7px;">
                        <p style="margin-bottom: 0;line-height: 150%;">
                            Date Vehicle Becomes Abandoned<br>
                            <span style="font-weight: bold;">{{ fields.AbandonedDate | date:'longDate':'UTC' }}</span>
                        </p>
                    </div>
                </div>
                <div class="row" style="border-top: 1px solid black; border-right: 1px solid black; border-left: 1px solid black;">
                    <div class="col" style="border-right: 1px solid black; padding: 5px 7px;">
                        <div class="row gutters">
                            <div class="col">
                                <p style="margin-bottom: 0;line-height: 150%;">City<br>
                                <span style="font-weight: bold;">{{ fields.CompanyCity }}</span></p>
                            </div>
                            <div class="col">
                                <p style="margin-bottom: 0;line-height: 150%;">State<br>
                                <span style="font-weight: bold;">{{ fields.CompanyState }}</span></p>
                            </div>
                            <div class="col">
                                <p style="margin-bottom: 0;line-height: 150%;">Zip<br>
                                <span style="font-weight: bold;">{{ fields.CompanyZip }}</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="col" style="padding: 5px 7px;">
                        <p style="margin-bottom: 0;line-height: 150%;">
                            Location Vehicle Removed From<br>
                            <span style="font-weight: bold;">{{ fields.AutoRemovalAddress }}</span>
                        </p>
                    </div>
                </div>
                <div class="row" style="border: 1px solid black;">
                    <div class="col" style="border-right: 1px solid black; padding: 5px 7px;">
                        <p style="margin-bottom: 0;line-height: 150%;">
                            Telephone Number (including area code)<br>
                            <span style="font-weight: bold;">{{ fields.CompanyPhone }}</span>
                        </p>
                    </div>
                    <div class="col" style="padding: 5px 7px;">
                        <div class="row gutters">
                            <div class="col">
                                <p style="margin-bottom: 0;line-height: 150%;">City<br>
                                <span style="font-weight: bold;">{{ fields.AutoRemovalCity }}</span></p>
                            </div>
                            <div class="col">
                                <p style="margin-bottom: 0;line-height: 150%;">State<br>
                                <span style="font-weight: bold;">{{ fields.AutoRemovalState }}</span></p>
                            </div>
                            <div class="col">
                                <p style="margin-bottom: 0;line-height: 150%;">Zip<br>
                                <span style="font-weight: bold;">{{ fields.AutoRemovalZip }}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>