import { Model } from '../common/model';

export class Feed extends Model {

    AutoID: number;
    Description: string;
    Created: Date;
    Modified: Date;

    constructor(properties?: Object) {
        super(properties);
    }

}
