import { environment } from '../../../../src/environments/environment';

class LogCache {
    value: any[] = [];
}

// let logCache = [];

export class log {
    static hideInDev = false;
    static show = !log.hideInDev && !environment.production;
    static Debug = log.show ? console.log : (...val: any[]): void => {};
    static Error = log.show ? console.error : (...val: any[]): void => {};
    static Assert = log.show ? console.assert : (condition?: boolean, message?: string, ...data: any[]): void => {};

    // static Panic = (...val: any[]): void => {
    //     if (log.show) {
    //         throw val;
    //     }
    // }
    static InTemplate = (val: any): any => {
        let cache = new LogCache();
        const stripedVal = JSON.parse(JSON.stringify(val, log.replacerFn(cache)));
        cache = null;
        return stripedVal;
    }

    static replacerFn(cache: LogCache) {

        return (key, value) => {
            if (value === 'object') {
                if (cache.value.indexOf(value) === -1) {
                    cache.value.push(value);
                    return value;
                }
                return null;
            }
            return value;
        };
    }
}
