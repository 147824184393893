import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../user';
import { UserRole } from '../user';
import { UserService } from '../user';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { log } from '../common/dev/log';
import { AppService } from '../app.service';
import { UniqueUsernameValidator, passwordsEqualValidator } from '../common/validators';
import { ErrorStateMatcher } from '@angular/material/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: "sign-up",
    templateUrl: "sign-up.component.html"
})

export class SignUpComponent implements OnInit {

    getUsername = () => this.currentUser ? this.currentUser.Username : '';

    roles: UserRole[];
    currentUser: User;

    form: FormGroup;

    matcher = new MyErrorStateMatcher()

    constructor(
        private route: ActivatedRoute,
        private us: UserService,
        private apps: AppService,
        private router: Router
    ){
        this.currentUser = new User();
        this.form = this.createFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            roles: UserRole[],
        }) => {

            log.Debug("data: ", data)

            this.roles = data.roles;

            const index = this.roles.findIndex(r => r.Name == "Pending")
            if( index != -1 ) {
                this.form.get('RoleID').patchValue(this.roles[index].ID)
            }
            
        });
    }

    createFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            Username: new FormControl(null, {
                validators: [Validators.required],
                asyncValidators: [UniqueUsernameValidator.createValidator({username: this.getUsername, service: this.us})]
            }),
            RoleID: new FormControl(null, Validators.required),
            Password: new FormControl(null, [Validators.required, Validators.minLength(12)]),
            ConfirmPassword: new FormControl()
        }, { validators: [passwordsEqualValidator] })
    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }

    onSubmit(e): void {

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched or invalid: ", this.form)
            log.Debug("hasErrors: ", this.form.hasError('passwordsNoMatch'))
            return;
        }

        this.save();
    }

    save() {
        this.us.SignUp(this.form.value)
        .subscribe((savedUser: User) => {
            this.apps.Confirmation('You have been successfully signed up!');
            this.form.markAsPristine();
            this.router.navigate(['/login'], {relativeTo: this.route});
        })
    }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && control.parent.dirty && control.touched);
        const invalidParent = !!(control && control.parent && control.parent.hasError('passwordsNoMatch') && control.parent.dirty && control.touched);
        log.Debug("control parent: ", control.parent)

        return (invalidCtrl || invalidParent)   
    }
}