import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map, tap } from 'rxjs/operators';
import { log } from '../common/dev/log';
import { Customer } from './customer';
import { CustomerService } from './customer.service';
import { STATES } from '../common/state';
import { CustomersDataSource } from './customers-data-source';
import { MatPaginator } from '@angular/material/paginator';
import { CustomersSearchService } from './customers-search.service';

@Component({
    selector: "search-customers",
    templateUrl: "./search-customers.component.html"
})

export class SearchCustomersComponent implements OnInit {

    filteredCustomers;

    title: string;

    form: FormGroup;
    public states = STATES;

    displayedColumns: string[] = ['customer_number', 'company', 'last_name', 'first_name', 'address', 'city', 'state', 'zip', 'phone', 'type']
    dataSource: CustomersDataSource;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    constructor (
        private route: ActivatedRoute,
        private cs: CustomerService,
        private css: CustomersSearchService,
    ){
        this.form = this.createFormGroup()
    }
    ngOnInit(): void {

        this.dataSource = new CustomersDataSource(this.css);
        this.dataSource.loadCustomers(new Customer());
        this.dataSource.updateLength(new Customer());

        this.route.data.subscribe((data: {
            title: string,
        }) => {
            log.Debug("data: ", data)

            this.title = data.title;

        });
    }

    ngAfterViewInit() {
        this.paginator.page.pipe(
            tap(() => this.loadNextPage())
        ).subscribe();
    }

    loadNextPage() {
        this.dataSource.loadCustomers(this.beforeSubmit(), this.paginator.pageIndex, this.paginator.pageSize )
    }

    createFormGroup() {
        return new FormGroup({
            CustomerNumber: new FormControl(),
            Category: new FormControl(),
            Company: new FormControl(),
            LastName: new FormControl(),
            FirstName: new FormControl(),
            Address1: new FormControl(),
            City: new FormControl(),
            State: new FormControl(),
            Zip: new FormControl(),
            Phone: new FormControl(),
            County: new FormControl()
        });
    }


    reset(e) {
        e.preventDefault()
        e.stopPropagation()

        this.form.reset();
    }

    beforeSubmit(): Customer {
        let customer = new Customer(this.form.value)
        return Object.keys(customer).reduce((acc, cur) => {
            const value = customer[cur]
            if (value != null) {
                acc[cur] = value
            }
            return acc
        }, new Customer());
    }

    onSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        this.paginator.pageIndex = 0
        this.loadNextPage()
        this.dataSource.updateLength(this.beforeSubmit())
    }
}