import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Transaction } from '../transaction';
import { MONTHS } from '../common/month';
import { log } from '../common/dev/log';
import { PDFService } from '../pdf';

@Component({
    selector: "test-pdf",
    templateUrl: "./test-pdf.component.html",
    styleUrls: ['./single-print.component.css']
})

export class TestPDFComponent implements OnInit {

    constructor (
        private route: ActivatedRoute,
        private pdfs: PDFService
    ){}
    ngOnInit(): void {
        this.route.data.subscribe((data: {
        }) => {

            log.Debug("data: ", data)

        });
    }

    test() {
        log.Debug("test")
        const resp = this.pdfs.Test()
        log.Debug("resp in test: ", resp)
    }

}