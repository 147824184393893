import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ChargeTypeService } from './charge-type.service';
import { ChargeType } from './charge-type';

@Injectable()
export class ChargeTypesResolve implements Resolve<ChargeType[]> {
    constructor (
        private router: Router,
        private cts: ChargeTypeService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<ChargeType[]> {
        return this.cts.GetAll();
    }
}