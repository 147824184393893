<mat-card class="edit-customer small-form">
    <h1 class="title">{{ title }}</h1>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
        <mat-card-content>
            <mat-error *ngIf="form.errors?.hasNoName && (form.touched || form.dirty) && !isSearch">Customer must have a last name or company name.</mat-error>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input type="text" placeholder="Customer Number" matInput [matAutocomplete]="auto" formControlName="CustomerNumber">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer.CustomerNumber">{{ customer.CustomerNumber }}</mat-option>
                            <mat-option *ngIf="!isEdit && !isSearch" [value]="nextCustomerNumber" selected="true">{{ nextCustomerNumber }}</mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="form.get('CustomerNumber')?.invalid && !isSearch">{{ getErrorMessage(form.get('CustomerNumber')) }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input type="text" matInput placeholder="Company" formControlName="Company">
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col--size-5">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Last Name" formControlName="LastName">
                    </mat-form-field>
                </div>
                <div class="col--size-5">
                    <mat-form-field>
                        <input matInput type="text" placeholder="First Name" formControlName="FirstName">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput type="text" placeholder="MI" formControlName="MiddleInitial">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Address 1" formControlName="Address1">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Address 2" formControlName="Address2">
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col--size-3">
                    <mat-form-field>
                        <input matInput type="text" placeholder="City" formControlName="City">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <mat-select placeholder="State" formControlName="State">
                            <mat-option *ngFor="let state of states" [value]="state.Code">{{ state.Code }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col--size-3">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Zip" formControlName="Zip">
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input matInput type="tel" placeholder="Phone" formControlName="Phone">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput type="text" placeholder="County" formControlName="County">
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input matInput type="number" placeholder="Credit Limit" formControlName="CreditLimit">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Terms" formControlName="Terms">
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <mat-select placeholder="Statement Frequency" formControlName="StatementFrequency">
                            <mat-option value="Monthly">Monthly</mat-option>
                            <mat-option value="Weekly">Weekly</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <mat-select placeholder="Type" formControlName="Category">
                            <mat-option value="Auction">Auction</mat-option>
                            <mat-option value="Invoice">Invoice</mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('Category')?.invalid && !isSearch">{{ getErrorMessage(form.get('Category')) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions end>
            <button mat-raised-button color="primary" *ngIf="!isSearch" type="submit"><mat-icon>save</mat-icon>Save</button>
            <button mat-raised-button color="primary" *ngIf="isSearch" type="submit"><mat-icon>search</mat-icon>Search</button>
            <!-- <button mat-raised-button color="accent" click><mat-icon>clear</mat-icon>Clear Form</button> -->
            <button type="button" mat-raised-button color="primary" *ngIf="isEdit" (click)="gotoNew()"><mat-icon>clear</mat-icon>Clear</button>
            <button type="button" mat-raised-button color="warn" *ngIf="isEdit" (click)="delete($event)"><mat-icon>delete</mat-icon>Delete</button>
        </mat-card-actions>
    </form>
</mat-card>
<pre class="debug">{{ form.value | json }}</pre>