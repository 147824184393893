import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { log } from '../common/dev/log';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DeleteResponse } from '../common/delete-response';
import { AutoModel } from './auto-model';

@Injectable({
    providedIn: 'root'
})

export class AutoModelService extends HttpService implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    GetAll(): Observable<AutoModel[]> {
        return this.http.get<AutoModel[]>('/api/auto_models/all').pipe(map(this.toAutoModel));
    }

    GetByID(auto_model_id: number): Observable<AutoModel> {
        return this.http.get<AutoModel>('/api/auto_models/get/' + auto_model_id);
    }

    toAutoModel(autoModel) {
        const temp = autoModel.map( autoModel => {
            return new AutoModel(autoModel);
        });
        return temp;
    }

    save(autoModel: AutoModel): Observable<AutoModel> {
        if (!!autoModel.ID) {
            return this.http.put<AutoModel>('/api/auto_models/update/' + autoModel.ID, autoModel);
        }
        return this.http.post<AutoModel>('/api/auto_models/create', autoModel);
    }

    saveAll(autoModel: AutoModel[]): Observable<AutoModel[]> {
        return this.http.put<AutoModel[]>('/api/auto_models/update_all', autoModel);
    }

    delete(autoModel: AutoModel): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/auto_models/delete/' + autoModel.ID)
    }

    SearchByValue(searchAutoModel: AutoModel): Observable<AutoModel[]> {
        log.Debug("in search by value")
        return this.http.post<AutoModel[]>('/api/auto_models/search-by-value', searchAutoModel).pipe(map(this.toAutoModel));
    }

    SearchByNames(searchAutoModel: AutoModel): Observable<AutoModel[]> {
        return this.http.post<AutoModel[]>('/api/auto_models/search-by-names', searchAutoModel).pipe(map(this.toAutoModel));
    }

}