import { Injectable } from '@angular/core';
import { AsyncValidator, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { log } from '../dev/log';
import { HttpService } from '../services';

export interface CanUnique {
    username: () => string,
    service: DefaultService
}

export interface DefaultService {
    isUsernameUnique(number: string): Observable<boolean>;
}

@Injectable({ providedIn: 'root' })

export class UniqueUsernameValidator {

    constructor (
    ) {}

    static createValidator(canUnique: CanUnique) {
        log.Debug("canUnique number: ", canUnique.username())
        log.Debug("canUnique: ", canUnique)
        return (control: AbstractControl) => {
            log.Debug("control: ", control)
            log.Debug("username: ", canUnique.username())
            if( canUnique.username() && canUnique.username() == control.value ) {
                return of(null)
            } 
            return canUnique.service.isUsernameUnique(control.value).pipe(
                map(isUnique => (!isUnique ? { nonUniqueUsername: true } : null)),
                catchError(() => null)
            );
        }
    }
}