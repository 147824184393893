import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auto } from '../auto';
import { MONTHS } from '../common/month';
import { FormGroup, FormControl } from '@angular/forms';
import { Setting } from '../setting';
import { Officer } from '../officer';
import { Feed, FeedService } from '../feed';
import { log } from '../common/dev/log'

@Component({
    selector: "t-22b",
    templateUrl: "./t-22b.component.html",
    styleUrls: ['./single-print.component.css', './t-22b.component.css']
})

export class T22BComponent implements OnInit {

    private _months = MONTHS;

    currentAuto: Auto;
    officers: Officer[];

    form: FormGroup;

    fields = {
        Year: '',
        Make: '',
        Model: '',
        BodyStyle: '',
        Color: '',
        VIN: ['','','','','','','','','','','','','','','','','','','',''],
        TagAndState: '',
        PossessorName: '',
        PossessorLicense: '',
        PossessorAddress: '',
        PossessorApartment: '',
        PossessorPhone: '',
        PossessorCity: '',
        PossessorState: '',
        PossessorZip: '',
        PossessorCounty: '',
        OfficerName: '',
        OfficerBadgeNumber: '',
        OfficerAgencyAndPhone: '',
        OfficerTitle: '',
        OfficerAgencyAddress: '',
        OfficerAgencyCityAndCounty: '',
        OfficerResponse: '',
        OfficerDateAndTime: '',
    }
//Change to note maybe?
    constructor (
        private route: ActivatedRoute,
        private FeedService: FeedService,
    ){
        this.form = this.createFormGroup()
    }
    ngOnInit(): void {
        this.route.data.subscribe((data: {
            isBlank: boolean,
            currentAuto: Auto,
            officers: Officer[],
            settings: Setting[],
        }) => {

            this.currentAuto = data.currentAuto;
            this.officers = data.officers;

            if(!data.isBlank) {
                this.fields.Year = this.currentAuto.AutoYear || '';
                this.fields.Make = this.currentAuto.Make.Name || '';
                this.fields.Model = this.currentAuto.AutoModel.Name || '';
                // this.fields.BodyStyle = '';
                this.fields.Color = this.currentAuto.Color || '';
                for(var i = 0; i < this.currentAuto.Vin.length; i++) {
                    this.fields.VIN[i] = this.currentAuto.Vin.charAt(i) || '';
                }
                this.fields.TagAndState = this.currentAuto.Tag + ", " + this.currentAuto.State || ''

                // testing
                // this.fields.PossessorName = 'possessor name';
                // this.fields.PossessorLicense = 'possessor license';
                // this.fields.PossessorAddress = 'possessor address';
                // this.fields.PossessorApartment = 'apt.'
                // this.fields.PossessorPhone = 'possessor phone';
                // this.fields.PossessorCity = 'possessor city';
                // this.fields.PossessorState = 'state';
                // this.fields.PossessorZip = 'possessor zip';
                // this.fields.PossessorCounty = 'possessor county';

            }
        });
    }

    createFormGroup() {
        return new FormGroup({
            Officer: new FormControl()
        })
    }

    print(): void {
        //Change to note maybe?
        this.FeedService.save(new Feed({ AutoID: this.currentAuto.ID, Description: "T-22B Printed", Created: new Date() })).subscribe(
            resp => log.Debug("resp: ", resp)
        )
        window.print();
    }
}
