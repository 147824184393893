import { Model } from '../common/model';

export class UserRoleCache extends Model {

    Role: string;
    Created: Date;

    constructor(properties?: Object) {
        super(properties)
    }
}