import { Model } from '../common/model';
import { Company } from './company';

export class DailyReport extends Model {

    Companies: Company[];
    Total: number;
    Active: boolean;
    Created: Date;
    Modified: Date;

    constructor(properties?: Object) {
        super(properties)
    }
}