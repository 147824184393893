<mat-card class="search search-customers">
    <h1 class="title">{{ title }}</h1>
    <div class="row gutters">
        <div class="col">
            <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
                <mat-card class="searh-panel">
                    <mat-card-content>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                        <input type="text" placeholder="Customer Number" matInput [matAutocomplete]="auto" formControlName="CustomerNumber">
                                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                            <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer.CustomerNumber">{{ customer.CustomerNumber }}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <mat-select placeholder="Type" formControlName="Category">
                                        <mat-option value="Auction">Auction</mat-option>
                                        <mat-option value="Invoice">Invoice</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Company" formControlName="Company">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Last Name" formControlName="LastName">
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="First Name" formControlName="FirstName">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Address" formControlName="Address1">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col--size-3">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="City" formControlName="City">
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <mat-select placeholder="State" formControlName="State">
                                        <mat-option *ngFor="let state of states" [value]="state.Code">{{ state.Code }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col--size-3">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Zip" formControlName="Zip">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Phone" formControlName="Phone">
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="County" formControlName="County">
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-actions end>
                        <button color="primary" type="submit" mat-raised-button class="button--float-right"><mat-icon>search</mat-icon>Search</button>
                        <button color="primary" type="button" mat-raised-button class="button--float-right" (click)="reset($event)"><mat-icon>autorenew</mat-icon>Clear</button>
                    </mat-card-actions>
                </mat-card>
            </form>
        </div>
        <div class="col--size-2" style="position: relative;">
            <div class="spinner-container" *ngIf="dataSource.loading$ | async" style="position: absolute; top: 200px; left: 50%; transform: translate( -50%, 0 );">
                <mat-spinner></mat-spinner>
            </div>
            <table mat-table [dataSource]="dataSource">

                <!-- customer_number -->
                <ng-container matColumnDef="customer_number">
                    <th mat-header-cell *matHeaderCellDef>Customer Number</th>
                    <td mat-cell *matCellDef="let customer"><a [routerLink]="['edit', customer.ID]">{{ customer.CustomerNumber }}</a></td>
                </ng-container>

                <!-- company -->
                <ng-container matColumnDef="company">
                    <th mat-header-cell *matHeaderCellDef>Company</th>
                    <td mat-cell *matCellDef="let customer">{{ customer.Company }}</td>
                </ng-container>

                <!-- last_name -->
                <ng-container matColumnDef="last_name">
                    <th mat-header-cell *matHeaderCellDef>Last Name</th>
                    <td mat-cell *matCellDef="let customer">{{ customer.LastName }}</td>
                </ng-container>

                <!-- first_name -->
                <ng-container matColumnDef="first_name">
                    <th mat-header-cell *matHeaderCellDef>First Name</th>
                    <td mat-cell *matCellDef="let customer">{{ customer.FirstName }}</td>
                </ng-container>

                <!-- address -->
                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef>Address</th>
                    <td mat-cell *matCellDef="let customer">{{ customer.Address1 }}</td>
                </ng-container>

                <!-- city -->
                <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef>City</th>
                    <td mat-cell *matCellDef="let customer">{{ customer.City }}</td>
                </ng-container>

                <!-- state -->
                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef>State</th>
                    <td mat-cell *matCellDef="let customer">{{ customer.State }}</td>
                </ng-container>

                <!-- zip -->
                <ng-container matColumnDef="zip">
                    <th mat-header-cell *matHeaderCellDef>Zip</th>
                    <td mat-cell *matCellDef="let customer">{{ customer.Zip }}</td>
                </ng-container>

                <!-- phone -->
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>Phone</th>
                    <td mat-cell *matCellDef="let customer">{{ customer.Phone }}</td>
                </ng-container>

                <!-- type -->
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>Type</th>
                    <td mat-cell *matCellDef="let customer">{{ customer.Category }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [length]="dataSource.length$ | async" [pageSize]="10" [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
        </div>
    </div>
</mat-card>
<pre class="debug">{{ form.value | json }}</pre>