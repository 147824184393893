import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DeleteResponse } from '../common/delete-response';
import { Feed } from './feed';
import { log } from '../common/dev/log';

@Injectable({
    providedIn: 'root'
})

export class FeedService extends HttpService implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    GetByAutoID( autoID ): Observable<Feed[]> {
        return this.http.get<Feed[]>('/api/feeds/by_auto_id/' + autoID).pipe(map(this.toFeed));
    }

    GetAll(): Observable<Feed[]> {
        return this.http.get<Feed[]>('/api/feeds/all').pipe(map(this.toFeed));
    }

    toFeed(feed) {
        const temp = feed.map( feed => {
            return new Feed(feed);
        });
        return temp;
    }

    save(feed: Feed): Observable<Feed> {
        log.Debug("in feed service save: ", feed)
        if (!!feed.ID) {
            return this.http.put<Feed>('/api/feeds/update/' + feed.ID, feed);
        }
        return this.http.post<Feed>('/api/feeds/create', feed);
    }

    saveAll(feeds: Feed[]): Observable<Feed[]> {
        return this.http.put<Feed[]>('/api/feeds/update_all', feeds);
    }

    delete(feed: Feed): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/feeds/delete/' + feed.ID)
    }

}
