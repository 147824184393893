import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DeleteResponse } from '../common/delete-response';
import { Payment } from './payment';
import { DefaultService } from '../common/validators';

@Injectable({
    providedIn: 'root'
})

export class PaymentService extends HttpService implements OnDestroy, DefaultService {
    private _subscriptions: Subscription[] = [];

    //isNumberUnique: CanCheckUnique;

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    Get( payment_id: number ): Observable<Payment> {
        return this.http.get<Payment>('/api/payments/get/' + payment_id).pipe(map(val => new Payment(val)));
    }

    GetAll(): Observable<Payment[]> {
        return this.http.get<Payment[]>('/api/payments/all').pipe(map(this.toPayment));
    }

    toPayment(payment) {
        const temp = payment.map( payment => {
            return new Payment(payment);
        });
        return temp;
    }

    isNumberUnique(number: string): Observable<boolean> {
        return this.http.get<boolean>('/api/payments/is_unique/' + number).pipe(map(val => val));
    }

    save(payment: Payment): Observable<Payment> {
        if (!!payment.ID) {
            return this.http.put<Payment>('/api/payments/update/' + payment.ID, payment);
        }
        return this.http.post<Payment>('/api/payments/create', payment);
    }

    SaveWithInvoice(payment: Payment): Observable<Payment> {
        if (!!payment.ID) {
            return this.http.put<Payment>('/api/payments/update_with_invoice/' + payment.ID, payment);
        }
        return this.http.post<Payment>('/api/payments/create_with_invoice', payment);
    }

    saveWithoutInvoiceID(payment: Payment): Observable<Payment> {
        return this.http.post<Payment>('/api/payments/create_without_invoice_id', payment);
    }

    delete(payment: Payment): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/payments/delete/' + payment.ID)
    }

}