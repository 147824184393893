import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from '../common/dev/log';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { Observable, of } from 'rxjs';
import { startWith, map, tap, take } from 'rxjs/operators';
import { EmailService } from './email.service';
import { EmailData } from './email-data';
import { DatePipe } from '@angular/common';
import { AutoService } from '../auto/auto.service';

@Component({
    selector: "email",
    templateUrl: "./email.component.html"
})

export class EmailComponent implements OnInit {

    title: string;

    form: FormGroup;
    emailData: EmailData;

    isAuctionAdPetition;

    constructor (
        private route: ActivatedRoute,
        private apps: AppService,
        private router: Router,
        private es: EmailService,
        private datepipe: DatePipe,
        private as: AutoService
    ) {
        this.form = this.createFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            isBlank: boolean,
            isAuctionAd: boolean,
            isInvoice: boolean,
            isAuctionAdPetition: boolean,
            emailData: EmailData
        }) => {
            log.Debug("data: ", data)
            console.log("data: ", data)
            this.title = data.title;
            this.emailData = data.emailData
            this.isAuctionAdPetition = data.isAuctionAdPetition
            if(data.isAuctionAd || data.isAuctionAdPetition) {
                let messageString = "In accordance with Georgia Law Chapter 40-11, the following vehicles have been deemed abandoned and will be sold in a public auction on "
                messageString += data.emailData.Date + " "
                messageString += ". Said vehicles are located at "
                messageString += data.emailData.Name + " "
                messageString += data.emailData.Address + ", "
                messageString += data.emailData.City + ", "
                messageString += data.emailData.State + " "
                messageString += data.emailData.Zip + ", "
                messageString += data.emailData.Phone
                messageString += ". All vehicles are foreclosed on and will be sold with contents to the highest bidder.\n\n"

                for( let auto of data.emailData.Autos ) {
                    messageString += auto.AutoYear + " " + auto.Make.Name + " " + auto.AutoModel.Name + " " + auto.Vin + "\n\n"
                }

                this.form.get('Message').patchValue(messageString);
                this.form.get('To').patchValue(data.emailData.To);
                this.form.get('From').patchValue(data.emailData.From);

            } else if(data.isInvoice) {
                let messageString = data.emailData.Invoice.InvoiceNumber

                this.form.get('Message').patchValue(messageString);
                this.form.get('To').patchValue(data.emailData.To);
                this.form.get('From').patchValue(data.emailData.From);
            }
        })
    }

    createFormGroup() {
        return new FormGroup({
            To: new FormControl(),
            From: new FormControl(),
            CustomMessage: new FormControl(),
            Message: new FormControl(),
            PetitionDeadlineDate: new FormControl(),
        })
    }

    removeAuto(ID: number): void {
      const index = this.emailData.Autos.findIndex(a => a.ID == ID)
      if( index > -1 ) {
      	this.as.unmarkAsAuction(this.emailData.Autos[index]).subscribe( resp => {
      		if(resp) {
      			this.emailData.Autos.splice(index, 1);
      		}
      	})
      }
    }

    formatDate(date: Date): string {
        return this.datepipe.transform(date, 'MM/dd/yyyy')
    }

    onSubmit(e): void {

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched: ", this.form)
            return;
        }

        this.sendEmail();
    }

    sendEmail() {
        log.Debug("before Send email: ", this.form.value);
        Object.assign(this.emailData, new EmailData(this.form.value))
        this.emailData.PetitionDeadlineDate = this.formatDate(this.form.get('PetitionDeadlineDate').value)
        this.es.submit(this.emailData)
        .subscribe((isSent: boolean) => {
            log.Debug("isSent: ", isSent)

            if( isSent ) {
                this.apps.Confirmation('Email submitted.');
                this.form.markAsPristine();
                this.router.navigate(['../../', 'dashboard'], {relativeTo: this.route});
            }

        })
    }

}
