import { Routes } from '@angular/router';

import { ManageChargeTypesComponent } from './manage-charge-types.component';

import { ChargeTypesResolve } from './charge-types.resolve';

export const CHARGE_TYPE_ROUTES: Routes = [
    {
        path: 'charge-types',
        component: ManageChargeTypesComponent,
        data: {
            title: 'Manage Charge Types'
        },
        resolve: {
            chargeTypes: ChargeTypesResolve
        }
    }
]