import { Model } from '../common/model';
import { CompanyAgingReport } from './company-aging-report';


export class AgingReport extends Model {

    CompanyAgingReports: CompanyAgingReport[];
    CurrentDue: number;
    ThirtyDayDue: number;
    SixtyDayDue: number;
    NinetyDayDue: number;
    TotalDue: number;
    Active: boolean;
    Created: Date;
    Modified: Date;

    constructor(properties?: Object) {
        super(properties)
    }
}