import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { log } from '../common/dev/log';
import { AppService } from '../app.service';
import { UniqueUsernameValidator, passwordsEqualValidator } from '../common/validators';
import { ErrorStateMatcher } from '@angular/material/core';
import { AutoModel } from './auto-model';
import { AutoModelService } from './auto-model.service';
import { MakeService } from '../make';

@Component({
    selector: "edit-auto-model",
    templateUrl: "edit-auto-model.component.html"
})

export class EditAutoModelComponent implements OnInit {

    title: string;
    currentAutoModel: AutoModel;
    isEdit: boolean;

    form: FormGroup;
    makeFilterCtrl: FormControl = new FormControl();
    filteredMakes;

    constructor(
        private route: ActivatedRoute,
        private apps: AppService,
        private router: Router,
        private _autoModelService: AutoModelService,
        private _makeService: MakeService
    ){
        this.currentAutoModel = new AutoModel();
        this.form = this.createFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            currentAutoModel: AutoModel,
            edit: boolean,
        }) => {

            log.Debug("data: ", data)
            log.Debug("user: ", this.currentAutoModel.Name)

            this.title = data.title;
            this.isEdit = data.edit;
            this.currentAutoModel = data.currentAutoModel;

            if(this.isEdit) {
                Object.assign(this.currentAutoModel, data.currentAutoModel);
                this.form.patchValue(this.currentAutoModel);

                this._makeService.GetByID(this.currentAutoModel.MakeID).subscribe( r => {
                    this.filteredMakes = [r];
                })

            } else {
                this._makeService.SearchByValue('a').subscribe( r => {
                    this.filteredMakes = r;
                });
            }
            
        });

        // this._makeService.SearchByValue('a').subscribe(r => {
        //     this.filteredMakes = r;
        // });

        this.makeFilterCtrl.valueChanges.subscribe(() => {
            this.filterMakes();
        });
    }

    protected filterMakes() {

        let search = this.makeFilterCtrl.value
        if( search === '' || search === null ) {
            return 
        }
        search = search.toLowerCase();

        // filter the makes
        this._makeService.SearchByValue(search).subscribe(r => {
            this.filteredMakes = r
        })
    }

    createFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            MakeID: new FormControl(null, Validators.required),
            Name: new FormControl(null, Validators.required)
        })
    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }

    onSubmit(e): void {

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched or invalid: ", this.form)
            return;
        }

        this.save();
    }

    save() {
        this._autoModelService.save(this.form.value)
        .subscribe((savedAutoModel: AutoModel) => {
            this.apps.Confirmation('Model saved.');
            this.form.markAsPristine();

            if( !this.isEdit ) {
                this.currentAutoModel = new AutoModel(savedAutoModel);
                this.router.navigate(['../', 'edit', this.currentAutoModel.ID], {relativeTo: this.route});
                return
            }

            log.Debug("savedAutoModel: ", savedAutoModel)
            
            this.currentAutoModel = savedAutoModel
            this.form.patchValue(savedAutoModel);
            this.router.navigate(['../..'], {relativeTo: this.route});
        })
    }

    delete(e) {
        e.preventDefault();
        e.stopPropagation();
        if (confirm('This model will be deleted. Are you sure?')) {
            this._autoModelService.delete(this.currentAutoModel).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Model deleted.');
                    this.router.navigate(['models']);
                }
            })
        }
    }
}
