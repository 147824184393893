import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from '../common/dev/log';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { Customer } from './customer';
import { CustomerService } from './customer.service';
import { Observable } from 'rxjs';
import { startWith, map, tap, take } from 'rxjs/operators';
import { STATES } from '../common/state';
import { UniqueNumberValidator, hasNameValidator } from '../common/validators';

@Component({
    selector: "edit-customer",
    templateUrl: "./edit-customer.component.html"
})

export class EditCustomerComponent implements OnInit {

    filteredCustomers: Observable<Customer[]>;

    title: string;
    isEdit: boolean;
    isSearch: boolean;
    nextCustomerNumber: string;

    getCustomerNumber = () => this.currentCustomer.CustomerNumber;

    form: FormGroup;

    public customers: Customer[];
    public states = STATES;
    public currentCustomer: Customer;
    
    constructor (
        private route: ActivatedRoute,
        private apps: AppService,
        private cs: CustomerService,
        private router: Router
    ) {
        this.currentCustomer = new Customer();
        this.form = this.createFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            edit: boolean,
            search: boolean,
            nextCustomerNumber: string,
            currentCustomer: Customer,
        }) => {
            log.Debug("data: ", data)
            this.title = data.title;
            this.isEdit = data.edit;
            this.isSearch = data.search;
            this.nextCustomerNumber = data.nextCustomerNumber
            this.customers = []

            this.filteredCustomers = this.form.controls['CustomerNumber'].valueChanges.pipe(
                startWith(''),
                map(value => this._filterCustomers(value))
            );

            if (!this.isEdit && !this.isSearch) {
                this.form.controls['CustomerNumber'].setValue(this.nextCustomerNumber);
                this.form.controls['StatementFrequency'].setValue('Monthly');
            } else if ( this.isEdit ) {
                Object.assign(this.currentCustomer, data.currentCustomer);
                this.form.patchValue(this.currentCustomer);
                this.customers = []
            } else {
                this.form.get('CustomerNumber').clearValidators();
                this.form.get('CustomerNumber').clearAsyncValidators();
                this.form.get('CustomerNumber').updateValueAndValidity();

                this.form.get('Category').clearValidators();
                this.form.get('Category').updateValueAndValidity();
                this.customers = []
            }

        })
    }



    createFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            CustomerNumber: new FormControl(null, {
                validators: [Validators.required],
                asyncValidators: [UniqueNumberValidator.createValidator({number: this.getCustomerNumber, service: this.cs})]
            }),
            Company: new FormControl(),
            LastName: new FormControl(),
            FirstName: new FormControl(),
            MiddleInitial: new FormControl(),
            Address1: new FormControl(),
            Address2: new FormControl(),
            City: new FormControl(),
            State: new FormControl(),
            Zip: new FormControl(),
            Phone: new FormControl(),
            County: new FormControl(),
            CreditLimit: new FormControl(),
            Terms: new FormControl(),
            StatementFrequency: new FormControl(),
            Category: new FormControl('', Validators.required)
        }, { validators: hasNameValidator })
    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }

    gotoNew() {
        this.router.navigate(['../../', 'new'], {relativeTo: this.route});
    }

    private _filterCustomers(value: string): Customer[] {
        log.Debug('value: ', value)
        if(!this.customers) {
            return null
        }
        log.Debug('return: ', this.customers.filter(option => option.CustomerNumber.toLowerCase().indexOf(value.toLowerCase()) === 0))
        return this.customers.filter(option => option.CustomerNumber.toLowerCase().indexOf(value.toLowerCase()) === 0);
    }

    onSubmit(e): void {

        if( this.isSearch && this.form.dirty ) {
            this.form.setErrors(null)
            this.cs.GetByNumber(this.form.get('CustomerNumber').value).pipe(take(1)).subscribe(customerID => {
                if (customerID == -1) {
                    this.apps.Error('Cannot find an customer with that customer number.');
                    return
                }
                this.router.navigate([customerID], {relativeTo: this.route});
            });
            
            return
        }

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched: ", this.form)
            return;
        }

        this.save();
    }

    save() {
        this.cs.save(this.form.value)
        .subscribe((savedCustomer: Customer) => {
            this.apps.Confirmation('Customer saved.');
            this.form.markAsPristine();

            if( !this.isEdit ) {
                this.currentCustomer = new Customer(savedCustomer);
                this.customers.push(this.currentCustomer);
                this.router.navigate(['../', 'edit', savedCustomer.ID], {relativeTo: this.route});
            }

            log.Debug("savedCustomer: ", savedCustomer)
            
            const index = this.customers.findIndex( customer => customer.ID === this.currentCustomer.ID );
            if (index != -1) {
                Object.assign(this.customers[index], new Customer(savedCustomer));
            }
            this.currentCustomer = savedCustomer
            this.form.patchValue(savedCustomer);
        })
    }

    delete(e) {
        e.preventDefault();
        e.stopPropagation();
        if (confirm('This auto will be deleted. Are you sure?')) {
            this.cs.delete(this.currentCustomer).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Customer deleted.');
                    const index = this.customers.findIndex( customer => customer.ID === this.currentCustomer.ID);
                    this.customers.splice(index, 1);
                    this.router.navigate(['dashboard']);
                }
            })
        }
    }

}