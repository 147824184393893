import { Routes } from '@angular/router';

import { ManageSettingsComponent } from './manage-settings.component';

import { SettingsResolve } from './settings.resolve';

export const SETTING_ROUTES: Routes = [
    {
        path: 'settings',
        component: ManageSettingsComponent,
        data: {
            title: 'Settings'
        },
        resolve: {
            settings: SettingsResolve
        }
    }
]