import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, from } from 'rxjs';
import { map, concatMap, reduce } from 'rxjs/operators';
import { SettingService } from './setting.service';
import { Setting } from './setting';

@Injectable()
export class SettingsToMapResolve implements Resolve<{}> {
    constructor (
        private router: Router,
        private ss: SettingService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<{}> {
        return this.ss.GetAllToMap();
    }
}