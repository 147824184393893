import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from '../common/dev/log';
import { MONTHS } from '../common/month';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DailyReport, DailyReportService } from '../daily-report';
import { Invoice } from '../invoice';
import { LoadingService } from '../common/loading.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
    selector: "daily-cash-sales-report",
    templateUrl: "./daily-cash-sales-report.component.html"
})

export class DailyCashSalesReportComponent implements OnInit {

    report$: Observable<DailyReport>;
    private _months = MONTHS
    moment = new MomentDateAdapter('L');
    currentDate = this.moment.today();
    loading: boolean;
    form: FormGroup
    downloading = false;

    constructor (
        private route: ActivatedRoute,
        private drs: DailyReportService,
        private ls: LoadingService
    ){
        this.form = this.createFormGroup();
    }
    ngOnInit(): void {
        this.route.data.subscribe((data: {
        }) => {
            log.Debug("data: ", data)
        });
    }

    createFormGroup(): FormGroup {
        return new FormGroup({
            Date: new FormControl(null, Validators.required)
        })
    }

    getName( company: string, lastName: string, firstName: string ) {
        if(!this.empty(company)) {
            return company
        }

        if(!this.empty(lastName)) {
            if(!this.empty(firstName)) {
                return firstName + ' ' + lastName
            }
            return lastName
        }

        return ""
    }

    getCityStateZip(city: string, state: string, zip: string): string {

        let cityValid = !this.empty(city);
        let stateValid = !this.empty(state);
        let zipValid = !this.empty(zip);

        if( cityValid && stateValid && zipValid ) {
            return city + ', ' + state + ' ' + zip;
        } else if( cityValid && stateValid && !zipValid ) {
            return city + ', ' + state;
        } else if( !cityValid && stateValid && zipValid ) {
            return state + ' ' + zip;
        } else if(!cityValid && stateValid && !zipValid ) {
            return state;
        } else if( cityValid && !stateValid && zipValid ) {
            return city + ' ' + zip;
        } else if( cityValid && !stateValid &&  !zipValid ) {
            return city;
        } else if( !cityValid && !stateValid && zipValid ) {
            return zip;
        }

        return '';

    }

    print(): void {
        window.print();
    }

    getInvoiceAmount(invoice: Invoice): number {
        return this.drs.GetInvoiceCreditAmount(invoice);
    } 


    formatDate(date: Date): string {
        let tempDate = this.moment.parse(date, 'YYYY-MM-DDTHH:mm:ssZ');
        return this.moment.getMonth(tempDate) + '/' + this.moment.getDate(tempDate) + '/' + this.moment.getYear(tempDate);
    }

    empty(str: string): boolean {
        if( '' != str && null != str && undefined != str ) {
            return false;
        }
        return true;
    }

    onSubmit(e: Event) {
        e.preventDefault()

        this.report$ = this.drs.SearchCashSalesDailyReport(this.form.get('Date').value)
        
        this.downloading = false;
    }
}