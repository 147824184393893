import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auto } from '../auto';
import { MONTHS } from '../common/month';
import { log } from '../common/dev/log';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Charge } from '../charge';

@Component({
    selector: "foreclosure-of-lien",
    templateUrl: "./foreclosure-of-lien.component.html",
    styleUrls: ['./single-print.component.css']
})

export class ForeclosureOfLienComponent implements OnInit {

    private _months = MONTHS;
    isBlank: boolean

    moment = new MomentDateAdapter('L');

    fields = {
        CaseNumber: '',
        Year: '',
        Make: '',
        Model: '',
        VIN: '',
        Amount: '',
        LienEndDate: '',
        StoragePerDay: '',
        CurrentDay: '',
        CurrentMonth: '',
        CurrentYear: ''
    }

    constructor (
        private route: ActivatedRoute
    ){}
    ngOnInit(): void {
        this.route.data.subscribe((data: {
            isBlank: boolean,
            currentAuto: Auto
        }) => {

            log.Debug("data: ", data);

            this.isBlank = data.isBlank
            if(!data.isBlank) {
                let currentDate = this.moment.today();
                let lienEndDate = this.moment.today();

                this.fields.CaseNumber = data.currentAuto.CaseNumber || '';
                this.fields.Year = data.currentAuto.AutoYear || '';
                this.fields.Make = data.currentAuto.Make.Name || '';
                this.fields.Model = data.currentAuto.AutoModel.Name || '';
                this.fields.VIN = data.currentAuto.Vin || '';
                this.fields.Amount = data.currentAuto.PriceOut.toString() || '';
                this.fields.LienEndDate = 'unsure' //this._months[this.moment.getMonth(lienEndDate)] + ' ' + this.moment.getDate(lienEndDate) + ', ' + this.moment.getYear(lienEndDate) || '';
                this.fields.StoragePerDay = this.getStoragePerDay(data.currentAuto.Charges);
                this.fields.CurrentDay = this.moment.getDate(currentDate).toString() || '';
                this.fields.CurrentMonth = this._months[this.moment.getMonth(lienEndDate)] || '';
                this.fields.CurrentYear = this.moment.getYear(currentDate).toString() || '';

            }
        });
    }

    getStoragePerDay(charges: Charge[]): string {
        const index = charges.findIndex(charge => "Storage Per Day" == charge.Name);
        if( -1 == index ) {
            return '';
        }
        return charges[index].Charge.toString();
    }

    print(): void {
        window.print();
    }
}