import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from '../common/dev/log';
import { Setting } from './setting';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { SettingService } from './setting.service';
import { AppService } from '../app.service'

@Component({
    selector: "manage-settings",
    templateUrl: "./manage-settings.component.html"
})

export class ManageSettingsComponent implements OnInit {

    title: string;
    settings: Setting[];

    form: FormGroup;

    constructor (
        private route: ActivatedRoute,
        private ss: SettingService,
        private apps: AppService,

    ){
        this.form = this.createFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            settings: Setting[]
        }) => {
            log.Debug("data: ", data)
            this.title = data.title;
            this.settings = data.settings.filter(setting => setting.Name !== 'Next Invoice Number')
            for (let s in this.settings) {
                this.addSetting();
            }
            this.form.get('Settings').patchValue(this.settings);
        })
    }

    createFormGroup() {
        return new FormGroup({
            Settings: new FormArray([])
        })
    }

    createSettingFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            Name: new FormControl(),
            Value: new FormControl(null, Validators.required)
        })
    }

    addSetting(): void {
        let settings = this.form.get('Settings') as FormArray;
        settings.push(this.createSettingFormGroup());
    }
    removeSetting(i: number): void {
        let settings = this.form.get('Settings') as FormArray;
        settings.removeAt(i);
        this.settings.splice(i, 1)
    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }

    onSubmit(e): void {

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched: ", this.form)
            return;
        }

        this.save();
    }

    save() {
        this.ss.save(this.form.get('Settings').value)
        .subscribe((savedSettings: Setting[]) => {
            this.apps.Confirmation('Settings saved.');
            this.form.markAsPristine();

            log.Debug("savedSettings: ", savedSettings)
            this.form.get('Settings').patchValue(savedSettings);
            this.settings = savedSettings
        })
    }

    deleteSetting(e, index: number) {
        e.preventDefault();
        e.stopPropagation();


        const setting = this.settings[index] as Setting;
        if(!Boolean(setting)) {
            log.Debug("setting: ", setting)
            this.removeSetting(index);
            return
        }

        if(confirm("This setting will be deleted. Are you sure?")) {
            this.ss.delete(<Setting>setting).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Setting deleted.');
                    this.removeSetting(index);
                }
            })
        }
    }
}