import { Model } from '../common/model';
import { Owner } from '../owner';
import { Charge } from '../charge';
import { Make } from '../make';
import { AutoModel } from '../auto-model';

export interface TempAuto {
    AutoNumber?: string;
    DateIn?: Date;
    ImpoundType?: string;
    Make?: string;
    Model?: string;
}

export class Auto extends Model {

    Make: Make;
    AutoModel: AutoModel;
    Owners: Owner[];
    Lienholders: Owner[];
    Charges: Charge[];
    StatusID: number;
    StatusDescriptionID: number;
    IsClosed: boolean;
    AutoNumber: string;
    DateIn: Date;
    ImpoundTypeID: number;
    InvoiceID: number;
    AutoYear: string;
    Color: string;
    Vin: string;
    Tag: string;
    State: string;
    DecalNumber: string;
    DecalYear: string;
    PriceIn: number;
    DateOut: Date;
    PriceOut: number;
    CaseNumber: string;
    RemovalStreet: string;
    RemovalCity: string;
    RemovalState: string;
    RemovalZip: string;
    IsRequest: boolean;
    IsNotification: boolean;
    IsInsurancePickup: boolean;
    HasKeys: boolean;
    IsAuction: boolean;
    Active: boolean;
    Created: Date;
    Modified: Date;

    get hasMake(): boolean {
        return Boolean(this.Make) && Boolean(this.Make.ID); // the last check is optional.
    }    

    get MakeID(): number { // the dual type is optional
        return this.hasMake ? this.Make.ID : 0;
    }    

    set MakeID(val: number) {
        if(this.hasMake && this.Make.ID == val){ // if make exists and ids are the same do nothing;
            return;
        }
        this.Make = new Make({ID: val}); // this is garbage colletion just incase there are other values in the make, so we make a new object.
    }

    get hasAutoModel(): boolean {
        return Boolean(this.AutoModel) && Boolean(this.AutoModel.ID); // the last check is optional.
    }    

    get AutoModelID(): number { // the dual type is optional
        return this.hasAutoModel ? this.AutoModel.ID : 0;
    }    

    set AutoModelID(val: number) {
        if(this.hasAutoModel && this.AutoModel.ID == val){ // if make exists and ids are the same do nothing;
            return;
        }
        this.AutoModel = new AutoModel({ID: val}); // this is garbage colletion just incase there are other values in the make, so we make a new object.
    }

    get hasLienholder(): boolean {
        return Boolean(this.Lienholders) && Boolean(this.Lienholders[0]) && Boolean(this.Lienholders[0].ID)
    }

    get LienholderID(): number {
        return this.hasLienholder ? this.Lienholders[0].ID : 0;
    }

    set LienholderID(val: number) {
        if (this.hasLienholder && this.Lienholders[0].ID == val) {
            return;
        }
        if (undefined == this.Lienholders) {
            this.Lienholders = []
        }
        this.Lienholders[0] = new Owner({ID: val});
    }

    get hasSecondLienholder(): boolean {
        return Boolean(this.Lienholders) && Boolean(this.Lienholders[1]) && Boolean(this.Lienholders[1].ID)
    }

    get SecondLienholderID(): number {
        return this.hasSecondLienholder ? this.Lienholders[1].ID : 0;
    }

    set SecondLienholderID(val: number) {
        if (this.hasSecondLienholder && this.Lienholders[1].ID == val) {
            return;
        }
        if (undefined == this.Lienholders) {
            this.Lienholders = []
        }
        this.Lienholders[1] = new Owner({ID: val});
    }

    get hasOwner(): boolean {
        return Boolean(this.Owners) && Boolean(this.Owners[0]) && Boolean(this.Owners[0].ID)
    }

    get OwnerID(): number {
        return this.hasOwner ? this.Owners[0].ID : 0;
    }

    set OwnerID(val: number) {
        if (this.hasOwner && this.Owners[0].ID == val) {
            return;
        }
        if (undefined == this.Owners) {
            this.Owners = []
        }
        this.Owners[0] = new Owner({ID: val});
    }

    get hasSecondOwner(): boolean {
        return Boolean(this.Owners) && Boolean(this.Owners[1]) && Boolean(this.Owners[1].ID)
    }

    get SecondOwnerID(): number {
        return this.hasSecondOwner ? this.Owners[1].ID : 0;
    }

    set SecondOwnerID(val: number) {
        if (this.hasSecondOwner && this.Owners[1].ID == val) {
            return;
        }
        if (undefined == this.Owners) {
            this.Owners = []
        }
        this.Owners[1] = new Owner({ID: val});
    }

    constructor(properties?: Object) {
        super(properties);
        this.Owners = this.Owners || [];
        this.Lienholders = this.Lienholders || [];
    }

}