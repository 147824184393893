import { Routes } from '@angular/router';

import { ManageOfficersComponent } from './manage-officers.component';
import { OfficersResolve } from './officers.resolve';

export const OFFICER_ROUTES: Routes = [
    {
        path: 'officers',
        component: ManageOfficersComponent,
        data: {
            title: 'Manage Officers'
        },
        resolve: {
            officers: OfficersResolve
        }
    }
]