<button class="print-button" mat-raised-button (click)="print()" color="primary" style="margin-bottom: 10px;"><mat-icon>print</mat-icon>Print</button>
<mat-card class="small-form no-print" style="margin-bottom: 30px;">
    <form [formGroup]="form">
        <div class="row gutters">
            <div class="col">
                <mat-form-field>
                    <input matInput [matDatepicker]="Date" placeholder="Date" formControlName="Date">
                    <mat-datepicker-toggle matSuffix [for]="Date"></mat-datepicker-toggle>
                    <mat-datepicker #Date></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </form>
    <mat-card-actions>
        <button type="submit" mat-raised-button color="primary" (click)="onSubmit($event)"><mat-icon>list</mat-icon>Get</button>
    </mat-card-actions>
</mat-card>
<div id="print-section" style="position: relative;">
<ng-container *ngIf="report$ | async as report">
    <ng-container *ngIf="report.Companies.length > 0; else empty">
        <div class="report-container print-out-container">
            <div class="report report--daily-cash-sales-report print-out">
                <p class="title bold">Daily Cash Sales Report</p>
                <p class="bold">{{ form.get('Date').value | date: 'M/d/yyyy':'utc' }}</p>
                <div class="row gutters border-bottom">
                    <div class="col">
                        <p class="bold">Company</p>
                    </div>
                    <div class="col">
                        <p class="bold">Invoice</p>
                    </div>
                    <div class="col">
                        <p class="bold">Amount</p>
                    </div>
                </div>
                <div *ngFor="let company of report.Companies">
                    <div class="row gutters" *ngFor="let invoice of company.Invoices; index as i;">
                        <div class="col">
                            <p class="bold">{{ 0 == i ? getName(company.Name, company.LastName, company.FirstName) : "" }}</p>
                        </div>
                        <div class="col">
                            <p>{{ invoice.InvoiceNumber }}</p>
                        </div>
                        <div class="col">
                            <p>{{ invoice.Paid | currency }}</p>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col">
                        </div>
                        <div class="col text-right">
                            <p class="bold italic">Company Total:</p>
                        </div>
                        <div class="col">
                            <p class="bold">{{ company.Total | currency }}</p>
                        </div>
                    </div>
                </div>
                <div class="row gutters">
                    <div class="col">
                    </div>
                    <div class="col">
                        <p class="bold">Report Total:</p>
                    </div>
                    <div class="col">
                        <p class="bold">{{ report.Total | currency }}</p>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #empty><p style="margin-top: 60px; text-align: center;">No cash sales to report for {{ this.form.get('Date').value | date:"shortDate":"utc" }}.</p></ng-template>
</ng-container>
</div>