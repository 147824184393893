import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from '../common/dev/log';
import { StatusDescription } from './status-description';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { StatusDescriptionService } from './status-description.service';
import { AppService } from '../app.service'

@Component({
    selector: "manage-status-descriptions",
    templateUrl: "./manage-status-descriptions.component.html"
})

export class ManageStatusDescriptionsComponent implements OnInit {

    title: string;
    statusDescriptions: StatusDescription[];

    form: FormGroup;

    constructor (
        private route: ActivatedRoute,
        private sds: StatusDescriptionService,
        private apps: AppService,

    ){
        this.form = this.createFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            statusDescriptions: StatusDescription[]
        }) => {
            log.Debug("data: ", data)
            this.title = data.title;
            this.statusDescriptions = data.statusDescriptions
            for (let cT in this.statusDescriptions) {
                this.addStatusDescription();
            }
            this.form.get('StatusDescriptions').patchValue(this.statusDescriptions);
        })
    }

    createFormGroup() {
        return new FormGroup({
            StatusDescriptions: new FormArray([])
        })
    }

    createStatusDescriptionFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            Name: new FormControl(null, Validators.required)
        })
    }

    addStatusDescription(): void {
        let statusDescriptions = this.form.get('StatusDescriptions') as FormArray;
        statusDescriptions.push(this.createStatusDescriptionFormGroup());
    }
    removeStatusDescription(i: number): void {
        let statusDescriptions = this.form.get('StatusDescriptions') as FormArray;
        statusDescriptions.removeAt(i);
        this.statusDescriptions.splice(i, 1)
    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }

    onSubmit(e): void {

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched: ", this.form)
            return;
        }

        this.save();
    }

    save() {
        this.sds.save(this.form.get('StatusDescriptions').value)
        .subscribe((savedStatusDescriptions: StatusDescription[]) => {
            this.apps.Confirmation('Status Descriptions saved.');
            this.form.markAsPristine();

            log.Debug("savedStatusDescriptions: ", savedStatusDescriptions)
            this.form.get('StatusDescriptions').patchValue(savedStatusDescriptions);
            this.statusDescriptions = savedStatusDescriptions
        })
    }

    deleteStatusDescription(e, index: number) {
        e.preventDefault();
        e.stopPropagation();


        const statusDescription = this.statusDescriptions[index] as StatusDescription;
        if(!Boolean(statusDescription)) {
            log.Debug("impoundType: ", statusDescription)
            this.removeStatusDescription(index);
            return
        }

        if(confirm("This status description will be deleted. Are you sure?")) {
            this.sds.delete(<StatusDescription>statusDescription).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Status Description deleted.');
                    this.removeStatusDescription(index);
                }
            })
        }
    }
}