<mat-card class="check-list-autos">
    <h1 class="title">{{ title }}</h1>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
        <mat-card-content>
            <table class="table">
                <tr>
                    <th>
                        <mat-checkbox color="primary"
                                        formControlName="CheckAll"
                                        (click)="checkAll($event)">
                        </mat-checkbox>
                    </th>
                    <th>Auto Number</th>
                    <th>Date In</th>
                    <th>Year</th>
                    <th>Make</th>
                    <th>Model</th>
                </tr>
                <tr class="checklist-autos-form-array" formArrayName="Autos" *ngFor="let auto of form.get('Autos')['controls']; let i = index">
                    <td [formGroupName]="i">
                        <mat-checkbox color="primary" formControlName="IsT158">
                        </mat-checkbox>
                    </td>
                    <td>{{ autos[i].AutoNumber }}</td>
                    <td>{{ autos[i].DateIn | date: 'MMMM d, yyyy':'UTC' }}</td>
                    <td>{{ autos[i].AutoYear }}</td>
                    <td>{{ autos[i].Make.Name }}</td>
                    <td>{{ autos[i].AutoModel.Name }}</td>
                </tr>
            </table>
        </mat-card-content>
        <mat-card-actions end>
            <button mat-raised-button color="primary"><mat-icon>{{ button.icon }}</mat-icon>{{ button.text }}</button>
        </mat-card-actions>
    </form>
</mat-card>
<pre class="debug">{{ form.value | json }}</pre>