import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DriverService } from './driver.service';
import { DriverReport } from './driver-report';

@Injectable()
export class DriverReportsResolve implements Resolve<DriverReport[]> {
    constructor (
        private router: Router,
        private ds: DriverService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<DriverReport[]> {
        return this.ds.GetDriverReports();
    }
}