import { Model } from '../common/model';
import { Invoice } from '../invoice';

export class Payment extends Model {

    Amount: number;
    InvoiceID: number;
    Invoice: Invoice;
    Name: string;
    Date: Date;
    Created: Date;
    Modified: Date;

    // constructor(properties?: Object) {
    //     super(properties);
    // }

}