import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DeleteResponse } from '../common/delete-response';
import { Officer } from './officer';

@Injectable({
    providedIn: 'root'
})

export class OfficerService extends HttpService implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    GetAll(): Observable<Officer[]> {
        return this.http.get<Officer[]>('/api/officers/all').pipe(map(this.toOfficer));
    }

    toOfficer(officer) {
        const temp = officer.map( officer => {
            return new Officer(officer);
        });
        return temp;
    }

    save(officers: Officer[]): Observable<Officer[]> {
        return this.http.put<Officer[]>('/api/officers/update_all', officers);
    }

    delete(officer: Officer): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/officers/delete/' + officer.ID)
    }

}