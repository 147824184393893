import { Model } from '../common/model';
import { Make } from '../make';

export class AutoModel extends Model {

    MakeID: number;
    Make: Make;
    Name: string;
    Active: boolean;
    Created: Date;
    Modified: Date;

    // constructor(properties?: Object) {
    //     super(properties);
    // }

}