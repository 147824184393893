import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { User } from './user';

@Injectable()
export class CurrentUserResolve implements Resolve<User> {
    constructor (
        private router: Router,
        private us: UserService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<User> {
        const user_id = +route.paramMap.get('user_id');
        return this.us.Get(user_id);
    }
}