import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DeleteResponse } from '../common/delete-response';
import { Transaction } from './transaction';

@Injectable({
    providedIn: 'root'
})

export class TransactionService extends HttpService implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    Get( transaction_id: number ): Observable<Transaction> {
        return this.http.get<Transaction>('/api/transactions/get/' + transaction_id).pipe(map(val => new Transaction(val)));
    }

    GetAll(): Observable<Transaction[]> {
        return this.http.get<Transaction[]>('/api/transactions/all').pipe(map(this.toTransaction));
    }

    toTransaction(transaction) {
        const temp = transaction.map( transaction => {
            return new Transaction(transaction);
        });
        return temp;
    }

    save(transaction: Transaction): Observable<Transaction> {
        if (!!transaction.ID) {
            return this.http.put<Transaction>('/api/transactions/update/' + transaction.ID, transaction);
        }
        return this.http.post<Transaction>('/api/transactions/create', transaction);
    }

    delete(transaction: Transaction): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>('/api/transactions/delete/' + transaction.ID)
    }

    isNumberUnique(transactionNumber: string): Observable<boolean> {
        return this.http.get<boolean>('/api/transactions/is_unique/' + transactionNumber).pipe(map(val => val));
    }

    GetByNumber(transactionNumber: string): Observable<number> {
        return this.http.get<number>('/api/transactions/get_by_number/' + transactionNumber).pipe(map(val => val));
    }
    GetNextTransactionNumber(): Observable<string> {
        return this.http.get<string>('/api/transactions/next').pipe(map(val => val));
    }

    Search(transaction: Transaction): Observable<Transaction[]> {
        return this.http.post<Transaction[]>('/api/transactions/search', transaction).pipe(map(val => val));
    }

}