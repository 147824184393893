import { Routes } from '@angular/router';

import { ManageImpoundTypesComponent } from './manage-impound-types.component';
import { ImpoundTypesResolve } from './impound-types.resolve';

export const IMPOUND_TYPE_ROUTES: Routes = [
    {
        path: 'impound-types',
        component: ManageImpoundTypesComponent,
        data: {
            title: 'Manage Impound Types'
        },
        resolve: {
            impoundTypes: ImpoundTypesResolve
        }
    }
]