import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auto } from '../auto';
import { MONTHS } from '../common/month';
import { log } from '../common/dev/log';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Charge } from '../charge';
import { FormGroup, FormControl } from '@angular/forms';
import { Feed, FeedService } from '../feed';

@Component({
    selector: "affidavit-court-order",
    templateUrl: "./affidavit-court-order.component.html",
    styleUrls: ['./single-print.component.css']
})

export class AffidavitCourtOrderComponent implements OnInit {

    private _months = MONTHS;
    isBlank: boolean

    moment = new MomentDateAdapter('L');

    form: FormGroup;

    autoID: number;

    fields = {
        CaseNumber: '',
        Year: '',
        Make: '',
        Model: '',
        VIN: '',
        Amount: '',
        LienEndDate: '',
        StoragePerDay: '',
        CurrentDay: '',
        CurrentMonth: '',
        CurrentYear: '',
        CompanyCounty: '',
        CompanyAgent: '',
        VehicleYear: '',
        Location: '',
        Payment: '',
        CompanyAddress: '',
        CompanyCityStateZipCounty: '',
        CompanyPhone: '',
        CurrentDate: '',
        CurrentYearTruncated: ''
    }

    constructor (
        private route: ActivatedRoute,
        private FeedService: FeedService,
    ){
        this.form = this.createFormGroup();
    }
    ngOnInit(): void {
        this.route.data.subscribe((data: {
            isBlank: boolean,
            currentAuto: Auto,
            settings: {}
        }) => {

            log.Debug("data: ", data);

            this.autoID = data.currentAuto.ID

            this.isBlank = data.isBlank
            if(!data.isBlank) {
                let currentDate = this.moment.today();
                let lienEndDate = this.moment.today();

                this.fields.CaseNumber = data.currentAuto.CaseNumber || '';
                this.fields.Year = data.currentAuto.AutoYear || '';
                this.fields.Make = data.currentAuto.Make.Name || '';
                this.fields.Model = data.currentAuto.AutoModel.Name || '';
                this.fields.VIN = data.currentAuto.Vin || '';
                this.fields.Amount = data.currentAuto.PriceOut.toString() || '';
                this.fields.LienEndDate = 'unsure' //this._months[this.moment.getMonth(lienEndDate)] + ' ' + this.moment.getDate(lienEndDate) + ', ' + this.moment.getYear(lienEndDate) || '';
                this.fields.StoragePerDay = this.getStoragePerDay(data.currentAuto.Charges);
                this.fields.CurrentDay = this.moment.getDate(currentDate).toString() || '';
                this.fields.CurrentMonth = this._months[this.moment.getMonth(lienEndDate)] || '';
                this.fields.CurrentYear = this.moment.getYear(currentDate).toString() || '';

                this.fields.CompanyCounty = data.settings['Company County'] || '';
                this.fields.CompanyAgent = data.settings['Form Agent'] || '';
                this.fields.VehicleYear = data.currentAuto.AutoYear || '';
                this.fields.Location = data.settings['Company Address'] + ', ' + data.settings['Company State'] + ' '
                    + data.settings['Company Zip'];
                this.fields.Payment = data.currentAuto.PriceOut.toString() || '';
                this.fields.CompanyAddress = data.settings['Company Address'] || '';
                this.fields.CompanyCityStateZipCounty = data.settings['Company City'] + ', ' + data.settings['Company State']
                    + ', ' + data.settings['Company Zip'] + ', ' + data.settings['Company County'];
                this.fields.CompanyPhone = data.settings['Company Phone'] || '';
                this.fields.CurrentDate = this._months[this.moment.getMonth(currentDate)] + ' ' + this.moment.getDate(currentDate)
                    + ', ' + this.moment.getYear(currentDate);
                this.fields.CurrentYearTruncated = this.getTruncatedYear(this.moment.getYear(currentDate));

            }
        });
    }

    createFormGroup() {
        return new FormGroup({
            AbandonedTotal: new FormControl(),
            LienAmount: new FormControl(),
            Date: new FormControl(),
        })
    }

    getTruncatedYear( year: number ): string {

        if( null == year || undefined == year ) {
            return ''
        }

        return year.toString().substring(2);

    }

    getStoragePerDay(charges: Charge[]): string {
        const index = charges.findIndex(charge => "Storage Per Day" == charge.Name);
        if( -1 == index ) {
            return '';
        }
        return charges[index].Charge.toString();
    }

    print(): void {
        //Change to note maybe?
        this.FeedService.save(new Feed({ AutoID: this.autoID, Description: "Affidavit and Court Order Printed", Created: new Date() })).subscribe(
            resp => log.Debug("resp: ", resp)
        )
        window.print();
    }
}
