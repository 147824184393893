import { Model } from '../common/model';
import { Invoice } from '../invoice';

export class Company extends Model {

    Name: string;
    LastName: string;
    FirstName: string;
    Invoices: Invoice[];
    Total: number;
    Active: boolean;
    Created: Date;
    Modified: Date;

    constructor(properties?: Object) {
        super(properties)
    }
}