<mat-card class="manage-officers medium-form manage-table">
    <h1 class="title">{{ title }}</h1>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
        <mat-card-content>
            <div class="officers-form-array" formArrayName="Officers" *ngFor="let officer of form.get('Officers')['controls']; let i = index">
                <div class="form-group-container" [formGroupName]="i">
                    <mat-card style="margin-bottom: 15px">
                        <div class="row gutters">
                            <div class="col--size-6">
                                <div class="row gutters">
                                    <div class="col" style="padding: 0 0 0 10px;">
                                        <mat-form-field>
                                            <input matInput type="text" placeholder="Name" formControlName="Name" required>
                                            <mat-error *ngIf="officer.get('Name')?.invalid">{{ getErrorMessage(officer.get('Name')) }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field>
                                            <input matInput type="text" placeholder="Badge" formControlName="Badge" required>
                                            <mat-error *ngIf="officer.get('Badge')?.invalid">{{ getErrorMessage(officer.get('Badge')) }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col" style="padding: 0 10px 0 0;">
                                        <mat-form-field>
                                            <input matInput type="text" placeholder="Title" formControlName="Title" required>
                                            <mat-error *ngIf="officer.get('Title')?.invalid">{{ getErrorMessage(officer.get('Title')) }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row gutters">
                                    <div class="col" style="padding: 0 0 0 10px;">
                                        <mat-form-field>
                                            <input matInput type="text" placeholder="Agency Name" formControlName="AgencyName" required>
                                            <mat-error *ngIf="officer.get('AgencyName')?.invalid">{{ getErrorMessage(officer.get('AgencyName')) }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field>
                                            <input matInput type="text" placeholder="Agency Phone" formControlName="AgencyPhone" required>
                                            <mat-error *ngIf="officer.get('AgencyPhone')?.invalid">{{ getErrorMessage(officer.get('AgencyPhone')) }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field>
                                            <input matInput type="text" placeholder="Agency Address" formControlName="AgencyAddress" required>
                                            <mat-error *ngIf="officer.get('AgencyAddress')?.invalid">{{ getErrorMessage(officer.get('AgencyAddress')) }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field>
                                            <input matInput type="text" placeholder="Agency City" formControlName="AgencyCity" required>
                                            <mat-error *ngIf="officer.get('AgencyCity')?.invalid">{{ getErrorMessage(officer.get('AgencyCity')) }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col" style="padding: 0 10px 0 0;">
                                        <mat-form-field>
                                            <input matInput type="text" placeholder="Agency County" formControlName="AgencyCounty" required>
                                            <mat-error *ngIf="officer.get('AgencyCounty')?.invalid">{{ getErrorMessage(officer.get('AgencyCounty')) }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col" style="margin: auto;">
                                <button type="button" mat-raised-button color="warn" (click)="deleteOfficer($event, i)" style="display: block; margin: 0 auto;"><mat-icon>delete</mat-icon></button>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions end>
            <button type="button" mat-raised-button color="primary" (click)="addOfficer()"><mat-icon>add</mat-icon>Add</button>
            <button type="submit" mat-raised-button color="primary"><mat-icon>save</mat-icon>Save Changes</button>
        </mat-card-actions>
    </form>
</mat-card>
<pre class="debug">{{ form.value | json }}</pre>
