import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { startWith, map, tap, take } from 'rxjs/operators';
import { log } from '../common/dev/log';
import { Auto } from './auto';
import { AutoService } from './auto.service';
import { Status } from '../status';
import { ImpoundType } from '../impound-type';
import { Make, MakeService } from '../make';
import { AutoModel, AutoModelService } from '../auto-model';
import { STATES } from '../common/state';
import { Owner } from '../owner';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator'
import { AutosDataSource } from './autos-data-source';
import { AutosSearchService } from './autos-search.service';
import { AutoSearchData } from './auto-search-data';
import { AppService } from '../app.service';
import { StatusDescription } from '../status-description/index';

@Component({
    selector: "search-autos",
    templateUrl: "./search-autos.component.html"
})

export class SearchAutosComponent implements OnInit {

    title: string;
    downloading: boolean;

    form: FormGroup;
    download: FormGroup;
    public statuses: Status[];
    public impoundTypes: ImpoundType[];
    public makes: Make[];
    public autoModels: AutoModel[];
    public states = STATES;
    public statusDescriptions: StatusDescription[];

    filteredMakes$: Observable<Make[]>;
    filteredAutoModels$: Observable<AutoModel[]>;

    displayedColumns: string[] = ['auto_number', 'status', 'impound_type', 'year', 'make', 'model', 'date_in', 'date_out', 'color', 'state', 'vin', 'tag']
    dataSource: AutosDataSource;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    constructor (
        private route: ActivatedRoute,
        private as: AutoService,
        private ms: MakeService,
        private ams: AutoModelService,
        private ass: AutosSearchService,
        private apps: AppService,
    ){
        this.form = this.createFormGroup()
        this.download = this.createDownloadFormGroup()
    }
    ngOnInit(): void {

        this.dataSource = new AutosDataSource(this.ass);
        this.dataSource.loadAutos(new AutoSearchData());
        this.dataSource.updateLength(new AutoSearchData());

        this.route.data.subscribe((data: {
            title: string,
            statuses: Status[],
            impoundTypes: ImpoundType[],
            makes: Make[],
            autoModels: AutoModel[],
            statusDescriptions: StatusDescription[],
        }) => {
            log.Debug("data: ", data)

            this.title = data.title;
            this.statuses = data.statuses
            this.impoundTypes = data.impoundTypes
            this.makes = data.makes
            this.autoModels = data.autoModels
            this.statusDescriptions = data.statusDescriptions
        });
    }

    ngAfterViewInit() {
        this.paginator.page.pipe(
            tap(() => this.loadNextPage())
        ).subscribe();
    }

    loadNextPage() {
        log.Debug("page index: ", this.paginator.pageIndex )
        this.dataSource.loadAutos(this.beforeSubmit(), this.paginator.pageIndex, this.paginator.pageSize)
    }

    createFormGroup() {
        return new FormGroup({
            AutoNumber: new FormControl(),
            StatusID: new FormControl(),
            StatusDescriptionID: new FormControl(),
            ImpoundTypeID: new FormControl(),
            AutoYear: new FormControl(),
            Make: new FormControl(),
            AutoModel: new FormControl(),
            DateInStart: new FormControl(),
            DateInEnd: new FormControl(),
            DateOutStart: new FormControl(),
            DateOutEnd: new FormControl(),
            Color: new FormControl(),
            State: new FormControl(),
            Vin: new FormControl(),
            Tag: new FormControl(),
            OwnerFirstName: new FormControl(),
            OwnerLastNameCompany: new FormControl()
        });
    }

    createDownloadFormGroup() {
        return new FormGroup({
            Filename: new FormControl(null, Validators.required)
        })
    }

    filterMakes = (value: string | Make): Observable<Make[]> => {
        value = value || 'a';
        if (typeof value != 'string' ) {
            return of([value])
        }
        return this.ms.SearchByValue(<string>value).pipe(take(1));
    }

    displayMakes = (make: Make): string => {
        return make ? make.Name : ""
    }

    initializeAutoModels() {
        const make = this.form.get('Make').value
        this.filteredAutoModels$ = this.ams.SearchByValue(new AutoModel({Name: 'a', MakeID: make.ID}));
    }

    filterAutoModels = (value: string | AutoModel): Observable<AutoModel[]> => {
        value = value || 'a';

        // if auto model selected
        if (typeof value != 'string') {
            return of([value])
        }

        // if make selected and searching by auto model name
        let make = this.form.controls['Make'].value;
        if (make) {
            return this.ams.SearchByValue(new AutoModel({Name: value, MakeID: make.ID}));
        }

        // initial values
        return this.ams.SearchByValue(new AutoModel({Name: 'a', MakeID: 1}));

    }

    displayAutoModels = (autoModel: AutoModel): string => {
        return autoModel ? autoModel.Name : ""
    }

    getStatusByID( id: number ): string {
        const index = this.statuses.findIndex( status => status.ID === id);
        if (index === -1) {
            return ''
        }
        return this.statuses[index].Name;
    }

    getStatusDescriptionByID( id: number ): string {
        const index = this.statusDescriptions.findIndex( sd => sd.ID === id);
        if (index === -1) {
            return ''
        }
        return this.statusDescriptions[index].Name;
    }

    getImpoundTypeByID( id: number ): string {
        const index = this.impoundTypes.findIndex( impoundType => impoundType.ID === id);
        if (index === -1) {
            return ''
        }
        return this.impoundTypes[index].Name;
    }

    reset(e) {
        e.preventDefault()
        e.stopPropagation()

        this.form.reset();
        this.form.get('Make').patchValue(new Make({Name: ""}));
        this.form.get('AutoModel').patchValue(new AutoModel({Name: ""}));
    }

    onDownload(e) {
        e.preventDefault()
        e.stopPropagation()

        if(this.download.get('Filename').value == null) {
            return
        }

        let autoSearchData = new AutoSearchData(this.form.value)
        autoSearchData.MakeID = 0
        if(this.form.get('Make').value != null) {
            autoSearchData.MakeID = this.form.get('Make').value.ID
        }
        autoSearchData.AutoModelID = 0
        if(this.form.get('AutoModel').value != null) {
            autoSearchData.AutoModelID = this.form.get('AutoModel').value.ID
        }

        autoSearchData.Filename = this.download.get('Filename').value

        this.downloading = true
        this.as.SearchAutosPDF(autoSearchData).subscribe( blob => {
            const a = document.createElement('a');
            a.setAttribute('download', autoSearchData.Filename+'.pdf');
            a.setAttribute('href', window.URL.createObjectURL(blob));
            a.click()
            this.downloading = false;
        })

    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }

    beforeSubmit(): AutoSearchData {
        let autoSearchData = new AutoSearchData(this.form.value)
        autoSearchData.MakeID = 0
        if(this.form.get('Make').value != null) {
            autoSearchData.MakeID = this.form.get('Make').value.ID
        }
        autoSearchData.AutoModelID = 0
        if(this.form.get('AutoModel').value != null) {
            autoSearchData.AutoModelID = this.form.get('AutoModel').value.ID
        }

        return autoSearchData;
    }

    onSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        this.paginator.pageIndex = 0
        this.loadNextPage()
        this.dataSource.updateLength(this.beforeSubmit())
    }
}
