<mat-card class="small-form no-print" style="margin-bottom: 30px;">
    <form [formGroup]="form">
        <div class="row gutters">
            <div class="col">
                <div class="row">
                    <mat-form-field>
                        <mat-label>Company</mat-label>
                        <pgl-search-select
                            placeholder="Company" 
                            label="Company" 
                            formControlName="Customer" 
                            [options]="filteredCustomers$ | async"
                            [filterWith]="filterCustomers"
                            [displayWith]="displayCustomers"
                            startWith="a"
                            autoActiveFirstOption>
                        </pgl-search-select>
                        <mat-error *ngIf="form.get('Customer')?.invalid">{{ getErrorMessage(form.get('Customer')) }}</mat-error>
                    </mat-form-field>
                </div>
                <mat-checkbox color="primary" #showYearRange>Year Range</mat-checkbox>
                <div *ngIf="!showYearRange.checked" class="row">
                    <mat-form-field>
                        <mat-select placeholder="Year" formControlName="Year">
                            <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="showYearRange.checked" class="row gutters">
                    <div class="col">
                        <mat-form-field>
                            <mat-select placeholder="From Year" formControlName="FromYear">
                                <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field>
                            <mat-select placeholder="To Year" formControlName="ToYear">
                                <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <mat-form-field>
                        <mat-select placeholder="Month" formControlName="Month">
                            <mat-option *ngFor="let month of months; index as i" [value]="i + 1">{{ month }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
    <div  *ngIf="downloading" style="width:200px">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <button mat-raised-button (click)="salesReportPDF()" color="primary"><mat-icon>print</mat-icon>Download</button>
</mat-card>
