export class State {
    Name: string
    Code: string
}

export const STATES: State[] = [
    { Name: "Alabama", Code: "AL" },
    { Name: "Alaska", Code: "AK" },
    { Name: "Arizona", Code: "AZ" },
    { Name: "Arkansas", Code: "AR" },
    { Name: "California", Code: "CA" },
    { Name: "Colorado", Code: "CO" },
    { Name: "Connecticut", Code: "CT" },
    { Name: "Delaware", Code: "DE" },
    { Name: "Florida", Code: "FL" },
    { Name: "Georgia", Code: "GA" },
    { Name: "Hawaii", Code: "HI" },
    { Name: "Idaho", Code: "ID" },
    { Name: "Illinois", Code: "IL" },
    { Name: "Indiana", Code: "IN" },
    { Name: "Iowa", Code: "IA" },
    { Name: "Kansas", Code: "KS" },
    { Name: "Kentucky", Code: "KY" },
    { Name: "Louisiana", Code: "LA" },
    { Name: "Maine", Code: "ME" },
    { Name: "Maryland", Code: "MD" },
    { Name: "Massachusetts", Code: "MA" },
    { Name: "Michigan", Code: "MI" },
    { Name: "Minnesota", Code: "MN" },
    { Name: "Mississippi", Code: "MS" },
    { Name: "Missouri", Code: "MO" },
    { Name: "Montana", Code: "MT" },
    { Name: "Nebraska", Code: "NE" },
    { Name: "Nevada", Code: "NV" },
    { Name: "New Hampshire", Code: "NH" },
    { Name: "New Jersey", Code: "NJ" },
    { Name: "New Mexico", Code: "NM" },
    { Name: "New York", Code: "NY" },
    { Name: "North Carolina", Code: "NC" },
    { Name: "North Dakota", Code: "ND" },
    { Name: "Ohio", Code: "OH" },
    { Name: "Oklahoma", Code: "OK" },
    { Name: "Oregon", Code: "OR" },
    { Name: "Pennsylvania", Code: "PA" },
    { Name: "Rhode Island", Code: "RI" },
    { Name: "South Carolina", Code: "SC" },
    { Name: "South Dakota", Code: "SD" },
    { Name: "Tennessee", Code: "TN" },
    { Name: "Texas", Code: "TX" },
    { Name: "Utah", Code: "UT" },
    { Name: "Vermont", Code: "VT" },
    { Name: "Virginia", Code: "VA" },
    { Name: "Washington", Code: "WA" },
    { Name: "West Virginia", Code: "WV" },
    { Name: "Wisconsin", Code: "WI" },
    { Name: "Wyoming", Code: "WY" },
];