<mat-card class="small-form no-print" style="margin-bottom: 30px;">
    <form [formGroup]="form">
        <div class="row gutters">
            <div class="col">
                <div class="row">
                    <mat-form-field>
                        <mat-select placeholder="Frequency" formControlName="StatementFrequency" (selectionChange)="this.form.get('IndividualStatement').patchValue(null)">
                            <mat-option value="Monthly">Monthly</mat-option>
                            <mat-option value="Weekly">Weekly</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <p>or</p>
                <div class="row">
                    <mat-form-field>
                        <mat-label>Select Company Statement</mat-label>
                        <mat-select formControlName="IndividualStatement" (selectionChange)="this.form.get('StatementFrequency').patchValue('')">
                            <mat-option [value]=null>All</mat-option>
                            <mat-option *ngFor="let ic of invoiceCustomers" [value]=ic.ID>{{ic.Company}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
    <div  *ngIf="downloading" style="width:200px">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <button mat-raised-button (click)="statementsPDF()" color="primary"><mat-icon>print</mat-icon>Download</button>
</mat-card>