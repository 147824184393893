import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomerService } from './customer.service';
import { Customer } from './customer';

@Injectable()
export class CustomersResolve implements Resolve<Customer[]> {
    constructor (
        private router: Router,
        private fs: CustomerService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<Customer[]> {
        return this.fs.GetAll();
    }
}