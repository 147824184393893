import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './common/interceptor/httpconfig.interceptor';
import { RouterModule } from '@angular/router';
// import 'hammerjs';
// import { NgxPrintModule } from 'ngx-print';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard';

//import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ReactiveFormsModule } from '@angular/forms';
import { PglSearchSelectModule } from '@pgl-cortex/pgl-search-select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TextFieldModule } from '@angular/cdk/text-field';
import { DatePipe, CommonModule } from '@angular/common';

import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MatMomentDateModule,
    MomentDateAdapter,
    MAT_MOMENT_DATE_FORMATS,
} from '@angular/material-moment-adapter';

import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE
} from '@angular/material/core'

import { FullMaterialModule } from './material-module';

import {
    SearchAutosComponent,
    EditAutoComponent,
    MarkAuctionAutosComponent,
    MarkT158AutosComponent,
    CloseAutosComponent,
    CloseAutosResolve,
    T158AutosResolve,
    AutoService,
    CurrentAutoResolve,
    AutosResolve,
    NextAutoNumberResolve,
    RegistrationRequestAutosResolve,
    NotificationLetterAutosResolve,
    AutosSearchService,
    AutoNumbersResolve
} from './auto';

import {
    SearchInvoicesComponent,
    EditInvoiceComponent,
    InvoicesResolve,
    InvoiceService,
    NextInvoiceNumberResolve,
    CurrentInvoiceResolve,
    StatementsResolve,
    AgingReportResolve,
    InvoicesSearchService
} from './invoice';

import {
    EditPaymentComponent,
    CurrentPaymentResolve,
} from './payment';

import {
    SearchCustomersComponent,
    EditCustomerComponent,
    CustomersResolve,
    CustomerService,
    NextCustomerNumberResolve,
    CurrentCustomerResolve,
    CustomerNumbersResolve,
    AuctionCustomerNamesResolve,
    InvoiceCustomersResolve,
    InvoiceCustomerNumbersResolve,
    CustomersSearchService
} from './customer';

import {
    SearchDriversComponent,
    EditDriverComponent,
    DriversResolve,
    DriverService,
    NextDriverNumberResolve,
    CurrentDriverResolve,
    DriverCommissionsResolve,
    DriverReportsResolve,
    DriversSearchService
} from './driver';

import {
    SearchTransactionsComponent,
    EditTransactionComponent,
    TransactionsResolve,
    TransactionService,
    NextTransactionNumberResolve,
    CurrentTransactionResolve,
    TransactionsSearchService,
} from './transaction';

// import {
//     UsersComponent,
//     EditUserComponent,
//     UsersResolve,
//     UserService
// } from './common/user';

import {
    ManageChargeTypesComponent,
    ChargeTypesResolve,
    ChargeTypeService
} from './charge-type';

import {
    ManageImpoundTypesComponent,
    ImpoundTypesResolve,
    ImpoundTypeService
} from './impound-type';

import {
    ManageStatusDescriptionsComponent,
    StatusDescriptionsResolve,
    StatusDescriptionService,
} from './status-description';

import {
    ManageMakesComponent,
    MakesResolve,
    MakeService,
    EditMakeComponent,
    CurrentMakeResolve,
} from './make';

import {
    ManageAutoModelsComponent,
    AutoModelsResolve,
    AutoModelService,
    EditAutoModelComponent,
    CurrentAutoModelResolve,
} from './auto-model';

import {
    ManageStatusesComponent,
    StatusesResolve,
    StatusService
} from './status';

import {
    FeedsResolve,
    FeedService
} from './feed';

import {
    NotesResolve,
    NoteService
} from './note';

import {
    ManageSettingsComponent,
    SettingService,
    SettingsResolve,
    SettingsToMapResolve
} from './setting';

import {
    CurrentUserResolve,
    EditUserComponent,
    UserRolesResolve,
    UserService,
    UsersComponent,
    UsersResolve
} from './user';

import {
    DailyReportResolve,
    DailyCreditReportResolve,
    DailyDebitReportResolve
} from './daily-report';

import {
    ManageOfficersComponent,
    OfficerService,
    OfficersResolve
} from './officer';

import { NotesDialog, MenuDialog, FeedsDialog } from './common/dialog';

import {
    MV603Component,
    MV603RComponent,
    MV603AComponent,
    ForeclosureOfLienComponent,
    T158Component,
    PrintComponent,
    // ToPDFComponent,
    RegistrationRequestComponent,
    NotificationLetterComponent,
    MV603LettersComponent,
    BillOfSaleComponent,
    AffidavitCourtOrderComponent,
    DailyDebitReportComponent,
    DailyCreditReportComponent,
    DailyCashSalesReportComponent,
    CustomerListingComponent,
    StatementsComponent,
    StatementsPDFComponent,
    AgingReportComponent,
    AgingReportPDFComponent,
    DriverReportComponent,
    DriverCommissionDueReportComponent,
    DriverCommissionDueReportPDFComponent,
    T22BComponent,
    RequestForOwnerInformationComponent,
    PetitionForLienForeclosureComponent,
    MotionForLienForeclosureComponent,
    AMVNoticeComponent,
    TestPDFComponent,
    AutoByDateRangeComponent,
    SalesReportPDFComponent,
} from './print';

import {
    LoginComponent,
    AuthService,
    AuthGuardService,
    LogoutComponent,
    LogoutResolve,
    SignUpComponent,
} from './user_auth';

import {
    EmailComponent,
    AuctionAdEmailResolve,
    AuctionAdPetitionEmailResolve,
    InvoiceEmailResolve
} from './email';

import {
    PDFService,
} from './pdf';

import { LoadingService } from './common/loading.service';

import {
    AppService,
} from './app.service';



@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SearchAutosComponent,
    EditAutoComponent,
    MarkAuctionAutosComponent,
    MarkT158AutosComponent,
    CloseAutosComponent,
    SearchInvoicesComponent,
    EditInvoiceComponent,
    SearchCustomersComponent,
    EditCustomerComponent,
    SearchDriversComponent,
    EditDriverComponent,
    SearchTransactionsComponent,
    EditTransactionComponent,
    UsersComponent,
    EditUserComponent,
    ManageChargeTypesComponent,
    ManageImpoundTypesComponent,
    ManageStatusDescriptionsComponent,
    ManageMakesComponent,
    ManageAutoModelsComponent,
    EditAutoModelComponent,
    ManageStatusesComponent,
    NotesDialog,
    FeedsDialog,
    MenuDialog,
    PrintComponent,
    // ToPDFComponent,
    MV603Component,
    MV603RComponent,
    MV603AComponent,
    ForeclosureOfLienComponent,
    RegistrationRequestComponent,
    NotificationLetterComponent,
    T158Component,
    MV603LettersComponent,
    BillOfSaleComponent,
    ManageSettingsComponent,
    AffidavitCourtOrderComponent,
    DailyDebitReportComponent,
    DailyCreditReportComponent,
    DailyCashSalesReportComponent,
    CustomerListingComponent,
    StatementsComponent,
    StatementsPDFComponent,
    AgingReportComponent,
    AgingReportPDFComponent,
    DriverReportComponent,
    DriverCommissionDueReportComponent,
    DriverCommissionDueReportPDFComponent,
    ManageOfficersComponent,
    LoginComponent,
    LogoutComponent,
    EmailComponent,
    EditPaymentComponent,
    T22BComponent,
    RequestForOwnerInformationComponent,
    PetitionForLienForeclosureComponent,
    MotionForLienForeclosureComponent,
    AMVNoticeComponent,
    TestPDFComponent,
    SignUpComponent,
    AutoByDateRangeComponent,
    SalesReportPDFComponent,
    EditMakeComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    ReactiveFormsModule,
    // PdfViewerModule,
    // NgxPrintModule,
    NgxMatSelectSearchModule,
    PglSearchSelectModule,
    TextFieldModule,
    FullMaterialModule,
    CommonModule
  ],
  entryComponents: [
      NotesDialog,
      FeedsDialog,
      MenuDialog
  ],
  providers: [
      {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true}},
      {
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
      },
      {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
      {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
      AppService,
      AutoService,
      CurrentAutoResolve,
      AutosResolve,
      NextAutoNumberResolve,
      InvoicesResolve,
      InvoiceService,
      CustomersResolve,
      CustomerService,
      DriversResolve,
      DriverService,
      TransactionsResolve,
      TransactionService,
      UsersResolve,
      UserService,
      CurrentUserResolve,
      CurrentAutoModelResolve,
      UserRolesResolve,
      ChargeTypesResolve,
      ChargeTypeService,
      ImpoundTypesResolve,
      ImpoundTypeService,
      StatusDescriptionService,
      StatusDescriptionsResolve,
      MakesResolve,
      MakeService,
      AutoModelsResolve,
      AutoModelService,
      StatusesResolve,
      StatusService,
      FeedsResolve,
      FeedService,
      NotesResolve,
      NoteService,
      RegistrationRequestAutosResolve,
      NotificationLetterAutosResolve,
      NextCustomerNumberResolve,
      CurrentCustomerResolve,
      NextTransactionNumberResolve,
      AutoNumbersResolve,
      CustomerNumbersResolve,
      CurrentTransactionResolve,
      NextDriverNumberResolve,
      CurrentDriverResolve,
      NextInvoiceNumberResolve,
      DriverCommissionsResolve,
      CurrentInvoiceResolve,
      SettingService,
      SettingsResolve,
      SettingsToMapResolve,
      CloseAutosResolve,
      T158AutosResolve,
      AuctionCustomerNamesResolve,
      DailyDebitReportResolve,
      DailyCreditReportResolve,
      InvoiceCustomersResolve,
      StatementsResolve,
      AgingReportResolve,
      DriverReportsResolve,
      InvoiceCustomerNumbersResolve,
      OfficerService,
      OfficersResolve,
      AuthService,
      AuthGuardService,
      LogoutResolve,
      AuctionAdEmailResolve,
      AuctionAdPetitionEmailResolve,
      AutosSearchService,
      CustomersSearchService,
      DriversSearchService,
      InvoicesSearchService,
      TransactionsSearchService,
      LoadingService,
      CurrentPaymentResolve,
      InvoiceEmailResolve,
      DatePipe,
      PDFService,
      CurrentMakeResolve,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
