<button class="print-button" mat-raised-button (click)="print()" color="primary" style="margin-bottom: 10px;"><mat-icon>print</mat-icon>Print</button>

<div *ngIf="!(currentAuto.Owners.length > 0)">
    <p style="text-align: center; font-size: 20px;margin-bottom: 10px;">There are no owners for this vehicle</p>
</div>
<mat-card class="petition-for-lien-foreclosure-form small-form hide-in-print" style="margin-bottom: 30px;">
    <!-- <h1 class="title">{{ title }}</h1> -->
    <form [formGroup]="form" novalidate>
        <mat-card-content>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="DateFiled" placeholder="Filed Date" formControlName="DateFiled">
                        <mat-datepicker-toggle matSuffix [for]="DateFiled"></mat-datepicker-toggle>
                        <mat-datepicker #DateFiled></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="DateVehicleWasTowed" placeholder="Date Vehicle was Towed" formControlName="DateVehicleWasTowed">
                        <mat-datepicker-toggle matSuffix [for]="DateVehicleWasTowed"></mat-datepicker-toggle>
                        <mat-datepicker #DateVehicleWasTowed></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="DateInformationRequested" placeholder="Date Information was Requested" formControlName="DateInformationRequested">
                        <mat-datepicker-toggle matSuffix [for]="DateInformationRequested"></mat-datepicker-toggle>
                        <mat-datepicker #DateInformationRequested></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="DateOfFirstNotice" placeholder="Date of First Notice" formControlName="DateOfFirstNotice">
                        <mat-datepicker-toggle matSuffix [for]="DateOfFirstNotice"></mat-datepicker-toggle>
                        <mat-datepicker #DateOfFirstNotice></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <mat-label>Method of First Notice</mat-label>
                        <mat-select formControlName="MethodOfFirstNotice">
                            <mat-option value="mailed">Mailed</mat-option>
                            <mat-option value="hand_delivery">Hand Delivery</mat-option>
                            <mat-option value="advertised">Advertised</mat-option>
                            <mat-option value="disclaimer">Disclaimer</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput type="number" placeholder="Removal Fee" formControlName="RemovalFee" required>
                            <mat-error *ngIf="form.get('RemovalFee')?.invalid">{{ getErrorMessage(form.get('RemovalFee')) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input matInput type="number" placeholder="Storage Per Day" formControlName="StoragePerDay" required>
                            <mat-error *ngIf="form.get('StoragePerDay')?.invalid">{{ getErrorMessage(form.get('StoragePerDay')) }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput type="number" placeholder="Days Stored" formControlName="DaysStored" required>
                            <mat-error *ngIf="form.get('DaysStored')?.invalid">{{ getErrorMessage(form.get('DaysStored')) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input matInput type="number" placeholder="Storage Total" formControlName="StorageTotal" required>
                            <mat-error *ngIf="form.get('StorageTotal')?.invalid">{{ getErrorMessage(form.get('StorageTotal')) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input matInput type="number" placeholder="Future Storage Fee" formControlName="FutureStorageFee" required>
                            <mat-error *ngIf="form.get('FutureStorageFee')?.invalid">{{ getErrorMessage(form.get('FutureStorageFee')) }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput type="number" placeholder="Cost of Obtaining Owner Information" formControlName="CostOfInformation" required>
                            <mat-error *ngIf="form.get('CostOfInformation')?.invalid">{{ getErrorMessage(form.get('CostOfInformation')) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input matInput type="number" placeholder="Repair Fees" formControlName="RepairFees" required>
                            <mat-error *ngIf="form.get('RepairFees')?.invalid">{{ getErrorMessage(form.get('RepairFees')) }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput type="number" placeholder="Notification/Advertising Fees" formControlName="AdvertisementFees" required>
                            <mat-error *ngIf="form.get('AdvertisementFees')?.invalid">{{ getErrorMessage(form.get('AdvertisementFees')) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input matInput type="number" placeholder="Total" formControlName="Total" required>
                            <mat-error *ngIf="form.get('Total')?.invalid">{{ getErrorMessage(form.get('Total')) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions end>
        </mat-card-actions>
    </form>
</mat-card>
<div id="print-section">
    <div *ngFor="let owner of currentAuto.Owners">
        <div class="amv-lien-foreclosure">
            <p style="page-break-before: always; margin-bottom: 0;"></p>
            <div class="petition" style="position: relative; padding: 0; height: 822px; box-shadow: none;">

<html>
<head><meta http-equiv=Content-Type content="text/html; charset=UTF-8">
<style type="text/css">
span.cls_003{font-family:Times,serif;font-size:14.1px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_003{font-family:Times,serif;font-size:14.1px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_005{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_005{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_009{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: underline}
div.cls_009{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_006{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_006{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_010{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: underline}
div.cls_010{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_007{font-family:Times,serif;font-size:9.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_007{font-family:Times,serif;font-size:9.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_008{font-family:Times,serif;font-size:8.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_008{font-family:Times,serif;font-size:8.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_002{font-family:Times,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_002{font-family:Times,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
</style>
<script type="text/javascript" src="79c120c2-f10a-11e9-9d71-0cc47a792c0a_id_79c120c2-f10a-11e9-9d71-0cc47a792c0a_files/wz_jsgraphics.js"></script>
</head>
<body>
<div style="position:absolute;left:50%;margin-left:-306px;top:0px;width:612px;height:792px;border-style:outset;overflow:hidden">
<div style="position:absolute;left:510px;top:25px" class="cls_009"><span class="cls_005">{{ fields.AutoNumber }}</span></div>
<div style="position:absolute;left:277px;top:48.60px" class="cls_003"><span class="cls_003">{{ fields.CompanyCounty }}</span></div>
<div style="position:absolute;left:101.18px;top:48.60px" class="cls_003"><span class="cls_003">MAGISTRATE COURT OF ______________COUNTY, GEORGIA</span></div>
<div style="position:absolute;left:81.00px;top:76.58px" class="cls_005"><span class="cls_005">{{ form.get('DateFiled').value | date:'shortDate':'utc' }}</span></div>
<div style="position:absolute;left:36.00px;top:76.58px" class="cls_005"><span class="cls_005">Date Filed ________________</span></div>
<div style="position:absolute;left:324.07px;top:76.58px" class="cls_005"><span class="cls_005">Case No: _______________</span></div>
<div style="position:absolute;left:365px;top:76.58px" class="cls_005"><span class="cls_005">{{ fields.AutoCaseNumber }}</span></div>
<div style="position:absolute;left:36px;top:101.90px" class="cls_009"><span class="cls_009">{{ fields.CompanyName }}</span></div>
<div style="position:absolute;left:36px;top:101.90px" class="cls_009"><span class="cls_009">__________________________________________</span></div>
<div style="position:absolute;left:36px;top:119.18px" class="cls_009"><span class="cls_009">{{ fields.CompanyAddress }}</span></div>
<div style="position:absolute;left:36px;top:119.18px" class="cls_009"><span class="cls_009">__________________________________________</span></div>
<div style="position:absolute;left:36px;top:136.46px" class="cls_009"><span class="cls_009">{{ fields.CompanyCityStateZip }}</span></div>
<div style="position:absolute;left:36px;top:136.46px" class="cls_009"><span class="cls_009">__________________________________________</span></div>
<div style="position:absolute;left:36.00px;top:153.62px" class="cls_006"><span class="cls_006">Plaintiff(s) Name, Address</span></div>
<div style="position:absolute;left:264.08px;top:155.51px" class="cls_006"><span class="cls_006">vs</span></div>
<div style="position:absolute;left:288.07px;top:101.90px" class="cls_009"><span class="cls_009">{{ getName( owner.Company, owner.FirstName, owner.LastName) }}</span></div>
<div style="position:absolute;left:288.07px;top:101.90px" class="cls_009"><span class="cls_009">____________________________________________________</span></div>
<div style="position:absolute;left:288.07px;top:119.18px" class="cls_009"><span class="cls_009">{{ getAddress( owner.Address1, owner.Address2) }}</span></div>
<div style="position:absolute;left:288.07px;top:119.18px" class="cls_009"><span class="cls_009">____________________________________________________</span></div>
<div style="position:absolute;left:288.07px;top:136.46px" class="cls_009"><span class="cls_009">{{ getCityStateZip( owner.City, owner.State, owner.Zip ) }}</span></div>
<div style="position:absolute;left:288.07px;top:136.46px" class="cls_009"><span class="cls_009">____________________________________________________</span></div>
<div style="position:absolute;left:288.84px;top:155.69px" class="cls_006"><span class="cls_006">Vehicle Owner Name, Address</span></div>
<div style="position:absolute;left:36px;top:176.66px" class="cls_009"><span class="cls_009">{{ fields.AutoYearMakeModel }}</span></div>
<div style="position:absolute;left:36px;top:176.66px" class="cls_009"><span class="cls_009">__________________________________________</span></div>
<div style="position:absolute;left:36px;top:193.94px" class="cls_009"><span class="cls_009">{{ fields.AutoTagState }}</span></div>
<div style="position:absolute;left:36px;top:193.94px" class="cls_009"><span class="cls_009">__________________________________________</span></div>
<div style="position:absolute;left:36px;top:211.10px" class="cls_009"><span class="cls_009">{{ fields.AutoVin }}</span></div>
<div style="position:absolute;left:36px;top:211.10px" class="cls_009"><span class="cls_009">__________________________________________</span></div>
<div style="position:absolute;left:36.00px;top:228.38px" class="cls_006"><span class="cls_006">Motor Vehicle Description and VIN</span></div>
<div style="position:absolute;left:288.07px;top:176.66px" class="cls_009"><span class="cls_009">{{ fields.Lienholder1Name }}</span></div>
<div style="position:absolute;left:288.07px;top:176.66px" class="cls_009"><span class="cls_009">____________________________________________________</span></div>
<div style="position:absolute;left:288.07px;top:193.94px" class="cls_009"><span class="cls_009">{{ fields.Lienholder2Name }}</span></div>
<div style="position:absolute;left:288.07px;top:193.94px" class="cls_009"><span class="cls_009">____________________________________________________</span></div>
<div style="position:absolute;left:288.07px;top:211.10px" class="cls_009"><span class="cls_009">____________________________________________________</span></div>

<div style="position:absolute;left:289.53px;top:228.38px" class="cls_006"><span class="cls_006">Lien Holders Notified</span></div>
<div style="position:absolute;left:138.50px;top:251.45px" class="cls_006"><span class="cls_006">PETITION FOR ABANDONED MOTOR VEHICLE LIEN FORECLOSURE</span></div>
<div style="position:absolute;left:36.00px;top:262.85px" class="cls_009"><span class="cls_009">____________________________________________________________________________________________________________</span></div>
<div style="position:absolute;left:36.00px;top:285.89px" class="cls_005"><span class="cls_005">NOW COMES PETITIONER, a [ {{ fields.Mark }} ] towing or storage facility [ ] repair facility [ ] salvage dealer, and files this petition to foreclose on</span></div>
<div style="position:absolute;left:36.00px;top:297.41px" class="cls_005"><span class="cls_005">the above-referenced abandoned motor vehicle.</span></div>
<div style="position:absolute;left:36px;top:320.45px" class="cls_005"><span class="cls_005">{{ form.get('DateVehicleWasTowed').value | date:'shortDate':'utc'}}</span></div>
<div style="position:absolute;left:36px;top:331.45px" class="cls_005"><span class="cls_005">{{ form.get('DateInformationRequested').value | date:'shortDate':'utc'}}</span></div>
<div style="position:absolute;left:36px;top:343.45px" class="cls_005"><span class="cls_005">{{ form.get('DateOfFirstNotice').value | date:'shortDate':'utc'}}</span></div>
<div style="position:absolute;left:36px;top:320.45px" class="cls_005"><span class="cls_005">__________</span></div>
<div style="position:absolute;left:91.10px;top:320.45px" class="cls_005"><span class="cls_005">Date vehicle was [ {{ fields.Mark }} ] towed [ ] left at repair facility [ ] left with salvage dealer (authority to tow attached)</span></div>
<div style="position:absolute;left:36px;top:331.85px" class="cls_005"><span class="cls_005">__________</span></div>
<div style="position:absolute;left:91.10px;top:331.85px" class="cls_005"><span class="cls_005">Date information was requested from Department of Revenue or authorized entity (copy of owner information attached)</span></div>
<div style="position:absolute;left:36px;top:343.37px" class="cls_005"><span class="cls_005">__________</span></div>
<div style="position:absolute;left:91.10px;top:343.37px" class="cls_005"><span class="cls_005">Date and method of first notice [ <span *ngIf="this.form.get('MethodOfFirstNotice').value == 'mailed'">{{ fields.Mark }}</span> ] mailed [ <span *ngIf="this.form.get('MethodOfFirstNotice').value == 'hand_delivery'">{{ fields.Mark }}</span> ] hand delivery [ <span *ngIf="this.form.get('MethodOfFirstNotice').value == 'advertised'">{{ fields.Mark }}</span> ] advertised [ <span *ngIf="this.form.get('MethodOfFirstNotice').value == 'disclaimer'">{{ fields.Mark }}</span> ] disclaimer (proof of compliance attached for</span></div>
<div style="position:absolute;left:36.00px;top:354.89px" class="cls_005"><span class="cls_005">all owners)</span></div>
<div style="position:absolute;left:36.00px;top:377.93px" class="cls_005"><span class="cls_005">The accrued fees allowed under OCGA § 40-11-19, as set forth below, have not been paid.</span></div>
<div style="position:absolute;left:36.00px;top:400.85px" class="cls_005"><span class="cls_005">PETITIONER PRAYS that the lien be foreclosed and an order be issued to allow for public sale of the vehicle to satisfy a lien for the</span></div>
<div style="position:absolute;left:36.00px;top:412.39px" class="cls_005"><span class="cls_005">following amounts:</span></div>
<div style="position:absolute;left:72.02px;top:435.43px" class="cls_005"><span class="cls_005">Removal (Towing) Fee:</span></div>
<div style="position:absolute;left:288.07px;top:435.43px" class="cls_005"><span class="cls_005">{{ form.get('RemovalFee').value | currency }}</span></div>
<div style="position:absolute;left:288.07px;top:435.43px" class="cls_005"><span class="cls_005">_____________</span></div>
<div style="position:absolute;left:123.02px;top:446.95px" class="cls_005"><span class="cls_005">{{ form.get('StoragePerDay').value | currency }}</span></div>
<div style="position:absolute;left:72.02px;top:446.95px" class="cls_005"><span class="cls_005">Storage: (At </span><span class="cls_010">________    </span><span class="cls_005"> per day for</span></div>
<div style="position:absolute;left:225.09px;top:446.95px" class="cls_005"><span class="cls_005">{{ form.get('DaysStored').value }}</span></div>
<div style="position:absolute;left:225.09px;top:446.95px" class="cls_005"><span class="cls_005">____ days)</span></div>
<div style="position:absolute;left:288.07px;top:446.95px" class="cls_005"><span class="cls_005">{{ form.get('StorageTotal').value | currency }}</span></div>
<div style="position:absolute;left:288.07px;top:446.95px" class="cls_005"><span class="cls_005">_____________</span></div>
<div style="position:absolute;left:72.02px;top:458.35px" class="cls_005"><span class="cls_005">Future Storage Fee</span></div>
<div style="position:absolute;left:288.07px;top:458.35px" class="cls_005"><span class="cls_005">{{ form.get('FutureStorageFee').value | currency }}</span></div>
<div style="position:absolute;left:288.07px;top:458.35px" class="cls_005"><span class="cls_005">_____________</span></div>
<div style="position:absolute;left:362.59px;top:458.35px" class="cls_005"><span class="cls_005">per day from date of filing this action through 25</span></div>
<div style="position:absolute;left:288.07px;top:469.87px" class="cls_005"><span class="cls_005">days after judgment is rendered</span></div>
<div style="position:absolute;left:72.02px;top:481.39px" class="cls_005"><span class="cls_005">Repair Fees (if applicable, with invoice attached):</span></div>
<div style="position:absolute;left:288.07px;top:481.39px" class="cls_005"><span class="cls_005">{{ form.get('RepairFees').value | currency }}</span></div>
<div style="position:absolute;left:288.07px;top:481.39px" class="cls_005"><span class="cls_005">_____________</span></div>
<div style="position:absolute;left:72.02px;top:492.91px" class="cls_005"><span class="cls_005">Cost of Obtaining Owner Information:</span></div>
<div style="position:absolute;left:288.07px;top:492.91px" class="cls_005"><span class="cls_005">{{ form.get('CostOfInformation').value | currency }}</span></div>
<div style="position:absolute;left:288.07px;top:492.91px" class="cls_005"><span class="cls_005">_____________</span></div>
<div style="position:absolute;left:72.02px;top:504.43px" class="cls_005"><span class="cls_005">Notification/Advertising Fees:</span></div>
<div style="position:absolute;left:288.07px;top:504.43px" class="cls_005"><span class="cls_005">{{ form.get('AdvertisementFees').value | currency }}</span></div>
<div style="position:absolute;left:288.07px;top:504.43px" class="cls_005"><span class="cls_005">_____________</span></div>
<div style="position:absolute;left:72.02px;top:515.95px" class="cls_010"><span class="cls_010">TOTAL:</span></div>
<div style="position:absolute;left:288.07px;top:515.95px" class="cls_005"><span class="cls_005">{{ form.get('Total').value | currency }}</span></div>
<div style="position:absolute;left:288.07px;top:515.95px" class="cls_005"><span class="cls_005">_____________</span></div>
<div style="position:absolute;left:36.00px;top:538.87px" class="cls_005"><span class="cls_005">PETITIONER ALSO PRAYS for prejudgment interest at the rate of 4% per annum from which the OCGA § 40-11-19 notification</span></div>
<div style="position:absolute;left:36.00px;top:550.39px" class="cls_005"><span class="cls_005">was received, 15% attorney’s fees (if applicable), and all costs of court.</span></div>
<div style="position:absolute;left:36.00px;top:573.43px" class="cls_007"><span class="cls_007">Sworn to and Subscribed before me</span></div>
<div style="position:absolute;left:60px;top:583.78px" class="cls_007"><span class="cls_007">{{ this.form.get('DateFiled').value | date:'d':'utc' }}</span></div>
<div style="position:absolute;left:109px;top:583.78px" class="cls_007"><span class="cls_007">{{ this.form.get('DateFiled').value | date:'MMMM':'utc' }}</span></div>
<div style="position:absolute;left:183px;top:583.78px" class="cls_007"><span class="cls_007">{{ this.form.get('DateFiled').value | date:'yy':'utc' }}</span></div>
<div style="position:absolute;left:36.00px;top:583.78px" class="cls_007"><span class="cls_007">This ____ day of_____________20_____</span></div>
<div style="position:absolute;left:36.00px;top:603px" class="cls_008"><span class="cls_007">__________________________________</span></div>
<div style="position:absolute;left:36.00px;top:614.98px" class="cls_008"><span class="cls_008">Notary Public or Clerk</span></div>
<div style="position:absolute;left:253px;top:603px" class="cls_008"><span class="cls_007">_____________________________________________________</span></div>
<div style="position:absolute;left:288.07px;top:614.98px" class="cls_008"><span class="cls_008">Plaintiff/Attorney for Plaintiff</span></div>
<div style="position:absolute;left:36.00px;top:624.10px" class="cls_008"><span class="cls_008">My Commission Expires:</span></div>
<div style="position:absolute;left:123px;top:624.10px" class="cls_008"><span class="cls_007">_________________</span></div>
<div style="position:absolute;left:275.69px;top:646.78px" class="cls_002"><span class="cls_002">SUMMONS</span></div>
<div style="position:absolute;left:36.00px;top:674.38px" class="cls_002"><span class="cls_002">YOU MUST FILE AN ANSWER TO THIS CLAIM NO LATER THAN TEN (10) DAYS FROM RECEIPT</span></div>
<div style="position:absolute;left:36.00px;top:688.18px" class="cls_002"><span class="cls_002">OF THIS NOTICE USING THE STANDARDIZED ANSWER FORM PROVIDED.  FAILURE TO FILE A</span></div>
<div style="position:absolute;left:36.00px;top:701.98px" class="cls_002"><span class="cls_002">TIMELY  ANSWER  WILL  RESULT  IN  FORECLOSURE  OF  THE  LIEN  AND  A  COURT  ORDER</span></div>
<div style="position:absolute;left:36.00px;top:715.78px" class="cls_002"><span class="cls_002">AUTHORIZING DISPOSITION OF THE VEHICLE PURSUANT TO OCGA 40-11-19.2.</span></div>
<div style="position:absolute;left:36.00px;top:763.92px" class="cls_002"><span class="cls_002">MAG 40-01</span></div>
</div>

</body>
</html>


            </div>
            <p style="page-break-before: always; margin-bottom: 0;"></p>
            <div class="answer" style="position: relative; height: 822px;">

                <html>
<head><meta http-equiv=Content-Type content="text/html; charset=UTF-8">
<style type="text/css">
span.cls_003{font-family:Times,serif;font-size:14.1px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_003{font-family:Times,serif;font-size:14.1px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_005{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_005{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_007{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: underline}
div.cls_007{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_006{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_006{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_002{font-family:Times,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_002{font-family:Times,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
</style>
<script type="text/javascript" src="4612ee9e-f10b-11e9-9d71-0cc47a792c0a_id_4612ee9e-f10b-11e9-9d71-0cc47a792c0a_files/wz_jsgraphics.js"></script>
</head>
<body>
<div style="position:absolute;left:50%;margin-left:-306px;top:0px;width:612px;height:792px;border-style:outset;overflow:hidden">
<!-- <div style="position:absolute;left:0px;top:0px">
<img src="4612ee9e-f10b-11e9-9d71-0cc47a792c0a_id_4612ee9e-f10b-11e9-9d71-0cc47a792c0a_files/background1.jpg" width=612 height=792></div> -->
<div style="position:absolute;left:510px;top:25px" class="cls_009"><span class="cls_005">{{ fields.AutoNumber }}</span></div>
<div style="position:absolute;left:277px;top:48.60px" class="cls_003"><span class="cls_003">{{ fields.CompanyCounty }}</span></div>
<div style="position:absolute;left:101.18px;top:48.60px" class="cls_003"><span class="cls_003">MAGISTRATE COURT OF ______________COUNTY, GEORGIA</span></div>
<div style="position:absolute;left:81.00px;top:76.58px" class="cls_005"><span class="cls_005"></span></div>
<div style="position:absolute;left:36.00px;top:76.58px" class="cls_005"><span class="cls_005">Date Filed ________________</span></div>
<div style="position:absolute;left:324.07px;top:76.58px" class="cls_005"><span class="cls_005">Case No: _______________</span></div>
<div style="position:absolute;left:365.07px;top:76.58px" class="cls_005"><span class="cls_005">{{ fields.AutoCaseNumber }}</span></div>
<div style="position:absolute;left:36px;top:101.90px" class="cls_009"><span class="cls_009">{{ fields.CompanyName }}</span></div>
<div style="position:absolute;left:36px;top:101.90px" class="cls_009"><span class="cls_009">__________________________________________</span></div>
<div style="position:absolute;left:36px;top:119.18px" class="cls_009"><span class="cls_009">{{ fields.CompanyAddress }}</span></div>
<div style="position:absolute;left:36px;top:119.18px" class="cls_009"><span class="cls_009">__________________________________________</span></div>
<div style="position:absolute;left:36px;top:136.46px" class="cls_009"><span class="cls_009">{{ fields.CompanyCityStateZip }}</span></div>
<div style="position:absolute;left:36px;top:136.46px" class="cls_009"><span class="cls_009">__________________________________________</span></div>
<div style="position:absolute;left:36.00px;top:153.62px" class="cls_006"><span class="cls_006">Plaintiff(s) Name, Address</span></div>
<div style="position:absolute;left:264.08px;top:155.51px" class="cls_006"><span class="cls_006">vs</span></div>
<div style="position:absolute;left:288.07px;top:101.90px" class="cls_009"><span class="cls_009">{{ getName( owner.Company, owner.FirstName, owner.LastName ) }}</span></div>
<div style="position:absolute;left:288.07px;top:101.90px" class="cls_009"><span class="cls_009">____________________________________________________</span></div>
<div style="position:absolute;left:288.07px;top:119.18px" class="cls_009"><span class="cls_009">{{ getAddress( owner.Address1, owner.Address2 ) }}</span></div>
<div style="position:absolute;left:288.07px;top:119.18px" class="cls_009"><span class="cls_009">____________________________________________________</span></div>
<div style="position:absolute;left:288.07px;top:136.46px" class="cls_009"><span class="cls_009">{{ getCityStateZip( owner.City, owner.State, owner.Zip ) }}</span></div>
<div style="position:absolute;left:288.07px;top:136.46px" class="cls_009"><span class="cls_009">____________________________________________________</span></div>
<div style="position:absolute;left:288.84px;top:155.69px" class="cls_006"><span class="cls_006">Vehicle Owner Name, Address</span></div>
<div style="position:absolute;left:36px;top:176.66px" class="cls_009"><span class="cls_009">{{ fields.AutoYearMakeModel }}</span></div>
<div style="position:absolute;left:36px;top:176.66px" class="cls_009"><span class="cls_009">__________________________________________</span></div>
<div style="position:absolute;left:36px;top:193.94px" class="cls_009"><span class="cls_009">{{ fields.AutoTagState }}</span></div>
<div style="position:absolute;left:36px;top:193.94px" class="cls_009"><span class="cls_009">__________________________________________</span></div>
<div style="position:absolute;left:36px;top:211.10px" class="cls_009"><span class="cls_009">{{ fields.AutoVin }}</span></div>
<div style="position:absolute;left:36px;top:211.10px" class="cls_009"><span class="cls_009">__________________________________________</span></div>
<div style="position:absolute;left:36.00px;top:228.38px" class="cls_006"><span class="cls_006">Motor Vehicle Description and VIN</span></div>
<div style="position:absolute;left:288.07px;top:176.66px" class="cls_009"><span class="cls_009">{{ fields.Lienholder1Name }}</span></div>
<div style="position:absolute;left:288.07px;top:176.66px" class="cls_009"><span class="cls_009">____________________________________________________</span></div>
<div style="position:absolute;left:288.07px;top:193.94px" class="cls_009"><span class="cls_009">{{ fields.Lienholder2Name }}</span></div>
<div style="position:absolute;left:288.07px;top:193.94px" class="cls_009"><span class="cls_009">____________________________________________________</span></div>
<div style="position:absolute;left:288.07px;top:211.10px" class="cls_009"><span class="cls_009">____________________________________________________</span></div>
<div style="position:absolute;left:286.16px;top:228.38px" class="cls_006"><span class="cls_006">Lien Holders Notified</span></div>
<div style="position:absolute;left:36.00px;top:239.90px" class="cls_007"><span class="cls_007">____________________________________________________________________________________________________________</span></div>
<div style="position:absolute;left:106.70px;top:262.85px" class="cls_006"><span class="cls_006">ANSWER TO PETITION FOR ABANDONED MOTOR VEHICLE LIEN FORECLOSURE</span></div>
<div style="position:absolute;left:36.00px;top:285.89px" class="cls_005"><span class="cls_005">NOW COMES RESPONDENT, Owner of the above-referenced vehicle, and files this answer to the petition to foreclose lien on the</span></div>
<div style="position:absolute;left:36.00px;top:297.41px" class="cls_005"><span class="cls_005">above-referenced abandoned motor vehicle.</span></div>
<div style="position:absolute;left:36.00px;top:320.45px" class="cls_005"><span class="cls_005">Owner Name:</span></div>
<div style="position:absolute;left:108.02px;top:320.45px" class="cls_005"><span class="cls_005">_________________________________________</span></div>
<div style="position:absolute;left:36.00px;top:331.85px" class="cls_005"><span class="cls_005">Owner Address:</span></div>
<div style="position:absolute;left:108.02px;top:331.85px" class="cls_005"><span class="cls_005">_________________________________________</span></div>
<div style="position:absolute;left:36.00px;top:366.41px" class="cls_005"><span class="cls_005">Date owner obtained an interest in the motor vehicle: ____________________</span></div>
<div style="position:absolute;left:36.00px;top:389.45px" class="cls_005"><span class="cls_005">Check all that apply, and explain in the space provided (attach additional sheets if necessary).</span></div>
<div style="position:absolute;left:91.10px;top:412.39px" class="cls_005"><span class="cls_005">[ ] The towing or storage firm was not authorized to remove the vehicle for the following reason(s):</span></div>
<div style="position:absolute;left:91.10px;top:458.35px" class="cls_005"><span class="cls_005">[ ] The lien claimed is not valid because:</span></div>
<div style="position:absolute;left:92.06px;top:504.43px" class="cls_005"><span class="cls_005">[ ] Additional facts that support why the lien should not be foreclosed upon:</span></div>
<div style="position:absolute;left:92.06px;top:550.39px" class="cls_005"><span class="cls_005">[ ] I dispute the following charges:</span></div>
<div style="position:absolute;left:36.00px;top:607.90px" class="cls_005"><span class="cls_005">THEREFORE, Respondent prays for a hearing on all relevant issues within ten (10) days.</span></div>
<div style="position:absolute;left:36.00px;top:642.46px" class="cls_005"><span class="cls_005">Sworn to and Subscribed before me</span></div>
<div style="position:absolute;left:36.00px;top:653.98px" class="cls_005"><span class="cls_005">This ____ day of_____________20_____</span></div>
<div style="position:absolute;left:36px;top:676.90px" class="cls_005"><span class="cls_005">__________________________________</span></div>
<div style="position:absolute;left:36.00px;top:688.42px" class="cls_005"><span class="cls_005">Notary Public or Clerk</span></div>
<div style="position:absolute;left:288.07px;top:677px" class="cls_005"><span class="cls_005">___________________________________________</span></div>
<div style="position:absolute;left:288.07px;top:688.42px" class="cls_005"><span class="cls_005">Defendant/Attorney for Defendant</span></div>
<div style="position:absolute;left:36.00px;top:699.94px" class="cls_005"><span class="cls_005">My Commission Expires:</span></div>
<div style="position:absolute;left:144px;top:699.94px" class="cls_005"><span class="cls_005">_____________</span></div>
<div style="position:absolute;left:36.00px;top:763.92px" class="cls_002"><span class="cls_002">MAG 40-02</span></div>
</div>

</body>
</html>


            </div>
        </div>
    </div>
</div>
