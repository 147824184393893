import { Model } from '../common/model';

export class Make extends Model {

    ID: number;
    Name: string;
    Active: boolean;
    Created: Date;
    Modified: Date;

    constructor(properties?: Object) {
        super(properties)
    }
}