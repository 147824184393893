export { Auto } from './auto';
export { AUTO_ROUTES } from './auto.routes';
export { SearchAutosComponent } from './search-autos.component';
export { EditAutoComponent } from './edit-auto.component';
export { MarkAuctionAutosComponent } from './mark-auction-autos.component';
export { MarkT158AutosComponent } from './mark-t158-autos.component';
export { CloseAutosComponent } from './close-autos.component';
export { AutoService } from './auto.service';
export { CurrentAutoResolve } from './current-auto.resolve';
export { AutosResolve } from './autos.resolve';
export { NextAutoNumberResolve } from './next-auto-number.resolve';
export { RegistrationRequestAutosResolve } from './registration-request-autos.resolve';
export { NotificationLetterAutosResolve } from './notification-letter-autos.resolve';
export { AutoNumbersResolve } from './auto-numbers.resolve';
export { CloseAutosResolve } from './close-autos.resolve';
export { T158AutosResolve } from './t158-autos.resolve';
export { AutosSearchService } from './autos-search.service';