<mat-card class="login small-form">
        <h1 class="title">{{ title }}</h1>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
        <mat-card-content>
            <div class="row">
                <div class="col">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Username" formControlName="Username">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field>
                        <input matInput type="password" placeholder="Password" formControlName="Password">
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions end>
            <button type="button" mat-raised-button color="primary" (click)="signUp()"><mat-icon>person_add</mat-icon>Sign Up</button>
            <button type="submit" mat-raised-button color="primary"><mat-icon>verified_user</mat-icon>Login</button>
        </mat-card-actions>
    </form>
</mat-card>
<pre class="debug">{{ form.value | json }}</pre>