import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from '../common/dev/log';
import { ChargeType } from './charge-type';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ChargeTypeService } from './charge-type.service';
import { AppService } from '../app.service'

@Component({
    selector: "manage-charge-types",
    templateUrl: "./manage-charge-types.component.html"
})

export class ManageChargeTypesComponent implements OnInit {

    title: string;
    chargeTypes: ChargeType[];

    form: FormGroup;

    autoIncludeChargeTypes: number[] = [1,2,7,8];

    constructor (
        private route: ActivatedRoute,
        private cts: ChargeTypeService,
        private apps: AppService,

    ){
        this.form = this.createFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            chargeTypes: ChargeType[]
        }) => {
            log.Debug("data: ", data)
            this.title = data.title;
            this.chargeTypes = data.chargeTypes
            for (let cT in this.chargeTypes) {
                this.addChargeType();
            }
            this.form.get('ChargeTypes').patchValue(this.chargeTypes);
        })
    }

    createFormGroup() {
        return new FormGroup({
            ChargeTypes: new FormArray([])
        })
    }

    createChargeTypeFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            Name: new FormControl(null, Validators.required)
        })
    }

    addChargeType(): void {
        let chargeTypes = this.form.get('ChargeTypes') as FormArray;
        chargeTypes.push(this.createChargeTypeFormGroup());
    }
    removeChargeType(i: number): void {
        let chargeTypes = this.form.get('ChargeTypes') as FormArray;
        chargeTypes.removeAt(i);
        this.chargeTypes.splice(i, 1)
    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }

    onSubmit(e): void {

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched: ", this.form)
            return;
        }

        this.save();
    }

    save() {
        this.cts.save(this.form.get('ChargeTypes').value)
        .subscribe((savedChargeTypes: ChargeType[]) => {
            this.apps.Confirmation('Charge Types saved.');
            this.form.markAsPristine();

            log.Debug("savedChargeTypes: ", savedChargeTypes)
            this.form.get('ChargeTypes').patchValue(savedChargeTypes);
            this.chargeTypes = savedChargeTypes
        })
    }

    deleteChargeType(e, index: number) {
        e.preventDefault();
        e.stopPropagation();

        log.Debug("remove index: ", index)
        log.Debug("chargetypes form array: ", this.form.get('ChargeTypes'))
        log.Debug("charge types array: ", this.chargeTypes)

        const chargeType = this.chargeTypes[index] as ChargeType;
        log.Debug("chargeType: ", chargeType)

        if(!Boolean(chargeType)) {
            this.removeChargeType(index);
            return
        }

        if(confirm("This charge type will be deleted. Are you sure?")) {
            this.cts.delete(<ChargeType>chargeType).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Charge type deleted.');
                    this.removeChargeType(index);
                }
            })
        }
    }
}