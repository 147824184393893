import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AutoModelService } from './auto-model.service';
import { AutoModel } from './auto-model';

@Injectable()
export class AutoModelsResolve implements Resolve<AutoModel[]> {
    constructor (
        private router: Router,
        private ams: AutoModelService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<AutoModel[]> {
        return this.ams.GetAll();
    }
}