import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from '../common/dev/log';
import { MONTHS } from '../common/month';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Driver, DriverReport, DriverInvoice, DriverService } from '../driver';
import { FormGroup, FormControl } from '@angular/forms';
import { map, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: "driver-commission-due-report-pdf",
    templateUrl: "./driver-commission-due-report-pdf.component.html"
})

export class DriverCommissionDueReportPDFComponent implements OnInit {

    downloading = false;
    isPrintedDownloading = false;
    todaysDate = new Date();
    twoWeeksAgoDate;

    form: FormGroup
    isPrintedForm: FormGroup

    constructor (
        private route: ActivatedRoute,
        private ds: DriverService
    ){
        this.form = this.createFormGroup()
        this.isPrintedForm = this.createIsPrintedFormGroup()
    }
    ngOnInit(): void {
        this.route.data.subscribe((data: {
        }) => {
            log.Debug("data: ", data)
        });
    }

    createFormGroup(): FormGroup {
        return new FormGroup({
            StartDate: new FormControl(new Date(new Date().setDate(this.todaysDate.getDate() - 14))),
            EndDate: new FormControl(new Date(this.todaysDate)),
            DriverNumber: new FormControl()
        })
    }

    createIsPrintedFormGroup(): FormGroup {
        return new FormGroup({
            DriverNumber: new FormControl(),
        })
    }

    driverCommissionDueReportsPDFByDate() {
        this.downloading = true
        this.ds.SearchDriverReportsPDF(this.form.value).subscribe( blob => {
            const a = document.createElement('a');
            a.setAttribute('download', 'driver-commission-due-report.pdf');
            a.setAttribute('href', window.URL.createObjectURL(blob));
            a.click()
            this.downloading = false;
        })
    }

    driverCommissionDueReportsPDFByIsInvoicePrinted() {
        this.isPrintedDownloading = true
        this.ds.SearchDriverReportsPDFByIsInvoicePrinted(this.isPrintedForm.value).subscribe( blob => {
            const a = document.createElement('a');
            a.setAttribute('download', 'driver-commission-due-report.pdf');
            a.setAttribute('href', window.URL.createObjectURL(blob));
            a.click()
            this.isPrintedDownloading = false;
        })
    }

    driverCommissionDueReportsPDFByIsInvoicePrintedPreview() {
        this.isPrintedDownloading = true
        this.ds.SearchDriverReportsPDFByIsInvoicePrintedPreview(this.isPrintedForm.value).subscribe( blob => {
            const a = document.createElement('a');
            a.setAttribute('download', 'driver-commission-due-report-preview.pdf');
            a.setAttribute('href', window.URL.createObjectURL(blob));
            a.click()
            this.isPrintedDownloading = false;
        })
    }

}
