import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PaymentService } from './payment.service';
import { Payment } from './payment';

@Injectable()
export class CurrentPaymentResolve implements Resolve<Payment> {
    constructor (
        private router: Router,
        private ps: PaymentService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<Payment> {
        const payment_id = +route.paramMap.get('payment_id');
        return this.ps.Get(payment_id);
    }
}