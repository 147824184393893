<mat-card class="edit-user small-form">
    <h1 class="title">Sign Up</h1>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
        <mat-card-content>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input type="text" matInput placeholder="Username" formControlName="Username">
                        <mat-error *ngIf="form.get('Username')?.invalid">{{ getErrorMessage(form.get('Username')) }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <mat-select placeholder="Role" formControlName="RoleID" [disabled]="true">
                            <mat-option *ngFor="let role of roles" [value]="role.ID">{{ role.Name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('RoleID')?.invalid">{{ getErrorMessage(form.get('RoleID')) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input type="password" matInput placeholder="Password" formControlName="Password">
                        <mat-error *ngIf="form.get('Password')?.invalid">{{ getErrorMessage(form.get('Password')) }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input type="password" matInput placeholder="Confirm Password" formControlName="ConfirmPassword" [errorStateMatcher]="matcher">
                        <mat-error *ngIf="form.hasError('passwordsNoMatch')">Your passwords do not match.</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions end>
            <button mat-raised-button color="primary" type="submit"><mat-icon>person_add</mat-icon>Sign Up</button>
        </mat-card-actions>
    </form>
</mat-card>