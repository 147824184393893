<div id="print-section" style="position: relative;">
<html>
<head><meta http-equiv=Content-Type content="text/html; charset=UTF-8">
<style type="text/css">

span.cls_002{font-family:"Calibri",serif;font-size:9.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_002{font-family:"Calibri",serif;font-size:9.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_003{font-family:Arial,serif;font-size:11.6px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_003{font-family:Arial,serif;font-size:11.6px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_004{font-family:Arial,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_004{font-family:Arial,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_005{font-family:Arial,serif;font-size:9.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_005{font-family:Arial,serif;font-size:9.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_007{font-family:Arial,serif;font-size:8.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_007{font-family:Arial,serif;font-size:8.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_008{font-family:Arial,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_008{font-family:Arial,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
}
</style>
<!-- <script type="text/javascript" src="aa6313c8-24de-11e9-8f58-0cc47a792c0a_id_aa6313c8-24de-11e9-8f58-0cc47a792c0a_files/wz_jsgraphics.js"></script> -->
</head>
<body>
<div style="position:absolute;left:50%;margin-left:-306px;top:0px;width:612px;height:792px;border-style:outset;overflow:hidden">
<div style="position:absolute;left:0px;top:0px">
<img src="assets/images/mv-603-form-background.jpg" width=612 height=792></div>
<div style="position:absolute;left:455.40px;top:35.52px" class="cls_002"><span class="cls_002">MV-603 (Rev. 04-2017)</span></div>
<div style="position:absolute;left:99.96px;top:70.93px" class="cls_003"><span class="cls_003">NOTICE OF AN ABANDONED VEHICLE AND REQUEST FOR INFORMATION</span></div>
<div style="position:absolute;left:117.84px;top:85.56px" class="cls_004"><span class="cls_004">Research fee:  $2.00 for printout of tag, title & lien information per abandoned vehicle</span></div>
<div style="position:absolute;left:72.00px;top:106.08px" class="cls_005"><span class="cls_005">I do solemnly swear or affirm that the vehicle described herein is or will become abandoned or a derelict motor vehicle</span></div>
<div style="position:absolute;left:72.00px;top:117.24px" class="cls_005"><span class="cls_005">as described in §40-11-1 of the OCGA.  As provided in Code Sections §40-11-2 and §40-11-9 of the OCGA, this is to</span></div>
<div style="position:absolute;left:72.00px;top:128.40px" class="cls_005"><span class="cls_005">request the names and addresses of all owners, lessors, lessees, security interest holders and lien holders of such</span></div>
<div style="position:absolute;left:72.00px;top:139.44px" class="cls_005"><span class="cls_005">vehicle.</span></div>
<div style="position:absolute;left:72.00px;top:158.77px" class="cls_005"><span class="cls_005">I understand that I shall not be entitled to any storage fees if I do not provide the notice and information required by</span></div>
<div style="position:absolute;left:72.00px;top:169.09px" class="cls_005"><span class="cls_005">§40-11-2 and §40-11-9 of the OCGA.  I also understand that any person who knowingly provides false and misleading</span></div>
<div style="position:absolute;left:72.00px;top:179.41px" class="cls_005"><span class="cls_005">information when providing any notice of information as required by §40-11-2 and §40-11-9 of the OCGA shall be guilty</span></div>
<div style="position:absolute;left:72.01px;top:189.85px" class="cls_005"><span class="cls_005">of a misdemeanor punishable by fine up to $1000 or by imprisonment of up to one (1) year.</span></div>
<div style="position:absolute;left:77.88px;top:215.52px" class="cls_007"><span class="cls_007">Name of Person Removing or Storing Vehicle</span></div>
<!-- Input -->
<div style="position:absolute;left:77.88px;top:230px" class="cls_007"><span class="cls_007">{{ fields.RemoverName }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:311.40px;top:215.52px" class="cls_007"><span class="cls_007">Vehicle Identification Number</span></div>
<!-- Input -->
<div style="position:absolute;left:311.40px;top:230px" class="cls_007"><span class="cls_007">{{ fields.VIN }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:77.88px;top:245.52px" class="cls_007"><span class="cls_007">Street</span></div>
<!-- Input -->
<div style="position:absolute;left:77.88px;top:260px" class="cls_007"><span class="cls_007">{{ fields.RemoverStreet }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:311.40px;top:245.52px" class="cls_007"><span class="cls_007">Year</span></div>
<!-- Input -->
<div style="position:absolute;left:311.40px;top:260px" class="cls_007"><span class="cls_007">{{ fields.Year }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:370.71px;top:245.52px" class="cls_007"><span class="cls_007">Make</span></div>
<!-- Input -->
<div style="position:absolute;left:370.71px;top:260px" class="cls_007"><span class="cls_007">{{ fields.Make }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:427.97px;top:245.52px" class="cls_007"><span class="cls_007">Model</span></div>
<!-- Input -->
<div style="position:absolute;left:427.97px;top:260px" class="cls_007"><span class="cls_007">{{ fields.Model }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:77.88px;top:275.52px" class="cls_007"><span class="cls_007">City</span></div>
<!-- Input -->
<div style="position:absolute;left:77.88px;top:290px" class="cls_007"><span class="cls_007">{{ fields.RemoverCity }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:160.74px;top:275.52px" class="cls_007"><span class="cls_007">State</span></div>
<!-- Input -->
<div style="position:absolute;left:160.74px;top:290px" class="cls_007"><span class="cls_007">{{ fields.RemoverState }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:194.95px;top:275.52px" class="cls_007"><span class="cls_007">Zip</span></div>
<!-- Input -->
<div style="position:absolute;left:194.95px;top:290px" class="cls_007"><span class="cls_007">{{ fields.RemoverZip }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:311.40px;top:275.52px" class="cls_007"><span class="cls_007">Tag No.</span></div>
<!-- Input -->
<div style="position:absolute;left:311.40px;top:290px" class="cls_007"><span class="cls_007">{{ fields.Tag }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:355.45px;top:275.52px" class="cls_007"><span class="cls_007">State of Issue</span></div>
<!-- Input -->
<div style="position:absolute;left:355.45px;top:290px" class="cls_007"><span class="cls_007">{{ fields.TagState }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:424.46px;top:275.52px" class="cls_007"><span class="cls_007">Date Vehicle Removed</span></div>
<!-- Input -->
<div style="position:absolute;left:424.46px;top:290px" class="cls_007"><span class="cls_007">{{ fields.RemovedDate | date:'shortDate':'UTC' }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:77.88px;top:305.52px" class="cls_007"><span class="cls_007">Present Location of Vehicle (Street Address)</span></div>
<!-- Input -->
<div style="position:absolute;left:77.88px;top:320px" class="cls_007"><span class="cls_007">{{ fields.PresentLocation }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:311.40px;top:305.52px" class="cls_007"><span class="cls_007">Date Vehicle Becomes Abandoned</span></div>
<!-- Input -->
<div style="position:absolute;left:311.40px;top:320px" class="cls_007"><span class="cls_007">{{ fields.AbandonedDate | date:'shortDate':'UTC' }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:77.88px;top:335.52px" class="cls_007"><span class="cls_007">City</span></div>
<!-- Input -->
<div style="position:absolute;left:77.88px;top:350px" class="cls_007"><span class="cls_007">{{ fields.PresentCity }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:160.74px;top:335.52px" class="cls_007"><span class="cls_007">State</span></div>
<!-- Input -->
<div style="position:absolute;left:160.74px;top:350px" class="cls_007"><span class="cls_007">{{ fields.PresentState }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:194.95px;top:335.52px" class="cls_007"><span class="cls_007">Zip</span></div>
<!-- Input -->
<div style="position:absolute;left:194.95px;top:350px" class="cls_007"><span class="cls_007">{{ fields.PresentZip }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:311.40px;top:335.52px" class="cls_007"><span class="cls_007">Location Vehicle Removed From</span></div>
<!-- Input -->
<div style="position:absolute;left:311.40px;top:350px" class="cls_007"><span class="cls_007">{{ fields.FromLocation }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:77.88px;top:365.52px" class="cls_007"><span class="cls_007">Telephone Number (including area code)</span></div>
<!-- Input -->
<div style="position:absolute;left:77.88px;top:380px" class="cls_007"><span class="cls_007">{{ fields.PresentPhone }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:311.40px;top:365.52px" class="cls_007"><span class="cls_007">City</span></div>
<!-- Input -->
<div style="position:absolute;left:311.40px;top:380px" class="cls_007"><span class="cls_007">{{ fields.FromCity }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:396.42px;top:365.52px" class="cls_007"><span class="cls_007">State</span></div>
<!-- Input -->
<div style="position:absolute;left:396.42px;top:380px" class="cls_007"><span class="cls_007">{{ fields.FromState }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:435.07px;top:365.52px" class="cls_007"><span class="cls_007">ZIP</span></div>
<!-- Input -->
<div style="position:absolute;left:435.07px;top:380px" class="cls_007"><span class="cls_007">{{ fields.FromZip }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:72.00px;top:417.96px" class="cls_004"><span class="cls_004">Sworn to and subscribed before me this __________day of _______________________, ____________</span></div>
<div style="position:absolute;left:265.56px;top:430.44px" class="cls_005"><span class="cls_005">(Day)</span></div>
<!-- Input -->
<div style="position:absolute;left:255px;top:417.5px" class="cls_005"><span class="cls_005">{{ fields.CurrentDay }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:387.00px;top:430.44px" class="cls_005"><span class="cls_005">(Month)</span></div>
<!-- Input -->
<div style="position:absolute;left:341.5px;top:417.5px" class="cls_005"><span class="cls_005">{{ fields.CurrentMonth }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:490.56px;top:430.44px" class="cls_005"><span class="cls_005">(Year)</span></div>
<!-- Input -->
<div style="position:absolute;left:475px;top:417.5px" class="cls_005"><span class="cls_005">{{ fields.CurrentYear }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:72.00px;top:462.00px" class="cls_004"><span class="cls_004">X__________________________________________________________________________________</span></div>
<div style="position:absolute;left:191.88px;top:473.64px" class="cls_005"><span class="cls_005">(Authorized signature of person removing/storing vehicle)</span></div>
<div style="position:absolute;left:72.00px;top:504.36px" class="cls_004"><span class="cls_004">X_________________________________________</span></div>
<div style="position:absolute;left:324.00px;top:504.36px" class="cls_004"><span class="cls_004">______________________________________</span></div>
<div style="position:absolute;left:103.56px;top:515.88px" class="cls_004"><span class="cls_004">(</span><span class="cls_005">Notary Public’s Signature and Seal or Stamp)</span></div>
<div style="position:absolute;left:342.00px;top:516.84px" class="cls_005"><span class="cls_005">(Date My Notary Public Commission Expires)</span></div>
<div style="position:absolute;left:72.00px;top:549.84px" class="cls_004"><span class="cls_004">__________________________________________</span></div>
<div style="position:absolute;left:85.56px;top:561.36px" class="cls_004"><span class="cls_004">(</span><span class="cls_005">Notary Public’s Georgia Driver’s License Number)</span></div>
<div style="position:absolute;left:72.00px;top:584.40px" class="cls_004"><span class="cls_004">Mail the Notice and Request for Motor Vehicle Information (Form MV-603) and fee to the County Tag Agent</span></div>
<div style="position:absolute;left:72.00px;top:595.81px" class="cls_004"><span class="cls_004">in the county where the vehicle is stored or to:</span></div>
<div style="position:absolute;left:180.00px;top:620.64px" class="cls_008"><span class="cls_008">ATTN:  Research/Abandoned Vehicles</span></div>
<div style="position:absolute;left:180.00px;top:634.20px" class="cls_004"><span class="cls_004">Department of Revenue/Motor Vehicle Division</span></div>
<div style="position:absolute;left:180.00px;top:646.68px" class="cls_004"><span class="cls_004">PO Box 740381</span></div>
<div style="position:absolute;left:180.00px;top:657.96px" class="cls_004"><span class="cls_004">ATLANTA, GA  30374-0381</span></div>
<div style="position:absolute;left:72.00px;top:679.44px" class="cls_004"><span class="cls_004">Except for signatures, this form must be typed, electronically completed & printed or legibly hand printed in</span></div>
<div style="position:absolute;left:72.00px;top:690.96px" class="cls_004"><span class="cls_004">black or blue ink.</span></div>
<div style="position:absolute;left:195.12px;top:726.36px" class="cls_003"><span class="cls_003">ORIGINAL FORM MUST BE SUBMITTED.</span></div>
<div style="position:absolute;left:150.35px;top:740.64px" class="cls_003"><span class="cls_003">ANY ALTERATION OR CORRECTION VOIDS THIS FORM.</span></div>
</div>
</body>
</html>
<button mat-raised-button (click)="print()" color="primary" style="position: absolute; left: 0; top: 0;"><mat-icon>print</mat-icon>Print</button>
</div>