import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { OfficerService } from './officer.service';
import { Officer } from './officer';

@Injectable()
export class OfficersResolve implements Resolve<Officer[]> {
    constructor (
        private router: Router,
        private os: OfficerService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<Officer[]> {
        return this.os.GetAll();
    }
}