import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AutoService } from './auto.service';
import { Auto } from './auto';

@Injectable()
export class T158AutosResolve implements Resolve<Auto[]> {
    constructor (
        private router: Router,
        private as: AutoService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<Auto[]> {
        return this.as.GetAllForBulkT158();
    }
}