<div id="print-section" style="position: relative;">
    <html>
<head><meta http-equiv=Content-Type content="text/html; charset=UTF-8">
<style type="text/css">
<!--
span.cls_002{font-family:"Calibri",serif;font-size:9.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_002{font-family:"Calibri",serif;font-size:9.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_003{font-family:Arial,serif;font-size:16.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_003{font-family:Arial,serif;font-size:16.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_005{font-family:Arial,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_005{font-family:Arial,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_006{font-family:Arial,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_006{font-family:Arial,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_007{font-family:Arial,serif;font-size:8.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_007{font-family:Arial,serif;font-size:8.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_008{font-family:Arial,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_008{font-family:Arial,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_009{font-family:Arial,serif;font-size:9.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_009{font-family:Arial,serif;font-size:9.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_010{font-family:Arial,serif;font-size:14.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_010{font-family:Arial,serif;font-size:14.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_012{font-family:Arial,serif;font-size:14.1px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_012{font-family:Arial,serif;font-size:14.1px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
-->
</style>
<script type="text/javascript" src="bb677828-44c6-11e9-9d71-0cc47a792c0a_id_bb677828-44c6-11e9-9d71-0cc47a792c0a_files/wz_jsgraphics.js"></script>
</head>
<body>
<div style="position:absolute;left:50%;margin-left:-306px;top:0px;width:612px;height:792px;border-style:outset;overflow:hidden">
<div style="position:absolute;left:0px;top:0px">
<img src="assets/images/mv-603r-form-background.jpg" width=612 height=792></div>
<div style="position:absolute;left:450.48px;top:35.52px" class="cls_002"><span class="cls_002">MV-603R (Rev. 04-2017)</span></div>
<div style="position:absolute;left:162.84px;top:70.80px" class="cls_003"><span class="cls_003">Notice of Abandoned Vehicle Release</span></div>
<div style="position:absolute;left:72.00px;top:99.00px" class="cls_005"><span class="cls_005">Upon final disposition of the abandoned motor vehicle, you must complete the following</span></div>
<div style="position:absolute;left:72.00px;top:113.76px" class="cls_005"><span class="cls_005">information and mail to:</span></div>
<div style="position:absolute;left:180.00px;top:136.68px" class="cls_006"><span class="cls_006">ATTN: Stop File</span></div>
<div style="position:absolute;left:180.00px;top:150.48px" class="cls_005"><span class="cls_005">Department of Revenue/Motor Vehicle Division</span></div>
<div style="position:absolute;left:180.00px;top:164.28px" class="cls_005"><span class="cls_005">PO Box 740384</span></div>
<div style="position:absolute;left:180.00px;top:178.08px" class="cls_005"><span class="cls_005">ATLANTA, GA 30374-0384</span></div>
<div style="position:absolute;left:77.88px;top:207.60px" class="cls_007"><span class="cls_007">Person Removing or Storing Vehicle</span></div>
<!-- Input -->
<div style="position:absolute;left:77.88px;top:224px" class="cls_007"><span class="cls_007">{{ fields.RemoverName }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:311.40px;top:207.60px" class="cls_007"><span class="cls_007">Vehicle Identification Number</span></div>
<!-- Input -->
<div style="position:absolute;left:311.4px;top:224px" class="cls_007"><span class="cls_007">{{ fields.VIN }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:77.88px;top:244.44px" class="cls_007"><span class="cls_007">Street</span></div>
<!-- Input -->
<div style="position:absolute;left:77.88px;top:260px" class="cls_007"><span class="cls_007">{{ fields.RemoverStreet }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:311.40px;top:244.44px" class="cls_007"><span class="cls_007">Year  Make  Model</span></div>
<!-- Input -->
<div style="position:absolute;left:311.4px;top:260px" class="cls_007"><span class="cls_007">{{ fields.YearMakeModel }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:77.88px;top:281.28px" class="cls_007"><span class="cls_007">City, State & Zip</span></div>
<!-- Input -->
<div style="position:absolute;left:77.88px;top:294px" class="cls_007"><span class="cls_007">{{ fields.RemoverCityStateZip }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:311.40px;top:281.28px" class="cls_007"><span class="cls_007">Tag No.  State of Issue</span></div>
<!-- Input -->
<div style="position:absolute;left:311.4px;top:294px" class="cls_007"><span class="cls_007">{{ fields.TagTagState }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:428.16px;top:281.28px" class="cls_007"><span class="cls_007">Date Removed</span></div>
<!-- Input -->
<div style="position:absolute;left:428.16px;top:294px" class="cls_007"><span class="cls_007">{{ fields.RemovedDate | date:'shortDate':'UTC' }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:77.88px;top:311.28px" class="cls_007"><span class="cls_007">Present Location of Vehicle (Street Address)</span></div>
<!-- Input -->
<div style="position:absolute;left:77.88px;top:328px" class="cls_007"><span class="cls_007">{{ fields.PresentLocation }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:311.40px;top:311.28px" class="cls_007"><span class="cls_007">Date Vehicle Becomes Abandoned</span></div>
<!-- Input -->
<div style="position:absolute;left:311.4px;top:328px" class="cls_007"><span class="cls_007">{{ fields.AbandonedDate | date:'shortDate':'UTC' }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:77.88px;top:348.12px" class="cls_007"><span class="cls_007">City, State & Zip</span></div>
<!-- Input -->
<div style="position:absolute;left:77.88px;top:364px" class="cls_007"><span class="cls_007">{{ fields.PresentCityStateZip }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:311.40px;top:348.12px" class="cls_007"><span class="cls_007">Location Vehicle Removed From</span></div>
<!-- Input -->
<div style="position:absolute;left:311.4px;top:364px" class="cls_007"><span class="cls_007">{{ fields.FromLocation }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:77.88px;top:384.96px" class="cls_007"><span class="cls_007">Telephone Number (including area code)</span></div>
<!-- Input -->
<div style="position:absolute;left:77.88px;top:400px" class="cls_007"><span class="cls_007">{{ fields.PresentPhone }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:311.40px;top:384.96px" class="cls_007"><span class="cls_007">City, State & Zip</span></div>
<!-- Input -->
<div style="position:absolute;left:311.4px;top:400px" class="cls_007"><span class="cls_007">{{ fields.FromCityStateZip }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:72.00px;top:444.36px" class="cls_008"><span class="cls_008">____________________________________________________________________________________</span></div>
<div style="position:absolute;left:191.88px;top:455.76px" class="cls_009"><span class="cls_009">(Authorized signature of person removing/storing vehicle)</span></div>
<div style="position:absolute;left:72.00px;top:473.88px" class="cls_005"><span class="cls_005">Under provisions of Section 40-11-2 & 40-11-9 of the OCGA, I hereby certify that the</span></div>
<div style="position:absolute;left:72.00px;top:487.68px" class="cls_005"><span class="cls_005">vehicle described above is no longer abandoned. I further certify that this notification is</span></div>
<div style="position:absolute;left:72.00px;top:501.48px" class="cls_005"><span class="cls_005">within the limit as prescribed by law.</span></div>
<div style="position:absolute;left:72.00px;top:524.28px" class="cls_010"><span class="cls_010">☐</span><span class="cls_005"> Reclaimed by owner, _________________________________on______________</span></div>
<div style="position:absolute;left:301.56px;top:543.12px" class="cls_009"><span class="cls_009">(Name)</span></div>
<div style="position:absolute;left:472.56px;top:543.12px" class="cls_009"><span class="cls_009">(Date)</span></div>
<div style="position:absolute;left:72.00px;top:562.92px" class="cls_010"><span class="cls_010">☐</span><span class="cls_005"> Recovered stolen vehicle claimed by _____________________________________</span></div>
<div style="position:absolute;left:94.56px;top:589.68px" class="cls_005"><span class="cls_005">on ________________________________________________________________</span></div>
<div style="position:absolute;left:72.00px;top:613.44px" class="cls_010"><span class="cls_010">☐</span><span class="cls_005"> Court order obtained to sell motor vehicle.</span></div>
<div style="position:absolute;left:72.00px;top:641.04px" class="cls_010"><span class="cls_010">☐</span><span class="cls_005"> Derelict motor vehicle (Form MV-603D attached or sent to MVD)</span></div>
<div style="position:absolute;left:72.00px;top:680.28px" class="cls_005"><span class="cls_005">Authorized Signature: __________________________________ Date: ____________</span></div>
<div style="position:absolute;left:169.23px;top:720.00px" class="cls_012"><span class="cls_012">ORIGINAL FORM MUST BE SUBMITTED.</span></div>
<div style="position:absolute;left:115.38px;top:737.40px" class="cls_012"><span class="cls_012">ANY ALTERATION OR CORRECTION VOIDS THIS FORM.</span></div>
</div>

</body>
</html>
    <button mat-raised-button (click)="print()" color="primary" style="position: absolute; left: 0; top: 0;"><mat-icon>print</mat-icon>Print</button>
</div>