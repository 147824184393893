import { Component, Inject, OnInit, NgZone, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { log } from '../dev/log';
import { Note, NoteService } from '../../note';
import { AppService } from '../../app.service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';

@Component({
    selector: 'notes-dialog',
    templateUrl: 'notes-dialog.component.html'
})

export class NotesDialog implements OnInit {

    form: FormGroup;
    notes: Note[];

    constructor (
        public dialogRef: MatDialogRef<NotesDialog>,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private ns: NoteService,
        private apps: AppService,
        private nz: NgZone
    ) {
        this.form = this.createFormGroup();
    }

    @ViewChild('autosize', {read: false, static: true}) autosize: CdkTextareaAutosize;

    triggerResize() {
        this.nz.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
    }

    ngOnInit(): void {
        console.log("data :", this.data['items'])
        console.log("data: ", typeof this.data['items'])
        this.notes = this.data['items'];
        for (let n in this.notes) {
            this.addNote();
        }
        this.form.get('Notes').patchValue(this.notes);
    }
    
    close(): void {
        this.dialogRef.close();
    }

    createFormGroup() {
        return new FormGroup({
            Notes: new FormArray([])
        })
    }

    createNoteFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            AutoID: new FormControl(this.data['autoID']),
            Value: new FormControl(),
        })
    }

    addNote(): void {
        let notes = this.form.get('Notes') as FormArray;
        notes.push(this.createNoteFormGroup());
    }
    removeNote(i: number): void {
        let notes = this.form.get('Notes') as FormArray;
        notes.removeAt(i);
        this.notes.splice(i, 1)
    }

    onSubmit(e): void {

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched: ", this.form)
            return;
        }

        this.save();
    }

    save() {
        this.ns.saveAll(this.form.get('Notes').value)
        .subscribe((savedNotes: Note[]) => {
            this.apps.Confirmation('Notes saved.');
            this.form.markAsPristine();

            log.Debug("savedNotes: ", savedNotes)
            this.form.get('Notes').patchValue(savedNotes);
            this.notes = savedNotes
        })
    }

    deleteNote(e, index: number) {
        e.preventDefault();
        e.stopPropagation();


        const note = this.notes[index] as Note;
        if(!Boolean(note)) {
            log.Debug("note: ", note)
            this.removeNote(index);
            return
        }

        if(confirm("This note will be deleted. Are you sure?")) {
            this.ns.delete(<Note>note).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Note deleted.');
                    this.removeNote(index);
                }
            })
        }
    }
}