<button class="print-button" mat-raised-button (click)="print()" color="primary" style="margin-bottom: 10px;"><mat-icon>print</mat-icon>Print</button>
<div id="print-section">
    <ng-container *ngIf="autos.length > 0; else empty">
        <div class="t-158s">
            <div *ngFor="let auto of autos">
                <p style="page-break-before: always; margin-bottom: 0;"></p>
                <div class="t-158">
<html>
<head><meta http-equiv=Content-Type content="text/html; charset=UTF-8">
<style type="text/css">
<!--
span.cls_002{font-family:Arial,serif;font-size:6.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_002{font-family:Arial,serif;font-size:6.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_003{font-family:Arial,serif;font-size:11.3px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_003{font-family:Arial,serif;font-size:11.3px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_004{font-family:Arial,serif;font-size:6.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_004{font-family:Arial,serif;font-size:6.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_005{font-family:Arial,serif;font-size:8.3px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_005{font-family:Arial,serif;font-size:8.3px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_006{font-family:Arial,serif;font-size:8.3px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_006{font-family:Arial,serif;font-size:8.3px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_007{font-family:Arial,serif;font-size:5.3px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_007{font-family:Arial,serif;font-size:5.3px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_008{font-family:Arial,serif;font-size:6.8px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_008{font-family:Arial,serif;font-size:6.8px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_009{font-family:Arial,serif;font-size:9.1px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_009{font-family:Arial,serif;font-size:9.1px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_010{font-family:Arial,serif;font-size:9.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_010{font-family:Arial,serif;font-size:9.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
-->
</style>
<script type="text/javascript" src="a1acdbaa-45c8-11e9-9d71-0cc47a792c0a_id_a1acdbaa-45c8-11e9-9d71-0cc47a792c0a_files/wz_jsgraphics.js"></script>
</head>
<body>
<div style="position:absolute; left:104px; top:0px; width:612px;height:792px;border-style:outset;overflow:hidden;">
<div style="position:absolute;left:0px;top:0px">
<img src="assets/images/t-158-form-background.jpg" width=612 height=792></div>
<div style="position:absolute;left:550px;top:15px" class="cls_005"><span class="cls_005">{{ auto.AutoNumber }}</span></div>
<div style="position:absolute;left:90.00px;top:35.25px" class="cls_002"><span class="cls_002">T-158</span></div>
<div style="position:absolute;left:90.00px;top:42.00px" class="cls_002"><span class="cls_002">WEB (Rev. 9/14)</span></div>
<div style="position:absolute;left:129.00px;top:57.00px" class="cls_003"><span class="cls_003">REPORT OF AND/OR SURRENDER OF GEORGIA LICENSE PLATE</span></div>

<div style="position:absolute;left:308.25px;top:71.25px" class="cls_004"><span class="cls_004">SECTION 1</span></div>
<div style="position:absolute;left:97.50px;top:75.00px" class="cls_002"><span class="cls_002">Company/Person Surrendering  License  Plate</span></div>
<!-- Input -->
<div style="position:absolute;left:97.5px;top:86px" class="cls_005"><span class="cls_005">{{ fields.CompanyName }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:306.00px;top:78.00px" class="cls_002"><span class="cls_002">Reason for Report:  (Check One)</span></div>
<div style="position:absolute;left:306.00px;top:84.75px" class="cls_002"><span class="cls_002">(</span></div>
<div style="position:absolute;left:314.27px;top:84.75px" class="cls_002"><span class="cls_002">)</span></div>
<div style="position:absolute;left:342.00px;top:84.75px" class="cls_002"><span class="cls_002">License Plate for the vehicle described below</span></div>
<div style="position:absolute;left:342.01px;top:91.50px" class="cls_002"><span class="cls_002">surrendered for the reason indicated.  (Check applicable reason in</span></div>
<div style="position:absolute;left:341.99px;top:98.25px" class="cls_002"><span class="cls_002">Section 2 below.)</span></div>
<div style="position:absolute;left:97.50px;top:103.50px" class="cls_002"><span class="cls_002">Authorized Agent Surrendering License Plate</span></div>
<!-- Input -->
<div style="position:absolute;left:97.5px;top:115px" class="cls_005"><span class="cls_005">{{ fields.CompanyAgent }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:306.00px;top:105.75px" class="cls_002"><span class="cls_002">(</span></div>
<div style="position:absolute;left:313.91px;top:105.75px" class="cls_002"><span class="cls_002">)</span></div>
<div style="position:absolute;left:341.99px;top:105.75px" class="cls_002"><span class="cls_002">I declare that no tag was on the vehicle described</span></div>
<div style="position:absolute;left:341.99px;top:112.50px" class="cls_002"><span class="cls_002">below.  (Check applicable reason in Section 2 below).</span></div>
<div style="position:absolute;left:305.99px;top:119.25px" class="cls_002"><span class="cls_002">(</span></div>
<div style="position:absolute;left:313.90px;top:119.25px" class="cls_002"><span class="cls_002">)</span></div>
<!-- Input -->
<div style="position:absolute;left:308.5px;top:119px" class="cls_005"><span class="cls_005">{{ fields.Mark }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:341.99px;top:119.25px" class="cls_002"><span class="cls_002">Tag unclaimed by owner, surrendered for</span></div>
<div style="position:absolute;left:341.99px;top:126.00px" class="cls_002"><span class="cls_002">cancellation.</span></div>
<div style="position:absolute;left:97.50px;top:132.00px" class="cls_002"><span class="cls_002">Mailing Address</span></div>
<!-- Input -->
<div style="position:absolute;left:97.5px;top:143px" class="cls_005"><span class="cls_005">{{ fields.CompanyAddress }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:341.99px;top:140.25px" class="cls_002"><span class="cls_002">Date of Surrender/Report:___________________</span></div>
<!-- Input -->
<div style="position:absolute;left:415px;top:136px" class="cls_005"><span class="cls_005">{{ fields.SurrenderDate | date:'shortDate':'UTC' }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:97.50px;top:159.75px" class="cls_002"><span class="cls_002">City</span></div>
<!-- Input -->
<div style="position:absolute;left:98.5px;top:171px" class="cls_005"><span class="cls_005">{{ fields.CompanyCity }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:186.98px;top:159.75px" class="cls_002"><span class="cls_002">State</span></div>
<!-- Input -->
<div style="position:absolute;left:187px;top:171px" class="cls_005"><span class="cls_005">{{ fields.CompanyState }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:237.80px;top:159.75px" class="cls_002"><span class="cls_002">Zip Code</span></div>
<!-- Input -->
<div style="position:absolute;left:238px;top:171px" class="cls_005"><span class="cls_005">{{ fields.CompanyZip }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:90.00px;top:180.75px" class="cls_005"><span class="cls_005">_</span></div>
<div style="position:absolute;left:295.72px;top:180.75px" class="cls_005"><span class="cls_005">___________________________________________________</span></div>
<div style="position:absolute;left:285.00px;top:198.75px" class="cls_006"><span class="cls_006">SECTION 2</span></div>
<div style="position:absolute;left:90.00px;top:217.50px" class="cls_005"><span class="cls_005">Georgia Law (O.C.G.A. Section 40-2-20) requires that a license plate be surrendered when a Certificate of Registration is</span></div>
<div style="position:absolute;left:89.99px;top:226.50px" class="cls_005"><span class="cls_005">not available for certain categories of vehicles.  The vehicle described below is in the category or categories shown:</span></div>
<div style="position:absolute;left:89.98px;top:235.51px" class="cls_005"><span class="cls_005">(Check Applicable Categories).</span></div>
<div style="position:absolute;left:126.00px;top:255.00px" class="cls_002"><span class="cls_002">(</span></div>
<div style="position:absolute;left:133.91px;top:255.00px" class="cls_002"><span class="cls_002">) COURT ORDER OR INVOLUNTARY TRANSFER</span></div>
<!-- Input -->
<div style="position:absolute;left:128.5px;top:255px" class="cls_005"><span class="cls_005">{{ fields.Mark }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:306.00px;top:255.00px" class="cls_002"><span class="cls_002">(</span></div>
<div style="position:absolute;left:313.89px;top:255.00px" class="cls_002"><span class="cls_002">) SALVAGE</span><span class="cls_007"> (If vehicle owner retains salvage vehicle, owner must surrender license plate.)</span></div>
<div style="position:absolute;left:125.99px;top:268.50px" class="cls_007"><span class="cls_007">(</span></div>
<div style="position:absolute;left:133.48px;top:267.75px" class="cls_007"><span class="cls_007">)</span><span class="cls_002"> REPOSSESSION</span></div>
<div style="position:absolute;left:306.00px;top:267.75px" class="cls_002"><span class="cls_002">(</span></div>
<div style="position:absolute;left:314.00px;top:267.75px" class="cls_002"><span class="cls_002">) ABANDONED/DERELICT</span></div>
<div style="position:absolute;left:89.99px;top:281.25px" class="cls_002"><span class="cls_002">Illegal use or transfer of a License Plate is a misdemeanor of a high and aggravated nature, punishable by a fine of not less than $500.00 or by confinement for not</span></div>
<div style="position:absolute;left:89.99px;top:288.00px" class="cls_002"><span class="cls_002">more than 12 months, or both.</span></div>
<div style="position:absolute;left:89.99px;top:295.50px" class="cls_002"><span class="cls_002">_________________________________________________________________________________________________________________________________</span></div>
<div style="position:absolute;left:89.99px;top:302.25px" class="cls_002"><span class="cls_002">_________________________________________________________________________________________________________________________________</span></div>
<div style="position:absolute;left:285.00px;top:315.00px" class="cls_006"><span class="cls_006">SECTION 3</span></div>
<div style="position:absolute;left:173.25px;top:333.75px" class="cls_006"><span class="cls_006">VEHICLE AND TAG INFORMATION (All Spaces Must Be Completed)</span></div>
<div style="position:absolute;left:186.76px;top:342.75px" class="cls_006"><span class="cls_006">DO NOT SURRENDER SPECIAL OR PRESTIGE LICENSE PLATES</span></div>
<div style="position:absolute;left:95.25px;top:352.50px" class="cls_008"><span class="cls_008">Year, Model & Make of Vehicle</span></div>
<!-- Input -->
<div style="position:absolute;left:95.25px;top:360px" class="cls_005"><span class="cls_005">{{ getYearModelMake(auto.AutoYear, auto.AutoModel.Name, auto.Make.Name) }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:257.25px;top:352.50px" class="cls_008"><span class="cls_008">Manufacturer’s ID Number (Complete ID# MUST BE SHOWN CORRECTLY)</span></div>
<!-- Input -->
<div style="position:absolute;left:257.25px;top:360px" class="cls_005"><span class="cls_005">{{ auto.Vin }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:95.25px;top:369.00px" class="cls_008"><span class="cls_008">License Plate number and year issued</span></div>
<!-- Input -->
<div style="position:absolute;left:95.25px;top:377px" class="cls_005"><span class="cls_005">{{ getTagTagState(auto.Tag, auto.State) }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:257.24px;top:369.00px" class="cls_008"><span class="cls_008">Decal Number (If Applicable)</span></div>
<!-- Input -->
<div style="position:absolute;left:257.24px;top:377px" class="cls_005"><span class="cls_005">{{ auto.DecalNumber }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:395.25px;top:369.00px" class="cls_008"><span class="cls_008">Decal Year</span></div>
<!-- Input -->
<div style="position:absolute;left:395.25px;top:377px" class="cls_005"><span class="cls_005">{{ auto.DecalYear }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:90.00px;top:386.25px" class="cls_002"><span class="cls_002">_________________________________________________________________________________________________________________________________</span></div>
<div style="position:absolute;left:90.00px;top:393.00px" class="cls_002"><span class="cls_002">_________________________________________________________________________________________________________________________________</span></div>
<div style="position:absolute;left:285.00px;top:406.50px" class="cls_006"><span class="cls_006">SECTION 4</span></div>
<div style="position:absolute;left:90.00px;top:429.75px" class="cls_002"><span class="cls_002">METHOD OF DELIVERY:  (</span></div>
<div style="position:absolute;left:170.00px;top:429.75px" class="cls_002"><span class="cls_002">)  MAIL TO STATE</span></div>
<div style="position:absolute;left:270.02px;top:429.75px" class="cls_002"><span class="cls_002">(</span></div>
<div style="position:absolute;left:277.85px;top:429.75px" class="cls_002"><span class="cls_002">)  MAIL TO COUNTY, COUNTY NAME:_________________________________________</span></div>
<div style="position:absolute;left:162.03px;top:444.00px" class="cls_002"><span class="cls_002">(</span></div>
<div style="position:absolute;left:171.53px;top:444.00px" class="cls_002"><span class="cls_002">)  HAND DELIVERED TO STATE</span></div>
<div style="position:absolute;left:270.05px;top:444.00px" class="cls_002"><span class="cls_002">(</span></div>
<div style="position:absolute;left:277.99px;top:444.00px" class="cls_002"><span class="cls_002">)  HAND DELIVERED TO COUNTY, COUNTY NAME:_____________________________</span></div>
<div style="position:absolute;left:90.03px;top:450.75px" class="cls_002"><span class="cls_002">_________________________________________________________________________________________________________________________________</span></div>
<div style="position:absolute;left:90.03px;top:457.50px" class="cls_002"><span class="cls_002">_________________________________________________________________________________________________________________________________</span></div>
<div style="position:absolute;left:285.00px;top:471.00px" class="cls_006"><span class="cls_006">SECTION 5</span></div>
<div style="position:absolute;left:90.00px;top:489.75px" class="cls_007"><span class="cls_007">________________________________________________________</span></div>
<div style="position:absolute;left:306.00px;top:489.75px" class="cls_007"><span class="cls_007">_____________________________________________________________________________</span></div>
<div style="position:absolute;left:122.92px;top:495.75px" class="cls_007"><span class="cls_007">(Typed/Printed Name of Person/Representative)  (Date)</span></div>
<!-- Input -->
<div style="position:absolute;left:96px;top:485px" class="cls_005"><span class="cls_005">{{ fields.CompanyAgent }}, {{ fields.SurrenderDate | date:'shortDate':'UTC' }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:305.98px;top:495.75px" class="cls_007"><span class="cls_007">(Signature of Authorized Representative of Company or Person Surrendering License Plate</span></div>
<div style="position:absolute;left:90.00px;top:513.00px" class="cls_002"><span class="cls_002">LICENSE PLATES AND FORM(S) MUST BE MAILED</span></div>
<div style="position:absolute;left:306.01px;top:513.00px" class="cls_002"><span class="cls_002">IF NOT MAILED, LICENSE PLATE AND FORM(S) MUST BE</span></div>
<div style="position:absolute;left:90.02px;top:519.75px" class="cls_002"><span class="cls_002">TO:  COUNTY TAG OFFICE OR</span></div>
<div style="position:absolute;left:306.03px;top:519.75px" class="cls_002"><span class="cls_002">DELIVERED IN PERSON TO:  COUNTY TAG OFFICE OR</span></div>
<div style="position:absolute;left:90.03px;top:526.50px" class="cls_002"><span class="cls_002">Motor Vehicle Division/Surrendered License Plates</span></div>
<div style="position:absolute;left:306.03px;top:526.50px" class="cls_002"><span class="cls_002">Motor Vehicle Division</span></div>
<div style="position:absolute;left:90.03px;top:533.25px" class="cls_002"><span class="cls_002">Post Office Box 740381 - Atlanta, Georgia 30374-0381</span></div>
<div style="position:absolute;left:306.04px;top:533.25px" class="cls_002"><span class="cls_002">4125 Welcome All Rd- Atlanta, Georgia 30349</span></div>
<div style="position:absolute;left:90.03px;top:547.50px" class="cls_002"><span class="cls_002">_________________________________________________________________________________________________________________________________</span></div>
<div style="position:absolute;left:162.00px;top:560.25px" class="cls_009"><span class="cls_009">ACKNOWLEDGEMENT OF RECEIPT OF THE ABOVE LISTED LICENSE PLATE</span></div>
<div style="position:absolute;left:101.25px;top:564.00px" class="cls_002"><span class="cls_002">COUNTY</span></div>
<div style="position:absolute;left:90.04px;top:591.75px" class="cls_009"><span class="cls_009">______________________________________________________________________________________</span></div>
<div style="position:absolute;left:108.14px;top:601.50px" class="cls_010"><span class="cls_010">(</span><span class="cls_008">Signature of County Employee)</span></div>
<div style="position:absolute;left:235.47px;top:603.75px" class="cls_008"><span class="cls_008">(License Plate No. Received)</span></div>
<div style="position:absolute;left:361.98px;top:603.75px" class="cls_008"><span class="cls_008">(County Name)</span></div>
<div style="position:absolute;left:466.34px;top:603.75px" class="cls_008"><span class="cls_008">(Date Received)</span></div>
<div style="position:absolute;left:89.95px;top:620.25px" class="cls_008"><span class="cls_008">______________________________________________________________________________________________________________</span></div>
<div style="position:absolute;left:89.95px;top:628.50px" class="cls_008"><span class="cls_008">______________________________________________________________________________________________________________</span></div>
<div style="position:absolute;left:175.50px;top:643.50px" class="cls_006"><span class="cls_006">COUNTY MUST COMPLETE ACKNOWLEDGEMENT WHEN FORM AND</span></div>
<div style="position:absolute;left:188.25px;top:652.50px" class="cls_006"><span class="cls_006">LICENSE PLATE ARE SURRENDERED TO COUNTY TAG OFFICE</span></div>
<div style="position:absolute;left:90.01px;top:661.50px" class="cls_006"><span class="cls_006">________________________________________________________________________________________________</span></div>
<div style="position:absolute;left:90.00px;top:679.50px" class="cls_007"><span class="cls_007">ORIGINAL:  MOTOR VEHICLE DIVISION</span></div>
<div style="position:absolute;left:197.97px;top:679.50px" class="cls_007"><span class="cls_007">1 COPY:  RETAINED BY COMPANY/AGENT AND SUBMITTED WITH SUBSEQUENT TITLE/TAG APPLICATION</span></div>
<div style="position:absolute;left:198.00px;top:685.50px" class="cls_007"><span class="cls_007">1 COPY:  RETAINED BY COMPANY/AGENT AND SUBMITTED WITH SUBSEQUENT TITLE/TAG APPLICATION</span></div>
<div style="position:absolute;left:198.01px;top:690.75px" class="cls_007"><span class="cls_007">1 COPY:  RETAINED BY COMPANY/AGENT/SURRENDERING LICENSE PLATE</span></div>
<div style="position:absolute;left:216.01px;top:702.75px" class="cls_007"><span class="cls_007">Applicable copies should be distributed and/or retained by company/agent</span></div>
<div style="position:absolute;left:214.49px;top:708.00px" class="cls_007"><span class="cls_007">The Motor Vehicle Division will not make copies of a completed form T -158.</span></div>
<div style="position:absolute;left:123.00px;top:725.25px" class="cls_004"><span class="cls_004">SUBMISSION OF THIS FORM AND LICENSE PLATE DOES NOT CONSTITUTE PROOF THAT AD VALOREM TAXES HAVE BEEN PAID</span></div>
</div>
</body>
</html>

                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #empty><p style="text-align: center; margin-top: 60px;">No autos marked for T-158s.</p></ng-template>
</div>
