import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { log } from '../common/dev/log';

@Injectable({
    providedIn: 'root'
})

export class PDFService extends HttpService implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    constructor (
        protected http: HttpClient,
        private router: Router
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    Test() {
        log.Debug("test in pdf.service")
        return this.http.get('/api/pdf/test').subscribe();
    }

}