import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auto, AutoService } from '../auto';
import { Make } from '../make';
import { AutoModel } from '../auto-model';
import { log } from '../common/dev/log';
import { FormControl, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: "auto-by-date-range",
    templateUrl: "./auto-by-date-range.component.html"
})

export class AutoByDateRangeComponent implements OnInit {

    autos: Observable<Auto[]>;
    form: FormGroup;

    constructor (
        private route: ActivatedRoute,
        private as: AutoService
    ){
        this.form = this.createFormGroup()
    }
    ngOnInit(): void {
        this.route.data.subscribe((data: {
        }) => {
            log.Debug("data: ", data)
        });

        // this.form.get('DateIn').valueChanges.pipe(
        //     map(value => this.as.SearchByDateRange(this.form.value))
        // ).subscribe( value => this.autos = value)

        // this.form.get('DateOut').valueChanges.pipe(
        //     map(value => this.as.SearchByDateRange(this.form.value))
        // ).subscribe( value => this.autos = value)

    }

    createFormGroup(): FormGroup {
        return new FormGroup({
            DateIn: new FormControl(),
            DateOut: new FormControl()
        })
    }

    onSubmit() {
        this.autos = this.as.SearchByDateRange(this.form.value);
    }

    print(): void {
        window.print();
    }

}