import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { StatusService } from './status.service';
import { Status } from './status';

@Injectable()
export class StatusesResolve implements Resolve<Status[]> {
    constructor (
        private router: Router,
        private ss: StatusService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<Status[]> {
        return this.ss.GetAll();
    }
}