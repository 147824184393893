import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomerService } from './customer.service';
import { Customer } from './customer';

@Injectable()
export class CurrentCustomerResolve implements Resolve<Customer> {
    constructor (
        private router: Router,
        private cs: CustomerService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<Customer> {
        const customer_id = +route.paramMap.get('customer_id');
        return this.cs.Get(customer_id);
    }
}