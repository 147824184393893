<mat-card class="settings small-form manage-table">
    <h1 class="title">{{ title }}</h1>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
        <mat-card-content>
            <div class="settings-form-array" formArrayName="Settings" *ngFor="let setting of form.get('Settings')['controls']; let i = index">
                <div class="form-group-container" [formGroupName]="i">
                    <div class="row gutters">
                        <div class="col" style="padding: 0 10px;">
                            <mat-form-field>
                                <input readonly="true" matInput type="text" placeholder="Name" formControlName="Name">
                            </mat-form-field>
                        </div>
                        <div class="col" style="padding: 0 10px;">
                            <mat-form-field>
                                <input matInput type="text" placeholder="Value" formControlName="Value" required>
                                <mat-error *ngIf="setting.get('Value')?.invalid">{{ getErrorMessage(setting.get('Value')) }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions end>
            <!-- <button type="button" mat-raised-button color="primary" (click)="addSetting()"><mat-icon>add</mat-icon>Add</button> -->
            <button type="submit" mat-raised-button color="primary"><mat-icon>save</mat-icon>Save Changes</button>
        </mat-card-actions>
    </form>
</mat-card>
<pre class="debug">{{ form.value | json }}</pre>