<mat-card class="edit-transaction small-form">
    <h1 class="title">{{ title }}</h1>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
        <mat-card-content>
            <div class="row gutters" *ngIf="isSearch">
                <div class="col">
                    <mat-form-field>
                        <input type="text" placeholder="Transaction Number" matInput [matAutocomplete]="transaction" formControlName="TransactionNumber">
                        <mat-autocomplete autoActiveFirstOption #transaction="matAutocomplete">
                            <mat-option *ngFor="let transaction of filteredTransactions | async" [value]="transaction.TransactionNumber">{{ transaction.TransactionNumber }}</mat-option>
                            <mat-option *ngIf="!isEdit && !isSearch" [value]="nextTransactionNumber" selected="true">{{ nextTransactionNumber }}</mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="form.get('TransactionNumber')?.invalid && !isSearch">{{ getErrorMessage(form.get('TransactionNumber')) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="Date" placeholder="Date" formControlName="Transacted" [required]="!isSearch">
                        <mat-datepicker-toggle matSuffix [for]="Date"></mat-datepicker-toggle>
                        <mat-datepicker #Date></mat-datepicker>
                        <mat-error *ngIf="form.get('Transacted')?.invalid">{{ getErrorMessage(form.get('Transacted')) }}</mat-error>
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field>
                        <mat-label>Auto Number</mat-label>
                        <pgl-search-select
                            placeholder="Auto Number"
                            label="Auto Number"
                            formControlName="Auto"
                            [filterWith]="filterAutos"
                            [displayWith]="displayAutos"
                            startWith="0"
                            autoActiveFirstOption>
                        </pgl-search-select>
                        <mat-error *ngIf="form.get('Auto')?.invalid">{{ getErrorMessage(form.get('Auto')) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">

                <div class="col">
                    <mat-form-field>
                        <mat-label>Customer</mat-label>
                        <pgl-search-select
                            placeholder="Customer" 
                            label="Customer"
                            formControlName="Customer"
                            [filterWith]="filterCustomers"
                            [displayWith]="displayCustomers"
                            startWith="a"
                            autoActiveFirstOption>
                        </pgl-search-select>
                        <mat-error *ngIf="form.get('Customer')?.invalid">{{ getErrorMessage(form.get('Customer')) }}</mat-error>
                    </mat-form-field>
                    
                </div>

                <!-- NA commented out 5/11/23 -->
                <!-- <div class="col">
                    <mat-form-field>
                        <input type="text" placeholder="Customer" matInput [matAutocomplete]="customer" formControlName="CustomerID" [required]="!isSearch">
                        <mat-autocomplete autoActiveFirstOption #customer="matAutocomplete" [displayWith]="displayCustomer.bind(this)">
                            <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer.ID">{{ getName(customer) }}</mat-option>
                        </mat-autocomplete> -->
                        <!-- <mat-autocomplete autoActiveFirstOption #customer="matAutocomplete">
                            <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer.ID">{{ getName(customer) }}</mat-option>
                        </mat-autocomplete> -->
                        <!-- <mat-error *ngIf="form.get('CustomerID')?.invalid">{{ getErrorMessage(form.get('CustomerID')) }}</mat-error>
                    </mat-form-field>
                </div> -->
                <div class="col">
                    <mat-form-field>
                        <input matInput type="number" placeholder="Sale Amount" formControlName="SaleAmount" [required]="!isSearch">
                        <mat-error *ngIf="form.get('SaleAmount')?.invalid">{{ getErrorMessage(form.get('SaleAmount')) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions end>
            <button mat-raised-button color="primary" *ngIf="!isSearch" type="submit"><mat-icon>save</mat-icon>Save</button>
            <button mat-raised-button color="primary" *ngIf="!isSearch && !isEdit" type="button" (click)="saveAndPrint()"><mat-icon>credit_card</mat-icon>Save and Print</button>
            <button mat-raised-button color="primary" *ngIf="isSearch" type="submit"><mat-icon>search</mat-icon>Search</button>
            <button type="button" mat-raised-button color="primary" *ngIf="isEdit" (click)="goToBillOfSale()"><mat-icon>credit_card</mat-icon>Bill of Sale</button>
            <!-- <button mat-raised-button color="accent" click><mat-icon>clear</mat-icon>Clear Form</button> -->
            <button type="button" mat-raised-button color="primary" *ngIf="isEdit" (click)="gotoNew()"><mat-icon>clear</mat-icon>Clear</button>
            <button type="button" mat-raised-button color="warn" *ngIf="isEdit" (click)="delete($event)"><mat-icon>delete</mat-icon>Delete</button>
        </mat-card-actions>
    </form>
</mat-card>
<pre class="debug">{{ form.value | json }}</pre>
