<mat-card class="small-form deck" style="margin-bottom: 15px;">
    <form [formGroup]="filterForm">
        <div class="row gutters">
            <div class="col">
                <mat-form-field>
                    <mat-label>Make</mat-label>
                    <mat-select formControlName="make_id">
                        <mat-option>
                            <ngx-mat-select-search 
                                [formControl]="makeFilterCtrl"
                                [placeholderLabel]="'Find make...'" 
                                [noEntriesFoundLabel]="'no matching make found'">
                                <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option [value]=null>All</mat-option>
                        <mat-option *ngFor="let m of filteredMakes" [value]=m.ID>{{m.Name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field>
                    <input matInput type="text" placeholder="Filter" formControlName="filter">
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-card>
<mat-card class="manage-auto-models manage-table">
    <a mat-raised-button color="primary" matTooltip="Add Model" routerLink="new"><mat-icon>add</mat-icon>Add</a>
    <h1 class="title">{{ title }}</h1>
    <table mat-table #table [dataSource]="autoModels" class="table mat-elevation-z7 gutters sticky-header">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Model</th>
            <td mat-cell data-label="AutoModel" *matCellDef="let element"> {{element.Name}} </td>
        </ng-container>

        <ng-container matColumnDef="make">
            <th mat-header-cell *matHeaderCellDef>Make</th>
            <td mat-cell data-label="Make" *matCellDef="let element"> {{element.Make.Name}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell data-label="Actions" *matCellDef="let element">
                <a mat-raised-button color="primary" matTooltip="Edit Model" [routerLink]="['edit', element.ID]"><mat-icon>edit</mat-icon></a>
                <a mat-raised-button color="warn" matTooltip="Delete Model" (click)="delete(element.ID, element.Name)"><mat-icon>delete</mat-icon></a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</mat-card>