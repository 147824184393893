import { Routes } from '@angular/router';

import { LoginComponent } from './login.component';
import { LogoutComponent } from './logout.component';
import { LogoutResolve } from './logout.resolve';
import { SignUpComponent } from './sign-up.component';
import { UserRolesResolve } from '../user';

export const AUTH_ROUTES: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        data: {
            title: 'Login'
        }
    },
    {
        path: 'logout',
        component: LogoutComponent,
        resolve: {
            isLoggedOut: LogoutResolve
        }
    },
    {
        path: 'sign-up',
        component: SignUpComponent,
        resolve: {
            roles: UserRolesResolve
        }
    }
]