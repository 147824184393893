import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from '../common/dev/log';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { Driver } from './driver';
import { DriverService } from './driver.service';
import { Observable } from 'rxjs';
import { startWith, map, tap, take } from 'rxjs/operators';
import { STATES } from '../common/state';
import { UniqueNumberValidator, hasNameValidator } from '../common/validators';

@Component({
    selector: "edit-driver",
    templateUrl: "./edit-driver.component.html"
})

export class EditDriverComponent implements OnInit {

    filteredDrivers: Observable<Driver[]>;

    title: string;
    isEdit: boolean;
    isSearch: boolean;
    nextDriverNumber: string;

    getDriverNumber = () => this.currentDriver.DriverNumber;

    form: FormGroup;

    public drivers: Driver[];
    public states = STATES;
    public currentDriver: Driver;
    
    constructor (
        private route: ActivatedRoute,
        private apps: AppService,
        private ds: DriverService,
        private router: Router
    ) {
        this.currentDriver = new Driver();
        this.form = this.createFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            edit: boolean,
            search: boolean,
            nextDriverNumber: string,
            currentDriver: Driver,
        }) => {
            log.Debug("data: ", data)
            this.title = data.title;
            this.isEdit = data.edit;
            this.isSearch = data.search;
            this.nextDriverNumber = data.nextDriverNumber
            this.drivers = []

            this.filteredDrivers = this.form.controls['DriverNumber'].valueChanges.pipe(
                startWith(''),
                map(value => this._filterDrivers(value))
            );

            if (!this.isEdit && !this.isSearch) {
                this.form.controls['DriverNumber'].setValue(this.nextDriverNumber);
            } else if ( this.isEdit ) {
                Object.assign(this.currentDriver, data.currentDriver);
                this.form.patchValue(this.currentDriver);
                this.drivers = []
                log.Debug("current driver number: ", this.currentDriver.DriverNumber)
            } else {
                this.form.get('DriverNumber').clearValidators();
                this.form.get('DriverNumber').clearAsyncValidators();
                this.form.get('DriverNumber').updateValueAndValidity();

                this.form.get('LastName').clearValidators();
                this.form.get('LastName').updateValueAndValidity();

                this.form.get('Commission').clearValidators();
                this.form.get('Commission').updateValueAndValidity();
                this.drivers = []
            }

        })
    }

    createFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            DriverNumber: new FormControl(null, {
                validators: [Validators.required],
                asyncValidators: [UniqueNumberValidator.createValidator({number: this.getDriverNumber, service: this.ds})]
            }),
            LastName: new FormControl(null, Validators.required),
            FirstName: new FormControl(),
            MiddleInitial: new FormControl(),
            Phone: new FormControl(),
            Commission: new FormControl(null, Validators.required),
        })
    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }

    gotoNew() {
        this.router.navigate(['../../', 'new'], {relativeTo: this.route});
    }

    private _filterDrivers(value: string): Driver[] {
        if(!this.drivers) {
            return null
        }
        return this.drivers.filter(option => option.DriverNumber.toLowerCase().indexOf(value.toLowerCase()) === 0);
    }

    onSubmit(e): void {

        if( this.isSearch && this.form.dirty ) {
            this.form.setErrors(null)
            this.ds.GetByNumber(this.form.get('DriverNumber').value).pipe(take(1)).subscribe(driverID => {
                if (driverID == -1) {
                    this.apps.Error('Cannot find an driver with that driver number.');
                    return
                }
                this.router.navigate([driverID], {relativeTo: this.route});
            });
            
            return
        }

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched: ", this.form)
            return;
        }

        this.save();
    }

    save() {
        this.ds.save(this.form.value)
        .subscribe((savedDriver: Driver) => {
            this.apps.Confirmation('Driver saved.');
            this.form.markAsPristine();

            if( !this.isEdit ) {
                this.currentDriver = new Driver(savedDriver);
                this.drivers.push(this.currentDriver);
                this.router.navigate(['../', 'edit', savedDriver.ID], {relativeTo: this.route});
            }

            const index = this.drivers.findIndex( driver => driver.ID === this.currentDriver.ID );
            if (index != -1) {
                Object.assign(this.drivers[index], new Driver(savedDriver));
            }
            Object.assign(this.currentDriver, savedDriver)
            this.form.patchValue(savedDriver, {emitEvent: false});
        })
    }

    delete(e) {
        e.preventDefault();
        e.stopPropagation();
        if (confirm('This driver will be deleted. Are you sure?')) {
            this.ds.delete(this.currentDriver).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Driver deleted.');
                    const index = this.drivers.findIndex( driver => driver.ID === this.currentDriver.ID);
                    this.drivers.splice(index, 1);
                    this.router.navigate(['dashboard']);
                }
            })
        }
    }

}