import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MakeService } from './make.service';
import { Make } from './make';

@Injectable()
export class MakesResolve implements Resolve<Make[]> {
    constructor (
        private router: Router,
        private ms: MakeService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<Make[]> {
        return this.ms.GetAll();
    }
}