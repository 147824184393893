import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { log } from '../dev/log';
import { Feed, FeedService } from '../../feed';
import { AppService } from '../../app.service';

@Component({
    selector: 'feeds-dialog',
    templateUrl: 'feeds-dialog.component.html'
})

export class FeedsDialog implements OnInit {

    form: FormGroup;
    feeds: Feed[];

    constructor (
        public dialogRef: MatDialogRef<FeedsDialog>,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private fs: FeedService,
        private apps: AppService
    ) {
        this.form = this.createFormGroup();
    }

    ngOnInit(): void {
        console.log("data :", this.data['items'][0])
        console.log("data: ", typeof this.data['items'][0])
        this.feeds = this.data['items'];
        for (let f in this.feeds) {
            this.addFeed();
        }
        this.form.get('Feeds').patchValue(this.feeds);
    }
    
    close(): void {
        this.dialogRef.close();
    }

    createFormGroup() {
        return new FormGroup({
            Feeds: new FormArray([])
        })
    }

    createFeedFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            AutoID: new FormControl(this.data['autoID']),
            Description: new FormControl(),
            Created: new FormControl(),
        })
    }

    addFeed(): void {
        let feeds = this.form.get('Feeds') as FormArray;
        feeds.push(this.createFeedFormGroup());
    }
    removeFeed(i: number): void {
        let feeds = this.form.get('Feeds') as FormArray;
        feeds.removeAt(i);
        this.feeds.splice(i, 1)
    }

    onSubmit(e): void {

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched: ", this.form)
            return;
        }

        this.save();
    }

    save() {
        this.fs.saveAll(this.form.get('Feeds').value)
        .subscribe((savedFeeds: Feed[]) => {
            this.apps.Confirmation('Feeds saved.');
            this.form.markAsPristine();

            log.Debug("savedFeeds: ", savedFeeds)
            this.form.get('Feeds').patchValue(savedFeeds);
            this.feeds = savedFeeds
        })
    }

    deleteFeed(e, index: number) {
        e.preventDefault();
        e.stopPropagation();


        const feed = this.feeds[index] as Feed;
        if(!Boolean(feed)) {
            log.Debug("feed: ", feed)
            this.removeFeed(index);
            return
        }

        if(confirm("This feed will be deleted. Are you sure?")) {
            this.fs.delete(<Feed>feed).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Feed deleted.');
                    this.removeFeed(index);
                }
            })
        }
    }

    log( feed: any ) {
        console.log(feed);
    }
}