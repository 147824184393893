import { Routes } from '@angular/router';

import { UsersComponent } from './users.component';
import { EditUserComponent } from './edit-user.component';
import { UserRolesResolve } from './user-roles.resolve';
import { CurrentUserResolve } from './current-user.resolve';
import { UsersResolve } from './users.resolve';

export const USER_ROUTES: Routes = [
    {
        path: '',
        component: UsersComponent,
        data: {
            title: 'Users'
        },
        resolve: {
            users: UsersResolve
        },
    },
    {
        path: 'new',
        component: EditUserComponent,
        data: {
            title: 'New User',
            edit: false,
        },
        resolve: {
            userRoles: UserRolesResolve,
            users: UsersResolve
        }
    },
    {
        path: 'edit',
        children: [
            {
                path: ':user_id',
                component: EditUserComponent,
                data: {
                    title: 'Edit User',
                    edit: true,
                },
                resolve: {
                    currentUser: CurrentUserResolve,
                    userRoles: UserRolesResolve,
                    users: UsersResolve
                }
            }
        ]
    }
]