<mat-card class="search search-autos">
    <h1 class="title">{{ title }}</h1>
    <div class="row gutters">
        <div class="col">
            <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
                <mat-card class="search-panel">
                    <mat-card-content>
                        <div class="row">
                            <div class="col">
                                <mat-form-field>
                                    <input type="text" placeholder="Auto Number" matInput formControlName="AutoNumber">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                    <mat-select placeholder="Status" formControlName="StatusID">
                                        <mat-option *ngFor="let status of statuses" [value]="status.ID">{{ status.Name }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <mat-select placeholder="Status Description" formControlName="StatusDescriptionID">
                                        <mat-option *ngFor="let statusDescription of statusDescriptions" [value]="statusDescription.ID">{{ statusDescription.Name }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                    <mat-select placeholder="Impound Type" formControlName="ImpoundTypeID">
                                        <mat-option *ngFor="let impoundType of impoundTypes" [value]="impoundType.ID">{{ impoundType.Name }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Year" formControlName="AutoYear">
                                    <mat-error *ngIf="form.get('AutoYear')?.invalid">{{ getErrorMessage(form.get('AutoYear')) }}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>Make</mat-label>
                                    <pgl-search-select
                                        placeholder="Make"
                                        label="Make"
                                        formControlName="Make"
                                        [options]="filteredMakes$ | async"
                                        [filterWith]="filterMakes"
                                        [displayWith]="displayMakes"
                                        startWith="a"
                                        (onSelect)="initializeAutoModels"
                                        autoActiveFirstOption>
                                    </pgl-search-select>
                                    <mat-error *ngIf="form.get('Make')?.invalid">{{ getErrorMessage(form.get('Make')) }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>Model</mat-label>
                                    <pgl-search-select
                                        placeholder="Model"
                                        label="Model"
                                        formControlName="AutoModel" 
                                        [options]="filteredAutoModels$ | async"
                                        [filterWith]="filterAutoModels"
                                        [displayWith]="displayAutoModels"
                                        autoActiveFirstOption>
                                    </pgl-search-select>
                                    <mat-error *ngIf="form.get('AutoModel')?.invalid">{{ getErrorMessage(form.get('AutoModel')) }}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                    <input matInput [matDatepicker]="DateInStart" placeholder="Date In Start" formControlName="DateInStart" [max]="form.value.DateInEnd">
                                    <mat-datepicker-toggle matSuffix [for]="DateInStart"></mat-datepicker-toggle>
                                    <mat-datepicker #DateInStart></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <input matInput [matDatepicker]="DateInEnd" placeholder="Date In End" formControlName="DateInEnd" [min]="form.value.DateInStart">
                                    <mat-datepicker-toggle matSuffix [for]="DateInEnd"></mat-datepicker-toggle>
                                    <mat-datepicker #DateInEnd></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                    <input matInput [matDatepicker]="DateOutStart" placeholder="Date Out Start" formControlName="DateOutStart" [max]="form.value.DateOutEnd">
                                    <mat-datepicker-toggle matSuffix [for]="DateOutStart"></mat-datepicker-toggle>
                                    <mat-datepicker #DateOutStart></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <input matInput [matDatepicker]="DateOutEnd" placeholder="Date Out End" formControlName="DateOutEnd" [min]="form.value.DateOutStart">
                                    <mat-datepicker-toggle matSuffix [for]="DateOutEnd"></mat-datepicker-toggle>
                                    <mat-datepicker #DateOutEnd></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Color" formControlName="Color">
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <mat-select placeholder="State" formControlName="State">
                                        <mat-option *ngFor="let state of states" [value]="state.Code">{{ state.Code }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="VIN" formControlName="Vin">
                                    <mat-error *ngIf="form.get('Vin')?.invalid">{{ getErrorMessage(form.get('Vin')) }}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Tag #" formControlName="Tag">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Owner First Name" formControlName="OwnerFirstName">
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Owner Last Name/Company" formControlName="OwnerLastNameCompany">
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-actions end>
                        <button color="primary" type="submit" mat-raised-button class="button--float-right"><mat-icon>search</mat-icon>Search</button>
                        <button color="primary" type="button" mat-raised-button class="button--float-right" (click)="reset($event)"><mat-icon>autorenew</mat-icon>Clear</button>
                    </mat-card-actions>
                </mat-card>
            </form>
            <form [formGroup]="download" (ngSubmit)="onDownload($event)" novalidate>
                <mat-card style="margin-top: 10px;">
                    <mat-card-content>
                        <div class="row">
                            <div class="col">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Filename of Download" formControlName="Filename" required>
                                    <mat-error *ngIf="download.get('Filename')?.invalid">{{ getErrorMessage(download.get('Filename')) }}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-actions>
                        <div  *ngIf="downloading" style="width:200px">
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </div>
                        <button color="primary" type="submit" mat-raised-button class="button--float-right"><mat-icon>list_alt</mat-icon>Download</button>
                    </mat-card-actions>
                </mat-card>
            </form>
        </div>
        <div class="col--size-2" style="position: relative;">
            <div class="spinner-container" *ngIf="dataSource.loading$ | async" style="position: absolute; top: 200px; left: 50%; transform: translate( -50%, 0 );">
                <mat-spinner></mat-spinner>
            </div>

            <table mat-table [dataSource]="dataSource">

                <!-- auto_number -->
                <ng-container matColumnDef="auto_number">
                    <th mat-header-cell *matHeaderCellDef>Auto Number</th>
                    <td mat-cell *matCellDef="let auto"><a [routerLink]="['edit', auto.ID]">{{ auto.AutoNumber }}</a></td>
                </ng-container>

                <!-- status -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let auto">{{ getStatusByID(auto.StatusID) }}</td>
                </ng-container>

                <!-- impound_type -->
                <ng-container matColumnDef="impound_type">
                    <th mat-header-cell *matHeaderCellDef>Impound Type</th>
                    <td mat-cell *matCellDef="let auto">{{ getImpoundTypeByID(auto.ImpoundTypeID) }}</td>
                </ng-container>

                <!-- year -->
                <ng-container matColumnDef="year">
                    <th mat-header-cell *matHeaderCellDef>Year</th>
                    <td mat-cell *matCellDef="let auto">{{ auto.AutoYear }}</td>
                </ng-container>

                <!-- make -->
                <ng-container matColumnDef="make">
                    <th mat-header-cell *matHeaderCellDef>Make</th>
                    <td mat-cell *matCellDef="let auto">{{ auto.Make.Name }}</td>
                </ng-container>

                <!-- model -->
                <ng-container matColumnDef="model">
                    <th mat-header-cell *matHeaderCellDef>Model</th>
                    <td mat-cell *matCellDef="let auto">{{ auto.AutoModel.Name }}</td>
                </ng-container>

                <!-- date_in -->
                <ng-container matColumnDef="date_in">
                    <th mat-header-cell *matHeaderCellDef>Date In</th>
                    <td mat-cell *matCellDef="let auto">{{ auto.DateIn | date:'shortDate':'UTC' }}</td>
                </ng-container>

                <!-- date_out -->
                <ng-container matColumnDef="date_out">
                    <th mat-header-cell *matHeaderCellDef>DateOut</th>
                    <td mat-cell *matCellDef="let auto">{{ auto.DateOut | date:'shortDate':'UTC' }}</td>
                </ng-container>

                <!-- color -->
                <ng-container matColumnDef="color">
                    <th mat-header-cell *matHeaderCellDef>Color</th>
                    <td mat-cell *matCellDef="let auto">{{ auto.Color }}</td>
                </ng-container>

                <!-- state -->
                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef>State</th>
                    <td mat-cell *matCellDef="let auto">{{ auto.State }}</td>
                </ng-container>

                <!-- vin -->
                <ng-container matColumnDef="vin">
                    <th mat-header-cell *matHeaderCellDef>Vin</th>
                    <td mat-cell *matCellDef="let auto">{{ auto.Vin }}</td>
                </ng-container>

                <!-- tag -->
                <ng-container matColumnDef="tag">
                    <th mat-header-cell *matHeaderCellDef>Tag</th>
                    <td mat-cell *matCellDef="let auto">{{ auto.Tag }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [length]="dataSource.length$ | async" [pageSize]="10" [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
        </div>
    </div>
</mat-card>
<pre class="debug">{{ form.value | json }}</pre>
