<mat-card class="small-form deck" style="margin-bottom: 15px;">
    <form [formGroup]="filterForm">
        <div class="row gutters">
            <div class="col">
                <mat-form-field>
                    <input matInput="matInput" type="text" placeholder="Filter" formControlName="filter">
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-card>

<mat-card class="manage-makes small-form manage-table">
    <h1 class="title">{{ title }}</h1>
    
    <mat-card-actions end="end">
        <button type="button" mat-raised-button="mat-raised-button" color="primary" (click)="addMake($event)">
            <mat-icon>add</mat-icon>Add</button>
    </mat-card-actions>

        <mat-card-content>
            <table mat-table #table [dataSource]="autoMakes" class="table sticky-header">
                <ng-container matColumnDef="Make">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let make">{{ make.Name }}</td>
                </ng-container>

                <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell  *matCellDef="let make">
                        <a mat-raised-button="mat-raised-button" color="primary" [routerLink]="['edit', make.ID]">
                            <mat-icon>edit</mat-icon>
                        </a>
                        <a mat-raised-button="mat-raised-button" color="warn" (click)="deleteMake(make.ID)">
                            <mat-icon>delete</mat-icon>
                        </a>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        </mat-card-content>

    </mat-card>