import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { log } from '../common/dev/log';

@Injectable()
export class LogoutResolve implements Resolve<boolean> {
    constructor (
        private router: Router,
        private auths: AuthService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<boolean> {
    	log.Debug("in logout resolve");
        return this.auths.Logout();
    }
}