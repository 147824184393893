<div id="app">
    <div id="main-navigation">
        <div class="wrapper">
            <ul class="menu menu--main">
                <li><a tabindex="-1" class="menu-item" routerLink="dashboard">Home</a></li>
                <ng-container *ngFor="let menu of MainMenu">
                    <li *ngIf="hasPermission(menu)"><button class="menu-item" tabindex="-1">{{ menu.Name }}</button>
                        <ul *ngIf="menu.SubMenu" class="menu menu--sub">
                            <ng-container *ngFor="let submenu of menu.SubMenu">
                                <li *ngIf="hasPermission(submenu)"><a class="menu-item" [routerLink]="submenu.Link">{{ submenu.Name }}</a ></li>
                            </ng-container>
                        </ul>
                    </li>
                </ng-container>
                <li *ngIf="IsLoggedIn"><a tabindex="-1" class="menu-item" routerLink="logout">Logout</a></li>
            </ul>
        </div>
        <div class="clear"></div>
    </div>
    <div id="content">
        <div class="wrapper">
            <router-outlet #o="outlet"></router-outlet>
        </div>
    </div>
</div>