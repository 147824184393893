<div id="print-section" style="position: relative;">
<html>
<head><meta http-equiv=Content-Type content="text/html; charset=UTF-8">
<style type="text/css">
<!--
span.cls_002{font-family:Arial,serif;font-size:8.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_002{font-family:Arial,serif;font-size:8.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_005{font-family:Arial,serif;font-size:8.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_005{font-family:Arial,serif;font-size:8.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_006{font-family:Arial,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_006{font-family:Arial,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_008{font-family:Arial,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_008{font-family:Arial,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_011{font-family:Arial,serif;font-size:8.9px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_011{font-family:Arial,serif;font-size:8.9px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_012{font-family:Arial,serif;font-size:9.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_012{font-family:Arial,serif;font-size:9.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
-->
</style>
<script type="text/javascript" src="3c410e04-44d7-11e9-9d71-0cc47a792c0a_id_3c410e04-44d7-11e9-9d71-0cc47a792c0a_files/wz_jsgraphics.js"></script>
</head>
<body>
<div style="position:absolute;left:50%;margin-left:-306px;top:0px;width:612px;height:792px;border-style:outset;overflow:hidden">
<div style="position:absolute;left:0px;top:0px">
<img src="assets/images/mv-603a-form-background.jpg" width=612 height=792></div>
<div style="position:absolute;left:43.20px;top:27.96px" class="cls_002"><span class="cls_002">MV-603A (Rev. 09-2014)</span></div>
<div style="position:absolute;left:43.20px;top:46.32px" class="cls_002"><span class="cls_002">State of Georgia</span></div>
<div style="position:absolute;left:43.20px;top:55.56px" class="cls_005"><span class="cls_005">County of</span></div>
<!-- Input -->
<div style="position:absolute;left:89px;top:54px" class="cls_008"><span class="cls_008">{{ fields.CompanyCounty }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:210.00px;top:64.20px" class="cls_006"><span class="cls_006">ABANDONED VEHICLE AFFIDAVIT</span></div>
<div style="position:absolute;left:43.20px;top:88.80px" class="cls_008"><span class="cls_008">I,</span></div>
<div style="position:absolute;left:340.08px;top:88.80px" class="cls_008"><span class="cls_008">, do hereby solemnly swear or affirm that the vehicle</span></div>
<div style="position:absolute;left:109.92px;top:100.32px" class="cls_002"><span class="cls_002">(Owner/Representative of Towing Company)</span></div>
<!-- Input -->
<div style="position:absolute;left:60px;top:89px" class="cls_008"><span class="cls_008">{{ fields.CompanyAgent }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:43.20px;top:109.56px" class="cls_008"><span class="cls_008">described as:</span></div>
<div style="position:absolute;left:557.75px;top:109.56px" class="cls_008"><span class="cls_008">that</span></div>
<div style="position:absolute;left:129.12px;top:121.08px" class="cls_002"><span class="cls_002">(Vehicle Identification Number)</span></div>
<!-- Input -->
<div style="position:absolute;left:114px;top:110px" class="cls_008"><span class="cls_008">{{ fields.VIN }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:262.45px;top:121.08px" class="cls_002"><span class="cls_002">(Vehicle Year)</span></div>
<!-- Input -->
<div style="position:absolute;left:263px;top:110px" class="cls_008"><span class="cls_008">{{ fields.VehicleYear }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:373.45px;top:121.08px" class="cls_002"><span class="cls_002">(Make)</span></div>
<!-- Input -->
<div style="position:absolute;left:374px;top:110px" class="cls_008"><span class="cls_008">{{ fields.Make }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:471.48px;top:121.08px" class="cls_005"><span class="cls_005">(Model)</span></div>
<!-- Input -->
<div style="position:absolute;left:472px;top:110px" class="cls_008"><span class="cls_008">{{ fields.Model }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:43.20px;top:139.44px" class="cls_008"><span class="cls_008">was  removed  to  and/or  has  been  stored  at:</span></div>
<div style="position:absolute;left:386.64px;top:150.96px" class="cls_002"><span class="cls_002">(Location)</span></div>
<!-- Input -->
<div style="position:absolute;left:268px;top:140px" class="cls_008"><span class="cls_008">{{ fields.Location }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:43.20px;top:160.20px" class="cls_008"><span class="cls_008">is an abandoned vehicle as defined in OCGA §40-11-1 (1), and has not been claimed by the owner, lessor, lessee or</span></div>
<div style="position:absolute;left:43.11px;top:171.60px" class="cls_008"><span class="cls_008">any holder of a security interest or lien.</span></div>
<div style="position:absolute;left:43.10px;top:193.56px" class="cls_008"><span class="cls_008">I hereby further solemnly swear or affirm that in following the procedure to foreclose on an abandoned vehicle lien on</span></div>
<div style="position:absolute;left:43.00px;top:204.96px" class="cls_008"><span class="cls_008">said vehicle, I have complied with the requirements of OCGA §40-11-2 as evidenced by the following:</span></div>
<div style="position:absolute;left:60.99px;top:226.80px" class="cls_008"><span class="cls_008">1.</span></div>
<div style="position:absolute;left:96.86px;top:226.80px" class="cls_008"><span class="cls_008">Within three (3) days of the removal or storage of said vehicle, I obtained the identity and address of all</span></div>
<div style="position:absolute;left:97.01px;top:238.33px" class="cls_008"><span class="cls_008">known owners of such vehicle from the law enforcement officer requesting removal of the vehicle or from a</span></div>
<div style="position:absolute;left:96.91px;top:249.85px" class="cls_008"><span class="cls_008">local law  enforcement agency for the jurisdiction  in  which my business is  located as evidenced by</span></div>
<div style="position:absolute;left:96.94px;top:261.37px" class="cls_008"><span class="cls_008">document(s) attached hereto and incorporated herein as “Exhibit A”; and,</span></div>
<div style="position:absolute;left:60.94px;top:273.02px" class="cls_008"><span class="cls_008">2.</span></div>
<div style="position:absolute;left:96.81px;top:273.02px" class="cls_008"><span class="cls_008">Using the information contained in Exhibit A, I notified or attempted to notify the owner, lessor, lessee, or</span></div>
<div style="position:absolute;left:96.98px;top:284.42px" class="cls_008"><span class="cls_008">any holder of a lien or security interest of said vehicle that said vehicle would be deemed abandoned at the</span></div>
<div style="position:absolute;left:97.20px;top:295.80px" class="cls_008"><span class="cls_008">expiration of thirty-days (30) as evidenced by the document(s) attached hereto and incorporated herein as</span></div>
<div style="position:absolute;left:97.13px;top:307.32px" class="cls_008"><span class="cls_008">“Exhibit B”; and,</span></div>
<div style="position:absolute;left:61.12px;top:318.73px" class="cls_008"><span class="cls_008">3.</span></div>
<div style="position:absolute;left:97.00px;top:318.73px" class="cls_008"><span class="cls_008">On or after the thirty-first day, but no later than the thirty-seventh day following the removal or storage of</span></div>
<div style="position:absolute;left:97.14px;top:330.25px" class="cls_008"><span class="cls_008">said vehicle without said vehicle having been redeemed, I gave notice in writing to the Department of</span></div>
<div style="position:absolute;left:97.12px;top:341.66px" class="cls_008"><span class="cls_008">Revenue or applicable County Tag Agent (hereinafter referred to as the “Department”) pursuant to the</span></div>
<div style="position:absolute;left:97.05px;top:353.18px" class="cls_008"><span class="cls_008">procedure set forth in OCGA §40-11-2 (e) and requested the name and address of all owners of such</span></div>
<div style="position:absolute;left:96.98px;top:364.58px" class="cls_008"><span class="cls_008">vehicle as evidenced by the document(s) attached hereto and incorporated herein as “Exhibit C”; and,</span></div>
<div style="position:absolute;left:60.92px;top:376.11px" class="cls_008"><span class="cls_008">4.</span></div>
<div style="position:absolute;left:96.79px;top:376.11px" class="cls_008"><span class="cls_008">Within five (5) calendar days of having received the information requested in Exhibit C, I either:</span></div>
<div style="position:absolute;left:96.91px;top:387.63px" class="cls_008"><span class="cls_008">A.   Notified the owner, any lessee, and any holder of a lien or security interest by certified mail and first</span></div>
<div style="position:absolute;left:114.93px;top:399.15px" class="cls_008"><span class="cls_008">class mail; or,</span></div>
<div style="position:absolute;left:96.92px;top:410.68px" class="cls_008"><span class="cls_008">B.   Placed an advertisement in a newspaper of general circulation in the county where such vehicle was</span></div>
<div style="position:absolute;left:114.96px;top:422.20px" class="cls_008"><span class="cls_008">obtained once a week for two (2) consecutive weeks; or,</span></div>
<div style="position:absolute;left:97.20px;top:433.56px" class="cls_008"><span class="cls_008">C.  Placed an advertisement at the county courthouse in such place where other public notices are posted</span></div>
<div style="position:absolute;left:115.24px;top:445.08px" class="cls_008"><span class="cls_008">for two (2) consecutive weeks;</span></div>
<div style="position:absolute;left:61.10px;top:456.49px" class="cls_008"><span class="cls_008">5.</span></div>
<div style="position:absolute;left:96.98px;top:456.49px" class="cls_008"><span class="cls_008">Advised the owner(s) of their obligations and rights to redeem such vehicle and such owner, lessee or</span></div>
<div style="position:absolute;left:97.05px;top:468.01px" class="cls_008"><span class="cls_008">holder of a lien or security interest disclaimed their ownership or interest in such vehicle as evidenced by the</span></div>
<div style="position:absolute;left:97.00px;top:479.42px" class="cls_008"><span class="cls_008">document(s) attached hereto and incorporated herein as “Exhibit D”; and,</span></div>
<div style="position:absolute;left:61.20px;top:490.92px" class="cls_008"><span class="cls_008">6.</span></div>
<div style="position:absolute;left:97.08px;top:490.92px" class="cls_008"><span class="cls_008">I have made a demand for payment of</span></div>
<!-- Input -->
<div style="position:absolute;left:294px;top:491px" class="cls_008"><span class="cls_008">{{ fields.Payment | currency }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:280.44px;top:490.92px" class="cls_008"><span class="cls_008">$</span></div>
<div style="position:absolute;left:366.12px;top:490.92px" class="cls_008"><span class="cls_008">, which has been made without satisfaction, as</span></div>
<div style="position:absolute;left:97.20px;top:502.32px" class="cls_008"><span class="cls_008">evidenced by the document(s) attached hereto and incorporated herein as “Exhibit E”, without a timely filing</span></div>
<div style="position:absolute;left:97.12px;top:513.84px" class="cls_008"><span class="cls_008">of a petition for a judicial hearing and/or the identity of the owner cannot be ascertained.</span></div>
<div style="position:absolute;left:43.20px;top:535.44px" class="cls_008"><span class="cls_008">Therefore, I am asking a court of competent jurisdiction to authorize the foreclosure of my lien and the sale of this motor</span></div>
<div style="position:absolute;left:43.12px;top:546.96px" class="cls_008"><span class="cls_008">vehicle.</span></div>
<div style="position:absolute;left:65.40px;top:577.20px" class="cls_002"><span class="cls_002">(Signature of Owner/Representative of Towing Company)</span></div>
<div style="position:absolute;left:449.05px;top:576.72px" class="cls_002"><span class="cls_002">(Date)</span></div>
<!-- Input -->
<div style="position:absolute;left:379px;top:567px" class="cls_008"><span class="cls_008">{{ fields.CurrentDate | date:'longDate':'UTC' }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:367.32px;top:596.40px" class="cls_011"><span class="cls_011">S</span><span class="cls_012">worn and Subscribed before me </span><span class="cls_011">this:</span></div>
<div style="position:absolute;left:103.32px;top:606.96px" class="cls_002"><span class="cls_002">(Address of Towing Company)</span></div>
<!-- Input -->
<div style="position:absolute;left:55px;top:596px" class="cls_008"><span class="cls_008">{{ fields.CompanyAddress }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:537.00px;top:606.48px" class="cls_002"><span class="cls_002">(Day)</span></div>
<!-- Input -->
<div style="position:absolute;left:533px;top:596px" class="cls_008"><span class="cls_008">{{ fields.CurrentDay }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:367.92px;top:625.32px" class="cls_002"><span class="cls_002">Day of</span></div>
<div style="position:absolute;left:486.01px;top:625.32px" class="cls_002"><span class="cls_002">, </span><span class="cls_005">20</span></div>
<div style="position:absolute;left:109.92px;top:634.44px" class="cls_005"><span class="cls_005">(City,</span><span class="cls_002"> State, Zip & County)</span></div>
<!-- Input -->
<div style="position:absolute;left:55px;top:624px" class="cls_008"><span class="cls_008">{{ fields.CompanyCityStateZipCounty }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:429.02px;top:634.44px" class="cls_002"><span class="cls_002">(Month)</span></div>
<!-- Input -->
<div style="position:absolute;left:405px;top:624px" class="cls_008"><span class="cls_008">{{ fields.CurrentMonth }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:523.09px;top:634.44px" class="cls_002"><span class="cls_002">(Year)</span></div>
<!-- Input -->
<div style="position:absolute;left:511px;top:624px" class="cls_008"><span class="cls_008">{{ fields.CurrentYearTruncated }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:83.64px;top:662.16px" class="cls_002"><span class="cls_002">(Telephone Number Including Area Code)</span></div>
<!-- Input -->
<div style="position:absolute;left:55px;top:651px" class="cls_008"><span class="cls_008">{{ fields.CompanyPhone }}</span></div>
<!-- End Input -->
<div style="position:absolute;left:380.04px;top:661.68px" class="cls_002"><span class="cls_002">(Notary Public’s Signature & Notary Seal or Stamp)</span></div>
<div style="position:absolute;left:405.96px;top:691.68px" class="cls_002"><span class="cls_002">(Date My Notary Commission </span><span class="cls_005">Expires)</span></div>
</div>
</body>
</html>
<button mat-raised-button (click)="print()" color="primary" style="position: absolute; left: 0; top: 0;"><mat-icon>print</mat-icon>Print</button>
</div>