import { Model } from '../common/model';

export class Owner extends Model {

    LastName: string;
    FirstName: string;
    Company: string;
    MiddleInitial: string;
    Address1: string;
    Address2: string;
    City: string;
    State: string;
    Zip: number;
    Phone1: string;
    Phone2: string;
    Fax: string;
    Active: boolean;
    Created: Date;
    Modified: Date;

    constructor(properties?: Object) {
        super(properties);
    }

}