import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from '../common/dev/log';
import { MONTHS } from '../common/month';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Statement } from '../invoice/statement';
import { InvoiceService } from '../invoice/invoice.service';
import { CustomerService } from '../customer/customer.service';
import { FormGroup, FormControl } from '@angular/forms';
import { map, debounceTime, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoadingService } from '../common/loading.service';
import { Customer } from '../customer';

@Component({
    selector: "statements-pdf ",
    templateUrl: "./statements-pdf.component.html"
})

export class StatementsPDFComponent implements OnInit {

    form: FormGroup;

    isLoading = true;
    downloading = false;

    public invoiceCustomers: Customer[];

    constructor (
        private route: ActivatedRoute,
        private is: InvoiceService,
        public ls: LoadingService,
        private cdr: ChangeDetectorRef,
        private cs: CustomerService
    ){
        this.form = this.createFormGroup()
    }
    ngOnInit(): void {
        this.cs.GetAllInvoiceDue().subscribe( val => {
            this.invoiceCustomers = val;
        })
        this.route.data.subscribe((data: {
        }) => {
            log.Debug("data: ", data);
        });
    }

    createFormGroup(): FormGroup {
        return new FormGroup({
            StatementFrequency: new FormControl(),
            IndividualStatement: new FormControl()
        });
    }

    statementsPDF() {
        this.downloading = true;
        this.is.SearchStatementsPDF(this.form.get('StatementFrequency').value, this.form.get('IndividualStatement').value).subscribe( blob => {
            const a = document.createElement('a');
            a.setAttribute('download', 'statements.pdf');
            a.setAttribute('href', window.URL.createObjectURL(blob));
            a.click()
            this.downloading = false;
        })
    }

}