import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpService } from '../common/services';
import { log } from '../common/dev/log';
import { User } from '../user';
import { UserRoleCache } from './user-role-cache';
import { AuthGroup, AuthGroups } from '../common/model';

@Injectable({
    providedIn: 'root'
})

export class AuthService extends HttpService implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    // private _hasPermission$ = new BehaviorSubject(false);
    // public hasPermission$ = this._hasPermission$.asObservable();

    private _isLoggedIn$ = new BehaviorSubject(false);
    public isLoggedIn$ = this._isLoggedIn$.asObservable();

    constructor (
        protected http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        super(http);
        this.router.events.subscribe( log.Debug )
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    Login( user: User ): Observable<User> {
        const headers = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' })
        };
        log.Debug("login")
        return this.http.post<User>('/api/users/login', user, headers).pipe(
            map(val => new User(val)),
            tap( _ => this._isLoggedIn$.next(true) ),
            tap( _ => log.Debug("login2"))
        );
    }

    Logout(): Observable<boolean> {
        this.ClearLocalUserRole();
        //this.http.get('/api/users/logout');
        log.Debug("logout: ")
        return this.http.get<boolean>('/api/users/logout').pipe(
            map(val => val),
            tap(_ => this._isLoggedIn$.next(false))
        );
    }

    GetLoggedInUser(): Observable<User> {
        return this.http.get<User>('/api/users/current-user').pipe(map(val => new User(val)));
    }

    SetLocalUserRole(user: User): void {
        log.Debug("setting user role")
        localStorage.setItem('user_role', user.Role);
        localStorage.setItem('user_created', new Date().toString())
    }

    LocalUserRoleIsValid( role, created: string ): boolean {

        if( role == null || created == null ) {
            log.Debug("role is null")
            return false
        }

        const duration = 8*60*60*1000
        //for testing
        //const duration = 20*1000

        let testDate = new Date(created)
        testDate.setTime(testDate.getTime() + (duration))

        if( testDate < new Date()) {
            log.Debug("role is old: ", testDate)
            return false
        }

        return true

    }

    GetLocalUserRole(): string {
        let role = localStorage.getItem('user_role');
        let created = localStorage.getItem('user_created');

        log.Debug("role: ", role)
        log.Debug("role type", typeof role )
        log.Debug("role includes { ", role.includes("{"))
        log.Debug("route: ", this.route)
        log.Debug("router: ", this.router)

        if( (role.includes("{") || role.includes("}") || typeof role === 'undefined' || role === null || role == '')
        	&& this.router.url != "/sign-up" && this.router.url != "/login" && this.router.url != "/logout" ) {
            log.Debug("in role null check: ", this.router.url)
            this.router.navigate(['login']);
            return ''
        }

        return role
    }

    ClearLocalUserRole(): void {
        localStorage.setItem('user_role', '');
        localStorage.setItem('user_created', '');
    }

    public userHasRole(): boolean {
        const role = localStorage.getItem('user_role');
        if( !role ) {
            return false
        }
        if( role == null || role == '' || role == null ) {
            return false
        }
        return true
    }

    HasPermission( authRoles: AuthGroup[] ): boolean {

        if( authRoles == null ) {
            log.Debug("authgroup is null")
            return false
        }

        const role = localStorage.getItem('user_role');
        const created = localStorage.getItem('user_created');

        if( !this.LocalUserRoleIsValid(role, created) ) {
            log.Debug("user role is not valid")
            this.Logout()
            return false
        }

        let userRole = this.GetLocalUserRole()

        if( !Boolean(userRole) ) {
            return false
        }

        log.Debug("has permission: ", authRoles.indexOf(AuthGroups[userRole.toUpperCase()]) > -1 )

        return authRoles.indexOf(AuthGroups[userRole.toUpperCase()]) > -1

    }

    


}