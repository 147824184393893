import { ValidatorFn, AbstractControl } from '@angular/forms';

export function idSelectedValidator(): ValidatorFn {
    return ( control: AbstractControl ): {[key: string]: any} | null => {
        let isNumber = false;
        if( typeof control.value === 'number' ) {
            isNumber = true;
        }

        return !isNumber ? {'isNotID': !isNumber} : null;
    };
}