import { Model } from '../common/model';
import { Transaction } from './transaction';

export class TransactionSearch extends Model {

    Transaction: Transaction;
    Filter: string;
    SortOrder: string;
    PageIndex: number;
    PageSize: number;

    constructor(properties?: Object) {
        super(properties);
    }

}