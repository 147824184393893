import { Model } from '../common/model';

export class AutoSearchData extends Model {

    AutoNumber: string;
    StatusID: number;
    ImpoundTypeID: number;
    AutoYear: string;
    MakeID: number;
    AutoModelID: number;
    DateInStart: Date;
    DateInEnd: Date;
    DateOutStart: Date;
    DateOutEnd: Date;
    Color: string;
    State: string;
    Vin: string;
    Tag: string;
    OwnerFirstName: string;
    OwnerLastNameCompany: string;

    Filename: string;
    

    constructor(properties?: Object) {
        super(properties);
    }

}