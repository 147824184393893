import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AutoModelService } from './auto-model.service';
import { AutoModel } from './auto-model';

@Injectable()
export class CurrentAutoModelResolve implements Resolve<AutoModel> {
    constructor (
        private router: Router,
        private _autoModelService: AutoModelService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<AutoModel> {
        const auto_model_id = +route.paramMap.get('auto_model_id');
        return this._autoModelService.GetByID(auto_model_id);
    }
}