<mat-card class="small-form no-print" style="margin-bottom: 30px;">
    <mat-card-title>By New</mat-card-title>
    <form [formGroup]="isPrintedForm">
        <div class="row gutters">
            <div class="col">
                <mat-form-field>
                    <input matInput placeholder="Driver Number" formControlName="DriverNumber" type="text">
                </mat-form-field>
            </div>
        </div>
    </form>
    <div  *ngIf="isPrintedDownloading" style="width:200px">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <button mat-raised-button (click)="driverCommissionDueReportsPDFByIsInvoicePrinted()" color="primary" style="margin-right: 5px;"><mat-icon>picture_as_pdf</mat-icon>Download</button>
    <button mat-raised-button (click)="driverCommissionDueReportsPDFByIsInvoicePrintedPreview()" color="secondary"><mat-icon>picture_as_pdf</mat-icon>Preview</button>
</mat-card>
<mat-card class="small-form no-print" style="margin-bottom: 30px;">
    <mat-card-title>By Date</mat-card-title>
    <form [formGroup]="form">
        <div class="row gutters">
            <div class="col">
                <mat-form-field>
                    <input matInput [matDatepicker]="StartDate" placeholder="Start Date" formControlName="StartDate" [max]="form.value.EndDate">
                    <mat-datepicker-toggle matSuffix [for]="StartDate"></mat-datepicker-toggle>
                    <mat-datepicker #StartDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field>
                    <input matInput [matDatepicker]="EndDate" placeholder="End Date" formControlName="EndDate" [min]="form.value.StartDate">
                    <mat-datepicker-toggle matSuffix [for]="EndDate"></mat-datepicker-toggle>
                    <mat-datepicker #EndDate></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row gutters">
            <div class="col">
                <mat-form-field>
                    <input matInput placeholder="Driver Number" formControlName="DriverNumber" type="text">
                </mat-form-field>
            </div>
        </div>
    </form>
    <div  *ngIf="downloading" style="width:200px">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <button mat-raised-button (click)="driverCommissionDueReportsPDFByDate()" color="primary"><mat-icon>picture_as_pdf</mat-icon>Download</button>
</mat-card>
