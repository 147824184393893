export class Model {
    ID: number;
    Parent: any;
    protected modelPath: string;

    constructor(properties?: Object) {
        if (!properties) {
            return;
        }

        this.update(properties);
    }

    static getDistict<T, K extends keyof T>(stack: T[], key: K) {
        if (!Boolean(stack)) {
            return [];
        }
        const seen = {};
        return stack.filter(item => {
            const k = item[key].toString().trim();
            return seen.hasOwnProperty(k) ? false : (seen[k] = true);
        });
    }

    static NumLength(num: number): number {

        return num.toString().length;
    }

    static isDate(d): d is Date {
        if (d instanceof Date) {
            return true;
        }
        return false;
    }

    static makeDate(strDate: string | null): Date {
        if (strDate == null) {
            return new Date();
        }
        return new Date(strDate);
    }

    update(properties: Object) {
        // TODO - better way?
        const knownDateFields = ['Created', 'Modified'];
        for (const key in properties) {
            if (!properties.hasOwnProperty(key)) {
                continue;
            }
            let value = properties[key];
            if (knownDateFields.indexOf(key) >= 0) {
                value = Model.isDate(value) ? value : Model.makeDate(value);
            }
            this[key] = value;
        }
    }

    // case sensitive;
    indexOfIn(stack, key?): number {
        key = key || 'ID';
        for ( const index in stack) {
            if (stack[index][key].constructor === this.constructor &&
                stack[index][key] === this[key]) {
                return +index;
            }
        }
        return -1;
    }

    existsIn(stack, key?): boolean {
        const index = this.indexOfIn(stack, key);
        return index > -1 ? true : false;
    }

    copy() {
        return Object.assign({}, this);
    }

    toJSON() {
      const proto = Object.getPrototypeOf(this);
      const jsonObj: any = Object.assign({}, this);    

      Object.entries(Object.getOwnPropertyDescriptors(proto))
        .filter(([key, descriptor]) => typeof descriptor.get === 'function')
        .map(([key, descriptor]) => {
          if (descriptor && key[0] !== '_') {
            try {
              const val = (this as any)[key];
              jsonObj[key] = val;
            } catch (error) {
              console.error(`Error calling getter ${key}`, error);
            }
          }
        });    

      return jsonObj;
    }

}

export interface Metarizer {
    ID?: number;
    Name: string;
    Value: string;
    Created?: string | Date;
    Modified?: string | Date;
}

export class Meta {
    ID?;
}

export class Menu {
    PathTo: string;
    List: MenuItem[];
}

export class MenuItem {
    Name: string;
    Path: any;
}

export type AuthGroup = 'PENDING' | 'STANDARD' | 'ACCOUNTING' | 'ALL' | 'ADMIN' | 'DEV';

export enum AuthGroups {
    PENDING = 'PENDING',
    STANDARD = 'STANDARD',
    ACCOUNTING = 'ACCOUNTING',
    ALL = 'ALL',
    ADMIN = 'ADMIN',
    DEV = 'DEV'
}

export class AuthRoles {
    static ADMIN: AuthGroup[] = ['ADMIN', 'DEV'];
    static ALL: AuthGroup[] = [...AuthRoles.ADMIN, 'ALL'];
    static ACCOUNTING: AuthGroup[] = [...AuthRoles.ALL, 'ACCOUNTING'];
    static STANDARD: AuthGroup[] = ['STANDARD', ...AuthRoles.ACCOUNTING];
    static PENDING: AuthGroup[] = ['PENDING', ...AuthRoles.STANDARD]
}