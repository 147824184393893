import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DailyReportService } from './daily-report.service';
import { DailyReport } from './daily-report';

@Injectable()
export class DailyCreditReportResolve implements Resolve<DailyReport> {
    constructor (
        private router: Router,
        private drs: DailyReportService
    ) {}

    resolve( route: ActivatedRouteSnapshot ): Observable<DailyReport> {
        return this.drs.GetCreditReport();
    }
}