import { Model } from '../common/model';
import { Invoice } from './invoice';

export class InvoiceSearch extends Model {

    Invoice: Invoice;
    Filter: string;
    SortOrder: string;
    PageIndex: number;
    PageSize: number;

    constructor(properties?: Object) {
        super(properties);
    }

}