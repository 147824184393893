import { Routes } from '@angular/router';
import { CurrentMakeResolve } from './current-make.resolve';
import { ManageMakesComponent } from './manage-makes.component';
import { MakesResolve } from './makes.resolve';
import { EditMakeComponent } from './edit-make.component';

export const MAKE_ROUTES: Routes = [
    {
        path: '',
        component: ManageMakesComponent,
        data: {
            title: 'Manage Makes'
        },
        resolve: {
            makes: MakesResolve
        }
    },
    {
        path: 'new',
        component: EditMakeComponent,
        data: {
            title: 'New Make',
            edit: false,
        },
    },
    {
        path: 'edit',
        children: [
            {
                path: ':id',
                component: EditMakeComponent,
                data: {
                    title: 'Edit Make',
                    edit: true,
                },
                resolve: {
                    currentMake: CurrentMakeResolve
                }
            }
        ]
    }
]