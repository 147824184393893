<!-- <pre>{{ myForm.value | json }}</pre> -->

<!-- <button mat-raised-button color="primary" (click)="print($event)"><mat-icon>print</mat-icon>Print</button> -->

<div id="pdf-form-page" class="container">

    <!-- Little trick to center the inputs to the viewer -->
    <!-- <div class="form-container">
        <form id="input-form" [formGroup]="myForm" novalidate> -->
        
            <!-- Cycle through all the inputs -->
            <!-- <div *ngFor="let input of inputList"> -->
                
                <!-- Insert an input accordingly and get the style/position from the component -->
                <!-- <input type="text"
                    [name]="input.name"
                    [formControlName]="input.name" 
                    [value]="input.value" 
                    [ngStyle]="getInputPosition(input)">
            </div>
        </form>
    </div>
    <pdf-viewer id="viewer" [src]="pdfSrc" (after-load-complete)="loadComplete($event)"></pdf-viewer> -->
</div>