import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { InvoiceSearch } from './invoice-search';
import { Invoice } from './invoice';

@Injectable()

export class InvoicesSearchService {

    constructor(
        private http: HttpClient,
    ){}

    SearchInvoices( invoice: Invoice, pageIndex = 0, pageSize = 10): Observable<Invoice[]> {

        const search = new InvoiceSearch({ Invoice: invoice, PageIndex: pageIndex, PageSize: pageSize })

        return this.http.post<Invoice[]>('/api/invoices/search', search).pipe(val => val)
    }

    GetResultsLength( invoice: Invoice ): Observable<number> {
        return this.http.post<number>('/api/invoices/search-results-length', invoice).pipe(val => val)
    }

}