import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../common/services';
import { DeleteResponse } from '../common/delete-response';
import { DailyReport } from './daily-report';
import { log } from '../common/dev/log';
import { Invoice } from '../invoice';

@Injectable({
    providedIn: 'root'
})

export class DailyReportService extends HttpService implements OnDestroy {
    private _subscriptions: Subscription[] = [];

    constructor (
        protected http: HttpClient,
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    init(): void {}

    GetReport(): Observable<DailyReport> {
        return this.http.get<DailyReport>('/api/daily-report/get').pipe(map(val => new DailyReport(val)));
    }

    GetDebitReport(): Observable<DailyReport> {
        return this.http.get<DailyReport>('/api/daily-report/get_debit').pipe(map(val => new DailyReport(val)));
    }

    GetCreditReport(): Observable<DailyReport> {
        return this.http.get<DailyReport>('/api/daily-report/get_credit').pipe(map(val => new DailyReport(val)));
    }

    // toDailyReport(dailyReport) {
    //     const temp = dailyReport.map( dailyReport => {
    //         return new DailyReport(dailyReport);
    //     });
    //     return temp;
    // }

    GetInvoiceDebitAmount(invoice: Invoice): number {
        let payments = invoice.Payments.reduce((acc, cur) => acc + cur.Amount, 0);
        return (payments + invoice.RemainingBalance) || 0;
    }

    GetInvoiceCreditAmount(invoice: Invoice): number {
        let payments = invoice.Payments.reduce((acc, cur) => acc + cur.Amount, 0);
        return payments || 0;
    }

    SearchCashSalesDailyReport(date: Date): Observable<DailyReport> {
        return this.http.post<DailyReport>('/api/daily-report/search_cash', date).pipe(map(val => new DailyReport(val)));
    }

    SearchCreditDailyReport(date: Date): Observable<DailyReport> {
        return this.http.post<DailyReport>('/api/daily-report/search_credit', date).pipe(map(val => new DailyReport(val)));
    }

    SearchDebitDailyReport(date: Date): Observable<DailyReport> {
        return this.http.post<DailyReport>('/api/daily-report/search_debit', date).pipe(map(val => new DailyReport(val)));
    }
}