import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthGroup, AuthRoles } from '../common/model';
import { AuthService } from '../user_auth';
import { log } from '../common/dev/log';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {

    public DashboardItems = [
        {
            Name: "Autos",
            Buttons: [
                {Name: "New", Icon: "add", Link: "../autos/new"},
                {Name: "Edit", Icon: "edit", Link: "../autos/edit"},
                {Name: "Search", Icon: "search", Link: "../autos"}
            ],
            HasPermission: this.hasPermission(AuthRoles.STANDARD)
        },
        {
            Name: "Invoices",
            Buttons: [
                {Name: "New", Icon: "add", Link: "../invoices/new"},
                {Name: "Edit", Icon: "edit", Link: "../invoices/edit"},
                {Name: "Search", Icon: "search", Link: "../invoices"}
            ],
            HasPermission: this.hasPermission(AuthRoles.ACCOUNTING)
        },
        {
            Name: "Customers",
            Buttons: [
                {Name: "New", Icon: "add", Link: "../customers/new"},
                {Name: "Edit", Icon: "edit", Link: "../customers/edit"},
                {Name: "Search", Icon: "search", Link: "../customers"}
            ],
            HasPermission: this.hasPermission(AuthRoles.ACCOUNTING)
        },
        {
            Name: "Drivers",
            Buttons: [
                {Name: "New", Icon: "add", Link: "../drivers/new"},
                {Name: "Edit", Icon: "edit", Link: "../drivers/edit"},
                {Name: "Search", Icon: "search", Link: "../drivers"}
            ],
            HasPermission: this.hasPermission(AuthRoles.ACCOUNTING)
        },
        {
            Name: "Auctions",
            Buttons: [
                {Name: "New", Icon: "add", Link: "../auctions/new"},
                {Name: "Edit", Icon: "edit", Link: "../auctions/edit"},
                {Name: "Search", Icon: "search", Link: "../auctions"}
            ],
            HasPermission: this.hasPermission(AuthRoles.ALL)
        }
    ]

    AuthRoles: AuthGroup[]

    constructor(
        private route: ActivatedRoute,
        private auths: AuthService
    ) {}

    ngOnInit(): void {
        this.route.data.subscribe(( data: {
            authRoles: AuthGroup[]
        }) => {
            this.AuthRoles = data.authRoles
        });
    }

    hasPermission( authRoles: AuthGroup[] ): boolean {
        log.Debug("in dashboard has Permission")
        return this.auths.HasPermission(authRoles);
    }

}
